<template>
    <div>
        <v-card flat>
            <v-card-title style="font: bold;" class="text-h4 text--primary">
                <!-- BOTON PARA IR A UNA VENTANA ANTERIOR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn large icon v-bind="attrs" v-on="on" color="primary" dark class="mx-2" @click="nuevaPoliza()" link :to="{ name: 'ListReportes' }"><v-icon>mdi-arrow-left</v-icon></v-btn>
                    </template>
                    <span>Atrás</span>
                </v-tooltip>
                Estado de Resultados
                <v-spacer></v-spacer>
                <!--Fecha Inicial-->
                <v-col cols="12" sm="3" md="3">
                <v-menu v-model="calendario" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="fechaI" label="Fecha Inicial" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="fechaI" @input="calendario = false, cambiaFechaFinal()"></v-date-picker>
                </v-menu>
                </v-col>
                <!--Fecha Final-->
                <v-col cols="12" sm="3" md="3">
                <v-menu v-model="calendario2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="fechaF" label="Fecha Final" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="fechaF" @input="calendario2 = false"></v-date-picker>
                </v-menu>
                </v-col>
                <!-- BOTON PARA CONSULTAR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="primary" dark class="mb-2" @click="dialog = true" v-bind="attrs" v-on="on"><v-icon>mdi-magnify</v-icon></v-btn>
                    </template>
                    <span>Buscar</span>
                </v-tooltip>
                <!-- DIALOG-->
                <template>
                    <v-row justify="center">
                      <v-dialog
                        v-model="dialog"
                        persistent
                        max-width="290"
                      >
                        <v-card>
                          <v-card-title class="text-h5">
                            ¿Agregar descuentos y bonificaciones sobre ventas?
                          </v-card-title>
                          <!-- <v-card-text>Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.</v-card-text> -->
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              text
                              @click="bonificaciones = false, dialogPolizaCierre = true"
                            >
                              NO
                            </v-btn>
                            <v-btn
                              color="primary"
                              text
                              @click="bonificaciones = true, dialogPolizaCierre = true"
                              >
                              SI
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-row>
                </template>
                <!-- DIALOG PARA TOMAR EN CUENTA POLIZA DE CIERRE -->
                <template>
                    <v-row justify="center">
                        <v-dialog
                        v-model="dialogPolizaCierre"
                        persistent
                        max-width="250">
                            <v-card>
                                <v-card-title class="text-h5">
                                    ¿Tomar en cuenta la poliza de cierre?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                        <v-btn
                                            color="primary"
                                            text
                                            @click="polizaCierre = false, GetEstadoResultados()"
                                            >
                                            NO
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            text
                                            @click="polizaCierre = true, GetEstadoResultados()"
                                            >
                                            SI
                                        </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-row>
                </template>
                <!-- EXPORTA EXCEL -->
                <xlsx-workbook>
                    <xlsx-sheet
                        :collection="collection"
                        v-for="sheet in sheets"
                        :key="sheet.name"
                        :sheet-name="sheet.name"
                    />
                    <xlsx-download>
                    </xlsx-download>
                    <xlsx-download :filename="nombreReporte">
                        <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2" @click="GeneraExcel()"><v-icon>mdi-file-excel-box-outline</v-icon></v-btn>
                            </template>
                            <span>Excel</span>
                        </v-tooltip>
                    </xlsx-download>
                </xlsx-workbook>

                <!-- BOTON PARA PDF -->
                <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-1" dark fab small @click="CreaPdf()"
                                v-bind="attrs" v-on="on">
                                <v-icon>mdi-file-pdf-box</v-icon>
                            </v-btn>
                        </template>
                        <span>Imprimir</span>
                </v-tooltip>

            </v-card-title>
            <v-card-text>
                <!-- INGRESOS -->
                <template v-if="itemsIngresos.length != 0">
                    <v-card flat>
                        <v-card-text>
                            <v-data-table :headers="headersER" :items="itemsIngresos" class="elevation-1" hide-default-footer :items-per-page="-1" hide-default-header>
                                
                                <template v-slot:item.importe="{ item }">
                                    <b style="font-size:18px" v-if="item.tipo === '='">{{ functionFormatPrice(item.importe) }}</b>
                                    <span v-else>{{ functionFormatPrice(item.importe) }}</span>
                                </template>

                                <template v-slot:item.nombre="{ item }">
                                    <b style="font-size:18px" v-if="item.tipo === '='">{{ item.tipo }}</b>
                                    <span v-else>{{ item.tipo }}</span>
                                </template>
                                <template v-slot:item.nombre="{ item }">
                                    <b style="font-size:18px" v-if="item.tipo === '='">{{ item.nombre }}</b>
                                    <span v-else>{{ item.nombre }}</span>
                                </template>

                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </template>             
            </v-card-text>
        </v-card>

        <!--Dialog loading-->
        <v-dialog v-model="loading" hide-overlay persistent width="300">
            <v-card color="primary" dark>
            <v-card-text>
                {{loadingMessage}}
                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import print from 'print-js';
    import pdf from 'vue-pdf';
    import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"
    import jsPDF from 'jspdf';

    export default {
        name: 'EstadoDeResultados',
        components: {
            XlsxWorkbook,
            XlsxSheet,
            XlsxDownload,
        },

        data: () => ({
            //LOADING
            loading: false,
            loadingMessage: '',

            //FECHAS
            fechaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            fechaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            calendario: false,
            calendario2: false,

            //DATATABLE ESTADO DE RESULTADOS
            headersER:[
                { text: "Tipo", value: "tipo" },
                { text: "Descripción", value: "nombre" },
                { text: "Importe", value: "importe", align: 'right' },
            ],
            itemsIngresos:[],
            itemsCostos:[],
            itemsGastos:[],
            itemsResultado:[],
            
            //LISTA PARA EXPORTAR
            listaExcel: [],
            sheets: [{ name: "SheetOne", data: [{ c: 2 }] }],
            collection: [{ a: 1, b: 2 }],
            nombreReporte: '',

            //DIALOG
            dialog: false,
            bonificaciones: false,

            // DIALOG PARA LA POLIZA DE CIERRE
            dialogPolizaCierre: false,
            polizaCierre: false,
        }),

        watch: {
            
        },

        computed: {
            empresa(){
                return this.$store.state.usuario
            },
        },

        created() {
            this.GetEmpresa();
        },
        methods: {
            async GetEmpresa() {
            console.log(this.empresa)
            try {
                let response = await axios.get('https://api-erp.contago.com.mx/api/Empresas/GetEmpresa/' + this.empresa.empresaBase);
                console.log('a',response.data)
                if (response.data.idEmpresa != 0) {
                    this.$store.state.empresaStore = response.data
                }
            } catch (error) {
                console.log(error);
            }
        },
            functionFormatPrice(value) {
                if(value != '-'){
                    let val = (value/1).toFixed(2)
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }else{
                    return '';
                }
            },

            functionBonificaciones(vlue){
                return value =  true
            },

            async GetEstadoResultadosDet(){
                this.itemsIngresos = []
                this.loadingMessage = 'Consultando...'
                this.loading = true;
                try {
                    let response = await axios.get('ReportesGenerales/GetEstaodDeResultadosAsync/'+this.empresa.empresaBase+'/' + this.fechaI + '/' + this.fechaF);
                    console.log(response.data);
                    let respuesta = response.data;

                    let ingresos = respuesta.filter( x => x.tipo === 'INGRESOS')
                    let costos = respuesta.filter( x => x.tipo === 'COSTOS')
                    let gastos = respuesta.filter( x => x.tipo === 'GASTOS')
                    let resultado = respuesta.filter( x => x.tipo === 'RESULTADO')
                    let participaciones = respuesta.filter( x => x.tipo === 'PARTICIPACIONES')
                    let Impuestos = respuesta.filter( x => x.tipo === 'IMPUESTOS')
                    let operaciones = respuesta.filter( x => x.tipo === 'OPERACIONES')

                    //RECORREMOS LAS LISTAS, PARA HACER EL REPORTE
                    var sumUtilidad = 0;
                    for(var i of ingresos){
                        var obj = { tipo: '+', nombre: i.nombre, importe:i.importe }
                        this.itemsIngresos.push(obj);
                        sumUtilidad = sumUtilidad + i.importe
                        obj = {}
                    }
                    for(var c of costos){
                        var obj = { tipo: '-', nombre: c.nombre, importe: c.importe }
                        this.itemsIngresos.push(obj);
                        sumUtilidad = sumUtilidad - c.importe
                        obj = {}
                    }
                    let utilidad = { tipo: '=', nombre: 'UTILIDAD BRUTA', importe: sumUtilidad }
                    this.itemsIngresos.push(utilidad);
                    let salto1 = { tipo: '', nombre: '', importe: '-' }
                    this.itemsIngresos.push(salto1);

                    var sumGastos = 0
                    for(var g of gastos){
                        var obj = { tipo: '-', nombre: g.nombre, importe: g.importe }
                        this.itemsIngresos.push(obj);
                        sumGastos = sumGastos + g.importe
                        obj = {}
                    }
                    let utilidadOperacion = { tipo: '=', nombre: 'UTILIDAD DE OPERACIÓN', importe: sumUtilidad - sumGastos}
                    this.itemsIngresos.push(utilidadOperacion);
                    this.itemsIngresos.push(salto1);

                    // RESULTADO INTEGRAL
                    for(var g of resultado){
                        var obj = { tipo: '-', nombre: g.nombre, importe: g.importe }
                        this.itemsIngresos.push(obj);
                        obj = {}
                    }
                    //PARTICIPACIONES
                    for(var g of participaciones){
                        var obj = { tipo: '+', nombre: g.nombre, importe: g.importe }
                        this.itemsIngresos.push(obj);
                        obj = {}
                    }

                    this.loading = false;
                } catch (error) {
                    console.log(error);
                    this.loading = false;
                }
            },
            async GetEstadoResultados(){
                this.itemsIngresos = []
                this.loadingMessage = 'Consultando...'
                this.loading = true;
                try {
                    let response = await axios.get('ReportesGenerales/GetEstaodDeResultadosAsync/'+this.empresa.empresaBase+'/' + this.fechaI + '/' + this.fechaF + '/' + this.bonificaciones + '/' + this.polizaCierre);
                    let respuesta = response.data;
                    console.log(response.data);

                    let ingresos = respuesta.filter( x => x.tipo === 'INGRESOS')
                    let costos = respuesta.filter( x => x.tipo === 'COSTOS')
                    let gastos = respuesta.filter( x => x.tipo === 'GASTOS')
                    let resultado = respuesta.filter( x => x.tipo === 'RESULTADO')
                    let participaciones = respuesta.filter( x => x.tipo === 'PARTICIPACIONES')
                    let Impuestos = respuesta.filter( x => x.tipo === 'IMPUESTOS')
                    let operaciones = respuesta.filter( x => x.tipo === 'OPERACIONES')

                    //INGRESOS
                    let salto1 = { tipo: '', nombre: '', importe: '-' }

                    let sumIngresos = ingresos.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                    let objIngresos = { tipo: '+', nombre: 'Ingresos netos', importe: sumIngresos }
                    this.itemsIngresos.push(objIngresos);

                    // COSTOS
                    let sumCosto = costos.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                    let objCosto = { tipo: '-', nombre: 'Costo de ventas', importe: sumCosto }
                    this.itemsIngresos.push(objCosto);

                    //UTILIDAD BRUTA
                    let objUtilidadBruta = { tipo: '=', nombre: 'UTILIDAD BRUTA', importe: sumIngresos - sumCosto }
                    this.itemsIngresos.push(objUtilidadBruta);
                    this.itemsIngresos.push(salto1);

                    //GASTOS GENERALES
                    let sumGastosGenerales = gastos.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                    let objGastosGenerales = { tipo: '-', nombre: 'Gastos generales', importe: sumGastosGenerales }
                    this.itemsIngresos.push(objGastosGenerales);

                    //UTILIDAD DE OPERACION
                    let objUtilidadDeOperacion = { tipo: '=', nombre: 'UTILIDAD DE OPERACIÓN', importe: objUtilidadBruta.importe - sumGastosGenerales }
                    this.itemsIngresos.push(objUtilidadDeOperacion);
                    this.itemsIngresos.push(salto1);

                    //RESULTADO INTEGRAL DE FINANCIAMIENTO
                    let sumResultadoIntegral = resultado.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                    let objResultadoIntegral = { tipo: '-', nombre: 'Resultado integral de financiamiento', importe: sumResultadoIntegral }
                    this.itemsIngresos.push(objResultadoIntegral);

                    //RESULTADO INTEGRAL DE FINANCIAMIENTO
                    let sumParticipaciones = participaciones.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                    let objParticipaciones = { tipo: '+', nombre: 'Participación en asociadas', importe: sumParticipaciones }
                    this.itemsIngresos.push(objParticipaciones);

                    //UTILIDAD ANTES DE IMPUESTOS A LA UTILIDAD
                    let objUtilidadImpuestos = { tipo: '=', nombre: 'UTILIDAD ANTES DE IMPUESTOS A LA UTILIDAD', importe: objUtilidadDeOperacion.importe - sumResultadoIntegral + sumParticipaciones }
                    this.itemsIngresos.push(objUtilidadImpuestos);
                    this.itemsIngresos.push(salto1);

                    //IMPUESTOS A LA UTILIDAD
                    let sumImpuestos = Impuestos.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                    let objImpuestos = { tipo: '-', nombre: 'Impuestos a la utilidad', importe: sumImpuestos }
                    this.itemsIngresos.push(objImpuestos);

                    //UTILIDAD DE OPERACIONES CONTINUAS
                    let objUtilidadOperaciones = { tipo: '=', nombre: 'UTILIDAD DE OPERACIONES CONTINUAS', importe: objUtilidadImpuestos.importe - sumImpuestos }
                    this.itemsIngresos.push(objUtilidadOperaciones);
                    this.itemsIngresos.push(salto1);

                    //OPERACIONES DISCONTINUADAS
                    let sumDiscontinuadas = operaciones.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                    let objDiscontinuadas = { tipo: '-', nombre: 'Operaciones discontinuadas', importe: sumDiscontinuadas }
                    this.itemsIngresos.push(objDiscontinuadas);

                    //UTILIDAD DE OPERACIONES CONTINUAS
                    let desc_ = 'UTILIDAD NETA'
                    if(objUtilidadOperaciones.importe - sumDiscontinuadas < 0){
                        desc_ = 'PÉRDIDA NETA'
                    }
                    let objUtilidadNeta = { tipo: '=', nombre: desc_, importe: objUtilidadOperaciones.importe - sumDiscontinuadas }
                    this.itemsIngresos.push(objUtilidadNeta);

                    this.loading = false;
                    this.dialogPolizaCierre = false
                    this.dialog = false
                } catch (error) {
                    console.log(error);
                    this.loading = false;
                    this.dialogPolizaCierre = false
                    this.dialog = false

                }
            },
            
            cambiaFechaFinal(){
                let fecha = this.fechaI.split('-');
                var primero = new Date(fecha[0],  fecha[1]-1, '01');
                var ultimoDia = new Date(primero.getFullYear(), primero.getMonth() + 1, 0);
                this.fechaF = format(parseISO(new Date(ultimoDia).toISOString()), 'yyyy-MM-dd')
            },
        
            GeneraExcel(){
                this.nombreReporte = 'Estado de resultados_' + this.empresa.rfc + '_' +  this.fechaI + ' al ' + this.fechaF + '.xlsx'
                this.collection = this.itemsIngresos;
            },

            CreaPdf(){
            //DATOS DE LA EMPRESA
            let xCabecera = 10;
            let yCabecera = 15;
            const doc = new jsPDF();

            if (this.$store.state.empresaStore.idEmpresa != 0){
                let base64logo = this.$store.state.empresaStore.logo.base64
                doc.addImage(base64logo, 'PNG', xCabecera, yCabecera, 65, 25);
            }
           
            var fecha = 'Del ' + this.fechaI + ' al ' + this.fechaF

            doc.setFontSize(14);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor("#FF0000");
            // doc.text('REPORTE', 195, yCabecera, { align: 'right' });
            doc.setTextColor("#000");

            doc.text('ESTADO DE RESULTADO', 195, yCabecera += 5, { align: 'right' });
            doc.setFontSize(12);
            doc.setFont('helvetica', 'normal');
            doc.text(this.empresa.empresa, 195, yCabecera += 5, { align: 'right' });
            doc.text(this.empresa.rfc, 195, yCabecera += 5, { align: 'right' });
            doc.text(fecha, 195, yCabecera += 5, { align: 'right' });

                // Convertir la lista en un formato compatible con autotable
                var tableEstadoResultados = this.itemsIngresos.map(item => [item.tipo, item.nombre, this.functionFormatPrice(item.importe)]);

                // Agregar la tabla con autotable
                doc.autoTable({
                head: [['Tipo', 'Nombre', 'Importe']],
                body: tableEstadoResultados,
                startY: yCabecera +=5, // Posición de inicio de la tabla
                headStyles: {
                        fillColor: [102,46,145] // Color de fondo en RGB (rojo, verde, azul)
                },
                });

                // // Guardar o mostrar el PDF
                doc.save('Estado_de_Resultados' + this.empresa.rfc + '_del_' + this.fechaI + '_al_' + this.fechaF + '.pdf');

            },
        }
    }
</script>