var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"text-h4 text--primary",staticStyle:{"font":"bold"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"large":"","icon":"","color":"primary","dark":"","link":"","to":{ name: 'ListReportesEspeciales' }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)]}}])},[_c('span',[_vm._v("Atrás")])]),_vm._v(" Depreciación "),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de Aplicacion del Ejercicio","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fechaI),callback:function ($$v) {_vm.fechaI=$$v},expression:"fechaI"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.calendario),callback:function ($$v) {_vm.calendario=$$v},expression:"calendario"}},[_c('v-date-picker',{on:{"input":function($event){_vm.calendario = false}},model:{value:(_vm.fechaI),callback:function ($$v) {_vm.fechaI=$$v},expression:"fechaI"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha Depreciación","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fechaDep),callback:function ($$v) {_vm.fechaDep=$$v},expression:"fechaDep"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.calendarioDep),callback:function ($$v) {_vm.calendarioDep=$$v},expression:"calendarioDep"}},[_c('v-date-picker',{on:{"input":function($event){_vm.calendarioDep = false}},model:{value:(_vm.fechaDep),callback:function ($$v) {_vm.fechaDep=$$v},expression:"fechaDep"}})],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"label":"Activo Fijo","items":_vm.itemsActivoFijo,"item-text":"activo_fijo","return-object":""},model:{value:(_vm.selectActivoFijo),callback:function ($$v) {_vm.selectActivoFijo=$$v},expression:"selectActivoFijo"}}),_c('span',[_vm._v(_vm._s(_vm.selectActivoFijo.activo_fijo))])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"label":"Activo Fijo","items":_vm.itemsTipoAcivoFijo,"item-text":"tipo_activo_fijo","return-object":""},model:{value:(_vm.selectTipoActivoFijo),callback:function ($$v) {_vm.selectTipoActivoFijo=$$v},expression:"selectTipoActivoFijo"}}),_c('span',[_vm._v(_vm._s(_vm.selectTipoActivoFijo.tipo_activo_fijo))])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Descripción Activo Fijo"},model:{value:(_vm.descripcion),callback:function ($$v) {_vm.descripcion=$$v},expression:"descripcion"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de Adquisición","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fechaAdqui),callback:function ($$v) {_vm.fechaAdqui=$$v},expression:"fechaAdqui"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.calendarioAdqui),callback:function ($$v) {_vm.calendarioAdqui=$$v},expression:"calendarioAdqui"}},[_c('v-date-picker',{on:{"input":function($event){_vm.calendarioAdqui = false}},model:{value:(_vm.fechaAdqui),callback:function ($$v) {_vm.fechaAdqui=$$v},expression:"fechaAdqui"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-text-field',{attrs:{"label":"Valor de la Adquisición","type":"number"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.pushActivo.apply(null, arguments)}},model:{value:(_vm.valorAdqui),callback:function ($$v) {_vm.valorAdqui=$$v},expression:"valorAdqui"}}),_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(_vm.valorAdqui)))])],1),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"icon":"","color":"primary","dark":""},on:{"click":function($event){return _vm.pushActivo()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-tag-plus")])],1)]}}])},[_c('span',[_vm._v("Agrega Activo")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"icon":"","color":"primary","dark":""},on:{"click":function($event){return _vm.calculaDep()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calculator")])],1)]}}])},[_c('span',[_vm._v("Calcular")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"icon":"","color":"primary","dark":""},on:{"click":function($event){return _vm.GuardarDepreciacion()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save-outline")])],1)]}}])},[_c('span',[_vm._v("Guardar")])])],1),_c('br'),_c('h2',[_vm._v("Reporte depreciación")]),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Filtrar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"items":_vm.listActivo,"headers":_vm.headersActivo,"search":_vm.search},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"#e74747"},on:{"click":function($event){return _vm.eliminarContacto(item)}}},[_vm._v("mdi-delete")])]}},{key:"item.inversion",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.inversion)))])]}},{key:"item.tipoActivoFijo.porcentaje",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.tipoActivoFijo.porcentaje))+"%")])]}},{key:"item.depreciacionAnual",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.depreciacionAnual)))])]}},{key:"item.depreciacionMensual",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.depreciacionMensual)))])]}},{key:"item.depreciacionEjercicio",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.depreciacionEjercicio)))])]}},{key:"item.depreciacionAcumulada",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.depreciacionAcumulada)))])]}},{key:"item.pendientesPorDepreciar",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.pendientesPorDepreciar)))])]}},{key:"item.inpMesAdquisicion",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.inpMesAdquisicion)))])]}},{key:"item.inpcUltimoMesPrimeraMitad",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.inpcUltimoMesPrimeraMitad)))])]}},{key:"item.factorActualizacion",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.factorActualizacion)))])]}},{key:"item.depreciacionFiscalEjercicio",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.depreciacionFiscalEjercicio)))])]}}])})],1)],1)],1),_c('v-card-actions')],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.loadingMessage)+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }