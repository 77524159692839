<template>
    <div>
        <!-- DIV PARA LA TABLA DE BALANZA DE PDF -->
        <div hidden>
            <table  id="example" class="display styled-table nowrap" style="width:100%"> 
            <Caption>
                <v-row>
                <v-col  cols="12" md="8" sm="8">
                    <div class="text-left">
                    <h3 style="text-align: left;">Balanza de Comprobación</h3>
                    <h5 style="text-align: left;">{{ this.empresa.empresa }}</h5>
                    <h5 style="text-align: left;">{{ this.empresa.rfc }}</h5>
                    <h5 style="text-align: left;">PERIODO: {{  this.tituloPDFBalanza  }} </h5>
                    </div>
                </v-col>
                <v-col cols="12" md="4" sm="4">
                    <div class="text-right mt-4 mr-5">
                        <template v-if="LogoEmpresa.idEmpresa == 0">
                            <img src="../../assets/autoconta chido.jpg"
                            height="80">
                        </template>
                        <template  v-if="LogoEmpresa.idEmpresa != 0">
                            <img :src="LogoEmpresa.logo.base64"
                            height="80">
                        </template>
                    </div>
                </v-col>
            </v-row>
            </Caption>
            <thead> 
                <tr> 
                    <th>No. de Cuenta</th> 
                    <th>Nombre de la cuenta</th> 
                    <th>Debe</th>
                    <th>Haber</th>
                    <th>Cargos</th>
                    <th>Abonos</th>
                    <th>Debe</th>
                    <th>Haber</th>
                </tr> 
            </thead> 
            <tbody > 
                <tr v-for="value in this.items"> 
                    <th style="text-align:left">{{value.numeroCuenta}}</th> 
                    <th style="text-align:left">{{value.nombreCuenta}}</th> 
                    <th style="text-align:right">{{functionFormatPrice(value.saldoInicialDebe)}}</th> 
                    <th style="text-align:right">{{functionFormatPrice(value.saldoInicialHaber)}}</th> 
                    <th style="text-align:right">{{functionFormatPrice(value.cargo)}}</th> 
                    <th style="text-align:right">{{functionFormatPrice(value.abono)}}</th> 
                    <th style="text-align:right">{{functionFormatPrice(value.saldoFinalDebe)}}</th> 
                    <th style="text-align:right">{{functionFormatPrice(value.saldoFinalHaber)}}</th> 
                </tr> 
                <tr> 
                    <th>SUMAS:</th> 
                    <th></th> 
                    <th>{{functionFormatPrice(sumCargoInicial)}}</th> 
                    <th>{{functionFormatPrice(sumAbonoInicial)}}</th> 
                    <th>{{functionFormatPrice(sumCargoMes)}}</th> 
                    <th>{{functionFormatPrice(sumAbonoMes)}}</th> 
                    <th>{{functionFormatPrice(sumCargoFinal)}}</th> 
                    <th>{{functionFormatPrice(sumAbonoFinal)}}</th> 
                </tr> 
            </tbody>
            </table>
        </div>

        <v-card>
            <!-- TITULOS DE LA TABLA -->
            <v-card-title style="font: bold;" class="text-h4 text--primary">
                <!-- BOTON PARA IR A UNA VENTANA ANTERIOR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn large icon v-bind="attrs" v-on="on" color="primary" dark class="mx-2" @click="nuevaPoliza()" link :to="{ name: 'ListReportes' }"><v-icon>mdi-arrow-left</v-icon></v-btn>
                    </template>
                    <span>Atrás</span>
                </v-tooltip>
                Balanza
                <v-spacer></v-spacer>
                <!--Fecha Inicial-->
                <v-col cols="12" sm="2" md="2">
                <v-menu v-model="calendario" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="fechaI" label="Fecha Inicial" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="fechaI" @input="calendario = false, cambiaFechaFinal()"></v-date-picker>
                </v-menu>
                </v-col>
                <!-- Fecha Final -->
                <v-col cols="12" sm="2" md="2">
                <v-menu v-model="calendario2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="fechaF" label="Fecha Final" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="fechaF" @input="calendario2 = false"></v-date-picker>
                </v-menu>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                    <v-select :items="itemsNivel" v-model="nivel" label="Nivel" return-object item-text="text"></v-select>
                </v-col>
                <!-- BOTON PARA CONSULTAR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2" @click="GetBalanza()"><v-icon>mdi-magnify</v-icon></v-btn>
                    </template>
                    <span>Buscar</span>
                </v-tooltip>
                <!-- EXPORTA EXCEL -->
                <xlsx-workbook>
                    <xlsx-sheet
                        :collection="collection"
                        v-for="sheet in sheets"
                        :key="sheet.name"
                        :sheet-name="sheet.name"
                    />
                    <xlsx-download>
                    </xlsx-download>
                    <xlsx-download :filename="nombreReporte">
                        <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2" @click="GeneraExcel()"><v-icon>mdi-file-excel-box-outline</v-icon></v-btn>
                            </template>
                            <span>Excel</span>
                        </v-tooltip>
                    </xlsx-download>
                </xlsx-workbook>
                <!-- EXPORTA A XML -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2" @click="abreDialogExportar()"><v-icon>mdi-xml</v-icon></v-btn>
                    </template>
                    <span>Generar XML</span>
                </v-tooltip>
                <!-- BOTON PARA IMPRIMIR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2" @click="exportToPDF()"><v-icon>mdi-printer</v-icon></v-btn>
                    </template>
                    <span>Imprimir Balanza</span>
                </v-tooltip>
            </v-card-title>

            <!-- CUERPO DE LA TABLA -->
            <v-card-text>
                <div>
                <v-data-table :headers="headersBalanza" :items="items" class="elevation-0" hide-default-footer fixed-header height="600px" :items-per-page="-1" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
                        </v-toolbar>
                    </template>
                    <!-- BOTON DE ACCION -->
                    <template v-slot:item.actions="{ item }">
                        <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon dense class="mr-2" @click="GetAuxiliar(item)" color="orange" v-bind="attrs" v-on="on">mdi-book-open-page-variant</v-icon>
                            </template>
                            <span>Auxiliar</span>
                        </v-tooltip>
                        <template v-if="item.tipo === 'R'">
                            <v-tooltip bottom color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon dense class="mr-2" @click="abrePutCuenta(item)" color="green" v-bind="attrs" v-on="on">mdi-clipboard-flow</v-icon>
                                </template>
                                <span>Re-asignar cuenta</span>
                            </v-tooltip>
                        </template>
                    </template>
                    <!-- FORMATO CON DECIMALES -->
                    <template v-slot:item.saldoInicialDebe="{ item }">
                        <span>{{ functionFormatPrice(item.saldoInicialDebe) }}</span>
                    </template>
                    <template v-slot:item.saldoInicialHaber="{ item }">
                        <span>{{ functionFormatPrice(item.saldoInicialHaber) }}</span>
                    </template>
                    <template v-slot:item.cargo="{ item }">
                        <span>{{ functionFormatPrice(item.cargo) }}</span>
                    </template>
                    <template v-slot:item.abono="{ item }">
                        <span>{{ functionFormatPrice(item.abono) }}</span>
                    </template>
                    <template v-slot:item.saldoFinalDebe="{ item }">
                        <span>{{ functionFormatPrice(item.saldoFinalDebe) }}</span>
                    </template>
                    <template v-slot:item.saldoFinalHaber="{ item }">
                        <span>{{ functionFormatPrice(item.saldoFinalHaber) }}</span>
                    </template>
                </v-data-table>
            </div>
                <br>
                <br>
                <br>
            </v-card-text>
            
            <!-- CAMPO CON LOS TOTALES -->
            <v-footer padless fixed>
                <v-card flat tile width="100%" class="text-center">
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="2" md="2">
                            <h2>{{ functionFormatPrice(sumCargoInicial) }}</h2>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                            <h2>{{ functionFormatPrice(sumAbonoInicial) }}</h2>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                            <h2>{{ functionFormatPrice(sumCargoMes) }}</h2>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                            <h2>{{ functionFormatPrice(sumAbonoMes) }}</h2>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                            <h2>{{ functionFormatPrice(sumCargoFinal) }}</h2>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                            <h2>{{ functionFormatPrice(sumAbonoFinal) }}</h2>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            </v-footer>
        </v-card>

        <!--Dialog loading-->
        <v-dialog v-model="loading" hide-overlay persistent width="300">
            <v-card color="primary" dark>
            <v-card-text>
                {{loadingMessage}}
                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
            </v-card>
        </v-dialog>

        <!-- DIALOG DEL AUXILIAR -->
        <v-dialog v-model="dialogAuxiliar" persistent>
            <v-card>
                <v-toolbar>
                    <!-- BOTON CERRAR -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-1" dark fab small @click="dialogAuxiliar = false"
                                v-bind="attrs" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <b style="font-size: 30px;">Auxiliar {{ objectAuxiliar.cuenta }}</b>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary darken-1"  dark fab small v-bind="attrs" v-on="on"
                                    @click="generarPDF()">
                                    <v-icon>mdi-file-pdf-box</v-icon>
                                </v-btn>
                    </template>
                    <span>PDF</span>
                </v-tooltip>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-card-text>
                    <v-row>
                        <v-col cols="3">
                            <v-text-field label="Saldo inicial" v-model="objectAuxiliar.saldoInicial"></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field label="Cargos" v-model="objectAuxiliar.cargos"></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field label="Abonos" v-model="objectAuxiliar.abonos"></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field label="SaldoFinal" v-model="objectAuxiliar.saldoFinal"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-data-table :items="itemsAuxiliar" :headers="headersAuxiliar">
                                <template v-slot:item.cargo="{ item }">
                                    <span>{{ functionFormatPrice(item.cargo) }}</span>
                                </template>
                                <template v-slot:item.abono="{ item }">
                                    <span>{{ functionFormatPrice(item.abono) }}</span>
                                </template>
                                <template v-slot:item.fechaPoliza="{ item }">
                                    <span>{{ functionFormatDate(item.fechaPoliza) }}</span>
                                </template>
                                <template v-slot:item.actions="{ item }">
                                    <v-tooltip bottom color="primary">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon dense class="mr-2" @click="GetPolizasId(item)" color="red" v-bind="attrs" v-on="on">mdi-file-pdf-box</v-icon>
                                        </template>
                                        <span>Ver póliza</span>
                                    </v-tooltip>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- DIALOG REASIGNA CUENTA -->
        <v-dialog v-model="dialogPutCuenta" persistent width="1000">
            <v-card>
                <v-toolbar>
                    <!-- BOTON CERRAR -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-1" dark fab small @click="dialogPutCuenta = false"
                                v-bind="attrs" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <b style="font-size: 30px;">Reasignar Cuenta {{cuentaEditar.numeroCuenta}} | {{cuentaEditar.nombreCuenta}}</b>
                    <v-spacer></v-spacer>
                    <!-- BOTON PARA CONFIRMAR -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-1" dark fab small @click="putCuenta()"
                                v-bind="attrs" v-on="on">
                                <v-icon>mdi-check</v-icon>
                            </v-btn>
                        </template>
                        <span>Confirmar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="1" md="12">
                            <v-select label="Cuenta Destino" return-object :items="itemsCuentaReemplazo" v-model="selectCuentaReemplazo" item-text="cuentaContable"></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

         <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
         <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>

        <!-- DIALOG QUE MUESTRA LA POLIZA -->
        <v-dialog v-model="dialogPdf">
            <v-card>
                <v-toolbar>
                    <v-btn fab dark small @click="dialogPdf=false" color="primary darken-1">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>Vista Previa</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn fab dark small color="primary darken-1" class="mx-2" @click="$refs.pdf.print()">
                        <v-icon>mdi-printer</v-icon>
                    </v-btn>
                    <v-btn fab dark small color="primary darken-1" class="mx-2" @click="descargaPdf()">
                        <v-icon>mdi-download</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-card-text>
                    <template>
                        <div class="text-center">
                            <v-icon color="primary" @click="page--">mdi-minus</v-icon>
                            {{page}} /{{numPages}}
                            <v-icon color="primary" @click="page++">mdi-plus</v-icon>
                            <div style="width: 100%">
                                <div v-if="loadedRatio > 0 && loadedRatio < 1"
                                    style="background-color: green; color: white; text-align: center"
                                    :style="{ width: loadedRatio * 100 + '%' }">{{ Math.floor(loadedRatio * 100) }}%
                                </div>
                                <pdf ref="pdf" style="border: 1px solid red" :src="srcPdf" :page="page" :rotate="rotate"
                                    @progress="loadedRatio = $event" @num-pages="numPages = $event"
                                    @link-clicked="page = $event"></pdf>
                            </div>
                        </div>
                    </template>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- DIALOG EXPORTA CATALOGO EN XML -->
        <v-dialog v-model="dialogExporta" persistent width="600" transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar>
                    <!-- BOTON CERRAR -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-1" dark fab small @click="dialogExporta = false"
                                v-bind="attrs" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <b style="font-size: 30px;">Generar XML</b>
                    <v-spacer></v-spacer>
                    <!-- BOTON CONFIRMAR -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-1" dark fab small @click="PostCatalogoXml()"
                                v-bind="attrs" v-on="on">
                                <v-icon>mdi-export-variant</v-icon>
                            </v-btn>
                        </template>
                        <span>Exportar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-card-text>
                    <br>
                    <v-row justify="space-around">
                        <v-date-picker
                        v-model="picker"
                        type="month"
                        color="primary lighten-1"
                        header-color="primary"
                        ></v-date-picker>
                    </v-row>
                    <v-select label="Tipo de Envío" :items="tipoEnvio" v-model="selectTipoEnvio"></v-select>
                    <template v-if="selectTipoEnvio === 'C | Complementaria'">
                        <v-menu v-model="calendarioExporta" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                            offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="fechaExporta" label="Fecha Última Modificación" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="fechaExporta" @input="calendarioExporta = false, cambiaFechaFinal()"></v-date-picker>
                        </v-menu>
                    </template>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- DIALOG QUE MUESTRA LA BALANZA -->
        <v-dialog v-model="dialogPdfBalanza">
            <v-card>
                <v-toolbar>
                    <v-btn fab dark small @click="dialogPdfBalanza=false" color="primary darken-1">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>Vista Previa</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn fab dark small color="primary darken-1" class="mx-2" @click="$refs.pdf.print()">
                        <v-icon>mdi-printer</v-icon>
                    </v-btn>
                    <!-- <v-btn fab dark small color="primary darken-1" class="mx-2" @click="descargaPdf()">
                        <v-icon>mdi-download</v-icon>
                    </v-btn> -->
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-card-text>
                    <template>
                        <div class="text-center">
                            <v-icon color="primary" @click="page--">mdi-minus</v-icon>
                            {{page}} /{{numPages}}
                            <v-icon color="primary" @click="page++">mdi-plus</v-icon>
                            <div style="width: 100%">
                                <div v-if="loadedRatio > 0 && loadedRatio < 1"
                                    style="background-color: green; color: white; text-align: center"
                                    :style="{ width: loadedRatio * 100 + '%' }">{{ Math.floor(loadedRatio * 100) }}%
                                </div>
                                <pdf ref="pdf" style="border: 1px solid red" :src="srcPdf" :page="page" :rotate="rotate"
                                    @progress="loadedRatio = $event" @num-pages="numPages = $event"
                                    @link-clicked="page = $event"></pdf>
                            </div>
                        </div>
                    </template>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import print from 'print-js';
    import pdf from 'vue-pdf';
    import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"
    import html2pdf from "html2pdf.js";
    import jsPDF from 'jspdf';

    export default {
        name: 'Balanza',
        components: {
            XlsxWorkbook,
            XlsxSheet,
            XlsxDownload,
            pdf: pdf,
        },

        data: () => ({
            tituloPDFBalanza:'',
            items: [],
            headersBalanza:[
                { text: 'Acciones', value: 'actions' },
                { text: "No. Cuenta", value: "numeroCuenta" },
                { text: "Nombre Cuenta", value: "nombreCuenta" },
                { text: "Debe", value: "saldoInicialDebe", align: 'right' },
                { text: "Haber", value: "saldoInicialHaber", align: 'right' },
                { text: "Cargos", value: "cargo", align: 'right' },
                { text: "Abonos", value: "abono", align: 'right' },
                { text: "Debe", value: "saldoFinalDebe", align: 'right' },
                { text: "Haber", value: "saldoFinalHaber", align: 'right' },
            ],
            search:'',

            //LOADING
            loadingMessage: '',
            loading: false,

            //FECHAS
            fechaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            fechaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            calendario: false,
            calendario2: false,
            itemsNivel:[
                {text: 'Todos', value: 99},
                {text: '1', value: 1},
                {text: '2', value: 2},
                {text: '3', value: 3}
            ],
            nivel: {text: 'Todos', value: 99},

            //AUXILIAR
            dialogAuxiliar: false,
            headersAuxiliar: [
                { text: 'Acciones', value: 'actions' },
                { text: "Tipo", value: "tipoPoliza" },
                { text: "Número", value: "numeroPoliza" },
                { text: "Fecha", value: "fechaPoliza" },
                { text: "Descripción", value: "descripcion" },
                { text: "Cargo", value: "cargo", align: 'right' },
                { text: "Abono", value: "abono", align: 'right' },
            ],
            itemsAuxiliar: [],
            objectAuxiliar:{
                cuenta: '',
                saldoInicial:0,
                cargos:0,
                abonos:0,
                saldoFinal:0,
            },

            //REASIGNAR CUENTA
            dialogPutCuenta: false,

            //SNACK BAR
            snackbarMessage: '',
            snackbar: false,
            cuentaEditar: {},
            itemsCuentaReemplazo: [],
            selectCuentaReemplazo: {},

            //PDF
            dialogPdf: false,
            srcPdf: '',
            loadedRatio: 0,
            page: 1,
            numPages: 0,
            rotate: 0,
            nombreArchivo: '',
            scrXml: '',

            //LISTA PARA EXPORTAR
            listaExcel: [],
            sheets: [{ name: "SheetOne", data: [{ c: 2 }] }],
            collection: [{ a: 1, b: 2 }],
            nombreReporte: '',

            //EXPORTA
            dialogExporta:false,
            picker: new Date().toISOString().substr(0, 7),
            tipoEnvio:['N | Normal','C | Complementaria'],
            selectTipoEnvio: 'N | Normal',
            calendarioExporta:false,
            fechaExporta: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),

            // PDF BALANZA
            dialogPdfBalanza: false
        }),

        watch: {
            
        },

        computed: {
            LogoEmpresa() {
            return this.$store.state.empresaStore;
        },
            empresa(){
                return this.$store.state.usuario
            },
            sumCargoInicial(){
                let array = this.items.filter( x => x.nivel === 1)
                return array.map(item => item.saldoInicialDebe).reduce((prev, curr) => prev + curr, 0);
            },
            sumAbonoInicial(){
                let array = this.items.filter( x => x.nivel === 1)
                return array.map(item => item.saldoInicialHaber).reduce((prev, curr) => prev + curr, 0);
            },
            sumCargoMes(){
                let array = this.items.filter( x => x.nivel === 1)
                return array.map(item => item.cargo).reduce((prev, curr) => prev + curr, 0);
            },
            sumAbonoMes(){
                let array = this.items.filter( x => x.nivel === 1)
                return array.map(item => item.abono).reduce((prev, curr) => prev + curr, 0);
            },
            sumCargoFinal(){
                let array = this.items.filter( x => x.nivel === 1)
                return array.map(item => item.saldoFinalDebe).reduce((prev, curr) => prev + curr, 0);
            },
            sumAbonoFinal(){
                let array = this.items.filter( x => x.nivel === 1)
                return array.map(item => item.saldoFinalHaber).reduce((prev, curr) => prev + curr, 0);
            },
        },

        created() {
            this.GetEmpresa();
        },

        methods: {
            functionFormatPrice(value) {
                let val = (value / 1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            functionFormatDate(value){
                let fecha = moment(value).format('DD/MM/yyyy')
                return fecha;
            },
            async GetBalanza(){
                console.time();
                this.loadingMessage = 'Consultando...'
                this.loading = true;
                try {
                    let response = await axios.get('ReportesGenerales/GetBalanza/'+this.empresa.empresaBase+'/' + this.fechaI + '/' + this.fechaF + '/' + this.nivel.value);
                    this.items = response.data;
                    this.loading = false;
                    // console.log(this.items)
                    console.timeEnd();
                } catch (error) {
                    this.items = [];
                    console.log(error);
                    this.loading = false;
                    console.timeEnd();
                }
            },
            async GetAuxiliar(item){
                this.loadingMessage = 'Consultando movimientos de la cuenta...'
                this.loading = true;
                try {
                    let response = await axios.get('ReportesGenerales/GetAuxiliar/'+this.empresa.empresaBase+'/' + item.numeroCuentaUnico + '/' + this.fechaI + '/' + this.fechaF);
                    this.itemsAuxiliar = response.data;
                    let sumCargo =  this.itemsAuxiliar.map(item => item.cargo).reduce((prev, curr) => prev + curr, 0);
                    let sumAbono = this.itemsAuxiliar.map(item => item.abono).reduce((prev, curr) => prev + curr, 0);

                    this.objectAuxiliar.cuenta = item.numeroCuenta + ' | ' + item.nombreCuenta;
                    this.objectAuxiliar.saldoInicial = this.functionFormatPrice(item.saldoInicialDebe - item.saldoInicialHaber);
                    this.objectAuxiliar.cargos = this.functionFormatPrice(sumCargo)
                    this.objectAuxiliar.abonos =  this.functionFormatPrice(sumAbono)
                    this.objectAuxiliar.saldoFinal = this.functionFormatPrice(item.saldoFinalDebe + item.saldoFinalHaber);

                    this.loading = false;
                    this.dialogAuxiliar = true;
                } catch (error) {
                    console.log(error);
                    this.loading = false;
                }
            },
            async abrePutCuenta(item){
                this.snackbar = false;
                this.dialogPutCuenta = true;
                this.cuentaEditar = item
                // console.log(item)

                this.loadingMessage = 'Consultando catálogo...'
                this.loading = true;
                try {
                    let response = await axios.get('ReportesGenerales/GetCatalogo/'+ this.empresa.empresaBase);
                    this.itemsCuentaReemplazo = response.data;
                    this.loading = false;
                } catch (error) {   
                    console.log(error);
                    this.loading = false;
                }
            },
            async putCuenta(){
                this.snackbar = false;
                if(Object.keys(this.selectCuentaReemplazo).length === 0){
                    this.snackbar = true;
                    this.snackbarMessage = 'Seleccione una cuenta...'
                }

                this.loadingMessage = 'Cambiando cuenta...'
                this.loading = true;
                try {
                    let response = await axios.get('AutoConta/ActualizaCatalogoConfig/'+ this.empresa.empresaBase + '/' + '/' + this.selectCuentaReemplazo.idCuenta + '/' + this.cuentaEditar.id );
                    this.loading = false;
                } catch (error) {   
                    console.log(error);
                    this.loading = false;
                }

            },
                        
            //CONSULTAMOS LA POLIZA DEL AUXILIAR
            async GetPolizasId(item){
                this.loadingMessage = 'Consultando póliza...'
                this.loading = true;
                try {
                    let response = await axios.get('GetPolizas/GetPolizasIdAsync/'+this.empresa.empresaBase+'/' + item.idPoliza);
                    // console.log(response.data)
                    let pdf = await this.creaPdfPolizas(response.data);
                } catch (error) {
                    console.log(error);
                    this.loading = false;
                }
            },

            async creaPdfPolizas(item){
                try {
                    let response = await axios.post('https://api-framework.contago.com.mx/api/pdfs/PdfPoliza/', item);
                    let pdf = 'data:application/pdf;base64,' + response.data.pdf_
                    this.srcPdf = pdf
                    
                    this.loading = false;
                    this.dialogAuxiliar = true;
                    this.dialogPdf = true;
                    let mes = this.getLongMonthName(new Date(item.fecha))
                    let año = item.fecha.substring(0,4)
                    this.nombreArchivo = item.tipoPoliza + '_' + item.numeroPoliza + '_' + mes + '_' + año
                    console.log(this.nombreArchivo)
                } catch (error) {
                    console.log(error);
                    this.loading = false;
                }
            }, 
  
            descargaPdf(){
                var pdf = this.srcPdf.split(',')
                this.download(pdf[1], this.nombreArchivo, 'pdf');
            },

            download(archivo, nombre, tipo) {
                var arrBuffer = this.base64ToArrayBuffer(archivo);
                var newBlob = new Blob([arrBuffer]);

                var data = window.URL.createObjectURL(newBlob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = data;
                link.download = nombre + '.' + tipo;
                link.click();
                window.URL.revokeObjectURL(data);
                link.remove();
            },

            base64ToArrayBuffer(base64) {
                var binary_string = window.atob(base64);
                var len = binary_string.length;
                var bytes = new Uint8Array(len);
                for (var i = 0; i < len; i++) {
                bytes[i] = binary_string.charCodeAt(i);
                }
                return bytes.buffer;
            },
        
            getLongMonthName(date) {
                const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
                // console.log('mes', monthNames[date.getMonth()])
                console.log('mes', monthNames[new Date(date).getMonth()])
                return monthNames[new Date(date).getMonth()];
            },

            cambiaFechaFinal(){
                let fecha = this.fechaI.split('-');
                var primero = new Date(fecha[0],  fecha[1]-1, '01');
                var ultimoDia = new Date(primero.getFullYear(), primero.getMonth() + 1, 0);
                this.fechaF = format(parseISO(new Date(ultimoDia).toISOString()), 'yyyy-MM-dd')
            },

            GeneraExcel(){
                // console.log(this.empresa)
                this.nombreReporte = 'Balanza_' + this.empresa.rfc + '_' +  this.fechaI + ' al ' + this.fechaF + '.xlsx'
                let listaExcel = [];
                for(let g of this.items){
                    let ObjD = { 
                        Numero_cuenta: g.numeroCuenta,
                        Nombre_Cuenta: g.nombreCuenta,
                        Saldo_Inicial_Debe: g.saldoInicialDebe,
                        Saldo_Inicial_Haber: g.saldoInicialHaber,
                        Cargo: g.cargo,
                        Abono: g.abono,
                        Saldo_Final_Debe: g.saldoFinalDebe,
                        Saldo_Final_Haber: g.saldoFinalHaber,
                    }
                    listaExcel.push(ObjD);
                    ObjD = Object.assign( {} );
                }
 
                let objT = {};
                listaExcel.push(objT);

                let ObjTT = { 
                    Numero_cuenta: '',
                    Nombre_Cuenta: 'SUMAS IGUALES',
                    Saldo_Inicial_Debe: this.sumCargoInicial,
                    Saldo_Inicial_Haber: this.sumAbonoInicial,
                    Cargo: this.sumCargoMes,
                    Abono: this.sumAbonoMes,
                    Saldo_Final_Debe: this.sumCargoFinal,
                    Saldo_Final_Haber: this.sumAbonoFinal,
                }
                listaExcel.push(ObjTT);

                this.listaExcel = listaExcel;
                this.collection = listaExcel;
                // console.log(this.listaExcel)
            },
            
            abreDialogExportar(){
                this.dialogExporta=true;
            },
            async PostCatalogoXml(){
                //GENERAMOS LA BALANZA
                console.time();
                let fecha = this.picker.split('-');
                var primero = new Date(fecha[0],  fecha[1]-1, '01');

                var primerDia = new Date(primero.getFullYear(), primero.getMonth(), 1);
                var ultimoDia = new Date(primero.getFullYear(), primero.getMonth() + 1, 0);

                var mesI = format(parseISO(new Date(primerDia).toISOString()), 'yyyy-MM-dd')
                var mesF = format(parseISO(new Date(ultimoDia).toISOString()), 'yyyy-MM-dd')
                var balanza = []

                this.loadingMessage = 'Consultando...'
                this.loading = true;
                try {
                    let response = await axios.get('ReportesGenerales/GetBalanza/'+this.empresa.empresaBase+'/' + mesI + '/' + mesF + '/99');
                    balanza = response.data;
                    this.loading = false;
                    // console.log(this.items)
                    console.timeEnd();
                } catch (error) {
                    balanza = [];
                    console.log(error);
                    this.loading = false;
                    console.timeEnd();
                }

                console.log(balanza.length)
                if(balanza.length == 0){
                    return;
                }

                //GENERAMOS EL XML
                let anio = fecha[0]
                let mes = fecha[1]
                let rfc = this.empresa.rfc
                var cabecera = '<BCE:Balanza xsi:schemaLocation="http://www.sat.gob.mx/esquemas/ContabilidadE/1_3/BalanzaComprobacion http://www.sat.gob.mx/esquemas/ContabilidadE/1_3/BalanzaComprobacion/BalanzaComprobacion_1_3.xsd" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:BCE="http://www.sat.gob.mx/esquemas/ContabilidadE/1_3/BalanzaComprobacion" TipoEnvio="'+this.selectTipoEnvio.substring(0,1)+'" Anio="'+ anio +'" Mes="'+ mes +'" RFC="'+ rfc +'" Version="1.3">'
                if(this.selectTipoEnvio === 'C | Complementaria'){
                    let fechaM = this.fechaExporta
                    cabecera = '<BCE:Balanza xsi:schemaLocation="http://www.sat.gob.mx/esquemas/ContabilidadE/1_3/BalanzaComprobacion http://www.sat.gob.mx/esquemas/ContabilidadE/1_3/BalanzaComprobacion/BalanzaComprobacion_1_3.xsd" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:BCE="http://www.sat.gob.mx/esquemas/ContabilidadE/1_3/BalanzaComprobacion" FechaModBal="'+ fechaM +'" TipoEnvio="'+this.selectTipoEnvio.substring(0,1)+'" Anio="'+ anio +'" Mes="'+ mes +'" RFC="'+ rfc +'" Version="1.3">'
                }

                var pie = '</BCE:Balanza>'
                var detalles = ''
                for(var c of balanza){
                    let inicial = Number(c.saldoInicialDebe) + Number(c.saldoInicialHaber)
                    let final = Number(c.saldoFinalDebe) + Number(c.saldoFinalHaber)
                    let asiento = '<BCE:Ctas NumCta="'+c.numeroCuenta+'" SaldoIni="'+inicial+'" Debe="'+c.cargo+'" Haber="'+c.abono+'" SaldoFin="'+final+'"/>'
                    detalles =  detalles + asiento+'\n'
                }

                let nombreArchivo = this.empresa.rfc +  anio + mes + 'B' +this.selectTipoEnvio.substring(0,1)
                this.downloadFile(cabecera+'\n' + detalles+'\n' +pie, nombreArchivo)
            },
            downloadFile(archivo, nombre) {
                let filename = nombre + '.xml';
                let text = archivo;

                let element = document.createElement('a');
                element.setAttribute('href', 'data:text/xml;charset=utf-8,' + encodeURIComponent(text));
                element.setAttribute('download', filename);

                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();

                document.body.removeChild(element);
            },

            async GetPDFBalanza(){
                console.time();
                this.loadingMessage = 'Consultando...'
                this.loading = true;
                try {
                    let response = await axios.get('ReportesGenerales/GetBalanza/'+this.empresa.empresaBase+'/' + this.fechaI + '/' + this.fechaF + '/' + this.nivel.value);
                    let pdf = await this.creaPdfBalanza(response.data);
                    // console.log(this.getLongMonthName(new Date(this.fechaI.substring(5,7))))
                    this.loading = false;
                } catch (error) {
                    this.items = [];
                    console.log(error);
                    this.loading = false;
                }
            },

            async creaPdfBalanza(item){
                try {
                    let response = await axios.post('https://api-framework.contago.com.mx/api/pdfs/PdfPoliza/', item);
                    let pdf = 'data:application/pdf;base64,' + response.data.pdf_
                    this.srcPdf = pdf
                    
                    this.loading = false;
                    // this.dialogAuxiliar = true;
                    this.dialogPdfBalanza = true;
                    
                    this.nombreArchivo = 'Balanza' + '_' + this.getLongMonthName(this.fechaI) + '_a_' + this.fechaF
                    console.log(this.nombreArchivo)
                } catch (error) {
                    console.log(error);
                    this.loading = false;
                }
            },

            async exportToPDF() {
                this.loadingMessage = 'Creando PDF...'
                this.loading = true;

                let F1 =this.fechaI.substring(0,4) + '-' + this.fechaI.substring(5,7) + '-05'  
                let F2 =this.fechaF.substring(0,4) + '-' + this.fechaF.substring(5,7) + '-05'  
                console.log(F1)
                console.log(F2)
            var opt = {
                margin: .1,
                filename: 'Balanza' + ' ' + this.fechaI.substring(8,10) + '-' + this.getLongMonthName(F1) + '-' + this.fechaI.substring(0,4) + ' a ' + this.fechaF.substring(8,10) + '-' + this.getLongMonthName(F2)+ '-' + this.fechaF.substring(0,4),
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                html2canvas: { scale: 4, letterRendering: true, },
                pagebreak: {
                    mode: ['avoid-all', 'css', 'legacy']
                },
                autoPaging: 'text'
            }
            this.tituloPDFBalanza = opt.filename
            // EXPORTA EL PDF
            // let file = '';
             await html2pdf().from(document.getElementById('example')).set(opt).toPdf().get('pdf').then((pdf) => {
                var totalPages = pdf.internal.getNumberOfPages();

                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i);
                    pdf.setFontSize(10);
                    pdf.setTextColor(0);
                    pdf.text(pdf.internal.pageSize.getWidth() - 1, pdf.internal.pageSize.getHeight() - 0.1, 'Página ' + i + " / " + totalPages);
                    pdf.text(pdf.internal.pageSize.getWidth() - 5, pdf.internal.pageSize.getHeight() - 0.1, 'Balanza generada');
                }
            }).save();
            // }).outputPdf();
            // file += btoa(jabubu)
            // console.log(file)
            this.loading = false;

        },
        async GetEmpresa() {
            console.log(this.empresa)
            try {
                let response = await axios.get('https://api-erp.contago.com.mx/api/Empresas/GetEmpresa/' + this.empresa.empresaBase);
                console.log('a',response.data)
                if (response.data.idEmpresa != 0) {
                    this.$store.state.empresaStore = response.data
                }
            } catch (error) {
                console.log(error);
            }
        },
        generarPDF(){
            function formatoNumerico(value) {
                    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                }

                //DATOS DE LA EMPRESA
                let xCabecera = 10;
                let yCabecera = 5;
                const doc = new jsPDF({ format: 'letter',});

                if (this.$store.state.empresaStore.idEmpresa != 0){
                    let base64logo = this.$store.state.empresaStore.logo.base64
                    doc.addImage(base64logo, 'PNG', xCabecera, yCabecera, 65, 25);
                }

                doc.setFontSize(14);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#000");

                doc.text(this.objectAuxiliar.cuenta,205, yCabecera += 5, { align: 'right' });
                doc.setFontSize(12);
                doc.setFont('helvetica', 'normal');
                doc.text(this.empresa.empresa, 205, yCabecera += 5, { align: 'right' });
                doc.text(this.empresa.rfc, 205, yCabecera += 5, { align: 'right' });
                let fecha = 'Del ' +this.fechaI  +' al '+this.fechaF
                doc.text(fecha, 205, yCabecera += 5, { align: 'right' });


                // Definir el color de la barra
                const color = '#662E91';
                const width = 205;
                const height = 8;
                // Dibujar la barra de color
                doc.setFillColor(color);
                doc.rect(5, yCabecera + 10, width, height, 'F');
                doc.setFontSize(11);
                
                doc.setTextColor("#fff");
                doc.text('TIPO', 7, yCabecera += 16, { align: 'left' });
                doc.text('NÚM.', 25, yCabecera , { align: 'left' });
                doc.text('FECHA', 45, yCabecera , { align: 'left' });
                doc.text('DESCRIPCIÓN', 70, yCabecera , { align: 'left' });
                doc.text('CARGO', 155, yCabecera , { align: 'left' });
                doc.text('ABONO', 185, yCabecera , { align: 'left' });

                //RECORREMOS EL ARRAY DE LOS CONCEPTOS
                let yLista = yCabecera + 2;
                for (let c of this.itemsAuxiliar) {
                    //CLAVE DEL SAT
                    doc.setFont('helvetica', 'normal');
                    doc.setFontSize(10);
                    doc.setTextColor("#000");

                    let tipoPoliza = c.tipoPoliza
                    let numeroPoliza = c.numeroPoliza
                    let fechaPoliza = c.fechaPoliza
                    let descripcion =  c.descripcion
                    let abono =  c.abono
                    let cargo =  c.cargo

                    doc.text(tipoPoliza, 7, yLista += 7, { align: 'left' });
                    doc.text(numeroPoliza.toString(), 37, yLista, { align: 'right' });
                    doc.text(this.functionFormatDate(fechaPoliza), 45, yLista, { align: 'left' });
                    doc.text(formatoNumerico(cargo), 180, yLista, { align: 'right' });
                    doc.text(formatoNumerico(abono), 210, yLista, { align: 'right' });


                    let concepto_ = doc.splitTextToSize(descripcion, 80);
                    let concepto_Height = doc.getTextDimensions(concepto_).h;

                    doc.text(concepto_, 70, yLista, { align: 'left' });
                    yLista += concepto_Height + 1

                    //INSERTMOS EL SALTO DE PAGINA
                    let espacioDisponible = doc.internal.pageSize.height - 40;
                    let dif = yLista > espacioDisponible

                    if (dif) {
                        doc.addPage();
                        yLista = 10;
                    } else {
                        const color_ = "#662E91"
                        doc.setDrawColor(color_);
                        doc.setLineWidth(.1);
                        doc.line(5, yLista +=3, 210, yLista);
                    }
                }

                doc.save(this.objectAuxiliar.cuenta +' - ' + this.empresa.rfc + '.pdf');

        },
        },
    }
</script>
<style>
.styled-table 
{ 
    border-collapse: collapse; 
    margin: 25px 0; 
    font-size: 1em; 
    font-family: sans-serif; 
    min-width: 450px; 
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); 
}
.styled-table thead tr 
{ 
    background-color: #662E91; 
    color: #ffffff; 
    text-align: middle; 
    font-size: 10px;
}
.styled-table th, .styled-table td 
{ 
    padding: 11px 11px; 
    font-size: 10px;
}
.styled-table tbody tr 
{ 
    border-bottom: 1px solid #dddddd; 
}
.styled-table tbody tr:nth-of-type(even) 
{ 
    background-color: #f3f3f3; 
} 
.styled-table tbody tr:last-of-type 
{ 
    border-bottom: 2px solid #009879; 
}
</style>