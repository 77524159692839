<template>
    <div>
        <v-card flat>
            <v-card-title style="font: bold;" class="text-h4 text--primary">
                <!-- BOTON PARA IR A UNA VENTANA ANTERIOR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn large icon v-bind="attrs" v-on="on" color="primary" dark class="mx-2"
                            @click="nuevaPoliza()" link :to="{ name: 'ListReportes' }">
                            <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>
                    </template>
                    <span>Atrás</span>
                </v-tooltip>
                Pérdidas de ejercicios anteriores
                <v-spacer></v-spacer>
                <v-select :items="itemsAnios" label="Año del ejercicio" outlined v-model="anios"></v-select>
                <v-col cols="12" sm="3" md="3">
                    <v-text-field label="Importe" type="number" v-model="importe" @keydown.enter="agregarATabla()">
                    </v-text-field>
                    <span>{{functionFormatPrice(importe)}}</span>
                </v-col>

                <!-- BOTON PARA CONSULTAR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="primary" dark class="mb-2" @click="agregarATabla()" v-bind="attrs" v-on="on">
                            <v-icon>mdi-checkbox-marked-circle-plus-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>Agregar</span>
                </v-tooltip>
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="primary" dark class="mb-2" @click="calculaReporte()" v-bind="attrs"
                            v-on="on">
                            <v-icon>mdi-calculator</v-icon>
                        </v-btn>
                    </template>
                    <span>Calcular</span>
                </v-tooltip>
                <!-- EXPORTA EXCEL
                <xlsx-workbook>
                    <xlsx-sheet
                        :collection="collection"
                        v-for="sheet in sheets"
                        :key="sheet.name"
                        :sheet-name="sheet.name"
                    />
                    <xlsx-download>
                    </xlsx-download>
                    <xlsx-download :filename="nombreReporte">
                        <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2" @click="GeneraExcel()"><v-icon>mdi-file-excel-box-outline</v-icon></v-btn>
                            </template>
                            <span>Excel</span>
                        </v-tooltip>
                    </xlsx-download>
                </xlsx-workbook> -->

            </v-card-title>
            <v-card-text>
                <!-- INGRESOS -->
                <v-data-table :items="itemsReporte" :headers="headersReporte" hide-default-footer :items-per-page="-1"
                    id="mytable">
                    <template v-slot:item.importe="{ item }">
                        <b style="font-size:18px" v-if="item.importe === '='">{{ functionFormatPrice(item.importe)
                            }}</b>
                        <span v-else>{{ functionFormatPrice(item.importe) }}</span>
                    </template>

                    <template v-slot:item.importeActualizado="{ item }">
                        <b style="font-size:18px" v-if="item.importeActualizado === '='">{{
                            functionFormatPrice(item.importeActualizado)
                            }}</b>
                        <span v-else>{{ functionFormatPrice(item.importeActualizado) }}</span>
                    </template>

                </v-data-table>
            </v-card-text>
        </v-card>

        <!--Dialog loading-->
        <v-dialog v-model="loading" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    {{loadingMessage}}
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import print from 'print-js';
    import pdf from 'vue-pdf';

    export default {
        name: 'PerdidasEjerciciosAnteriores',
        components: {

        },

        data: () => ({
            //LOADING
            loading: false,
            loadingMessage: '',

            //DATOS DEL REPORTE
            itemsReporte: [],
            headersReporte: [
                { text: 'EJERCICIO', value: 'ejercicio' },
                { text: 'IMPORTE', value: 'importe', align: 'right' },
                { text: 'INPC 1er MES SEGUNDA MITAD', value: 'inpcPrimerMitad', align: 'right' },
                { text: 'INPC ULTIMO MES DEL EJERCICIO', value: 'inpcUltimoMesEj', align: 'right' },
                { text: 'FACTOR', value: 'factor', align: 'right' },
                { text: 'IMPORTE ACTUALIZADO', value: 'importeActualizado', align: 'right' },
            ],

            anios: '',
            importe: 0,
            inpcReciente: 0,
            inpcAntiguo: 0,
            factor: 0,
            importeActualizado: 0,
            ejercicios: [],

            reporteChido: [],

            inpcEjercicio: '',
            factorEjercicio: '',
            importeEjercicio: '',
            aplicacionEjercicio: '',
            remanentesEjercicio: '',
            ObjetoBueno: {},
            listaObjetoBueno: [],
        }),

        watch: {

        },

        computed: {
            empresa() {
                return this.$store.state.usuario
            },

            itemsAnios() {
                let fecha = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd');
                let anio = fecha.split('-')[0];
                let lista = []
                lista.push(anio);
                lista.push(anio - 1);
                lista.push(anio - 2);
                lista.push(anio - 3);
                lista.push(anio - 4);
                lista.push(anio - 5);
                lista.push(anio - 6);
                lista.push(anio - 7);
                lista.push(anio - 8);

                return lista;
            },
        },

        methods: {

            async AgregaAREporte() {

                //SE HACE UN SELECT * FROM INPC
                await this.GetInpc();

                //SI LA TABLA NO TIENE NINGUNA ENTRADA AUN SE AGREGAN LOS PRIMEROS DATOS
                //SE LLAMA A LA FUNCION QUE AGREGA LOS DATOS
                if (this.itemsReporte.length === 0) {

                    this.getReporte()

                } else {

                    //SI EL IMPORTE ES MAYOR A CERO SE AGREGAN LOS DATOS A LA TABLA
                    //SI ES MENOR QUE CERO INGRESA A LA FUNCION PARA AGREGAR MAS COLUMNAS
                    if (this.importe > 0) {

                        this.getReporte()

                    } else {

                        this.getReporteExtra()

                    }

                }

            },

            async getReporte() {

                //OBTENEMOS INPC DEPENDIENDO DEL AÑO DE ADQUIICICION
                let ObjInpcA = this.listInpc.find(x => x.anio == this.anios)
                let ObjMesA = 'julio';
                this.inpcAntiguo = ObjInpcA[ObjMesA];

                let fechaActual = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
                let fechaA = fechaActual.split('-')
                let mesReciente = 'diciembre'
                this.mesesEjercicio = '12'
                if (this.anios === fechaA[0]) {
                    this.mesesEjercicio = fechaA[1]
                    // mesReciente = this.getLongMonthName(fechaActual)
                    mesReciente = this.getLongMonthName(fechaA[1])
                }

                let ObjInpcB = this.listInpc.find(x => x.anio == this.anios)
                let ObjMesB = mesReciente;
                this.inpcReciente = ObjInpcB[ObjMesB];

                //OBTENEMOS EL FACTOR DE ACTUALIZACION
                this.factor = (this.inpcReciente / this.inpcAntiguo)
                this.factor = Math.trunc(this.factor * 10000) / 10000
                //OBTENEMOS EL IMPORTE ACTUALIZADO
                this.importeActualizado = Math.round(this.importe * 1000000) / 1000000 * this.factor

                let Objeto = { ejercicio: this.anios, importe: this.importe, inpcPrimerMitad: this.inpcAntiguo, inpcUltimoMesEj: this.inpcReciente, factor: Math.round(this.factor * 10000) / 10000, importeActualizado: this.importeActualizado }
                this.itemsReporte.push(Objeto)
                Objeto = {};

                var arrayUniqueByKey = [...new Map(this.itemsReporte.map(item => [item['ejercicio'], item])).values()];

                this.itemsReporte = arrayUniqueByKey
                //ORDENAMOS LA TABLA DE MENOR A MAYOR DEPENDIENDO DEL EJERCICIO
                this.itemsReporte.sort(((a, b) => a.ejercicio - b.ejercicio))

            },

            async getReporteExtra() {

                let fechaActual = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
                let fechaA = fechaActual.split('-')
                let mesReciente = 'diciembre'
                this.mesesEjercicio = '12'
                if (this.anios === fechaA[0]) {
                    this.mesesEjercicio = fechaA[1]
                    // mesReciente = this.getLongMonthName(fechaActual)
                    mesReciente = this.getLongMonthName(fechaA[1])
                }

                let ObjInpcB = this.listInpc.find(x => x.anio == this.anios)
                let ObjMesB = mesReciente;
                var inpcReciente = ObjInpcB[ObjMesB];

                const arr = this.headersReporte;
                const arr2 = [
                    { text: 'INPC DIC ' + this.anios, value: 'inpc_dic' + this.anios, align: 'right' },
                    { text: 'FACTOR', value: 'factor' + this.anios, align: 'right' },
                    { text: 'IMPORTE ACTUALIZADO', value: 'importe_actualizado' + this.anios, align: 'right' },
                    { text: 'APLICACION ' + this.anios, value: 'aplicacion' + this.anios, align: 'right' },
                    { text: 'REMANENTES', value: 'remanentes' + this.anios, align: 'right' },
                ]

                // const arr3 = [...arr, ...arr2];
                // this.headersReporte = arr3;

                // let Objeto = new Object();
                // for (var r of this.headersReporte) {
                //     let a = r.value
                //     Objeto[a] = 0;
                //     if (a === 'ejercicio') {
                //         Objeto[a] = this.anios;
                //     }
                //     if (a === 'importe') {
                //         Objeto[a] = this.importe;
                //     }
                // }
                // this.itemsReporte.push(Objeto)



                // // console.log(this.inpcReciente)

                // // var inpc = 'inpc_dic' + this.anios
                // // console.log(inpc)
                // var cantidad1_ = Number(this.importe)
                // var cantidad2_ = 0
                // var resultado_ = 0
                // for (var i of this.itemsReporte) {
                //     i['inpc_dic' + this.anios] = inpcReciente
                //     let divisor = i['inpcUltimoMesEj']
                //     let factor_ = 0
                //     let impAct1 = i['importeActualizado']
                //     let impActNuevo_ = 0
                //     let remanenteNuevo_ = 0
                //     if (divisor != 0) {
                //         factor_ = inpcReciente / divisor
                //     }
                //     i['factor' + this.anios] = await Math.trunc(factor_ * 10000) / 10000
                //     impActNuevo_ = await Math.trunc((impAct1 * factor_) * 100) / 100
                //     i['importe_actualizado' + this.anios] = new Intl.NumberFormat('es-MX').format(impActNuevo_)

                //     //AQUI SE AGREGA EL RESULTADO A LA COLUMNA APLICACION
                //     i['aplicacion' + this.anios] = cantidad1_
                //     //AQUI HACE EL RECORRIDO Y TENDRIA QUE HACER LAS OPERACIONES CORRESPONDIENTES
                //     cantidad2_ = Number(impActNuevo_)
                //     resultado_ = cantidad1_ + cantidad2_

                //     console.log(resultado_)
                //     if (resultado_ >= 0) {
                //         i['aplicacion' + this.anios] = 0
                //         // console.log('es mayor')
                //         //     console.log('te falto dinero :v')
                //     } else {
                //         i['aplicacion' + this.anios] = resultado_
                //         //     console.log('pagaste bien :v')
                //     }

                //     remanenteNuevo_ = await Math.trunc((Number(impActNuevo_) + Number(this.importe)) * 100) / 100
                //     i['remanentes' + this.anios] = Intl.NumberFormat('es-MX').format(remanenteNuevo_)
                //     cantidad1_ = 100

                // }
                // // this.itemsReporte.push(Objeto)


            },

            async GetInpc() {
                try {
                    let respuesta = await axios.get('reporteAutoConta/GetInpc/' + this.empresa.empresaBase)
                    this.listInpc = respuesta.data;
                } catch (error) {
                    console.log(error.data)
                }
            },

            async agregarATabla() {

                await this.GetInpc();

                //OBTENEMOS INPC DEPENDIENDO DEL AÑO DE ADQUIICICION
                let ObjInpcA = this.listInpc.find(x => x.anio == this.anios)
                let ObjMesA = 'julio';
                this.inpcAntiguo = ObjInpcA[ObjMesA];

                let fechaActual = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
                let fechaA = fechaActual.split('-')
                let mesReciente = 'diciembre'
                this.mesesEjercicio = '12'
                if (this.anios === fechaA[0]) {
                    this.mesesEjercicio = fechaA[1]
                    // mesReciente = this.getLongMonthName(fechaActual)
                    mesReciente = this.getLongMonthName(fechaA[1])
                }

                let ObjInpcB = this.listInpc.find(x => x.anio == this.anios)
                let ObjMesB = mesReciente;
                this.inpcReciente = ObjInpcB[ObjMesB];

                //OBTENEMOS EL FACTOR DE ACTUALIZACION
                this.factor = (this.inpcReciente / this.inpcAntiguo)
                this.factor = Math.trunc(this.factor * 10000) / 10000
                //OBTENEMOS EL IMPORTE ACTUALIZADO
                this.importeActualizado = Math.round(this.importe * 1000000) / 1000000 * this.factor

                let Objeto = { ejercicio: this.anios, importe: this.importe, inpcPrimerMitad: this.inpcAntiguo, inpcUltimoMesEj: this.inpcReciente, factor: Math.round(this.factor * 10000) / 10000, importeActualizado: this.importeActualizado }
                this.itemsReporte.push(Objeto)
                Objeto = {};

                var arrayUniqueByKey = [...new Map(this.itemsReporte.map(item => [item['ejercicio'], item])).values()];

                this.itemsReporte = arrayUniqueByKey

                if (this.importe < 0) {

                    let Objeto = { ejercicio: this.anios, importe: this.importe, inpcPrimerMitad: 0, inpcUltimoMesEj: 0, factor: 0, importeActualizado: 0 }
                    this.itemsReporte.push(Objeto)
                    Objeto = {};

                    var arrayUniqueByKey = [...new Map(this.itemsReporte.map(item => [item['ejercicio'], item])).values()];

                    this.itemsReporte = arrayUniqueByKey

                }
                //ORDENAMOS LA TABLA DE MENOR A MAYOR DEPENDIENDO DEL EJERCICIO
                this.itemsReporte.sort(((a, b) => a.ejercicio - b.ejercicio))

            },

            async calculaReporte() {

                var cont = 0
                this.ejercicios = []

                for (var i of this.itemsReporte) {

                    if (i.importe < 0) {

                        cont = cont + 1;
                        this.ejercicios.push(i.ejercicio);

                    }
                }
                for (var i of this.ejercicios) {
                    await this.agregaColumna(i)
                }

                // let listaChida = []
                // let Objeto = new Object();
                // for (var l = 0; l < this.itemsReporte.length; l++) {
                //     for (var r of this.headersReporte) {
                //         let a = r.value
                //         Objeto[a] = 0;
                //     }
                //     listaChida.push(Objeto)
                // }
                // console.log(Objeto)



                // console.log(listaChida)
                // console.log(this.itemsReporte)

                // for (var i of this.headersReporte) {
                //     // a.ejercicio = this.itemsReporte[l].ejercicio

                //     console.log(i.value)
                // }

                let lista = []
                // console.log(listaChida)


                //FOR INVERTIDO :v


                for (var l = 0; l < this.itemsReporte.length; l++) {

                    this.ObjetoBueno = this.itemsReporte[l]

                    this.inpcEjercicio = 'inpc_dic'.concat(this.itemsReporte[l].ejercicio);
                    this.factorEjercicio = 'factor'.concat(this.itemsReporte[l].ejercicio);
                    this.importeEjercicio = 'importe_actualizado'.concat(this.itemsReporte[l].ejercicio);
                    this.aplicacionEjercicio = 'aplicacion'.concat(this.itemsReporte[l].ejercicio);
                    this.remanentesEjercicio = 'remanentes'.concat(this.itemsReporte[l].ejercicio);

                    // this.listaObjetoBueno.push(this.ObjetoBueno)
                    this.llenaColumna(this.inpcEjercicio)
                    this.llenaColumna(this.factorEjercicio)
                    this.llenaColumna(this.importeEjercicio)
                    //console.log(this.ObjetoBueno)
                }

                /* #ANTES */

                // //AQUI COMIENZA LO QUE FUNCIONA
                // let anio = '2018'
                // let anios = '2018'
                // let ObjInpcB = this.listInpc.find(x => x.anio == this.anios)
                // let ObjMesB = 'diciembre';
                // this.inpcReciente = ObjInpcB[ObjMesB];


                // let ObjetoBueno = new Object();
                // for (var r of this.headersReporte) {
                //     let a = r.value

                //     let inpcEjercicio = '';
                //     let factorEjercicio = '';
                //     let importeEjercicio = '';
                //     let aplicacionEjercicio = '';
                //     let remanentesEjercicio = '';

                //     for (var l = 0; l < this.itemsReporte.length; l++) {

                //         ObjetoBueno = this.itemsReporte[l]
                //         console.log(this.itemsReporte[l])

                //         inpcEjercicio = 'inpc_dic'.concat(this.itemsReporte[l].ejercicio);
                //         factorEjercicio = 'factor'.concat(this.itemsReporte[l].ejercicio);
                //         importeEjercicio = 'importe_actualizado'.concat(this.itemsReporte[l].ejercicio);
                //         aplicacionEjercicio = 'aplicacion'.concat(this.itemsReporte[l].ejercicio);
                //         remanentesEjercicio = 'remanentes'.concat(this.itemsReporte[l].ejercicio);

                //         // console.log(ObjetoBueno[a]);
                //     ObjetoBueno[inpcEjercicio] = 20188
                //     ObjetoBueno[factorEjercicio] = 666
                //     ObjetoBueno[importeEjercicio] = 777
                //     ObjetoBueno[aplicacionEjercicio] = 888
                // ObjetoBueno[remanentesEjercicio] = 999
                //     }

                //     // ObjetoBueno = { inpcEjercicio: '20188', factor2018: '666'}

                //     //console.log(ObjetoBueno[inpcEjercicio])

                // }
                // this.itemsReporte.push(ObjetoBueno)
                //AQUI TERMINA LO QUE FUNCIONA

                /* #endregion */


                // console.log(this.itemsReporte)

                // this.itemsReporte = this.itemsReporte.concat(ObjetoBueno);
                // this.itemsReporte.push(ObjetoBueno)



                // for (var l = 0; l < this.itemsReporte.length; l++) {

                //     for (var r of this.headersReporte) {
                //         let a = r.value
                //         ObjetoBueno[a] = this.itemsReporte[l];

                //         console.log(ObjetoBueno);
                //         // console.log(this.itemsReporte[l])

                //     }

                //     // console.log('inpc_dic' + this.itemsReporte[l].ejercicio)

                // }
                // this.itemsReporte.push(ObjetoBueno)
                // console.log(lista)

            },

            async llenaColumna(col) {

                switch (col) {

                    case this.inpcEjercicio:
                        // console.log(this.inpcEjercicio)

                        for (var r of this.headersReporte) {
                            let a = r.value

                            for (var l = 0; l < this.itemsReporte.length; l++) {

                                if (r.value === this.inpcEjercicio) {

                                    this.ObjetoBueno[this.inpcEjercicio] = 20188
                                }

                            }
                        }
                        // console.log(this.itemsReporte)
                        console.log(this.ObjetoBueno)
                        // console.log(this.inpcEjercicio)

                        let listaObj = [];
                        for (var l = 0; l < this.itemsReporte.length; l++) {

                        //     let obj = Object.assign(this.itemsReporte[l], arr4);

                        //     listaObj.push(obj);
                        }

                        break;

                    case this.factorEjercicio:

                        for (var r of this.headersReporte) {
                            let a = r.value

                            if (r.value === this.factorEjercicio) {

                                this.ObjetoBueno[this.factorEjercicio] = 666
                                // console.log('entro')

                            }

                        }
                        break;

                    case this.factorEjercicio:

                        for (var r of this.headersReporte) {
                            let a = r.value

                            if (r.value === this.factorEjercicio) {

                                this.ObjetoBueno[this.factorEjercicio] = 666
                                // console.log('entro')

                            }

                        }
                        break;

                    case this.importeEjercicio:
                        for (var r of this.headersReporte) {
                            let a = r.value

                            if (r.value === this.importeEjercicio) {

                                this.ObjetoBueno[this.importeEjercicio] = 777
                                // console.log('entro')

                            }

                        }
                        break;

                    default:
                        console.log("Error");
                        break;
                }



            },

            async agregaColumna(anioE) {

                let fechaActual = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
                let fechaA = fechaActual.split('-')
                let mesReciente = 'diciembre'
                this.mesesEjercicio = '12'
                if (anioE === fechaA[0]) {
                    this.mesesEjercicio = fechaA[1]
                    // mesReciente = this.getLongMonthName(fechaActual)
                    mesReciente = this.getLongMonthName(fechaA[1])
                }

                let ObjInpcB = this.listInpc.find(x => x.anio == anioE)
                let ObjMesB = mesReciente;
                var inpcReciente = ObjInpcB[ObjMesB];

                let inpcEje = 'inpc_dic' + anioE;
                let facEje = 'factor' + anioE;
                let impEje = 'importe_actualizado' + anioE;
                let apliEje = 'aplicacion' + anioE;
                let remEje = 'remanentes' + anioE;

                const arr = this.headersReporte;
                const arr2 = [
                    { text: 'INPC DIC ' + anioE, value: 'inpc_dic' + anioE, align: 'right' },
                    { text: 'FACTOR', value: 'factor' + anioE, align: 'right' },
                    { text: 'IMPORTE ACTUALIZADO', value: 'importe_actualizado' + anioE, align: 'right' },
                    { text: 'APLICACION ' + anioE, value: 'aplicacion' + anioE, align: 'right' },
                    { text: 'REMANENTES', value: 'remanentes' + anioE, align: 'right' },
                ]


                let arr4 = {}
                arr4[inpcEje] = 0
                arr4[facEje] = 0
                arr4[impEje] = 0
                arr4[apliEje] = 0
                arr4[remEje] = 0


                let listaObj = [];
                for (var l = 0; l < this.itemsReporte.length; l++) {

                    let obj = Object.assign(this.itemsReporte[l], arr4);

                    listaObj.push(obj);
                }
                // console.log(listaObj)

                const arr3 = [...arr, ...arr2];
                this.headersReporte = arr3;
            },

            functionFormatPrice(value) {
                let val = (value / 1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
        }

    }
</script>