var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"text-h4 text--primary",staticStyle:{"font":"bold"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"large":"","icon":"","color":"primary","dark":"","link":"","to":{ name: 'ListReportesEspeciales' }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)]}}])},[_c('span',[_vm._v("Atrás")])]),_vm._v(" Provisionales de ISR "),_c('v-spacer'),_c('v-select',{attrs:{"items":_vm.itemsAnios,"label":"Año del ejercicio","outlined":""},on:{"change":function($event){return _vm.GetISRCantidades()}},model:{value:(_vm.anios),callback:function ($$v) {_vm.anios=$$v},expression:"anios"}}),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"icon":"","color":"primary","dark":""},on:{"click":function($event){return _vm.GetReporte()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}])},[_c('span',[_vm._v("Buscar")])]),_c('xlsx-workbook',[_vm._l((_vm.sheets),function(sheet){return _c('xlsx-sheet',{key:sheet.name,attrs:{"collection":_vm.collection,"sheet-name":sheet.name}})}),_c('xlsx-download'),_c('xlsx-download',{attrs:{"filename":_vm.nombreReporte}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary darken-1","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.GeneraExcel()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)]}}])},[_c('span',[_vm._v("Generar Excel")])])],1)],2),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"icon":"","color":"primary","dark":""},on:{"click":function($event){return _vm.pushCalculosIsr()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calculator")])],1)]}}])},[_c('span',[_vm._v("Calcular")])])],1),_c('v-card-text',[_c('br'),_c('h2',[_vm._v("PROVISIONALES DE ISR")]),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-text-field',{attrs:{"label":"Coeficiente de Utilidad","type":"number"},model:{value:(_vm.coeficienteUtilidad),callback:function ($$v) {_vm.coeficienteUtilidad=$$v},expression:"coeficienteUtilidad"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-text-field',{attrs:{"label":"Amortización de pérdidas Anteriores","type":"number"},model:{value:(_vm.amortizacionPerdidasAnt),callback:function ($$v) {_vm.amortizacionPerdidasAnt=$$v},expression:"amortizacionPerdidasAnt"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-text-field',{attrs:{"label":"Tasa de impuestos","type":"number","hint":"Cantidad en formato decimal Ej: .30"},model:{value:(_vm.tasaDeImpuestos),callback:function ($$v) {_vm.tasaDeImpuestos=$$v},expression:"tasaDeImpuestos"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-row',{staticClass:"mt-1"},[_c('v-text-field',{attrs:{"label":"Retenciones bancarias","type":"number"},model:{value:(_vm.retencionesBancarias),callback:function ($$v) {_vm.retencionesBancarias=$$v},expression:"retencionesBancarias"}}),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"icon":"","color":"primary","dark":""},on:{"click":function($event){return _vm.GuardaCantidadesISR()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save-all")])],1)]}}])},[_c('span',[_vm._v("Guardar cantidades")])])],1)],1)],1),_c('v-data-table',{attrs:{"items":_vm.itemsReporteProvIsr,"headers":_vm.headersReporteProvIsr,"hide-default-footer":"","items-per-page":-1,"item-key":"mes"},scopedSlots:_vm._u([{key:"item.ingresos",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.ingresos)))])]}},{key:"item.devDescBonIngresos",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.devDescBonIngresos)))])]}},{key:"item.otrosIngresos",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.otrosIngresos)))])]}},{key:"item.productosFinancieros",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.productosFinancieros)))])]}},{key:"item.otrosProductos",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.otrosProductos)))])]}},{key:"item.ptuPagadaEjercicio",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.ptuPagadaEjercicio)))])]}},{key:"item.suma",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.suma)))])]}},{key:"item.totIngresosAcum",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.totIngresosAcum)))])]}},{key:"item.coeficienteUtilidad",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.coeficienteUtilidad)))])]}},{key:"item.utilidadFiscalEstimada",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.utilidadFiscalEstimada)))])]}},{key:"item.amortizacionPerdidasAnt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.amortizacionPerdidasAnt)))])]}},{key:"item.basePagoProvisional",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.basePagoProvisional)))])]}},{key:"item.tasaDeImpuestos",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.tasaDeImpuestos)))])]}},{key:"item.impuestoDeterminado",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.impuestoDeterminado)))])]}},{key:"item.pagosProvisionalesEA",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.pagosProvisionalesEA)))])]}},{key:"item.retencionesBancarias",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.retencionesBancarias)))])]}},{key:"item.pagoProvisionalAEnterar",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.pagoProvisionalAEnterar)))])]}}])})],1),_c('v-card-actions')],1),_c('v-dialog',{attrs:{"persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.loadingMessage)+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }