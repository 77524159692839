<template>
    <div>
        <v-card>
            <v-card-title style="font: bold;" class="text-h4 text--primary">
                <!-- BOTON PARA IR A UNA VENTANA ANTERIOR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn large icon v-bind="attrs" v-on="on" color="primary" dark class="mx-2" link :to="{ name: 'ListReportes' }"><v-icon>mdi-arrow-left</v-icon></v-btn>
                    </template>
                    <span>Atrás</span>
                </v-tooltip>
                Libro Diario
                <v-spacer></v-spacer>
                <!--Fecha Inicial-->
                <v-col cols="12" sm="2" md="2">
                    <v-menu v-model="calendario" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                        offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="fechaI" label="Fecha Inicial" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                            v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="fechaI" @input="calendario = false, cambiaFechaFinal()"></v-date-picker>
                    </v-menu>
                </v-col>
                <!--Fecha Final-->
                <v-col cols="12" sm="2" md="2">
                <v-menu v-model="calendario2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="fechaF" label="Fecha Final" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="fechaF" @input="calendario2 = false"></v-date-picker>
                </v-menu>
                </v-col>
                <!-- BOTON PARA BUSCAR POLIZAS -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2" @click="GetPolizas()"><v-icon>mdi-magnify</v-icon></v-btn>
                    </template>
                    <span>Buscar</span>
                </v-tooltip>
                <!-- EXPORTA EXCEL -->
                <xlsx-workbook>
                    <xlsx-sheet
                        :collection="collection"
                        v-for="sheet in sheets"
                        :key="sheet.name"
                        :sheet-name="sheet.name"
                    />
                    <xlsx-download>
                    </xlsx-download>
                    <xlsx-download :filename="nombreReporte">
                        <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2" @click="GeneraExcel()"><v-icon>mdi-file-excel-box-outline</v-icon></v-btn>
                            </template>
                            <span>Excel</span>
                        </v-tooltip>
                    </xlsx-download>
                </xlsx-workbook>
            </v-card-title>
            <v-card-text>
                <v-data-table :headers="headersLibroDiario" :items="itemsLibroDiario" class="elevation-1" :items-per-page="10" show-expand item-key="id">
                    <!-- FORMATO CON DECIMALES DEL CARGO -->
                    <template v-slot:item.cargo="{ item }">
                        <span>{{ functionFormatPrice(item.cargo) }}</span>
                    </template>
                    <!-- FORMATO CON DECIMALES CON ABONO -->
                    <template v-slot:item.abono="{ item }">
                        <span>{{ functionFormatPrice(item.abono) }}</span>
                    </template>
                    <!-- FORMATO DE FECHA -->
                    <template v-slot:item.fecha="{ item }">
                        <span>{{ functionFormatDate(item.fecha) }}</span>
                    </template>
                    <!-- DETALLES -->
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                            <v-card :colspan="headers.length">
                                <v-data-table :headers="headersDetalles" :items="item.detalles" hide-default-footer>
                                    <template v-slot:[`item.cargo`]="{ item }">
                                        <span>{{ functionFormatPrice(item.cargo) }}</span>
                                    </template>
                                    <template v-slot:[`item.abono`]="{ item }">
                                        <span>{{ functionFormatPrice(item.abono) }}</span>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </td>
                    </template>
                </v-data-table>
                <br>
                <br>
                <br>
            </v-card-text>
             <!-- CAMPO CON LOS TOTALES -->
             <v-footer padless fixed>
                <v-card flat tile width="100%" class="text-center">
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <h2>{{ functionFormatPrice(sumaCargos) }}</h2>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <h2>{{ functionFormatPrice(sumaAbonos) }}</h2>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            </v-footer>
        </v-card>

        <!--Dialog loading-->
        <v-dialog v-model="loading" hide-overlay persistent width="300">
            <v-card color="primary" dark>
            <v-card-text>
                {{loadingMessage}}
                <v-progress-linear stream buffer-value="0" color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import print from 'print-js';
    import pdf from 'vue-pdf';
    import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"


    import Vue from "vue";
    import JsonExcel from "vue-json-excel";

    Vue.component("downloadExcel", JsonExcel);

    export default {
        name: 'LibroDiario',
        components: {
            XlsxWorkbook,
            XlsxSheet,
            XlsxDownload,
        },

        data: () => ({
            //LOADING
            loadingMessage: '',
            loading: false,

            //FECHAS
            fechaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            fechaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            calendario: false,
            calendario2: false,

            //
            itemsLibroDiario:[],
            headersLibroDiario:[
                { text: "Tipo", value: "tipoPoliza" },
                { text: "No. póliza", value: "numeroPoliza" },
                { text: "Descripción", value: "descripcion" },
                { text: "Fecha", value: "fecha" },
                { text: "Cargo", value: "cargo", align: 'right' },
                { text: "Abono", value: "abono", align: 'right' },
                { text: "Estado", value: "estado" },
            ],

            headersDetalles: [
                { text: "No. Cuenta", value: "numeroCuenta" },
                { text: "Nombre cuenta", value: "nombreCuenta" },
                { text: "Cargo", value: "cargo", align: 'right' },
                { text: "Abono", value: "abono", align: 'right' },
            ],

            //LISTA PARA EXPORTAR
            listaExcel: [],
            sheets: [{ name: "SheetOne", data: [{ c: 2 }] }],
            collection: [{ a: 1, b: 2 }],
            nombreReporte: '',
        }),
        watch: {
            
        },
        computed: {
            sumaCargos(){
                return this.itemsLibroDiario.map(item => item.cargo).reduce((prev, curr) => prev + curr, 0);
            },
            sumaAbonos(){
                return this.itemsLibroDiario.map(item => item.abono).reduce((prev, curr) => prev + curr, 0);
            },
            empresa(){
                return this.$store.state.usuario
            },
        },
        created() {
            
        },
        methods: {
            functionFormatPrice(value) {
                let val = (value / 1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            functionFormatDate(value){
                let fecha = moment(value).format('DD/MM/yyyy')
                return fecha;
            },
            async GetPolizas(){
                console.time();
                this.loadingMessage = 'Buscando pólizas...'
                this.loading = true;
                try {
                    let response = await axios.get('GetPolizas/GetPolizasAsync/'+this.empresa.empresaBase+'/' + this.fechaI + '/' + this.fechaF + '/Todos');
                    this.itemsLibroDiario = response.data;
                    this.loading = false;
                    console.timeEnd();
                } catch (error) {
                    console.log(error);
                    this.$store.state.listPoliza= []
                    this.loading = false;
                    console.timeEnd();
                }
            },

            GeneraExcel(){
                this.nombreReporte = 'Libro diario ' + this.empresa.rfc + '_' +  this.fechaI + ' al ' + this.fechaF + '.xlsx'
                let listaExcel = [];
                let contador = 0;
                var salto = { tipo: '', descripcion: '', fecha: '', cargo: '', abono: '' }
                for(let g of this.itemsLibroDiario){
                    let ObjG = { tipo: g.tipoPoliza+' '+g.numeroPoliza, descripcion: g.descripcion, fecha: this.functionFormatDate(g.fecha), cargo: g.cargo, abono: g.abono }
                    listaExcel.push(ObjG);
                    ObjG = Object.assign( {} );
                    
                    for(let det of this.itemsLibroDiario[contador].detalles){
                        let ObjD = { tipo: '', descripcion: det.numeroCuenta + ' | ' + det.nombreCuenta, fecha: '', cargo: det.cargo, abono: det.abono }
                        listaExcel.push(ObjD);
                        ObjD = Object.assign( {} );
                    } 
                    listaExcel.push(salto)
                    contador ++;
                }
                var salto = { tipo: '', descripcion: '', fecha: '', cargo: '', abono: '' }
                let ObjSum = { tipo: '', descripcion: '', fecha: 'SUMAS', cargo: this.sumaCargos, abono: this.sumaAbonos }
                listaExcel.push(ObjSum)


                this.collection = listaExcel;
            },

            cambiaFechaFinal(){
                let fecha = this.fechaI.split('-');
                var primero = new Date(fecha[0],  fecha[1]-1, '01');
                var ultimoDia = new Date(primero.getFullYear(), primero.getMonth() + 1, 0);
                this.fechaF = format(parseISO(new Date(ultimoDia).toISOString()), 'yyyy-MM-dd')
            },
        },
    }
</script>