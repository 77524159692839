<template>
    <div>
        <v-card flat>
            <v-card-title style="font: bold;" class="text-h4 text--primary">
                <!-- BOTON PARA IR A UNA VENTANA ANTERIOR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn large icon v-bind="attrs" v-on="on" color="primary" dark class="mx-2" link :to="{ name: 'ListReportesEspeciales' }"><v-icon>mdi-arrow-left</v-icon></v-btn>
                    </template>
                    <span>Atrás</span>
                </v-tooltip>
                Estado de Costo de Producción y Venta                
                <!--Fecha Inicial-->
                <v-col cols="12" sm="3" md="3">
                <v-menu v-model="calendario" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="fechaI" label="Fecha Inicial" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="fechaI" @input="calendario = false"></v-date-picker>
                </v-menu>
                </v-col>
                <!--Fecha Final-->
                <v-col cols="12" sm="3" md="3">
                <v-menu v-model="calendario2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="fechaF" label="Fecha Final" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="fechaF" @input="calendario2 = false"></v-date-picker>
                </v-menu>
                </v-col>
                <!-- BOTON PARA CONSULTAR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="primary" dark class="mb-2" @click="GetReporte()" v-bind="attrs" v-on="on"><v-icon>mdi-magnify</v-icon></v-btn>
                    </template>
                    <span>Buscar</span>
                </v-tooltip>
            </v-card-title>
            <v-card-text>
                <v-data-table :items="itemsReporte" :headers="headersReporte" hide-default-footer :items-per-page="-1">
                    <template v-slot:item.importe="{ item }">
                        <b style="font-size:18px" v-if="item.observacion === '='">{{ functionFormatPrice(item.importe) }}</b>
                        <span v-else>{{ functionFormatPrice(item.importe) }}</span>
                    </template>

                    <template v-slot:item.nombre="{ item }">
                        <b style="font-size:18px" v-if="item.observacion === '='">{{ item.nombre }}</b>
                        <span v-else>{{ item.nombre }}</span>
                    </template>

                    <template v-slot:item.observacion="{ item }">
                        <b style="font-size:18px" v-if="item.observacion === '='">{{ item.observacion }}</b>
                        <span v-else>{{ item.observacion }}</span>
                  </template>
                </v-data-table>
                <br>
                <!-- <h2>Comprobación</h2>
                <v-data-table :items="itemsComprobacion" :headers="headersComprobacion" hide-default-footer :items-per-page="-1">

                </v-data-table> -->
            </v-card-text>
            <v-card-actions>
            </v-card-actions>
        </v-card>

        <!--Dialog loading-->
        <v-dialog v-model="loading" hide-overlay persistent width="300">
            <v-card color="primary" dark>
            <v-card-text>
                {{loadingMessage}}
                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import print from 'print-js';
    import pdf from 'vue-pdf';

    export default {
        name: 'EstadoDeCostoDeProduccionVenta',
        components: {
            
        },

        data: () => ({
            //LOADING
            loading: false,
            loadingMessage: '',

            //FECHAS
            fechaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            fechaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            calendario: false,
            calendario2: false,

            //DATOS DEL REPORTE
            itemsReporte:[],
            headersReporte: [
                { text: '', value: 'observacion' },
                { text: 'No. Cuenta', value: 'numero' },
                { text: 'Nombre Cuenta', value: 'nombre' },
                { text: 'Importe', value: 'importe', align: 'right'  },
            ],
            itemsComprobacion: [],
            headersComprobacion:[
                { text: 'No. Cuenta', value: 'numero' },
                { text: 'Nombre Cuenta', value: 'nombre' },
                { text: 'Importe', value: 'importe', align: 'right'  },
            ],
        }),

        watch: {
            
        },

        computed: {
            empresa(){
                return this.$store.state.usuario
            },
        },

        created() {
            
        },

        methods: {
            functionFormatPrice(value) {
                let val = (value / 1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            async GetReporte(){
                this.loading = true;
                this.loadingMessage = 'Consultando...';
                try{
                    console.time();
                    let respuesta = await axios.get('reporteAutoConta/EstaDeCosto/' + this.empresa.empresaBase + '/'+ this.fechaI + '/' + this.fechaF)
                    // console.log(respuesta.data)
                    this.armaReporte(respuesta.data);
                    console.timeEnd();
                    this.loading = false;
                }catch(error){
                    console.log(error.data)
                    console.timeEnd();
                    this.loading = false;
                }
            },

            armaReporte(item){
                console.log(item)

                let Lista = item;
                //PARTE UNO
                this.itemsReporte.push(Lista[0]);
                this.itemsReporte.push(Lista[1]);
                // Lista[0].importe = 50000
                // Lista[1].importe = 800000
                let suma1 = Lista[0].importe + Lista[1].importe;
                let suma1Obj = {numero: '', nombre:'MATERIA PRIMA DISPONIBLE', observacion:'=', importe: suma1}
                suma1Obj.nombre.bold();
                this.itemsReporte.push(suma1Obj);

                //PARTE DOS
                // Lista[2].importe = 350000
                this.itemsReporte.push(Lista[2]);
                let suma2 = suma1 - Lista[2].importe;
                let suma2Obj = {numero: '', nombre:'TOTAL MATERIA PRIMA UTILIZADA', observacion:'=', importe: suma2}
                this.itemsReporte.push(suma2Obj);

                //PARTE TRES
                // Lista[3].importe = 150000
                this.itemsReporte.push(Lista[3]);
                let suma3 = suma2 + Lista[3].importe;
                let suma3Obj = {numero: '', nombre:'COSTO PRIMO', observacion:'=', importe: suma3}
                this.itemsReporte.push(suma3Obj);

                //PARTE CUATRO
                // Lista[4].importe = 50000
                this.itemsReporte.push(Lista[4]);
                let suma4 = suma3 + Lista[4].importe;
                let suma4Obj = { numero: '', nombre:'COSTO PRODUCCIÓN PROCESADA', observacion:'=', importe: suma4 }
                this.itemsReporte.push(suma4Obj);

                //PARTE CINCO
                // Lista[5].importe = 100000
                this.itemsReporte.push(Lista[5]);
                let suma5 = suma4 + Lista[5].importe;
                let suma5Obj = { numero: '', nombre:'PROD EN PROCESO DISPONIBLE', observacion:'=', importe: suma5 }
                this.itemsReporte.push(suma5Obj);
                
                //PARTE SEIS
                // Lista[6].importe = 150000
                this.itemsReporte.push(Lista[6]);
                let suma6 = suma5 - Lista[6].importe;
                let suma6Obj = { numero: '', nombre:'COSTO DE PRODUCCIÓN TERMINADA', observacion:'=', importe: suma6 }
                this.itemsReporte.push(suma6Obj);

                //PARTE SIETE
                // Lista[7].importe = 100000
                // Lista[8].importe = 50000
                this.itemsReporte.push(Lista[7]);
                this.itemsReporte.push(Lista[8]);
                let suma7 = suma6 + Lista[7].importe + Lista[8].importe;
                let suma7Obj = { numero: '', nombre:'ARTÍCULOS TERMINADOS DISPONIBLES', observacion:'=', importe: suma7 }
                this.itemsReporte.push(suma7Obj);

                //PASO OCHO
                // Lista[9].importe = 50000
                this.itemsReporte.push(Lista[9]);
                let suma8 = suma7 - Lista[9].importe;
                let suma8Obj = { numero: '', nombre:'COSTO DE VENTAS', observacion:'=', importe: suma8 }
                this.itemsReporte.push(suma8Obj);

            },        
        }
    }
</script>