<template>
    <div>
        <v-card>
            <!-- TITULOS DE LA TABLA -->
            <v-card-title style="font: bold;" class="text-h4 text--primary">
                <!-- BOTON PARA IR A UNA VENTANA ANTERIOR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn large icon v-bind="attrs" v-on="on" color="primary" dark class="mx-2"
                            @click="nuevaPoliza()" link
                            :to="{ name: 'ListReportesEspeciales' }"><v-icon>mdi-arrow-left</v-icon></v-btn>
                    </template>
                    <span>Atrás</span>
                </v-tooltip>
                Flujo de Efectivo
                <v-spacer></v-spacer>
                <!--Fecha Inicial-->
                <v-select :items="itemsAnios" label="Año del ejercicio Antigüo" outlined v-model="anios"></v-select>
                <v-spacer></v-spacer>
                <!-- Fecha Final -->
                <v-select :items="itemsAnios2" label="Año del ejercicio Reciente" outlined v-model="anios2"></v-select>
                <!-- BOTON PARA CONSULTAR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2"
                            @click="getBalanzas()"><v-icon>mdi-magnify</v-icon></v-btn>
                    </template>
                    <span>Buscar</span>
                </v-tooltip>
                <!-- GENERA EL REPORTE -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2"
                            @click="AbreEstadoFlujosEfectivo()"><v-icon>mdi-file-chart-outline</v-icon></v-btn>
                    </template>
                    <span>Generar Reporte</span>
                </v-tooltip>

            </v-card-title>

            <!-- CUERPO DE LA TABLA -->
            <v-card-text>
                <div>
                    <v-expansion-panels v-model="panel" multiple>
                        <!-- Activos disponibles para venta -->
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <th> Activos disponibles para venta (campo manual) </th>
                                <v-spacer></v-spacer>
                                <tr>
                                    <th colspan="4" class="text-right">
                                        Total Diferencia de la Cuenta: {{
                                        functionFormatPrice(this.itemsActivosDisponiblesVenta[0].diferencia) }}
                                    </th>
                                </tr>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-data-table :headers="headersActivosDisponiblesVenta" :items="itemsActivosDisponiblesVenta" hide-default-footer
                                    class="elevation-0">
                                    <template v-slot:item.primerAnio="props">
                                        <v-edit-dialog :return-value.sync="props.item.primerAnio" @save="save">
                                            {{ props.item.primerAnio }}
                                            <template v-slot:input>
                                                <v-text-field v-model="props.item.primerAnio" 
                                                    label="Edit" single-line counter></v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </template>

                                    <template v-slot:item.segundoAnio="props">
                                        <v-edit-dialog :return-value.sync="props.item.segundoAnio" @save="save" 
                                           >
                                            {{ props.item.segundoAnio }}
                                            <template v-slot:input>
                                                <v-text-field v-model="props.item.segundoAnio" 
                                                    label="Edit" single-line counter></v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </template>
                                </v-data-table>

                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <!-- Impuestos a la utilidad por pagar -->
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <th> Impuestos a la utilidad por pagar (campo manual) </th>
                                <v-spacer></v-spacer>
                                <tr>
                                    <th colspan="4" class="text-right">
                                        Total Diferencia de la Cuenta: {{
                                        functionFormatPrice(this.itemsImpuestosUtilidadXPagar[0].diferencia) }}
                                    </th>
                                </tr>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-data-table :headers="headersImpuestosUtilidadXPagar" :items="itemsImpuestosUtilidadXPagar" hide-default-footer
                                    class="elevation-0">
                                    <template v-slot:item.primerAnio="props">
                                        <v-edit-dialog :return-value.sync="props.item.primerAnio" @save="saveIUPP">
                                            {{ props.item.primerAnio }}
                                            <template v-slot:input>
                                                <v-text-field v-model="props.item.primerAnio" 
                                                    label="Edit" single-line counter></v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </template>

                                    <template v-slot:item.segundoAnio="props">
                                        <v-edit-dialog :return-value.sync="props.item.segundoAnio" @save="saveIUPP" 
                                           >
                                            {{ props.item.segundoAnio }}
                                            <template v-slot:input>
                                                <v-text-field v-model="props.item.segundoAnio" 
                                                    label="Edit" single-line counter></v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </template>
                                </v-data-table>

                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <!-- Rentas cobradas por anticipado -->
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <th> Rentas cobradas por anticipado (campo manual) </th>
                                <v-spacer></v-spacer>
                                <tr>
                                    <th colspan="4" class="text-right">
                                        Total Diferencia de la Cuenta: {{
                                        functionFormatPrice(this.itemsRentasCobradasXAnticipado[0].diferencia) }}
                                    </th>
                                </tr>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-data-table :headers="headersRentasCobradasXAnticipado" :items="itemsRentasCobradasXAnticipado" hide-default-footer
                                    class="elevation-0">
                                    <template v-slot:item.primerAnio="props">
                                        <v-edit-dialog :return-value.sync="props.item.primerAnio" @save="saveRCPA">
                                            {{ props.item.primerAnio }}
                                            <template v-slot:input>
                                                <v-text-field v-model="props.item.primerAnio" 
                                                    label="Edit" single-line counter></v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </template>

                                    <template v-slot:item.segundoAnio="props">
                                        <v-edit-dialog :return-value.sync="props.item.segundoAnio" @save="saveRCPA" 
                                           >
                                            {{ props.item.segundoAnio }}
                                            <template v-slot:input>
                                                <v-text-field v-model="props.item.segundoAnio" 
                                                    label="Edit" single-line counter></v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </template>
                                </v-data-table>

                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        
                        <!-- Efectivo y equivalentes de efectivo -->
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <th> Efectivo y equivalentes de efectivo</th>
                                <v-spacer></v-spacer>
                                <tr>
                                    <th colspan="4" class="text-right">
                                        Total Diferencia de la Cuenta: {{
                                        functionFormatPrice(totalEfectivoEquivalentesDeEfectivo) }}
                                    </th>
                                </tr>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-data-table :headers="headers" :items="EfectivoEquivalentesDeEfectivo.lista"
                                    hide-default-footer class="elevation-0">
                                    <template v-slot:body.append>
                                        <tr>
                                            <td>Total</td>
                                            <td>{{ functionFormatPrice(sumaEfectivoEquivalentesDeEfectivo1) }}</td>
                                            <td>{{ functionFormatPrice(sumaEfectivoEquivalentesDeEfectivo2) }}</td>
                                        </tr>
                                    </template>
                                    <template v-slot:item.primerAnio="{ item }">
                                        <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                    </template>
                                    <template v-slot:item.segundoAnio="{ item }">
                                        <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                    </template>
                                    <template v-slot:item.diferencia="{ item }">
                                        <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                    </template>
                                </v-data-table>
                                <!-- <v-data-table :headers="headers" :items="items[0]['Efectivo y equivalentes de efectivo']"
                                        hide-default-footer class="elevation-0"></v-data-table> -->
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- Instrumentos financieros de negociación -->
                        <template>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <th> Instrumentos financieros de negociación</th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{
                                            functionFormatPrice(totalInstrumentosFinancierosNegociación) }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="InstrumentosFinancierosNegociación.lista"
                                        hide-default-footer class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaInstrumentosFinancierosNegociación1) }}
                                                </td>
                                                <td>{{ functionFormatPrice(sumaInstrumentosFinancierosNegociación2) }}
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                    <!-- <v-data-table :headers="headers" :items="items[0]['Efectivo y equivalentes de efectivo']"
                                        hide-default-footer class="elevation-0"></v-data-table> -->
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Clientes -->
                        <template>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <th> Clientes</th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{ functionFormatPrice(totalClientes) }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="Clientes.lista" hide-default-footer
                                        class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaClientes1) }}</td>
                                                <td>{{ functionFormatPrice(sumaClientes2) }}</td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Cuentas y documentos por cobrar -->
                        <template>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <th> Cuentas y documentos por cobrar</th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{
                                            functionFormatPrice(totalCuentasDocumentosXCobrar) }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="CuentasDocumentosXCobrar.lista"
                                        hide-default-footer class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaCuentasDocumentosXCobrar1) }}</td>
                                                <td>{{ functionFormatPrice(sumaCuentasDocumentosXCobrar1) }}</td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Deudores diversos -->
                        <template>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <th> Deudores diversos</th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{ functionFormatPrice(totalDeudoresDiversos)
                                            }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="DeudoresDiversos.lista" hide-default-footer
                                        class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaDeudoresDiversos1) }}</td>
                                                <td>{{ functionFormatPrice(sumaDeudoresDiversos2) }}</td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Estimación de cuentas incobrables -->
                        <template>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <th> Estimación de cuentas incobrables</th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{
                                            functionFormatPrice(totalEstimaciónCuentasIncobrables) }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="EstimaciónCuentasIncobrables.lista"
                                        hide-default-footer class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaEstimaciónCuentasIncobrables1) }}</td>
                                                <td>{{ functionFormatPrice(sumaEstimaciónCuentasIncobrables2) }}</td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Impuesto por recuperar -->
                        <template>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <th> Impuesto por recuperar </th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{
                                            functionFormatPrice(totalImpuestoXRecuperar) }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="ImpuestoXRecuperar.lista"
                                        hide-default-footer class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaImpuestoXRecuperar1) }}</td>
                                                <td>{{ functionFormatPrice(sumaImpuestoXRecuperar2) }}</td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Inventarios -->
                        <template>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <th> Inventarios </th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{ functionFormatPrice(totalInventarios) }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="Inventarios.lista" hide-default-footer
                                        class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaInventarios1) }}</td>
                                                <td>{{ functionFormatPrice(sumaInventarios2) }}</td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Pagos anticipados -->
                        <template>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <th> Pagos anticipados </th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{ functionFormatPrice(totalPagosAnticipados)
                                            }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="PagosAnticipados.lista" hide-default-footer
                                        class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaPagosAnticipados1) }}</td>
                                                <td>{{ functionFormatPrice(sumaPagosAnticipados2) }}</td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Propiedades, plantas y equipo -->
                        <template>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <th> Propiedades, plantas y equipo </th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{
                                            functionFormatPrice(totalPropiedadesPlantasEquipo) }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="PropiedadesPlantasEquipo.lista"
                                        hide-default-footer class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaPropiedadesPlantasEquipo1) }}</td>
                                                <td>{{ functionFormatPrice(sumaPropiedadesPlantasEquipo2) }}</td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Cuentas y documentos por cobrar a largo plazo -->
                        <template>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <th> Cuentas y documentos por cobrar a largo plazo </th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{
                                            functionFormatPrice(totalCuentasDocumentosXCobrarLargoPlazo) }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="CuentasDocumentosXCobrarLargoPlazo.lista"
                                        hide-default-footer class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaCuentasDocumentosXCobrarLargoPlazo1) }}
                                                </td>
                                                <td>{{ functionFormatPrice(sumaCuentasDocumentosXCobrarLargoPlazo2) }}
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Anticipo a proveedores -->
                        <template>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <th> Anticipo a proveedores </th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{
                                            functionFormatPrice(totalAnticipoProveedores) }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="AnticipoProveedores.lista"
                                        hide-default-footer class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaAnticipoProveedores1) }}</td>
                                                <td>{{ functionFormatPrice(sumaAnticipoProveedores2) }}</td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Activos intangibles -->
                        <template>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <th> Activos intangibles </th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{
                                            functionFormatPrice(totalActivosIntangibles) }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="ActivosIntangibles.lista"
                                        hide-default-footer class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaActivosIntangibles1) }}</td>
                                                <td>{{ functionFormatPrice(sumaActivosIntangibles2) }}</td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Inversiones en asociadas -->
                        <template>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <th> Inversiones en asociadas </th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{
                                            functionFormatPrice(totalInversionesAsociadas) }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="InversionesAsociadas.lista"
                                        hide-default-footer class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaInversionesAsociadas1) }}</td>
                                                <td>{{ functionFormatPrice(sumaInversionesAsociadas2) }}</td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Instrumentos financieros por cobrar a largo plazo -->
                        <template>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <th> Instrumentos financieros por cobrar a largo plazo </th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{
                                            functionFormatPrice(totalInstrumentosFinancierosXCobrarLargoPlazo) }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers"
                                        :items="InstrumentosFinancierosXCobrarLargoPlazo.lista" hide-default-footer
                                        class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{
                                                    functionFormatPrice(sumaInstrumentosFinancierosXCobrarLargoPlazo1)
                                                    }}</td>
                                                <td>{{
                                                    functionFormatPrice(sumaInstrumentosFinancierosXCobrarLargoPlazo2)
                                                    }}</td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Proveedores -->
                        <template>
                            <v-expansion-panel :value="panelOpen">
                                <v-expansion-panel-header>
                                    <th> Proveedores </th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{ functionFormatPrice(totalProveedores) }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="Proveedores.lista" hide-default-footer
                                        class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaProveedores1) }}</td>
                                                <td>{{ functionFormatPrice(sumaProveedores2) }}</td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Cuentas y documentos por pagar -->
                        <template>
                            <v-expansion-panel :value="panelOpen">
                                <v-expansion-panel-header>
                                    <th> Cuentas y documentos por pagar </th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{
                                            functionFormatPrice(totalCuentasDocumentosXPagar) }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="CuentasDocumentosXPagar.lista"
                                        hide-default-footer class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaCuentasDocumentosXPagar1) }}</td>
                                                <td>{{ functionFormatPrice(sumaCuentasDocumentosXPagar2) }}</td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Instrumentos financieros -->
                        <template>
                            <v-expansion-panel :value="panelOpen">
                                <v-expansion-panel-header>
                                    <th> Instrumentos financieros </th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{
                                            functionFormatPrice(totalInstrumentosFinancieros) }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="InstrumentosFinancieros.lista"
                                        hide-default-footer class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaInstrumentosFinancieros1) }}</td>
                                                <td>{{ functionFormatPrice(sumaInstrumentosFinancieros2) }}</td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Acreedores diversos -->
                        <template>
                            <v-expansion-panel :value="panelOpen">
                                <v-expansion-panel-header>
                                    <th> Acreedores diversos </th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{
                                            functionFormatPrice(totalAcreedoresDiversos) }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="AcreedoresDiversos.lista"
                                        hide-default-footer class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaAcreedoresDiversos1) }}</td>
                                                <td>{{ functionFormatPrice(sumaAcreedoresDiversos2) }}</td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Anticipo de clientes -->
                        <template>
                            <v-expansion-panel :value="panelOpen">
                                <v-expansion-panel-header>
                                    <th> Anticipo de clientes </th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{ functionFormatPrice(totalAnticipoClientes)
                                            }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="AnticipoClientes.lista" hide-default-footer
                                        class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaAnticipoClientes1) }}</td>
                                                <td>{{ functionFormatPrice(sumaAnticipoClientes2) }}</td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Provisiones -->
                        <template>
                            <v-expansion-panel :value="panelOpen">
                                <v-expansion-panel-header>
                                    <th> Provisiones </th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{ functionFormatPrice(totalProvisiones) }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="Provisiones.lista" hide-default-footer
                                        class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaProvisiones1) }}</td>
                                                <td>{{ functionFormatPrice(sumaProvisiones2) }}</td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Impuestos por pagar -->
                        <template>
                            <v-expansion-panel :value="panelOpen">
                                <v-expansion-panel-header>
                                    <th> Impuestos por pagar </th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{ functionFormatPrice(totalImpuestosXPagar) }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="ImpuestosXPagar.lista" hide-default-footer
                                        class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaImpuestosXPagar1) }}</td>
                                                <td>{{ functionFormatPrice(sumaImpuestosXPagar2) }}</td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Cuentas y documentos por pagar a largo plazo -->
                        <template>
                            <v-expansion-panel :value="panelOpen">
                                <v-expansion-panel-header>
                                    <th> Cuentas y documentos por pagar a largo plazo </th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{
                                            functionFormatPrice(totalCuentasDocumentosXPagarLargoPlazo) }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="CuentasDocumentosXPagarLargoPlazo.lista"
                                        hide-default-footer class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaCuentasDocumentosXPagarLargoPlazo1) }}
                                                </td>
                                                <td>{{ functionFormatPrice(sumaCuentasDocumentosXPagarLargoPlazo2) }}
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Instrumentos financieros a largo plazo -->
                        <template>
                            <v-expansion-panel :value="panelOpen">
                                <v-expansion-panel-header>
                                    <th> Instrumentos financieros a largo plazo </th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{
                                            functionFormatPrice(totalInstrumentosFinancierosLargoPlazo) }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="InstrumentosFinancierosLargoPlazo.lista"
                                        hide-default-footer class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaInstrumentosFinancierosLargoPlazo1) }}
                                                </td>
                                                <td>{{ functionFormatPrice(sumaInstrumentosFinancierosLargoPlazo2) }}
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Aportaciones para futuros aumentos de capital -->
                        <template>
                            <v-expansion-panel :value="panelOpen">
                                <v-expansion-panel-header>
                                    <th> Aportaciones para futuros aumentos de capital </th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{
                                            functionFormatPrice(totalAportacionesParaFuturosAumentosCapital) }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers"
                                        :items="AportacionesParaFuturosAumentosCapital.lista" hide-default-footer
                                        class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaAportacionesParaFuturosAumentosCapital1)
                                                    }}</td>
                                                <td>{{ functionFormatPrice(sumaAportacionesParaFuturosAumentosCapital2)
                                                    }}</td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Impuesto a la utilidad diferido por pagar -->
                        <template>
                            <v-expansion-panel :value="panelOpen">
                                <v-expansion-panel-header>
                                    <th> Impuesto a la utilidad diferido por pagar </th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{
                                            functionFormatPrice(totalImpuestoUtilidadDiferidoXPagar) }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="ImpuestoUtilidadDiferidoXPagar.lista"
                                        hide-default-footer class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaImpuestoUtilidadDiferidoXPagar1) }}</td>
                                                <td>{{ functionFormatPrice(sumaImpuestoUtilidadDiferidoXPagar2) }}</td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Beneficio a empleados -->
                        <template>
                            <v-expansion-panel :value="panelOpen">
                                <v-expansion-panel-header>
                                    <th> Beneficio a empleados </th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{
                                            functionFormatPrice(totalBeneficioEmpleados) }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="BeneficioEmpleados.lista"
                                        hide-default-footer class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaBeneficioEmpleados1) }}</td>
                                                <td>{{ functionFormatPrice(sumaBeneficioEmpleados2) }}</td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Provisiones a largo plazo -->
                        <template>
                            <v-expansion-panel :value="panelOpen">
                                <v-expansion-panel-header>
                                    <th> Provisiones a largo plazo </th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{
                                            functionFormatPrice(totalProvisionesLargoPlazo) }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="ProvisionesLargoPlazo.lista"
                                        hide-default-footer class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaProvisionesLargoPlazo1) }}</td>
                                                <td>{{ functionFormatPrice(sumaProvisionesLargoPlazo2) }}</td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Capital social -->
                        <template>
                            <v-expansion-panel :value="panelOpen">
                                <v-expansion-panel-header>
                                    <th> Capital social </th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{ functionFormatPrice(totalCapitalSocial) }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="CapitalSocial.lista" hide-default-footer
                                        class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaCapitalSocial1) }}</td>
                                                <td>{{ functionFormatPrice(sumaCapitalSocial2) }}</td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Utilidades del ejercicio -->
                        <template>
                            <v-expansion-panel :value="panelOpen">
                                <v-expansion-panel-header>
                                    <th> Utilidades del ejercicio </th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{
                                            functionFormatPrice(totalUtilidadesEjercicio) }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="UtilidadesEjercicio.lista"
                                        hide-default-footer class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaUtilidadesEjercicio1) }}</td>
                                                <td>{{ functionFormatPrice(sumaUtilidadesEjercicio2) }}</td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <!-- Otros resultados integrales -->
                        <template>
                            <v-expansion-panel expand>
                                <v-expansion-panel-header>
                                    <th> Otros resultados integrales </th>
                                    <v-spacer></v-spacer>
                                    <tr>
                                        <th colspan="4" class="text-right">
                                            Total Diferencia de la Cuenta: {{
                                            functionFormatPrice(totalOtrosResultadosIntegrales) }}
                                        </th>
                                    </tr>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table :headers="headers" :items="OtrosResultadosIntegrales.lista"
                                        hide-default-footer class="elevation-0">
                                        <template v-slot:body.append>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{ functionFormatPrice(sumaOtrosResultadosIntegrales1) }}</td>
                                                <td>{{ functionFormatPrice(sumaOtrosResultadosIntegrales2) }}</td>
                                            </tr>
                                        </template>
                                        <template v-slot:item.primerAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.primerAnio) }}</span>
                                        </template>
                                        <template v-slot:item.segundoAnio="{ item }">
                                            <span>{{ functionFormatPrice(item.segundoAnio) }}</span>
                                        </template>
                                        <template v-slot:item.diferencia="{ item }">
                                            <span>{{ functionFormatPrice(item.diferencia) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>

                    </v-expansion-panels>

                </div>
                <br>
                <br>
                <br>
            </v-card-text>

        </v-card>

        <!--Dialog loading-->
        <v-dialog v-model="loading" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    {{loadingMessage}}
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>

        <!-- DIALOG PARA ESTADO DE FLUJO DE EFECTIVO -->
        <v-dialog v-model="dialogEstadoFlujoEfectivo" persistent transition="dialog-bottom-transition" width="1300">
            <EstadoFlujoEfectivo @cierraDialogEFE="closeEstadoFlujosEfectivo()"></EstadoFlujoEfectivo>
        </v-dialog>

    </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import print from 'print-js';
    import pdf from 'vue-pdf';
    import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"
    import html2pdf from "html2pdf.js";
    import EstadoFlujoEfectivo from '../ReportesEspeciales/FlujoEfectivoEstado'


    export default {
        name: 'Balanza',
        components: {
            pdf: pdf,
            EstadoFlujoEfectivo,
        },

        data: () => ({
            items: [{ 'Acreedores diversos': [] }],

            headers: [
                { text: 'Cuenta', value: 'cuenta' },
                { text: 'Primer año', value: 'primerAnio' },
                { text: 'Segundo año', value: 'segundoAnio' },
                { text: 'Diferencia', value: 'diferencia' }
            ],

            //LOADING
            loadingMessage: '',
            loading: false,

            //FECHAS
            fechaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            fechaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            calendario: false,
            calendario2: false,

            // SNACK BAR
            snackbarMessage: '',
            snackbar: false,
            cuentaEditar: {},
            itemsCuentaReemplazo: [],
            selectCuentaReemplazo: {},

            anios: '',
            anios2: '',

            balanza1: [],
            balanza2: [],
            balanzaCompleta: [],

            panelOpen: true, // Inicialmente abierto

            //Efectivo y equivalentes de efectivo
            totalEfectivoEquivalentesDeEfectivo: 0,
            sumaEfectivoEquivalentesDeEfectivo1: 0,
            sumaEfectivoEquivalentesDeEfectivo2: 0,
            //Instrumentos financieros de negociación
            totalInstrumentosFinancierosNegociación: 0,
            sumaInstrumentosFinancierosNegociación1: 0,
            sumaInstrumentosFinancierosNegociación2: 0,
            //Clientes
            totalClientes: 0,
            sumaClientes1: 0,
            sumaClientes2: 0,
            //Cuentas y documentos por cobrar
            totalCuentasDocumentosXCobrar: 0,
            sumaCuentasDocumentosXCobrar1: 0,
            sumaCuentasDocumentosXCobrar2: 0,
            //Deudores diversos
            totalDeudoresDiversos: 0,
            sumaDeudoresDiversos1: 0,
            sumaDeudoresDiversos2: 0,
            //Estimación de cuentas incobrables
            totalEstimaciónCuentasIncobrables: 0,
            sumaEstimaciónCuentasIncobrables1: 0,
            sumaEstimaciónCuentasIncobrables2: 0,
            //Impuesto por recuperar
            totalImpuestoXRecuperar: 0,
            sumaImpuestoXRecuperar1: 0,
            sumaImpuestoXRecuperar2: 0,
            //Inventarios
            totalInventarios: 0,
            sumaInventarios1: 0,
            sumaInventarios2: 0,
            //Pagos anticipados
            totalPagosAnticipados: 0,
            sumaPagosAnticipados1: 0,
            sumaPagosAnticipados2: 0,
            //Propiedades, plantas y equipo
            totalPropiedadesPlantasEquipo: 0,
            sumaPropiedadesPlantasEquipo1: 0,
            sumaPropiedadesPlantasEquipo2: 0,
            //Cuentas y documentos por cobrar a largo plazo
            totalCuentasDocumentosXCobrarLargoPlazo: 0,
            sumaCuentasDocumentosXCobrarLargoPlazo1: 0,
            sumaCuentasDocumentosXCobrarLargoPlazo2: 0,
            //Anticipo a proveedores
            totalAnticipoProveedores: 0,
            sumaAnticipoProveedores1: 0,
            sumaAnticipoProveedores2: 0,
            //Activos intangibles
            totalActivosIntangibles: 0,
            sumaActivosIntangibles1: 0,
            sumaActivosIntangibles2: 0,
            //Inversiones en asociadas
            totalInversionesAsociadas: 0,
            sumaInversionesAsociadas1: 0,
            sumaInversionesAsociadas2: 0,
            //Instrumentos financieros por cobrar a largo plazo
            totalInstrumentosFinancierosXCobrarLargoPlazo: 0,
            sumaInstrumentosFinancierosXCobrarLargoPlazo1: 0,
            sumaInstrumentosFinancierosXCobrarLargoPlazo2: 0,
            //Proveedores
            totalProveedores: 0,
            sumaProveedores1: 0,
            sumaProveedores2: 0,
            //Cuentas y documentos por pagar
            totalCuentasDocumentosXPagar: 0,
            sumaCuentasDocumentosXPagar1: 0,
            sumaCuentasDocumentosXPagar2: 0,
            //Instrumentos financieros a largo plazo
            totalInstrumentosFinancieros: 0,
            sumaInstrumentosFinancieros1: 0,
            sumaInstrumentosFinancieros2: 0,
            //Acreedores diversos
            totalAcreedoresDiversos: 0,
            sumaAcreedoresDiversos1: 0,
            sumaAcreedoresDiversos2: 0,
            //Anticipo de clientes
            totalAnticipoClientes: 0,
            sumaAnticipoClientes1: 0,
            sumaAnticipoClientes2: 0,
            //Provisiones
            totalProvisiones: 0,
            sumaProvisiones1: 0,
            sumaProvisiones2: 0,
            //Impuestos por pagar
            totalImpuestosXPagar: 0,
            sumaImpuestosXPagar1: 0,
            sumaImpuestosXPagar2: 0,
            //Cuentas y documentos por pagar a largo plazo
            totalCuentasDocumentosXPagarLargoPlazo: 0,
            sumaCuentasDocumentosXPagarLargoPlazo1: 0,
            sumaCuentasDocumentosXPagarLargoPlazo2: 0,
            //Instrumentos financieros a largo plazo
            totalInstrumentosFinancierosLargoPlazo: 0,
            sumaInstrumentosFinancierosLargoPlazo1: 0,
            sumaInstrumentosFinancierosLargoPlazo2: 0,
            //Aportaciones para futuros aumentos de capital
            totalAportacionesParaFuturosAumentosCapital: 0,
            sumaAportacionesParaFuturosAumentosCapital1: 0,
            sumaAportacionesParaFuturosAumentosCapital2: 0,
            //Impuesto a la utilidad diferido por pagar
            totalImpuestoUtilidadDiferidoXPagar: 0,
            sumaImpuestoUtilidadDiferidoXPagar1: 0,
            sumaImpuestoUtilidadDiferidoXPagar2: 0,
            //Beneficio a empleados
            totalBeneficioEmpleados: 0,
            sumaBeneficioEmpleados1: 0,
            sumaBeneficioEmpleados2: 0,
            //Provisiones a largo plazo
            totalProvisionesLargoPlazo: 0,
            sumaProvisionesLargoPlazo1: 0,
            sumaProvisionesLargoPlazo2: 0,
            //Capital social
            totalCapitalSocial: 0,
            sumaCapitalSocial1: 0,
            sumaCapitalSocial2: 0,
            //Utilidades del ejercicio
            totalUtilidadesEjercicio: 0,
            sumaUtilidadesEjercicio1: 0,
            sumaUtilidadesEjercicio2: 0,
            //Otros resultados integrales
            totalOtrosResultadosIntegrales: 0,
            sumaOtrosResultadosIntegrales1: 0,
            sumaOtrosResultadosIntegrales2: 0,

            //Efectivo y equivalentes de efectivo
            EfectivoEquivalentesDeEfectivo: {
                nombreCuenta: 'Efectivo y equivalentes de efectivo',
                lista: []
            },
            //Instrumentos financieros de negociación
            InstrumentosFinancierosNegociación: {
                nombreCuenta: 'Instrumentos financieros de negociación',
                lista: []
            },
            //Clientes
            Clientes: {
                nombreCuenta: 'Clientes',
                lista: []
            },
            //Cuentas y documentos por cobrar
            CuentasDocumentosXCobrar: {
                nombreCuenta: 'Cuentas y documentos por cobrar',
                lista: []
            },
            //Deudores diversos
            DeudoresDiversos: {
                nombreCuenta: 'Deudores diversos',
                lista: []
            },
            //Estimación de cuentas incobrables
            EstimaciónCuentasIncobrables: {
                nombreCuenta: 'Estimación de cuentas incobrables',
                lista: []
            },
            //Impuesto por recuperar
            ImpuestoXRecuperar: {
                nombreCuenta: 'Impuesto por recuperar',
                lista: []
            },
            //Inventarios
            Inventarios: {
                nombreCuenta: 'Inventarios',
                lista: []
            },
            //Pagos anticipados
            PagosAnticipados: {
                nombreCuenta: 'Pagos anticipados',
                lista: []
            },
            //Propiedades, plantas y equipo
            PropiedadesPlantasEquipo: {
                nombreCuenta: 'Propiedades, plantas y equipo',
                lista: []
            },
            //Cuentas y documentos por cobrar a largo plazo
            CuentasDocumentosXCobrarLargoPlazo: {
                nombreCuenta: 'Cuentas y documentos por cobrar a largo plazo',
                lista: []
            },
            //Anticipo a proveedores
            AnticipoProveedores: {
                nombreCuenta: 'Anticipo a proveedores',
                lista: []
            },
            //Activos intangibles
            ActivosIntangibles: {
                nombreCuenta: 'Activos intangibles',
                lista: []
            },
            //Inversiones en asociadas
            InversionesAsociadas: {
                nombreCuenta: 'Inversiones en asociadas',
                lista: []
            },
            //Instrumentos financieros por cobrar a largo plazo
            InstrumentosFinancierosXCobrarLargoPlazo: {
                nombreCuenta: 'Instrumentos financieros por cobrar a largo plazo',
                lista: []
            },
            //Proveedores
            Proveedores: {
                nombreCuenta: 'Proveedores',
                lista: []
            },
            //Cuentas y documentos por pagar
            CuentasDocumentosXPagar: {
                nombreCuenta: 'Cuentas y documentos por pagar',
                lista: []
            },
            //Instrumentos financieros
            InstrumentosFinancieros: {
                nombreCuenta: 'Instrumentos financieros',
                lista: []
            },
            //Acreedores diversos
            AcreedoresDiversos: {
                nombreCuenta: 'Acreedores diversos',
                lista: []
            },
            //Anticipo de clientes
            AnticipoClientes: {
                nombreCuenta: 'Anticipo de clientes',
                lista: []
            },
            //Provisiones
            Provisiones: {
                nombreCuenta: 'Provisiones',
                lista: []
            },
            //Impuestos por pagar
            ImpuestosXPagar: {
                nombreCuenta: 'Impuestos por pagar',
                lista: []
            },
            //Cuentas y documentos por pagar a largo plazo
            CuentasDocumentosXPagarLargoPlazo: {
                nombreCuenta: 'Cuentas y documentos por pagar a largo plazo',
                lista: []
            },
            //Instrumentos financieros a largo plazo
            InstrumentosFinancierosLargoPlazo: {
                nombreCuenta: 'Instrumentos financieros a largo plazo',
                lista: []
            },
            //Aportaciones para futuros aumentos de capital
            AportacionesParaFuturosAumentosCapital: {
                nombreCuenta: 'Aportaciones para futuros aumentos de capital',
                lista: []
            },
            //Impuesto a la utilidad diferido por pagar
            ImpuestoUtilidadDiferidoXPagar: {
                nombreCuenta: 'Impuesto a la utilidad diferido por pagar',
                lista: []
            },
            //Beneficio a empleados
            BeneficioEmpleados: {
                nombreCuenta: 'Beneficio a empleados',
                lista: []
            },
            //Provisiones a largo plazo
            ProvisionesLargoPlazo: {
                nombreCuenta: 'Provisiones a largo plazo',
                lista: []
            },
            //Capital social
            CapitalSocial: {
                nombreCuenta: 'Capital social',
                lista: []
            },
            //Utilidades del ejercicio
            UtilidadesEjercicio: {
                nombreCuenta: 'Utilidades del ejercicio',
                lista: []
            },
            //Otros resultados integrales
            OtrosResultadosIntegrales: {
                nombreCuenta: 'Otros resultados integrales',
                lista: []
            },

            panel: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29],

            dialogEstadoFlujoEfectivo: false,

            // Activos disponibles para venta
            itemsActivosDisponiblesVenta: [{cuenta:'Activos disponibles para venta', primerAnio:0,segundoAnio:0, diferencia:0}],
            headersActivosDisponiblesVenta: [
                { text: 'Cuenta', value: 'cuenta' },
                { text: 'Primer año', value: 'primerAnio' },
                { text: 'Segundo año', value: 'segundoAnio' },
                { text: 'Diferencia', value: 'diferencia' }
            ],

            // Impuestos a la utilidad por pagar
            itemsImpuestosUtilidadXPagar: [{cuenta:'Impuestos a la utilidad por pagar', primerAnio:0,segundoAnio:0, diferencia:0}],
            headersImpuestosUtilidadXPagar: [
                { text: 'Cuenta', value: 'cuenta' },
                { text: 'Primer año', value: 'primerAnio' },
                { text: 'Segundo año', value: 'segundoAnio' },
                { text: 'Diferencia', value: 'diferencia' }
            ],

            // Rentas cobradas por anticipado
            itemsRentasCobradasXAnticipado: [{cuenta:'Rentas cobradas por anticipado', primerAnio:0,segundoAnio:0, diferencia:0}],
            headersRentasCobradasXAnticipado: [
                { text: 'Cuenta', value: 'cuenta' },
                { text: 'Primer año', value: 'primerAnio' },
                { text: 'Segundo año', value: 'segundoAnio' },
                { text: 'Diferencia', value: 'diferencia' }
            ],

            // activosDisponiblesVenta: 0,
            rentasCobradasXAnticipado: 0,
            participacionControladora: 0,
            participacionNoControladora: 0,

        }),

        watch: {

        },

        computed: {
            LogoEmpresa() {
                return this.$store.state.empresaStore;
            },
            empresa() {
                return this.$store.state.usuario
            },
            sumCargoInicial() {
                let array = this.items.filter(x => x.nivel === 1)
                return array.map(item => item.saldoInicialDebe).reduce((prev, curr) => prev + curr, 0);
            },
            sumAbonoInicial() {
                let array = this.items.filter(x => x.nivel === 1)
                return array.map(item => item.saldoInicialHaber).reduce((prev, curr) => prev + curr, 0);
            },
            sumCargoMes() {
                let array = this.items.filter(x => x.nivel === 1)
                return array.map(item => item.cargo).reduce((prev, curr) => prev + curr, 0);
            },
            sumAbonoMes() {
                let array = this.items.filter(x => x.nivel === 1)
                return array.map(item => item.abono).reduce((prev, curr) => prev + curr, 0);
            },
            sumCargoFinal() {
                let array = this.items.filter(x => x.nivel === 1)
                return array.map(item => item.saldoFinalDebe).reduce((prev, curr) => prev + curr, 0);
            },
            sumAbonoFinal() {
                let array = this.items.filter(x => x.nivel === 1)
                return array.map(item => item.saldoFinalHaber).reduce((prev, curr) => prev + curr, 0);
            },
            datosAgrupados() {
                var categorias = this.functionAgruparDatos(this.lista);
                var datos = [];

                for (var categoria in categorias) {
                    if (categorias.hasOwnProperty(categoria)) {
                        datos.push({ categoria: categoria, items: categorias[categoria] });
                    }
                }

                return datos;
            },

            itemsAnios() {
                let fecha = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd');
                let anio = fecha.split('-')[0];
                let lista = []
                lista.push(anio);
                lista.push(anio - 1);
                lista.push(anio - 2);
                lista.push(anio - 3);
                lista.push(anio - 4);
                lista.push(anio - 5);

                return lista;
            },
            itemsAnios2() {
                let fecha = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd');
                let anio = fecha.split('-')[0];
                let lista = []
                lista.push(anio);
                lista.push(anio - 1);
                lista.push(anio - 2);
                lista.push(anio - 3);
                lista.push(anio - 4);
                lista.push(anio - 5);

                return lista;
            },

            totalEjercicio() {
                return this.listasCombinadas.reduce((total, item) => total + item.diferencia, 0);
            },
            //SE USA EN LA PANTALLA DE FLUJO DE EFECTIVO
            estadoResultado() {
                return this.$store.state.estadoResultado;
            },




        },

        created() {
            this.GetEmpresa();
        },

        methods: {
            listasCombinadas() {
                this.balanzaCompleta = [];

                for (let i = 0; i < this.balanza2.length; i++) {
                    // const cuenta = this.balanza1[i].nombreCuenta;
                    // const primerAnio = this.balanza1[i].saldoFinalDebe;
                    // const segundoAnio = this.balanza2.find(item => item.nombreCuenta === cuenta)?.saldoFinalDebe || 0;
                    // const diferencia = primerAnio - segundoAnio;
                    // const numeroCuenta = this.balanza1[i].numeroCuenta;

                    // this.balanzaCompleta.push({
                    //     cuenta,
                    //     primerAnio,
                    //     segundoAnio,
                    //     diferencia,
                    //     numeroCuenta
                    // });

                    var cuenta = this.balanza2[i].nombreCuenta;
                    var primerAnio = 0;
                    var segundoAnio = 0;

                    switch (this.balanza2[i].numeroCuenta) {
                        case ('170'):
                        case ('171'):
                        case ('172'):
                        case ('201'):
                        case ('202'):
                        case ('208'):
                        case ('209'):
                        case ('203'):
                        case ('204'):
                        case ('205'):
                        case ('206'):
                        case ('210'):
                        case ('211'):
                        case ('212'):
                        case ('213'):
                        case ('214'):
                        case ('215'):
                        case ('216'):
                        case ('217'):
                        case ('218'):
                        case ('251'):
                        case ('252'):
                        case ('253'):
                        case ('254'):
                        case ('301'):
                        case ('259'):
                        case ('255'):
                        case ('256'):
                        case ('257'):
                        case ('258'):
                        case ('301'):
                        case ('302'):
                        case ('303'):
                        case ('304'):
                        case ('305'):
                        case ('306'):
                            primerAnio = this.balanza2[i].saldoInicialHaber;
                            segundoAnio = this.balanza2[i].saldoFinalHaber;
                            // segundoAnio = this.balanza2.find(item => item.nombreCuenta === cuenta)?.saldoInicialHaber || 0;
                            break; // al encontrar este 'break' no se continuará con el siguiente 'default:'
                        default:
                            primerAnio = this.balanza2[i].saldoInicialDebe;
                            segundoAnio = this.balanza2[i].saldoFinalDebe;
                            // segundoAnio = this.balanza2.find(item => item.nombreCuenta === cuenta)?.saldoFinalDebe || 0;
                            break;
                    }

                    // primerAnio = this.balanza1[i].saldoFinalDebe;
                    // segundoAnio = this.balanza2.find(item => item.nombreCuenta === cuenta)?.saldoFinalDebe || 0;
                    var diferencia = primerAnio - segundoAnio;
                    var numeroCuenta = this.balanza2[i].numeroCuenta;

                    this.balanzaCompleta.push({
                        cuenta,
                        primerAnio,
                        segundoAnio,
                        diferencia,
                        numeroCuenta
                    });

                }

            },

            clasifica() {

                // LIMPIA LOS DATOS
                this.$store.state.listActividadesOperacion = [];

                //Efectivo y equivalentes de efectivo
                this.EfectivoEquivalentesDeEfectivo = {
                    nombreCuenta: 'Efectivo y equivalentes de efectivo',
                    lista: []
                };
                //Instrumentos financieros de negociación
                this.InstrumentosFinancierosNegociación = {
                    nombreCuenta: 'Instrumentos financieros de negociación',
                    lista: []
                };
                //Clientes
                this.Clientes = {
                    nombreCuenta: 'Clientes',
                    lista: []
                };
                //Cuentas y documentos por cobrar
                this.CuentasDocumentosXCobrar = {
                    nombreCuenta: 'Cuentas y documentos por cobrar',
                    lista: []
                };
                //Deudores diversos
                this.DeudoresDiversos = {
                    nombreCuenta: 'Deudores diversos',
                    lista: []
                };
                //Estimación de cuentas incobrables
                this.EstimaciónCuentasIncobrables = {
                    nombreCuenta: 'Estimación de cuentas incobrables',
                    lista: []
                };
                //Impuesto por recuperar
                this.ImpuestoXRecuperar = {
                    nombreCuenta: 'Impuesto por recuperar',
                    lista: []
                };
                //Inventarios
                this.Inventarios = {
                    nombreCuenta: 'Inventarios',
                    lista: []
                };
                //Pagos anticipados
                this.PagosAnticipados = {
                    nombreCuenta: 'Pagos anticipados',
                    lista: []
                };
                //Propiedades, plantas y equipo
                this.PropiedadesPlantasEquipo = {
                    nombreCuenta: 'Propiedades, plantas y equipo',
                    lista: []
                };
                //Cuentas y documentos por cobrar a largo plazo
                this.CuentasDocumentosXCobrarLargoPlazo = {
                    nombreCuenta: 'Cuentas y documentos por cobrar a largo plazo',
                    lista: []
                };
                //Anticipo a proveedores
                this.AnticipoProveedores = {
                    nombreCuenta: 'Anticipo a proveedores',
                    lista: []
                };
                //Activos intangibles
                this.ActivosIntangibles = {
                    nombreCuenta: 'Activos intangibles',
                    lista: []
                };
                //Inversiones en asociadas
                this.InversionesAsociadas = {
                    nombreCuenta: 'Inversiones en asociadas',
                    lista: []
                };
                //Instrumentos financieros por cobrar a largo plazo
                this.InstrumentosFinancierosXCobrarLargoPlazo = {
                    nombreCuenta: 'Instrumentos financieros por cobrar a largo plazo',
                    lista: []
                };
                //Proveedores
                this.Proveedores = {
                    nombreCuenta: 'Proveedores',
                    lista: []
                };
                //Cuentas y documentos por pagar
                this.CuentasDocumentosXPagar = {
                    nombreCuenta: 'Cuentas y documentos por pagar',
                    lista: []
                };
                //Instrumentos financieros
                this.InstrumentosFinancieros = {
                    nombreCuenta: 'Instrumentos financieros',
                    lista: []
                };
                //Acreedores diversos
                this.AcreedoresDiversos = {
                    nombreCuenta: 'Acreedores diversos',
                    lista: []
                };
                //Anticipo de clientes
                this.AnticipoClientes = {
                    nombreCuenta: 'Anticipo de clientes',
                    lista: []
                };
                //Provisiones
                this.Provisiones = {
                    nombreCuenta: 'Provisiones',
                    lista: []
                };
                //Impuestos por pagar
                this.ImpuestosXPagar = {
                    nombreCuenta: 'Impuestos por pagar',
                    lista: []
                };
                //Cuentas y documentos por pagar a largo plazo
                this.CuentasDocumentosXPagarLargoPlazo = {
                    nombreCuenta: 'Cuentas y documentos por pagar a largo plazo',
                    lista: []
                };
                //Instrumentos financieros a largo plazo
                this.InstrumentosFinancierosLargoPlazo = {
                    nombreCuenta: 'Instrumentos financieros a largo plazo',
                    lista: []
                };
                //Aportaciones para futuros aumentos de capital
                this.AportacionesParaFuturosAumentosCapital = {
                    nombreCuenta: 'Aportaciones para futuros aumentos de capital',
                    lista: []
                };
                //Impuesto a la utilidad diferido por pagar
                this.ImpuestoUtilidadDiferidoXPagar = {
                    nombreCuenta: 'Impuesto a la utilidad diferido por pagar',
                    lista: []
                };
                //Beneficio a empleados
                this.BeneficioEmpleados = {
                    nombreCuenta: 'Beneficio a empleados',
                    lista: []
                };
                //Provisiones a largo plazo
                this.ProvisionesLargoPlazo = {
                    nombreCuenta: 'Provisiones a largo plazo',
                    lista: []
                };
                //Capital social
                this.CapitalSocial = {
                    nombreCuenta: 'Capital social',
                    lista: []
                };
                //Utilidades del ejercicio
                this.UtilidadesEjercicio = {
                    nombreCuenta: 'Utilidades del ejercicio',
                    lista: []
                };
                //Otros resultados integrales
                this.OtrosResultadosIntegrales = {
                    nombreCuenta: 'Otros resultados integrales',
                    lista: []
                };


                for (let a of this.balanzaCompleta) {

                    switch (a.numeroCuenta) {
                        // Efectivo y equivalentes de efectivo
                        case ('101'):
                        case ('102'):
                        case ('103'):
                            this.EfectivoEquivalentesDeEfectivo.lista.push(a)
                            break;
                        //Instrumentos financieros de negociación
                        case ('104'):
                            this.InstrumentosFinancierosNegociación.lista.push(a)
                            break;
                        // Clientes
                        case ('105'):
                            this.Clientes.lista.push(a)
                            break;
                        // Cuentas y documentos por cobrar
                        case ('106'):
                        case ('118'):
                        case ('119'):
                            this.CuentasDocumentosXCobrar.lista.push(a)
                            break;
                        // Deudores diversos
                        case ('107'):
                            this.DeudoresDiversos.lista.push(a)
                            break;
                        // Estimación de cuentas incobrables
                        case ('108'):
                            this.EstimaciónCuentasIncobrables.lista.push(a)
                            break;
                        // Impuesto por recuperar
                        case ('110'):
                        case ('113'):
                            this.ImpuestoXRecuperar.lista.push(a)
                            break;
                        // Inventarios
                        case ('115'):
                            this.Inventarios.lista.push(a)
                            break;
                        // Pagos anticipados
                        case ('109'):
                        case ('111'):
                        case ('112'):
                            this.PagosAnticipados.lista.push(a)
                            break;
                        // Propiedades, plantas y equipo
                        case ('151'):
                        case ('152'):
                        case ('153'):
                        case ('154'):
                        case ('155'):
                        case ('156'):
                        case ('157'):
                        case ('158'):
                        case ('159'):
                        case ('160'):
                        case ('161'):
                        case ('162'):
                        case ('163'):
                        case ('164'):
                        case ('165'):
                        case ('166'):
                        case ('167'):
                        case ('168'):
                        case ('169'):
                        case ('170'):
                        case ('171'):
                        case ('172'):
                            this.PropiedadesPlantasEquipo.lista.push(a)
                            break;
                        // Cuentas y documentos por cobrar a largo plazo
                        case ('186'):
                            this.CuentasDocumentosXCobrarLargoPlazo.lista.push(a)
                            break;
                        // Anticipo a proveedores
                        case ('120'):
                            this.AnticipoProveedores.lista.push(a)
                            break;
                        // Activos intangibles
                        case ('173'):
                        case ('174'):
                        case ('175'):
                        case ('176'):
                        case ('177'):
                        case ('178'):
                        case ('179'):
                        case ('180'):
                        case ('181'):
                        case ('182'):
                        case ('183'):
                        case ('184'):
                        case ('185'):
                            this.ActivosIntangibles.lista.push(a)
                            break;
                        // Inversiones en asociadas
                        case ('188'):
                        case ('189'):
                            this.InversionesAsociadas.lista.push(a)
                            break;
                        // Instrumentos financieros por cobrar a largo plazo
                        case ('190'):
                        case ('191'):
                            this.InstrumentosFinancierosXCobrarLargoPlazo.lista.push(a)
                            break;
                        // Proveedores
                        case ('201'):
                            this.Proveedores.lista.push(a)
                            break;
                        // Cuentas y documentos por pagar
                        case ('202'):
                        case ('203'):
                        case ('208'):
                        case ('209'):
                            this.CuentasDocumentosXPagar.lista.push(a)
                            break;
                        // Instrumentos financieros
                        case ('204'):
                            this.InstrumentosFinancieros.lista.push(a)
                            break;
                        // Acreedores diversos
                        case ('205'):
                            this.AcreedoresDiversos.lista.push(a)
                            break;
                        // Anticipo de clientes
                        case ('206'):
                            this.AnticipoClientes.lista.push(a)
                            break;
                        // Provisiones
                        case ('210'):
                        case ('211'):
                        case ('212'):
                            this.Provisiones.lista.push(a)
                            break;
                        // Impuestos por pagar
                        case ('213'):
                        case ('214'):
                        case ('215'):
                        case ('216'):
                        case ('217'):
                            this.ImpuestosXPagar.lista.push(a)
                            break;
                        // Cuentas y documentos por pagar a largo plazo
                        case ('218'):
                        case ('251'):
                        case ('252'):
                        case ('253'):
                            this.CuentasDocumentosXPagarLargoPlazo.lista.push(a)
                            break;
                        // Instrumentos financieros a largo plazo
                        case ('254'):
                            this.InstrumentosFinancierosLargoPlazo.lista.push(a)
                            break;
                        // Aportaciones para futuros aumentos de capital
                        case ('301'):
                            this.AportacionesParaFuturosAumentosCapital.lista.push(a)
                            break;
                        // Impuesto a la utilidad diferido por pagar
                        case ('259'):
                            this.ImpuestoUtilidadDiferidoXPagar.lista.push(a)
                            break;
                        // Beneficio a empleados
                        case ('255'):
                            this.BeneficioEmpleados.lista.push(a)
                            break;
                        // Provisiones a largo plazo
                        case ('256'):
                        case ('257'):
                        case ('258'):
                            this.ProvisionesLargoPlazo.lista.push(a)
                            break;
                        // Capital social
                        case ('301'):
                        case ('302'):
                        case ('303'):
                            this.CapitalSocial.lista.push(a)
                            break;
                        // Utilidades del ejercicio
                        case ('304'):
                        case ('305'):
                            this.UtilidadesEjercicio.lista.push(a)
                            break;
                        // Otros resultados integrales
                        case ('306'):
                            this.OtrosResultadosIntegrales.lista.push(a)
                            break;
                        // default:
                        //     this.cuenta1.lista.push('Sin categoria');
                        //     break;

                    }
                }
                //Efectivo y equivalentes de efectivo
                this.totalEfectivoEquivalentesDeEfectivo = this.sum(this.EfectivoEquivalentesDeEfectivo.lista, 'diferencia');
                this.sumaEfectivoEquivalentesDeEfectivo1 = this.sum(this.EfectivoEquivalentesDeEfectivo.lista, 'primerAnio');
                this.sumaEfectivoEquivalentesDeEfectivo2 = this.sum(this.EfectivoEquivalentesDeEfectivo.lista, 'segundoAnio');
                //Instrumentos financieros de negociación
                this.totalInstrumentosFinancierosNegociación = this.sum(this.InstrumentosFinancierosNegociación.lista, 'diferencia');
                this.sumaInstrumentosFinancierosNegociación1 = this.sum(this.InstrumentosFinancierosNegociación.lista, 'primerAnio');
                this.sumaInstrumentosFinancierosNegociación2 = this.sum(this.InstrumentosFinancierosNegociación.lista, 'segundoAnio');
                //Clientes
                this.totalClientes = this.sum(this.Clientes.lista, 'diferencia');
                this.sumaClientes1 = this.sum(this.Clientes.lista, 'primerAnio');
                this.sumaClientes2 = this.sum(this.Clientes.lista, 'segundoAnio');
                //Cuentas y documentos por cobrar
                this.totalCuentasDocumentosXCobrar = this.sum(this.CuentasDocumentosXCobrar.lista, 'diferencia');
                this.sumaCuentasDocumentosXCobrar1 = this.sum(this.CuentasDocumentosXCobrar.lista, 'primerAnio');
                this.sumaCuentasDocumentosXCobrar2 = this.sum(this.CuentasDocumentosXCobrar.lista, 'segundoAnio');
                //Deudores diversos
                this.totalDeudoresDiversos = this.sum(this.DeudoresDiversos.lista, 'diferencia');
                this.sumaDeudoresDiversos1 = this.sum(this.DeudoresDiversos.lista, 'primerAnio');
                this.sumaDeudoresDiversos2 = this.sum(this.DeudoresDiversos.lista, 'segundoAnio');
                //Estimación de cuentas incobrables
                this.totalEstimaciónCuentasIncobrables = this.sum(this.EstimaciónCuentasIncobrables.lista, 'diferencia');
                this.sumaEstimaciónCuentasIncobrables1 = this.sum(this.EstimaciónCuentasIncobrables.lista, 'primerAnio');
                this.sumaEstimaciónCuentasIncobrables2 = this.sum(this.EstimaciónCuentasIncobrables.lista, 'segundoAnio');
                //Impuesto por recuperar
                this.totalImpuestoXRecuperar = this.sum(this.ImpuestoXRecuperar.lista, 'diferencia');
                this.sumaImpuestoXRecuperar1 = this.sum(this.ImpuestoXRecuperar.lista, 'primerAnio');
                this.sumaImpuestoXRecuperar2 = this.sum(this.ImpuestoXRecuperar.lista, 'segundoAnio');
                //Inventarios
                this.totalInventarios = this.sum(this.Inventarios.lista, 'diferencia');
                this.sumaInventarios1 = this.sum(this.Inventarios.lista, 'primerAnio');
                this.sumaInventarios2 = this.sum(this.Inventarios.lista, 'segundoAnio');
                //Pagos anticipados
                this.totalPagosAnticipados = this.sum(this.PagosAnticipados.lista, 'diferencia');
                this.sumaPagosAnticipados1 = this.sum(this.PagosAnticipados.lista, 'primerAnio');
                this.sumaPagosAnticipados2 = this.sum(this.PagosAnticipados.lista, 'segundoAnio');
                //Propiedades, plantas y equipo
                this.totalPropiedadesPlantasEquipo = this.sum(this.PropiedadesPlantasEquipo.lista, 'diferencia');
                this.sumaPropiedadesPlantasEquipo1 = this.sum(this.PropiedadesPlantasEquipo.lista, 'primerAnio');
                this.sumaPropiedadesPlantasEquipo2 = this.sum(this.PropiedadesPlantasEquipo.lista, 'segundoAnio');
                //Cuentas y documentos por cobrar a largo plazo
                this.totalCuentasDocumentosXCobrarLargoPlazo = this.sum(this.CuentasDocumentosXCobrarLargoPlazo.lista, 'diferencia');
                this.sumaCuentasDocumentosXCobrarLargoPlazo1 = this.sum(this.CuentasDocumentosXCobrarLargoPlazo.lista, 'primerAnio');
                this.sumaCuentasDocumentosXCobrarLargoPlazo2 = this.sum(this.CuentasDocumentosXCobrarLargoPlazo.lista, 'segundoAnio');
                //Anticipo a proveedores
                this.totalAnticipoProveedores = this.sum(this.AnticipoProveedores.lista, 'diferencia');
                this.sumaAnticipoProveedores1 = this.sum(this.AnticipoProveedores.lista, 'primerAnio');
                this.sumaAnticipoProveedores2 = this.sum(this.AnticipoProveedores.lista, 'segundoAnio');
                //Activos intangibles
                this.totalActivosIntangibles = this.sum(this.ActivosIntangibles.lista, 'diferencia');
                this.sumaActivosIntangibles1 = this.sum(this.ActivosIntangibles.lista, 'primerAnio');
                this.sumaActivosIntangibles2 = this.sum(this.ActivosIntangibles.lista, 'segundoAnio');
                //Inversiones en asociadas
                this.totalInversionesAsociadas = this.sum(this.InversionesAsociadas.lista, 'diferencia');
                this.sumaInversionesAsociadas1 = this.sum(this.InversionesAsociadas.lista, 'primerAnio');
                this.sumaInversionesAsociadas2 = this.sum(this.InversionesAsociadas.lista, 'segundoAnio');
                //Instrumentos financieros por cobrar a largo plazo
                this.totalInstrumentosFinancierosXCobrarLargoPlazo = this.sum(this.InstrumentosFinancierosXCobrarLargoPlazo.lista, 'diferencia');
                this.sumaInstrumentosFinancierosXCobrarLargoPlazo1 = this.sum(this.InstrumentosFinancierosXCobrarLargoPlazo.lista, 'primerAnio');
                this.sumaInstrumentosFinancierosXCobrarLargoPlazo2 = this.sum(this.InstrumentosFinancierosXCobrarLargoPlazo.lista, 'segundoAnio');
                //Proveedores
                this.totalProveedores = this.sum(this.Proveedores.lista, 'diferencia');
                this.sumaProveedores1 = this.sum(this.Proveedores.lista, 'primerAnio');
                this.sumaProveedores2 = this.sum(this.Proveedores.lista, 'segundoAnio');
                // Cuentas y documentos por pagar
                this.totalCuentasDocumentosXPagar = this.sum(this.CuentasDocumentosXPagar.lista, 'diferencia');
                this.sumaCuentasDocumentosXPagar1 = this.sum(this.CuentasDocumentosXPagar.lista, 'primerAnio');
                this.sumaCuentasDocumentosXPagar2 = this.sum(this.CuentasDocumentosXPagar.lista, 'segundoAnio');
                //Instrumentos financieros a largo plazo
                this.totalInstrumentosFinancieros = this.sum(this.InstrumentosFinancieros.lista, 'diferencia');
                this.sumaInstrumentosFinancieros1 = this.sum(this.InstrumentosFinancieros.lista, 'primerAnio');
                this.sumaInstrumentosFinancieros2 = this.sum(this.InstrumentosFinancieros.lista, 'segundoAnio');
                // Acreedores diversos
                this.totalAcreedoresDiversos = this.sum(this.AcreedoresDiversos.lista, 'diferencia');
                this.sumaAcreedoresDiversos1 = this.sum(this.AcreedoresDiversos.lista, 'primerAnio');
                this.sumaAcreedoresDiversos2 = this.sum(this.AcreedoresDiversos.lista, 'segundoAnio');
                //Anticipo de clientes
                this.totalAnticipoClientes = this.sum(this.AnticipoClientes.lista, 'diferencia');
                this.sumaAnticipoClientes1 = this.sum(this.AnticipoClientes.lista, 'primerAnio');
                this.sumaAnticipoClientes2 = this.sum(this.AnticipoClientes.lista, 'segundoAnio');
                //Provisiones
                this.totalProvisiones = this.sum(this.Provisiones.lista, 'diferencia');
                this.sumaProvisiones1 = this.sum(this.Provisiones.lista, 'primerAnio');
                this.sumaProvisiones2 = this.sum(this.Provisiones.lista, 'segundoAnio');
                //Impuestos por pagar
                this.totalImpuestosXPagar = this.sum(this.ImpuestosXPagar.lista, 'diferencia');
                this.sumaImpuestosXPagar1 = this.sum(this.ImpuestosXPagar.lista, 'primerAnio');
                this.sumaImpuestosXPagar2 = this.sum(this.ImpuestosXPagar.lista, 'segundoAnio');
                //Cuentas y documentos por pagar a largo plazo
                this.totalCuentasDocumentosXPagarLargoPlazo = this.sum(this.CuentasDocumentosXPagarLargoPlazo.lista, 'diferencia');
                this.sumaCuentasDocumentosXPagarLargoPlazo1 = this.sum(this.CuentasDocumentosXPagarLargoPlazo.lista, 'primerAnio');
                this.sumaCuentasDocumentosXPagarLargoPlazo2 = this.sum(this.CuentasDocumentosXPagarLargoPlazo.lista, 'segundoAnio');
                //Instrumentos financieros a largo plazo
                this.totalInstrumentosFinancierosLargoPlazo = this.sum(this.InstrumentosFinancierosLargoPlazo.lista, 'diferencia');
                this.sumaInstrumentosFinancierosLargoPlazo1 = this.sum(this.InstrumentosFinancierosLargoPlazo.lista, 'primerAnio');
                this.sumaInstrumentosFinancierosLargoPlazo2 = this.sum(this.InstrumentosFinancierosLargoPlazo.lista, 'segundoAnio');
                //Aportaciones para futuros aumentos de capital
                this.totalAportacionesParaFuturosAumentosCapital = this.sum(this.AportacionesParaFuturosAumentosCapital.lista, 'diferencia');
                this.sumaAportacionesParaFuturosAumentosCapital1 = this.sum(this.AportacionesParaFuturosAumentosCapital.lista, 'primerAnio');
                this.sumaAportacionesParaFuturosAumentosCapital2 = this.sum(this.AportacionesParaFuturosAumentosCapital.lista, 'segundoAnio');
                //Impuesto a la utilidad diferido por pagar
                this.totalImpuestoUtilidadDiferidoXPagar = this.sum(this.ImpuestoUtilidadDiferidoXPagar.lista, 'diferencia');
                this.sumaImpuestoUtilidadDiferidoXPagar1 = this.sum(this.ImpuestoUtilidadDiferidoXPagar.lista, 'primerAnio');
                this.sumaImpuestoUtilidadDiferidoXPagar2 = this.sum(this.ImpuestoUtilidadDiferidoXPagar.lista, 'segundoAnio');
                //Beneficio a empleados
                this.totalBeneficioEmpleados = this.sum(this.BeneficioEmpleados.lista, 'diferencia');
                this.sumaBeneficioEmpleados1 = this.sum(this.BeneficioEmpleados.lista, 'primerAnio');
                this.sumaBeneficioEmpleados2 = this.sum(this.BeneficioEmpleados.lista, 'segundoAnio');
                //Provisiones a largo plazo
                this.totalProvisionesLargoPlazo = this.sum(this.ProvisionesLargoPlazo.lista, 'diferencia');
                this.sumaProvisionesLargoPlazo1 = this.sum(this.ProvisionesLargoPlazo.lista, 'primerAnio');
                this.sumaProvisionesLargoPlazo2 = this.sum(this.ProvisionesLargoPlazo.lista, 'segundoAnio');
                //Capital social
                this.totalCapitalSocial = this.sum(this.CapitalSocial.lista, 'diferencia');
                this.sumaCapitalSocial1 = this.sum(this.CapitalSocial.lista, 'primerAnio');
                this.sumaCapitalSocial2 = this.sum(this.CapitalSocial.lista, 'segundoAnio');
                //Utilidades del ejercicio
                this.totalUtilidadesEjercicio = this.sum(this.UtilidadesEjercicio.lista, 'diferencia');
                this.sumaUtilidadesEjercicio1 = this.sum(this.UtilidadesEjercicio.lista, 'primerAnio');
                this.sumaUtilidadesEjercicio2 = this.sum(this.UtilidadesEjercicio.lista, 'segundoAnio');
                //Otros resultados integrales
                this.totalOtrosResultadosIntegrales = this.sum(this.OtrosResultadosIntegrales.lista, 'diferencia');
                this.sumaOtrosResultadosIntegrales1 = this.sum(this.OtrosResultadosIntegrales.lista, 'primerAnio');
                this.sumaOtrosResultadosIntegrales2 = this.sum(this.OtrosResultadosIntegrales.lista, 'segundoAnio');

                var listaActividades = [];
                //Clientes
                var cuenta = this.Clientes.nombreCuenta;
                if (this.totalClientes === undefined) {
                    var total = 0
                } else {
                    var total = (this.totalClientes) * -1;
                }
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });
                //Cuentas y documentos por cobrar
                cuenta = this.CuentasDocumentosXCobrar.nombreCuenta;
                if (this.totalCuentasDocumentosXCobrar === undefined) {
                    total = 0
                } else {
                    total = (this.totalCuentasDocumentosXCobrar) * -1;
                }
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });
                //Deudores diversos
                cuenta = this.DeudoresDiversos.nombreCuenta;
                if (this.totalDeudoresDiversos === undefined) {
                    total = 0
                } else {
                    total = (this.totalDeudoresDiversos) * -1;
                }
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });
                // Estimación de cuentas incobrables
                cuenta = this.EstimaciónCuentasIncobrables.nombreCuenta;
                if (this.totalEstimaciónCuentasIncobrables === undefined) {
                    total = 0
                } else {
                    total = (this.totalEstimaciónCuentasIncobrables) * -1;
                }
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });
                //Impuesto por recuperar
                cuenta = this.ImpuestoXRecuperar.nombreCuenta;
                if (this.totalImpuestoXRecuperar === undefined) {
                    total = 0
                } else {
                    total = (this.totalImpuestoXRecuperar) * -1;
                }
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });
                //Inventarios
                cuenta = this.Inventarios.nombreCuenta;
                if (this.totalInventarios === undefined) {
                    total = 0
                } else {
                    total = (this.totalInventarios) * -1;
                }
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });
                //Pagos anticipados
                cuenta = this.PagosAnticipados.nombreCuenta;
                if (this.totalPagosAnticipados === undefined) {
                    total = 0
                } else {
                    total = (this.totalPagosAnticipados) * -1;
                }
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });
                //Activos disponibles para venta
                //BUSCAMOS SI ES QUE YA EXISTE UN REGISTO Y DE SER ASI LO ELIMINAMOS PARA AGREGARLO NUEVAMENTE,
                //ESTO EN EL CASO DE QUE REALICEN PRIMERO LA BUSQUEDA Y DESPUES LA INSERCION A MANO O VICEVERSA
                var indexToRemove = this.$store.state.listActividadesOperacion.findIndex(item => item.cuenta === 'Activos disponibles para venta');
                if (indexToRemove !== -1) {
                    // Eliminar el elemento usando splice()
                    this.$store.state.listActividadesOperacion.splice(indexToRemove, 1);
                }
                cuenta = 'Activos disponibles para venta'
                total = (this.itemsActivosDisponiblesVenta[0].diferencia) *-1
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });
                //Cuentas y documentos por cobrar a largo plazo
                cuenta = this.CuentasDocumentosXCobrarLargoPlazo.nombreCuenta;
                if (this.totalCuentasDocumentosXCobrarLargoPlazo === undefined) {
                    total = 0
                } else {
                    total = (this.totalCuentasDocumentosXCobrarLargoPlazo) * -1;
                }
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });
                //Anticipo a proveedores
                cuenta = this.AnticipoProveedores.nombreCuenta;
                if (this.totalAnticipoProveedores === undefined) {
                    total = 0
                } else {
                    total = (this.totalAnticipoProveedores) * -1;
                }
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });
                //Activos intangibles
                cuenta = this.ActivosIntangibles.nombreCuenta;
                if (this.totalActivosIntangibles === undefined) {
                    total = 0
                } else {
                    total = (this.totalActivosIntangibles) * -1;
                }
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });
                //Inversiones en asociadas
                cuenta = this.InversionesAsociadas.nombreCuenta;
                if (this.totalInversionesAsociadas === undefined) {
                    total = 0
                } else {
                    total = (this.totalInversionesAsociadas) * -1;
                }
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });
                //Proveedores
                cuenta = this.Proveedores.nombreCuenta;
                if (this.totalProveedores === undefined) {
                    total = 0
                } else {
                    total = this.totalProveedores;
                }
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });
                // Cuentas y documentos por pagar
                cuenta = this.CuentasDocumentosXPagar.nombreCuenta;
                if (this.totalCuentasDocumentosXPagar === undefined) {
                    total = 0
                } else {
                    total = this.totalCuentasDocumentosXPagar;
                }
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });
                //Instrumentos financieros de negociación
                cuenta = this.InstrumentosFinancierosNegociación.nombreCuenta;
                if (this.totalInstrumentosFinancierosNegociación === undefined) {
                    total = 0
                } else {
                    total = this.totalInstrumentosFinancierosNegociación;
                }
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });
                // Acreedores diversos
                cuenta = this.AcreedoresDiversos.nombreCuenta;
                if (this.totalAcreedoresDiversos === undefined) {
                    total = 0
                } else {
                    total = this.totalAcreedoresDiversos;
                }
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });
                //Impuestos a la utilidad por pagar
                //BUSCAMOS SI ES QUE YA EXISTE UN REGISTO Y DE SER ASI LO ELIMINAMOS PARA AGREGARLO NUEVAMENTE,
                //ESTO EN EL CASO DE QUE REALICEN PRIMERO LA BUSQUEDA Y DESPUES LA INSERCION A MANO O VICEVERSA
                indexToRemove = this.$store.state.listActividadesOperacion.findIndex(item => item.cuenta === 'Impuestos a la utilidad por pagar');
                if (indexToRemove !== -1) {
                    // Eliminar el elemento usando splice()
                    this.$store.state.listActividadesOperacion.splice(indexToRemove, 1);
                }
                cuenta = 'Impuestos a la utilidad por pagar'
                total = this.itemsImpuestosUtilidadXPagar[0].diferencia
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });
                // Anticipo de clientes
                cuenta = this.AnticipoClientes.nombreCuenta;
                if (this.totalAnticipoClientes === undefined) {
                    total = 0
                } else {
                    total = this.totalAnticipoClientes;
                }
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });
                //Impuestos por pagar
                cuenta = this.ImpuestosXPagar.nombreCuenta;
                if (this.totalImpuestosXPagar === undefined) {
                    total = 0
                } else {
                    total = this.totalImpuestosXPagar;
                }
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });
                //Cuentas y documentos por pagar a largo plazo
                cuenta = this.CuentasDocumentosXPagarLargoPlazo.nombreCuenta;
                if (this.totalCuentasDocumentosXPagarLargoPlazo === undefined) {
                    total = 0
                } else {
                    total = this.totalCuentasDocumentosXPagarLargoPlazo;
                }
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });
                //Instrumentos financieros a largo plazo
                cuenta = this.InstrumentosFinancierosLargoPlazo.nombreCuenta;
                if (this.totalInstrumentosFinancierosLargoPlazo === undefined) {
                    total = 0
                } else {
                    total = this.totalInstrumentosFinancierosLargoPlazo;
                }
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });
                //Rentas cobradas por anticipado
                //BUSCAMOS SI ES QUE YA EXISTE UN REGISTO Y DE SER ASI LO ELIMINAMOS PARA AGREGARLO NUEVAMENTE,
                //ESTO EN EL CASO DE QUE REALICEN PRIMERO LA BUSQUEDA Y DESPUES LA INSERCION A MANO O VICEVERSA
                indexToRemove = this.$store.state.listActividadesOperacion.findIndex(item => item.cuenta === 'Rentas cobradas por anticipado');
                if (indexToRemove !== -1) {
                    // Eliminar el elemento usando splice()
                    this.$store.state.listActividadesOperacion.splice(indexToRemove, 1);
                }
                cuenta = 'Rentas cobradas por anticipado'
                total = this.itemsRentasCobradasXAnticipado[0].diferencia
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });
                //Aportaciones para futuros aumentos de capital
                cuenta = this.AportacionesParaFuturosAumentosCapital.nombreCuenta;
                if (this.totalAportacionesParaFuturosAumentosCapital === undefined) {
                    total = 0
                } else {
                    total = this.totalAportacionesParaFuturosAumentosCapital;
                }
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });
                //Impuesto a la utilidad diferido por pagar
                cuenta = this.ImpuestoUtilidadDiferidoXPagar.nombreCuenta;
                if (this.totalImpuestoUtilidadDiferidoXPagar === undefined) {
                    total = 0
                } else {
                    total = this.totalImpuestoUtilidadDiferidoXPagar;
                }
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });
                //Beneficio a empleados
                cuenta = this.BeneficioEmpleados.nombreCuenta;
                if (this.totalBeneficioEmpleados === undefined) {
                    total = 0
                } else {
                    total = this.totalBeneficioEmpleados;
                }
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });
                //Provisiones a largo plazo
                cuenta = this.ProvisionesLargoPlazo.nombreCuenta;
                if (this.totalProvisionesLargoPlazo === undefined) {
                    total = 0
                } else {
                    total = this.totalProvisionesLargoPlazo;
                }
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });

                //ACTIVIDADES DE INVERSION
                // Propiedades, plantas y equipo
                cuenta = this.PropiedadesPlantasEquipo.nombreCuenta;
                
                for (let ncuenta of this.PropiedadesPlantasEquipo.lista) {

                    if (ncuenta.numeroCuenta == '171') {
                        ncuenta.primerAnio = ncuenta.primerAnio * -1
                        ncuenta.segundoAnio = ncuenta.segundoAnio * -1
                    }


                }

                // console.log('ppe', this.PropiedadesPlantasEquipo.lista[2].numeroCuenta)

                if (this.totalPropiedadesPlantasEquipo === undefined) {
                    total = 0
                } else {
                    total = (this.totalPropiedadesPlantasEquipo) * -1;
                }
                this.$store.state.listActividadesInversion.push({
                    cuenta,
                    total,
                });

                // ACTIVIDADES DE FINANCIAMIENTO
                //Capital social
                cuenta = this.CapitalSocial.nombreCuenta;
                if (this.totalCapitalSocial === undefined) {
                    total = 0
                } else {
                    total = this.totalCapitalSocial;
                }
                this.$store.state.listActividadesFinanciamiento.push({
                    cuenta,
                    total,
                });
                //Utilidades del ejercicio
                cuenta = this.UtilidadesEjercicio.nombreCuenta;
                if (this.totalUtilidadesEjercicio === undefined) {
                    total = 0
                } else {
                    total = this.totalUtilidadesEjercicio;
                }
                this.$store.state.listActividadesFinanciamiento.push({
                    cuenta,
                    total,
                });
                //Otros resultados integrales
                cuenta = this.OtrosResultadosIntegrales.nombreCuenta;
                if (this.totalOtrosResultadosIntegrales === undefined) {
                    total = 0
                } else {
                    total = this.totalOtrosResultadosIntegrales;
                }
                this.$store.state.listActividadesFinanciamiento.push({
                    cuenta,
                    total,
                });

                //EFECTIVO AL INICIO DEL EJERCICIO
                if (this.sumaEfectivoEquivalentesDeEfectivo2 === undefined) {
                    this.$store.state.efectivoInicioEjercicioStore = 0
                } else {
                    this.$store.state.efectivoInicioEjercicioStore = this.sumaEfectivoEquivalentesDeEfectivo2;
                }

                // console.log(this.$store.state.listActividadesOperacion)

            },
            sum(array, key) {
                return array.reduce((a, b) => a + (b[key] || 0), 0);
            },

            functionFormatPrice(value) {
                let val = (value / 1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },

            functionFormatPrice(value) {
                let val = (value / 1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            functionFormatDate(value) {
                let fecha = moment(value).format('DD/MM/yyyy')
                return fecha;
            },
            async getBalanzas() {
                // await this.GetBalanza1()
                await this.GetBalanza2()
                await this.listasCombinadas();
                await this.clasifica();
                await this.GetEstadoResultados();
            },

            async GetBalanza1() {
                this.loadingMessage = 'Consultando...'
                this.loading = true;
                var listaB1 = []
                var fechaIniAntigua = this.anios + '-01-01'
                var fechaFinAntigua = this.anios + '-12-31'
                try {
                    let response = await axios.get('ReportesGenerales/GetBalanza/' + this.empresa.empresaBase + '/' + fechaIniAntigua + '/' + fechaFinAntigua + '/1');
                    this.balanza1 = response.data
                    // console.log('balanza 1', this.balanza1)
                    // await this.functionAgruparDatos(response.data);
                    this.loading = false;
                    // console.timeEnd();
                } catch (error) {
                    this.items = [];
                    console.log(error);
                    this.loading = false;
                    console.timeEnd();
                }
            },
            async GetBalanza2() {
                this.loadingMessage = 'Consultando...'
                this.loading = true;
                var listaB1 = []
                var fechaIniReciente = this.anios2 + '-01-01'
                var fechaFinReciente = this.anios2 + '-12-31'
                try {
                    let response = await axios.get('ReportesGenerales/GetBalanza/' + this.empresa.empresaBase + '/' + fechaIniReciente + '/' + fechaFinReciente + '/1');
                    this.balanza2 = response.data
                    // console.log('balanza 2', this.balanza2)
                    // await this.functionAgruparDatos(response.data);
                    this.loading = false;
                    // console.timeEnd();
                } catch (error) {
                    this.items = [];
                    console.log(error);
                    this.loading = false;
                    console.timeEnd();
                }
            },

            async GetEstadoResultados() {
                this.itemsIngresos = []
                this.loadingMessage = 'Consultando...'
                this.loading = true;
                var fechaIniReciente = this.anios2 + '-01-01'
                var fechaFinReciente = this.anios2 + '-12-31'
                try {
                    let response = await axios.get('ReportesGenerales/GetEstaodDeResultadosAsync/' + this.empresa.empresaBase + '/' + fechaIniReciente + '/' + fechaFinReciente + '/true/false');
                    let respuesta = response.data;
                    // console.log(response.data);

                    let ingresos = respuesta.filter(x => x.tipo === 'INGRESOS')
                    let costos = respuesta.filter(x => x.tipo === 'COSTOS')
                    let gastos = respuesta.filter(x => x.tipo === 'GASTOS')
                    let resultado = respuesta.filter(x => x.tipo === 'RESULTADO')
                    let participaciones = respuesta.filter(x => x.tipo === 'PARTICIPACIONES')
                    let Impuestos = respuesta.filter(x => x.tipo === 'IMPUESTOS')
                    let operaciones = respuesta.filter(x => x.tipo === 'OPERACIONES')

                    //INGRESOS
                    let salto1 = { tipo: '', nombre: '', importe: '-' }

                    let sumIngresos = ingresos.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                    let objIngresos = { tipo: '+', nombre: 'Ingresos netos', importe: sumIngresos }
                    this.itemsIngresos.push(objIngresos);

                    // COSTOS
                    let sumCosto = costos.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                    let objCosto = { tipo: '-', nombre: 'Costo de ventas', importe: sumCosto }
                    this.itemsIngresos.push(objCosto);

                    //UTILIDAD BRUTA
                    let objUtilidadBruta = { tipo: '=', nombre: 'UTILIDAD BRUTA', importe: sumIngresos - sumCosto }
                    this.itemsIngresos.push(objUtilidadBruta);
                    this.itemsIngresos.push(salto1);

                    //GASTOS GENERALES
                    let sumGastosGenerales = gastos.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                    let objGastosGenerales = { tipo: '-', nombre: 'Gastos generales', importe: sumGastosGenerales }
                    this.itemsIngresos.push(objGastosGenerales);

                    //UTILIDAD DE OPERACION
                    let objUtilidadDeOperacion = { tipo: '=', nombre: 'UTILIDAD DE OPERACIÓN', importe: objUtilidadBruta.importe - sumGastosGenerales }
                    this.itemsIngresos.push(objUtilidadDeOperacion);
                    this.itemsIngresos.push(salto1);

                    //RESULTADO INTEGRAL DE FINANCIAMIENTO
                    let sumResultadoIntegral = resultado.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                    let objResultadoIntegral = { tipo: '-', nombre: 'Resultado integral de financiamiento', importe: sumResultadoIntegral }
                    this.itemsIngresos.push(objResultadoIntegral);

                    //RESULTADO INTEGRAL DE FINANCIAMIENTO
                    let sumParticipaciones = participaciones.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                    let objParticipaciones = { tipo: '+', nombre: 'Participación en asociadas', importe: sumParticipaciones }
                    this.itemsIngresos.push(objParticipaciones);

                    //UTILIDAD ANTES DE IMPUESTOS A LA UTILIDAD
                    let objUtilidadImpuestos = { tipo: '=', nombre: 'UTILIDAD ANTES DE IMPUESTOS A LA UTILIDAD', importe: objUtilidadDeOperacion.importe - sumResultadoIntegral + sumParticipaciones }
                    this.itemsIngresos.push(objUtilidadImpuestos);
                    this.itemsIngresos.push(salto1);

                    //IMPUESTOS A LA UTILIDAD
                    let sumImpuestos = Impuestos.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                    let objImpuestos = { tipo: '-', nombre: 'Impuestos a la utilidad', importe: sumImpuestos }
                    this.itemsIngresos.push(objImpuestos);

                    //UTILIDAD DE OPERACIONES CONTINUAS
                    let objUtilidadOperaciones = { tipo: '=', nombre: 'UTILIDAD DE OPERACIONES CONTINUAS', importe: objUtilidadImpuestos.importe - sumImpuestos }
                    this.itemsIngresos.push(objUtilidadOperaciones);
                    this.itemsIngresos.push(salto1);

                    //OPERACIONES DISCONTINUADAS
                    let sumDiscontinuadas = operaciones.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
                    let objDiscontinuadas = { tipo: '-', nombre: 'Operaciones discontinuadas', importe: sumDiscontinuadas }
                    this.itemsIngresos.push(objDiscontinuadas);

                    //UTILIDAD DE OPERACIONES CONTINUAS
                    let desc_ = 'UTILIDAD NETA'
                    if (objUtilidadOperaciones.importe - sumDiscontinuadas < 0) {
                        desc_ = 'PÉRDIDA NETA'
                    }
                    let objUtilidadNeta = { tipo: '=', nombre: desc_, importe: objUtilidadOperaciones.importe - sumDiscontinuadas }
                    this.itemsIngresos.push(objUtilidadNeta);

                    this.$store.state.estadoResultado = objUtilidadNeta.importe;
                    // console.log(this.$store.state.estadoResultado)

                    this.loading = false;
                    this.dialogPolizaCierre = false
                    this.dialog = false
                } catch (error) {
                    console.log(error);
                    this.loading = false;
                    this.dialogPolizaCierre = false
                    this.dialog = false

                }
            },


            base64ToArrayBuffer(base64) {
                var binary_string = window.atob(base64);
                var len = binary_string.length;
                var bytes = new Uint8Array(len);
                for (var i = 0; i < len; i++) {
                    bytes[i] = binary_string.charCodeAt(i);
                }
                return bytes.buffer;
            },

            getLongMonthName(date) {
                const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
                // console.log('mes', monthNames[date.getMonth()])
                console.log('mes', monthNames[new Date(date).getMonth()])
                return monthNames[new Date(date).getMonth()];
            },

            cambiaFechaFinal() {
                let fecha = this.fechaI.split('-');
                var primero = new Date(fecha[0], fecha[1] - 1, '01');
                var ultimoDia = new Date(primero.getFullYear(), primero.getMonth() + 1, 0);
                this.fechaF = format(parseISO(new Date(ultimoDia).toISOString()), 'yyyy-MM-dd')
            },

            async GetEmpresa() {
                try {
                    let response = await axios.get('https://api-erp.contago.com.mx/api/Empresas/GetEmpresa/' + this.empresa.empresaBase);
                    // console.log(response.data)
                    if (response.data.idEmpresa != 0) {
                        this.$store.state.empresaStore = response.data
                    }
                } catch (error) {
                    console.log(error);
                }
            },

            sumColumn(column) {
                return this.items.reduce(
                    (total, item) => total + (item[column] || 0),
                    0
                );
            },

            AbreEstadoFlujosEfectivo() {
                this.dialogEstadoFlujoEfectivo = true
            },
            closeEstadoFlujosEfectivo() {
                this.dialogEstadoFlujoEfectivo = false;
            },

            //Activos disponibles para venta
            save(){

                let primer = this.itemsActivosDisponiblesVenta[0].primerAnio
                let Segundo = this.itemsActivosDisponiblesVenta[0].segundoAnio

                let diferencia = Segundo - primer

                this.itemsActivosDisponiblesVenta[0].diferencia = diferencia

                const indexToRemove = this.$store.state.listActividadesOperacion.findIndex(item => item.cuenta === 'Activos disponibles para venta');
                if (indexToRemove !== -1) {
                    // Eliminar el elemento usando splice()
                    this.$store.state.listActividadesOperacion.splice(indexToRemove, 1);
                }
                var cuenta = 'Activos disponibles para venta';
                var total = diferencia;
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });

            },
            //Impuestos a la utilidad por pagar
            saveIUPP(){

            let primer = this.itemsImpuestosUtilidadXPagar[0].primerAnio
            let Segundo = this.itemsImpuestosUtilidadXPagar[0].segundoAnio

            let diferencia = Segundo - primer

            this.itemsImpuestosUtilidadXPagar[0].diferencia = diferencia

            const indexToRemove = this.$store.state.listActividadesOperacion.findIndex(item => item.cuenta === 'Impuestos a la utilidad por pagar');
            if (indexToRemove !== -1) {
                // Eliminar el elemento usando splice()
                this.$store.state.listActividadesOperacion.splice(indexToRemove, 1);
            }
            var cuenta = 'Impuestos a la utilidad por pagar';
            var total = diferencia;
            this.$store.state.listActividadesOperacion.push({
                cuenta,
                total,
            });

            },

            //Rentas cobradas por anticipado
            saveRCPA(){

                let primer = this.itemsRentasCobradasXAnticipado[0].primerAnio
                let Segundo = this.itemsRentasCobradasXAnticipado[0].segundoAnio

                let diferencia = Segundo - primer

                this.itemsRentasCobradasXAnticipado[0].diferencia = diferencia

                const indexToRemove = this.$store.state.listActividadesOperacion.findIndex(item => item.cuenta === 'Rentas cobradas por anticipado');
                if (indexToRemove !== -1) {
                    // Eliminar el elemento usando splice()
                    this.$store.state.listActividadesOperacion.splice(indexToRemove, 1);
                }
                var cuenta = 'Rentas cobradas por anticipado';
                var total = diferencia;
                this.$store.state.listActividadesOperacion.push({
                    cuenta,
                    total,
                });

            },

        },


    }
</script>
<style>
    .last-row {
        background-color: yellow;
        /* Puedes ajustar el estilo de resaltado según tus preferencias */
    }

    .styled-table {
        border-collapse: collapse;
        margin: 25px 0;
        font-size: 1em;
        font-family: sans-serif;
        min-width: 450px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    }

    .styled-table thead tr {
        background-color: #662E91;
        color: #ffffff;
        text-align: middle;
        font-size: 10px;
    }

    .styled-table th,
    .styled-table td {
        padding: 11px 11px;
        font-size: 10px;
    }

    .styled-table tbody tr {
        border-bottom: 1px solid #dddddd;
    }

    .styled-table tbody tr:nth-of-type(even) {
        background-color: #f3f3f3;
    }

    .styled-table tbody tr:last-of-type {
        border-bottom: 2px solid #009879;
    }
</style>