<template>
    <div>
        <v-card flat>
            <v-card-title style="font: bold;" class="text-h4 text--primary">
                <!-- BOTON PARA IR A UNA VENTANA ANTERIOR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn large icon v-bind="attrs" v-on="on" color="primary" dark class="mx-2" link
                            :to="{ name: 'ListReportesEspeciales' }">
                            <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>
                    </template>
                    <span>Atrás</span>
                </v-tooltip>
                Provisionales de IVA
                <v-spacer></v-spacer>
                <v-select :items="itemsAnios" label="Año del ejercicio" outlined v-model="anios"></v-select>
                <!-- BOTON PARA CONSULTAR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" class="ml-4 mb-7" dark fab small @click="GetReporte()" v-bind="attrs" v-on="on">
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                    </template>
                    <span>Buscar</span>
                </v-tooltip>

                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary darken-1" class="mb-7" dark fab small v-bind="attrs" v-on="on"
                                    @click="generarPDF()">
                                    <v-icon>mdi-file-pdf-box</v-icon>
                                </v-btn>
                    </template>
                    <span>PDF</span>
                </v-tooltip>

                <!-- BOTON PARA EXPORTAR -->
                <xlsx-workbook>
                    <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                        :sheet-name="sheet.name" />
                    <xlsx-download>
                    </xlsx-download>
                    <xlsx-download :filename="nombreReporte">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary darken-1" class="mb-7" dark fab small v-bind="attrs" v-on="on"
                                    @click="GeneraExcel()">
                                    <v-icon>mdi-microsoft-excel</v-icon>
                                </v-btn>
                            </template>
                            <span>Generar Excel</span>
                        </v-tooltip>
                    </xlsx-download>
                </xlsx-workbook>
            </v-card-title>

            <v-card-text>
                <br>
                <h2>PROVISIONALES DE IVA</h2>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-data-table :items="itemsReporteProvIva" :headers="headersReporteProvIva" hide-default-footer
                    :items-per-page="-1">
                    <template v-slot:item.ingresos="{ item }">
                        <span>{{ functionFormatPrice(item.ingresos) }}</span>
                    </template>

                    <template v-slot:item.productosFinancieros="{ item }">
                        <span>{{ functionFormatPrice(item.productosFinancieros) }}</span>
                    </template>

                    <template v-slot:item.ventasNetas="{ item }">
                        <span>{{ functionFormatPrice(item.ventasNetas) }}</span>
                    </template>

                    <template v-slot:item.ivaVentas="{ item }">
                        <span>{{ functionFormatPrice(item.ivaVentas) }}</span>
                    </template>

                    <template v-slot:item.impuestosTrasladadosCobrados="{ item }">
                        <span>{{ functionFormatPrice(item.impuestosTrasladadosCobrados) }}</span>
                    </template>

                    <!-- <template v-slot:item.clientes="{ item }">
                        <span>{{ functionFormatPrice(item.clientes) }}</span>
                    </template> -->
                    
                    <!-- <template v-slot:item.clientesSinIva="{ item }">
                        <span>{{ functionFormatPrice(item.clientesSinIva) }}</span>
                    </template> -->

                    <!-- <template v-slot:item.comprobacionImpuestos="{ item }">
                        <span>{{ functionFormatPrice(item.comprobacionImpuestos) }}</span>
                    </template> -->

                    <!-- <template v-slot:item.diferenciaIva="{ item }">
                        <span>{{ functionFormatPrice(item.diferenciaIva) }}</span>
                    </template> -->

                    <template v-slot:item.proveedores="{ item }">
                        <span>{{ functionFormatPrice(item.proveedores) }}</span>
                    </template>

                    <template v-slot:item.acreedores="{ item }">
                        <span>{{ functionFormatPrice(item.acreedores) }}</span>
                    </template>

                    <template v-slot:item.sumaPagos="{ item }">
                        <span>{{ functionFormatPrice(item.sumaPagos) }}</span>
                    </template>

                    <template v-slot:item.comprasIvaPagadas="{ item }">
                        <span>{{ functionFormatPrice(item.comprasIvaPagadas) }}</span>
                    </template>

                    <template v-slot:item.impuestosAcreditablesPagados="{ item }">
                        <span>{{ functionFormatPrice(item.impuestosAcreditablesPagados) }}</span>
                    </template>

                    <template v-slot:item.determinacionProvIvaC="{ item }">
                        <span>{{ functionFormatPrice(item.determinacionProvIvaC) }}</span>
                    </template>

                    <template v-slot:item.determinacionProvIvaF="{ item }">
                        <span>{{ functionFormatPrice(item.determinacionProvIvaF) }}</span>
                    </template>

                    <!-- <template v-slot:item.IsrSueldosYSalarios="{ item }">
                        <span>{{ functionFormatPrice(item.IsrSueldosYSalarios) }}</span>
                    </template>

                    <template v-slot:item.IsrAsimiladosASalarios="{ item }">
                        <span>{{ functionFormatPrice(item.IsrAsimiladosASalarios) }}</span>
                    </template>

                    <template v-slot:item.IsrArrendamiento="{ item }">
                        <span>{{ functionFormatPrice(item.IsrArrendamiento) }}</span>
                    </template>

                    <template v-slot:item.IsrServiciosProfecionales="{ item }">
                        <span>{{ functionFormatPrice(item.IsrServiciosProfecionales) }}</span>
                    </template>

                    <template v-slot:item.impuestosRetenidosIVA="{ item }">
                        <span>{{ functionFormatPrice(item.impuestosRetenidosIVA) }}</span>
                    </template>

                    <template v-slot:item.retensionesIMSS="{ item }">
                        <span>{{ functionFormatPrice(item.retensionesIMSS) }}</span>
                    </template> -->

                </v-data-table>
            </v-card-text>
            <v-card-actions>
            </v-card-actions>
        </v-card>

        <!--Dialog loading-->
        <v-dialog v-model="loading" persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    {{loadingMessage}}
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { XlsxRead, XlsxTable, XlsxJson, XlsxSheets, XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import print from 'print-js';
    import pdf from 'vue-pdf';
    import jsPDF from 'jspdf';

    export default {
        name: 'ProvisionalesIva',
        components: {
            XlsxWorkbook, 
            XlsxSheet, 
            XlsxDownload,
        },

        data: () => ({
            //LOADING
            loading: false,
            loadingMessage: '',

            //FECHAS
            fechaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            fechaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            calendario: false,
            calendario2: false,

            //DATOS DEL REPORTE
            itemsReporteProvIva: [],
            headersReporteProvIva: [
                { text: 'Mes', value: 'mes' },
                { text: 'Porcentaje', value: 'porcentaje' },
                { text: 'Ingresos', value: 'ingresos' },
                { text: 'Productos Financieros', value: 'productosFinancieros' },
                { text: 'Ventas netas', value: 'ventasNetas' },
                { text: 'IVA de ventas', value: 'ivaVentas' },
                { text: 'Impuestos trasladados cobrados', value: 'impuestosTrasladadosCobrados' },
                // { text: 'Clientes', value: 'clientes' },
                // { text: 'Clientes sin IVA', value: 'clientesSinIva' },
                // { text: 'Comprobación de impuestos', value: 'comprobacionImpuestos' },
                // { text: 'Diferencia de IVA', value: 'diferenciaIva' },
                { text: 'Proveedores', value: 'proveedores' },
                { text: 'Acredores', value: 'acreedores' },
                { text: 'Suma de pagos', value: 'sumaPagos' },
                { text: 'Compras con IVA pagadas', value: 'comprasIvaPagadas' },
                { text: 'Impuestos Acreditables Pagados', value: 'impuestosAcreditablesPagados' },
                { text: 'Determinación Provisional de IVA a CARGO', value: 'determinacionProvIvaC' },
                { text: 'Determinación Provisional de IVA a FAVOR', value: 'determinacionProvIvaF' },
                //{ text: 'ISR Sueldos y Salarios', value: 'IsrSueldosYSalarios' },
                //{ text: 'ISR Asimilados a Salarios', value: 'IsrAsimiladosASalarios' },
                //{ text: 'ISR Arrendamiento', value: 'IsrArrendamiento' },
                //{ text: 'ISR Servicios Profesionales', value: 'IsrServiciosProfecionales' },
                //{ text: 'Impuestos Retenidos IVA', value: 'impuestosRetenidosIVA' },
                //{ text: 'Retenciones IMSS', value: 'retensionesIMSS' },
            ],

            anios: '',
            sumaProvIva: 0,
            ventasNetas: 0,
            ivaVentas: 0,
            clientesSinIva: 0,
            comprobacionImpuestos: 0,
            diferenciaIva: 0,
            sumaPagos: 0,
            comprasIvaPagadas: 0,
            determinacionProvIvaC: 0,
            determinacionProvIvaF: 0,

            //LISTA PARA EXPORTAR
            listaExcel: [], 
            sheets: [{ name: "Hoja1", data: [{ c: 2 }] }], 
            collection: [{ a: 1, b: 2 }],
            nombreReporte: '',


        }),

        watch: {

        },

        computed: {
            empresa() {
                return this.$store.state.usuario
            },

            itemsAnios() {
                let fecha = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd');
                let anio = fecha.split('-')[0];
                let lista = []
                lista.push(anio);
                lista.push(anio - 1);
                lista.push(anio - 2);
                lista.push(anio - 3);
                lista.push(anio - 4);
                lista.push(anio - 5);

                return lista;
            },
        },

        created() {
            this.GetEmpresa();
        },

        methods: {
            async GetEmpresa() {
                console.log(this.empresa)
                try {
                    let response = await axios.get('https://api-erp.contago.com.mx/api/Empresas/GetEmpresa/' + this.empresa.empresaBase);
                    console.log('a', response.data)
                    if (response.data.idEmpresa != 0) {
                        this.$store.state.empresaStore = response.data
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            async GetReporte() {
                this.loading = true;
                this.loadingMessage = 'Consultando...';
                try {
                    console.time();
                    let respuesta = await axios.get('reporteAutoConta/ProvisionalIva/' + this.empresa.empresaBase + '/' + this.anios)
                    console.log('respuesta',respuesta.data)
                    var array = respuesta.data

                    const sumaIngresos = array.map(item => item.ingresos).reduce((prev, curr) => prev + curr, 0);
                    console.log('sumaIngresos',sumaIngresos)
                    const sumaImpuestosTrasladadosC = array.map(item => item.impuestosTrasladadosCobrados).reduce((prev, curr) => prev + curr, 0);
                    const sumaClientes = array.map(item => item.clientes).reduce((prev, curr) => prev + curr, 0);
                    const sumaProveedores = array.map(item => item.proveedores).reduce((prev, curr) => prev + curr, 0);
                    const sumaAcreedores = array.map(item => item.acreedores).reduce((prev, curr) => prev + curr, 0);
                    const sumaImpuestosAcreditablesP = array.map(item => item.impuestosAcreditablesPagados).reduce((prev, curr) => prev + curr, 0);
                    const sumaProductosFinancieros = array.map(item => item.productosFinancieros).reduce((prev, curr) => prev + curr, 0);
                    const sumaIsrSueldosYSalarios = array.map(item => item.IsrSueldosYSalarios).reduce((prev, curr) => prev + curr, 0);
                    const sumaIsrAsimiladosASalarios = array.map(item => item.IsrAsimiladosASalarios).reduce((prev, curr) => prev + curr, 0);
                    const sumaIsrArrendamiento = array.map(item => item.IsrArrendamiento).reduce((prev, curr) => prev + curr, 0);
                    const sumaIsrServiciosProfecionales = array.map(item => item.IsrServiciosProfecionales).reduce((prev, curr) => prev + curr, 0);
                    const sumaImpuestosRetenidosIVA = array.map(item => item.impuestosRetenidosIVA).reduce((prev, curr) => prev + curr, 0);
                    const sumaRetensionesIMSS = array.map(item => item.retensionesIMSS).reduce((prev, curr) => prev + curr, 0);
                    var sumatoria = {
                        mes: 'Suma', ingresos: sumaIngresos,
                        impuestosTrasladadosCobrados: sumaImpuestosTrasladadosC,
                        clientes: sumaClientes,
                        proveedores: sumaProveedores,
                        acreedores: sumaAcreedores,
                        impuestosAcreditablesPagados: sumaImpuestosAcreditablesP,
                        productosFinancieros: sumaProductosFinancieros,
                        IsrSueldosYSalarios: sumaIsrSueldosYSalarios,
                        IsrAsimiladosASalarios: sumaIsrAsimiladosASalarios,
                        IsrArrendamiento: sumaIsrArrendamiento,
                        IsrServiciosProfecionales: sumaIsrServiciosProfecionales,
                        impuestosRetenidosIVA: sumaImpuestosRetenidosIVA,
                        retensionesIMSS: sumaRetensionesIMSS,
                    };
                    array.push(sumatoria);

                    for (var po of array) {
                        //OBTENER PORCENTAJES
                        console.log('1',po.ingresos)
                        console.log('2',sumaIngresos)

                        const p = po.ingresos / sumaIngresos * 100;

                        po.porcentaje = Math.round(p * 100) / 100;
                        console.log('3',po.porcentaje)
                        //OBTENER VENTAS NETAS
                        this.ventasNetas = po.ingresos + po.productosFinancieros
                        po.ventasNetas = this.ventasNetas
                        //OBTENER IVA DE VENTAS
                        this.ivaVentas = Math.round((this.ventasNetas * 0.16) * 100) / 100
                        po.ivaVentas = this.ivaVentas
                        //OBTENER CLIENTES SIN IVA
                        this.clientesSinIva = Math.round((po.clientes / 1.16) * 100) / 100
                        po.clientesSinIva = this.clientesSinIva
                        //COMPROBACION DE LO EFECTIVAMENTE COBRADO
                        this.comprobacionImpuestos = Math.round(((po.clientes / 1.16) * 0.16) * 100) / 100
                        po.comprobacionImpuestos = this.comprobacionImpuestos
                        //DIFERENCIA DE IVA
                        this.diferenciaIva = Math.round((po.impuestosTrasladadosCobrados - this.comprobacionImpuestos) * 100) / 100
                        po.diferenciaIva = this.diferenciaIva
                        //SUMA DE PAGOS
                        this.sumaPagos = po.proveedores + po.acreedores
                        po.sumaPagos = this.sumaPagos
                        //COMPRAS CON IVA PAGADAS
                        this.comprasIvaPagadas = Math.round((po.impuestosAcreditablesPagados / 0.16) * 100) / 100
                        po.comprasIvaPagadas = this.comprasIvaPagadas
                        //DETERMINACION PROVISIONAL DE IVA A CARGO
                        if (po.impuestosTrasladadosCobrados > po.impuestosAcreditablesPagados) {
                            this.determinacionProvIvaC = Math.round((po.impuestosTrasladadosCobrados - po.impuestosAcreditablesPagados) * 100) / 100;
                        } else
                            this.determinacionProvIvaC = 0;
                        po.determinacionProvIvaC = Math.round((this.determinacionProvIvaC) * 100) / 100
                        //DETERMINACION PROVISIONAL DE IVA A FAVOR
                        if (po.impuestosTrasladadosCobrados < po.impuestosAcreditablesPagados) {
                            this.determinacionProvIvaF = Math.round((po.impuestosAcreditablesPagados - po.impuestosTrasladadosCobrados) * 100) / 100;
                        } else
                            this.determinacionProvIvaF = 0;
                        po.determinacionProvIvaF = Math.round((this.determinacionProvIvaF) * 100) / 100
                    }

                    this.itemsReporteProvIva = array
                    console.timeEnd();
                    this.loading = false;
                } catch (error) {
                    console.log(error.data)
                    console.timeEnd();
                    this.loading = false;
                }
            },

            GeneraExcel(){
                // console.log(this.empresa)
                console.log(this.itemsReporteProvIva)
                this.nombreReporte = 'Provisionales_IVA_' + this.empresa.rfc + '_' +  this.anios + '.xlsx'
                let listaExcel = [];
                for(let g of this.itemsReporteProvIva){
                    let ObjD = { 
                        Mes: g.mes,
                        Porcentaje: g.porcentaje,
                        Ingresos: g.ingresos,
                        'Productos Financieros': g.productosFinancieros,
                        'Ventas netas': g.ventasNetas,
                        'IVA de ventas': g.ivaVentas,
                        'Impuestos trasladados cobrados': g.impuestosTrasladadosCobrados,
                        // Clientes: g.clientes,
                        // Clientes_sin_IVA: g.clientesSinIva,
                        // Comprobacion_de_impuestos: g.comprobacionImpuestos,
                        // Diferencia_de_IVA: g.diferenciaIva,
                        Proveedores: g.proveedores,
                        Acreedores: g.acreedores,
                        'Suma de pagos': g.sumaPagos,
                        'Compras con IVA pagados': g.comprasIvaPagadas,
                        'Impuesto acreditable pagado': g.impuestosAcreditablesPagados,
                        'Determinación provisional de IVA a cargo': g.determinacionProvIvaC,
                        'Determinación provisional de IVA a favor': g.determinacionProvIvaF,
                        // ISR_sueldos_y_salarios: g.IsrSueldosYSalarios,
                        // ISR_asimilados_a_salarios: g.IsrAsimiladosASalarios,
                        // ISR_arrendamiento: g.IsrArrendamiento,
                        // ISR_servicios_profecionales: g.IsrServiciosProfecionales,
                        // Impuestos_retenidos_IVA: g.impuestosRetenidosIVA,
                        // Retenciones_IMS: g.retensionesIMSS,
                    }
                    listaExcel.push(ObjD);
                    ObjD = Object.assign( {} );
                }

                this.listaExcel = listaExcel;
                this.collection = listaExcel;

            },

            functionFormatPrice(value) {
                let val = (value / 1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },

            generarPDF(){
                function formatoNumerico(value) {
                    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                }

                //DATOS DE LA EMPRESA
                let xCabecera = 10;
                let yCabecera = 5;
                const doc = new jsPDF({ orientation: 'landscape', format: 'letter',});

                if (this.$store.state.empresaStore.idEmpresa != 0){
                    let base64logo = this.$store.state.empresaStore.logo.base64
                    doc.addImage(base64logo, 'PNG', xCabecera, yCabecera, 65, 25);
                }

                doc.setFontSize(14);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#000");

                doc.text('PROVISIONALES DE IVA', 275, yCabecera += 5, { align: 'right' });
                doc.setFontSize(12);
                doc.setFont('helvetica', 'normal');
                doc.text(this.empresa.empresa, 275, yCabecera += 5, { align: 'right' });
                doc.text(this.empresa.rfc, 275, yCabecera += 5, { align: 'right' });
                let fecha = 'Ejercicio: ' +this.anios 
                doc.text(fecha, 275, yCabecera += 5, { align: 'right' });


                // TABLA
                let xHeader = 3
                let yHeader = yCabecera
                const color = '#662E91';

                // Dibujar la barra de color
                doc.setFillColor(color);
                doc.rect(xHeader, yHeader += 5, 275, 20, 'F');

                doc.setFontSize(6);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#fff");
                

                // CABEZERA DE LA TABLA
                doc.text('Mes',  xHeader +=2, yHeader +=5, { align: 'left' });
                // doc.text('Porcentaje',  xHeader += 11, yHeader, { align: 'left' });
                doc.text('Ingresos',  xHeader += 15, yHeader, { align: 'left' });
                
                doc.text('Productos',  xHeader += 23, yHeader, { align: 'left' });
                doc.text('financieros',  xHeader, yHeader +5, { align: 'left' });
                
                doc.text('Ventas Netas',  xHeader += 21, yHeader, { align: 'left' });
                
                doc.text('IVA de',  xHeader += 24, yHeader, { align: 'left' });
                doc.text('ventas',  xHeader, yHeader +5, { align: 'left' });
                
                doc.text('Impuestos',  xHeader += 20, yHeader, { align: 'left' });
                doc.text('traslados',  xHeader, yHeader +5, { align: 'left' });
                doc.text('cobrados',  xHeader, yHeader +10, { align: 'left' });
                
                doc.text('Proveedores',  xHeader += 21, yHeader, { align: 'left' });
                
                doc.text('Acredores',  xHeader += 22, yHeader, { align: 'left' });
                
                doc.text('Suma de',  xHeader += 23, yHeader, { align: 'left' });
                doc.text('pagos',  xHeader, yHeader +5, { align: 'left' });

                doc.text('Compras con',  xHeader += 21, yHeader, { align: 'left' });
                doc.text('IVA pagadas',  xHeader, yHeader +5, { align: 'left' });

                doc.text('Impuestos',  xHeader += 23, yHeader, { align: 'left' });
                doc.text('acreditables',  xHeader, yHeader +5, { align: 'left' });
                doc.text('pagadas',  xHeader, yHeader +10, { align: 'left' });

                doc.text('Determinación',  xHeader += 21, yHeader, { align: 'left' });
                doc.text('Povisional de',  xHeader, yHeader +5, { align: 'left' });
                doc.text(' IVA a cargo',  xHeader, yHeader +10, { align: 'left' });

                doc.text('Determinación',  xHeader += 23, yHeader, { align: 'left' });
                doc.text('Povisional de',  xHeader, yHeader +5, { align: 'left' });
                doc.text('IVA a favor',  xHeader, yHeader +10, { align: 'left' });

                yHeader += 15;
                let xLista = 3
                for (let c of this.itemsReporteProvIva) {
                    //CLAVE DEL SAT
                    doc.setFont('helvetica', 'normal');
                    doc.setFontSize(5);
                    doc.setTextColor("#000");

                    let mes = c.mes
                    let porcentaje = c.porcentaje
                    let ingresos = c.ingresos
                    let productosFinancieros = c.productosFinancieros
                    let ventasNetas = c.ventasNetas
                    let ivaVentas = c.ivaVentas
                    let impuestosTrasladadosCobrados = c.impuestosTrasladadosCobrados
                    let proveedores =c.proveedores
                    let acreedores = c.acreedores
                    let sumaPagos = c.sumaPagos
                    let comprasIvaPagadas = c.comprasIvaPagadas
                    let impuestosAcreditablesPagados = c.impuestosAcreditablesPagados
                    let determinacionProvIvaC = c.determinacionProvIvaC
                    let determinacionProvIvaF = c.determinacionProvIvaF

                    doc.text(mes, xLista, yHeader += 5, { align: 'left' });
                    doc.setFontSize(6);
                    // doc.text(this.functionFormatPrice(porcentaje), xLista + 23, yHeader, { align: 'right' });
                    doc.text(formatoNumerico(ingresos), xLista + 32.5, yHeader, { align: 'right' });
                    doc.text(formatoNumerico(productosFinancieros), xLista + 55, yHeader, { align: 'right' });
                    doc.text(formatoNumerico(ventasNetas), xLista + 76.5, yHeader, { align: 'right' });
                    doc.text(formatoNumerico(ivaVentas), xLista + 99, yHeader, { align: 'right' });
                    doc.text(formatoNumerico(impuestosTrasladadosCobrados), xLista + 120.5, yHeader, { align: 'right' });
                    doc.text(formatoNumerico(proveedores), xLista + 141, yHeader, { align: 'right' });
                    doc.text(formatoNumerico(acreedores), xLista + 162.5, yHeader, { align: 'right' });
                    doc.text(formatoNumerico(sumaPagos), xLista + 184, yHeader, { align: 'right' });
                    doc.text(formatoNumerico(comprasIvaPagadas), xLista + 205.5, yHeader, { align: 'right' });
                    doc.text(formatoNumerico(impuestosAcreditablesPagados), xLista + 227, yHeader, { align: 'right' });
                    doc.text(formatoNumerico(determinacionProvIvaC), xLista + 248.5, yHeader, { align: 'right' });
                    doc.text(formatoNumerico(determinacionProvIvaF), xLista + 272, yHeader, { align: 'right' });


                    //INSERTMOS EL SALTO DE PAGINA
                    let espacioDisponible = doc.internal.pageSize.height - 40;
                    let dif = yHeader > espacioDisponible
                    if (dif) {
                        doc.addPage();
                        yHeader = 10;
                    } else {
                        const color_ = "#662E91"
                        doc.setDrawColor(color_);
                        doc.setLineWidth(.1);
                        doc.line(3, yHeader += 3, 293, yHeader);
                    }
                }

                doc.save('Provisionales_de_IVA_' + this.empresa.rfc + '.pdf');

            },
        }
    }
</script>