<template>
    <div>
        <v-card flat>
            <v-card-title style="font: bold;" class="text-h4 text--primary">
                <!-- BOTON PARA IR A UNA VENTANA ANTERIOR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn large icon v-bind="attrs" v-on="on" color="primary" dark class="mx-2" link
                            :to="{ name: 'ListReportesEspeciales' }">
                            <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>
                    </template>
                    <span>Atrás</span>
                </v-tooltip>
                Provisionales de ISR
                <v-spacer></v-spacer>
                <v-select :items="itemsAnios" label="Año del ejercicio" outlined v-model="anios"
                    v-on:change="GetISRCantidades()"></v-select>
                <!-- BOTON PARA CONSULTAR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="primary" dark class="mb-2" @click="GetReporte()" v-bind="attrs" v-on="on">
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                    </template>
                    <span>Buscar</span>
                </v-tooltip>
                <!-- BOTON PARA EXPORTAR -->
                <xlsx-workbook>
                    <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                        :sheet-name="sheet.name" />
                    <xlsx-download>
                    </xlsx-download>
                    <xlsx-download :filename="nombreReporte">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on"
                                    @click="GeneraExcel()">
                                    <v-icon>mdi-microsoft-excel</v-icon>
                                </v-btn>
                            </template>
                            <span>Generar Excel</span>
                        </v-tooltip>
                    </xlsx-download>
                </xlsx-workbook>
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2"
                            @click="pushCalculosIsr()">
                            <v-icon>mdi-calculator</v-icon>
                        </v-btn>
                    </template>
                    <span>Calcular</span>
                </v-tooltip>

                <!-- <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="primary" dark class="mb-2" @click="GeneraExcel()" v-bind="attrs" v-on="on">
                            <v-icon>mdi-microsoft-excel</v-icon>
                        </v-btn>
                    </template>
                    <span>Buscar</span>
                </v-tooltip> -->
            </v-card-title>

            <v-card-text>
                <br>
                <h2>PROVISIONALES DE ISR</h2>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-row>
                    <v-col cols="12" sm="3" md="3">
                        <v-text-field label="Coeficiente de Utilidad" type="number" v-model="coeficienteUtilidad">
                        </v-text-field>
                        <!-- <span>{{functionFormatPrice(coeficienteUtilidad)}}</span> -->
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                        <v-text-field label="Amortización de pérdidas Anteriores" type="number"
                            v-model="amortizacionPerdidasAnt">
                        </v-text-field>
                        <!-- <span>{{functionFormatPrice(amortizacionPerdidasAnt)}}</span> -->
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                        <v-text-field label="Tasa de impuestos" type="number" v-model="tasaDeImpuestos"
                            hint="Cantidad en formato decimal Ej: .30">
                        </v-text-field>
                        <!-- <span>{{functionFormatPrice(amortizacionPerdidasAnt)}}</span> -->
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                        <v-row class="mt-1">
                            <v-text-field label="Retenciones bancarias" type="number" v-model="retencionesBancarias">
                            </v-text-field>
                            <!-- <span>{{functionFormatPrice(amortizacionPerdidasAnt)}}</span> -->
                            <v-tooltip bottom color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2"
                                        @click="GuardaCantidadesISR()">
                                        <v-icon>mdi-content-save-all</v-icon>
                                    </v-btn>
                                </template>
                                <span>Guardar cantidades</span>
                            </v-tooltip>
                        </v-row>
                    </v-col>


                </v-row>
                <v-data-table :items="itemsReporteProvIsr" :headers="headersReporteProvIsr" hide-default-footer
                    :items-per-page="-1" item-key="mes">

                    <template v-slot:item.ingresos="{ item }">
                        <span>{{ functionFormatPrice(item.ingresos) }}</span>
                    </template>

                    <template v-slot:item.devDescBonIngresos="{ item }">
                        <span>{{ functionFormatPrice(item.devDescBonIngresos) }}</span>
                    </template>

                    <template v-slot:item.otrosIngresos="{ item }">
                        <span>{{ functionFormatPrice(item.otrosIngresos) }}</span>
                    </template>

                    <template v-slot:item.productosFinancieros="{ item }">
                        <span>{{ functionFormatPrice(item.productosFinancieros) }}</span>
                    </template>

                    <template v-slot:item.otrosProductos="{ item }">
                        <span>{{ functionFormatPrice(item.otrosProductos) }}</span>
                    </template>

                    <template v-slot:item.ptuPagadaEjercicio="{ item }">
                        <span>{{ functionFormatPrice(item.ptuPagadaEjercicio) }}</span>
                    </template>

                    <template v-slot:item.suma="{ item }">
                        <span>{{ functionFormatPrice(item.suma) }}</span>
                    </template>

                    <template v-slot:item.totIngresosAcum="{ item }">
                        <span>{{ functionFormatPrice(item.totIngresosAcum) }}</span>
                    </template>

                    <template v-slot:item.coeficienteUtilidad="{ item }">
                        <span>{{ functionFormatPrice(item.coeficienteUtilidad) }}</span>
                    </template>

                    <template v-slot:item.utilidadFiscalEstimada="{ item }">
                        <span>{{ functionFormatPrice(item.utilidadFiscalEstimada) }}</span>
                    </template>

                    <template v-slot:item.amortizacionPerdidasAnt="{ item }">
                        <span>{{ functionFormatPrice(item.amortizacionPerdidasAnt) }}</span>
                    </template>

                    <template v-slot:item.basePagoProvisional="{ item }">
                        <span>{{ functionFormatPrice(item.basePagoProvisional) }}</span>
                    </template>

                    <template v-slot:item.tasaDeImpuestos="{ item }">
                        <span>{{ functionFormatPrice(item.tasaDeImpuestos) }}</span>
                    </template>

                    <template v-slot:item.impuestoDeterminado="{ item }">
                        <span>{{ functionFormatPrice(item.impuestoDeterminado) }}</span>
                    </template>

                    <template v-slot:item.pagosProvisionalesEA="{ item }">
                        <span>{{ functionFormatPrice(item.pagosProvisionalesEA) }}</span>
                    </template>

                    <template v-slot:item.retencionesBancarias="{ item }">
                        <span>{{ functionFormatPrice(item.retencionesBancarias) }}</span>
                    </template>

                    <template v-slot:item.pagoProvisionalAEnterar="{ item }">
                        <span>{{ functionFormatPrice(item.pagoProvisionalAEnterar) }}</span>
                    </template>


                </v-data-table>
            </v-card-text>
            <v-card-actions>
            </v-card-actions>
        </v-card>

        <!--Dialog loading-->
        <v-dialog v-model="loading" persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    {{loadingMessage}}
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>


<script>
    import { XlsxRead, XlsxTable, XlsxJson, XlsxSheets, XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import print from 'print-js';
    import pdf from 'vue-pdf';

    export default {
        name: 'ProvisionalesIsr',
        components: {

            XlsxWorkbook,
            XlsxSheet,
            XlsxDownload,

        },

        data: () => ({
            //LOADING
            loading: false,
            loadingMessage: '',

            //FECHAS
            fechaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            fechaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            calendario: false,
            calendario2: false,

            //DATOS DEL REPORTE
            itemsReporteProvIsr: [],
            headersReporteProvIsr: [
                { text: 'Mes', value: 'mes' },
                { text: 'Ingresos', value: 'ingresos' },
                { text: 'Devoluciones, descuentos o bonificaciones sobre ingresos', value: 'devDescBonIngresos' },
                { text: 'Otros ingresos', value: 'otrosIngresos' },
                { text: 'Productos financieros', value: 'productosFinancieros' },
                { text: 'Otros productos', value: 'otrosProductos' },
                { text: 'PTU Pagada en el Ejercicio', value: 'ptuPagadaEjercicio' },
                { text: 'Total por Mes', value: 'suma' },
                { text: 'Total de Ingresos Acumulables', value: 'totIngresosAcum' },
                { text: 'Coeficiente de Utilidad', value: 'coeficienteUtilidad' },
                { text: 'Utilidad Fiscal Estimada', value: 'utilidadFiscalEstimada' },
                { text: 'Amortización de Perdidas Anteriores', value: 'amortizacionPerdidasAnt' },
                { text: 'Base de Pago Provisional', value: 'basePagoProvisional' },
                { text: 'Tasa de impuestos', value: 'tasaDeImpuestos' },
                { text: 'Impuesto Determinado', value: 'impuestoDeterminado' },
                { text: 'Pagos Provisionales Efectuados con Anterioridad', value: 'pagosProvisionalesEA' },
                { text: 'Retenciones Bancarias', value: 'retencionesBancarias' },
                { text: 'Pago Provisional a Enterar', value: 'pagoProvisionalAEnterar' },
            ],

            anios: '',
            totIngresosAcum: 0,
            coeficienteUtilidad: 0,
            utilidadFiscalEstimada: 0,
            amortizacionPerdidasAnt: 0,
            ptuPagadaEjercicio: 0,
            basePagoProvisional: 0,
            tasaDeImpuestos: 0,
            impuestoDeterminado: 0,
            pagosProvisionalesEA: 0,
            retencionesBancarias: 0,
            pagoProvisionalAEnterar: 0,

            //LISTA PARA EXPORTAR
            listaExcel: [],
            sheets: [{ name: "Hoja1", data: [{ c: 2 }] }],
            collection: [{ a: 1, b: 2 }],
            nombreReporte: '',

            cantidadesISR: {
                idCantidades: 0,
                anio: '',
                coeficienteUtilidad: '',
                amortizacionPerdidas: '',
                tasaImpuestos: '',
                retencionesBancarias: '',
            },
            ISRCantidad: {},
            idCantidades: 0,

        }),

        watch: {

        },

        computed: {
            empresa() {
                return this.$store.state.usuario
            },

            itemsAnios() {
                let fecha = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd');
                let anio = fecha.split('-')[0];
                let lista = []
                lista.push(anio);
                lista.push(anio - 1);
                lista.push(anio - 2);
                lista.push(anio - 3);
                lista.push(anio - 4);
                lista.push(anio - 5);

                return lista;
            },
        },

        created() {

        },

        methods: {

            async GetReporte() {
                this.loading = true;
                this.loadingMessage = 'Consultando...';
                try {
                    console.time();
                    let respuesta = await axios.get('reporteAutoConta/ProvisionalIsr/' + this.empresa.empresaBase + '/' + this.anios)
                    console.log(respuesta.data)
                    var array = respuesta.data

                    this.itemsReporteProvIsr = array
                    console.timeEnd();
                    this.loading = false;
                } catch (error) {
                    console.log(error.data)
                    console.timeEnd();
                    this.loading = false;
                }
            },

            pushCalculosIsr() {

                try {
                    let j = 0;
                    for (var i of this.itemsReporteProvIsr) {

                        //COEFICIENTE DE UTILIDAD
                        i.coeficienteUtilidad = this.coeficienteUtilidad
                        //UTILIDAD FISCAL ESTIMADA
                        i.utilidadFiscalEstimada = i.totIngresosAcum * i.coeficienteUtilidad
                        //AMORTIZACION DE PERDIDAS ANTERIORES
                        i.amortizacionPerdidasAnt = this.amortizacionPerdidasAnt
                        // //PTU PAGADO EN EL EJERCICIO
                        // i.ptuPagadaEjercicio = 0
                        //BASE PAGO PROVISIONAL
                        i.basePagoProvisional = i.utilidadFiscalEstimada - i.amortizacionPerdidasAnt - i.ptuPagadaEjercicio
                        //TASA IMPUESTO
                        i.tasaDeImpuestos = this.tasaDeImpuestos
                        //IMPUESTO DETERMINADO
                        i.impuestoDeterminado = 0
                        if (i.basePagoProvisional > 0) {
                            i.impuestoDeterminado = Math.round((i.basePagoProvisional * i.tasaDeImpuestos) * 100) / 100
                        }
                        //PAGOS PROVISIONALES EFECTUADOS CON ANTERIORIDAD
                        if (j < 12) {
                            if (j === 0) {
                                i.pagosProvisionalesEA = 0
                            } else {
                                i.pagosProvisionalesEA = this.itemsReporteProvIsr[j - 1].impuestoDeterminado
                            }
                            j++;
                        }
                        //RETENCIONES BANCARIAS
                        i.retencionesBancarias = Math.round((this.retencionesBancarias) * 100) / 100
                        //PROVISIONALES A ENTERAR
                        i.pagoProvisionalAEnterar = Math.round((i.impuestoDeterminado - i.pagosProvisionalesEA) * 100) / 100
                    }

                    let array = this.itemsReporteProvIsr
                    const sumaIngresos = this.sum(this.itemsReporteProvIsr, 'ingresos')
                    // const sumaIngresos = array.map(item => item.ingresos).reduce((prev, curr) => prev + curr, 0);
                    const sumaDevDescBonIngresos = array.map(item => item.devDescBonIngresos).reduce((prev, curr) => prev + curr, 0);
                    const sumaOtrosIngresos = array.map(item => item.otrosIngresos).reduce((prev, curr) => prev + curr, 0);
                    const sumaProductosFinancieros = array.map(item => item.productosFinancieros).reduce((prev, curr) => prev + curr, 0);
                    const sumaOtrosProductos = array.map(item => item.otrosProductos).reduce((prev, curr) => prev + curr, 0);
                    const sumaPtuPagadaEjercicio = array.map(item => item.ptuPagadaEjercicio).reduce((prev, curr) => prev + curr, 0);
                    const sumaMes = array.map(item => item.suma).reduce((prev, curr) => prev + curr, 0);
                    const sumaIngresosAcum = array.map(item => item.totIngresosAcum).reduce((prev, curr) => prev + curr, 0);
                    // const sumaCoeficienteUtilidad = array.map(item => item.coeficienteUtilidad).reduce((prev, curr) => prev + curr, 0);
                    const sumaUtilidadFiscalEstimada = array.map(item => item.utilidadFiscalEstimada).reduce((prev, curr) => prev + curr, 0);
                    // const sumaAmortizacionPerdidasAnt = array.map(item => item.amortizacionPerdidasAnt).reduce((prev, curr) => prev + curr, 0);
                    const sumaBasePagoProvisional = array.map(item => item.basePagoProvisional).reduce((prev, curr) => prev + curr, 0);
                    const sumaTasaDeImpuestos = array.map(item => item.tasaDeImpuestos).reduce((prev, curr) => prev + curr, 0);
                    // const sumaImpuestoDeterminado = array.map(item => item.impuestoDeterminado).reduce((prev, curr) => prev + curr, 0);
                    const sumaPagosProvisionalesEA = array.map(item => item.pagosProvisionalesEA).reduce((prev, curr) => prev + curr, 0);
                    const sumaRetencionesBancarias = array.map(item => item.retencionesBancarias).reduce((prev, curr) => prev + curr, 0);
                    const sumapagoProvisionalAEnterar = array.map(item => item.pagoProvisionalAEnterar).reduce((prev, curr) => prev + curr, 0);
                    var sumatoria = {
                        mes: 'Suma',
                        ingresos: sumaIngresos,
                        devDescBonIngresos: sumaDevDescBonIngresos,
                        otrosIngresos: sumaOtrosIngresos,
                        productosFinancieros: sumaProductosFinancieros,
                        otrosProductos: sumaOtrosProductos,
                        ptuPagadaEjercicio: sumaPtuPagadaEjercicio,
                        suma: sumaMes,
                        totIngresosAcum: sumaIngresosAcum,
                        // coeficienteUtilidad: Math.round(sumaCoeficienteUtilidad * 100) / 100, 
                        utilidadFiscalEstimada: sumaUtilidadFiscalEstimada,
                        // amortizacionPerdidasAnt: Math.round(sumaAmortizacionPerdidasAnt * 100) / 100,
                        basePagoProvisional: sumaBasePagoProvisional,
                        tasaDeImpuestos: this.tasaDeImpuestos,
                        // impuestoDeterminado: Math.round(sumaImpuestoDeterminado * 100) / 100,
                        pagosProvisionalesEA: sumaPagosProvisionalesEA,
                        retencionesBancarias: sumaRetencionesBancarias,
                        pagoProvisionalAEnterar: sumapagoProvisionalAEnterar
                    };
                    array.push(sumatoria);

                }
                catch (error) {
                    console.log(error)
                }

            },
            sum(array, key) {
                return array.reduce((a, b) => a + (b[key] || 0), 0);
            },

            functionFormatPrice(value) {
                let val = (value / 1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },

            GeneraExcel() {
                // console.log(this.empresa)
                console.log(this.itemsReporteProvIsr)
                this.nombreReporte = 'Provisionales_ISR_' + this.empresa.rfc + '_' + this.anios + '.xlsx'
                let listaExcel = [];
                for (let g of this.itemsReporteProvIsr) {
                    let ObjD = {
                        Mes: g.mes,
                        Ingresos: g.ingresos,
                        Devoluciones_descuentos_o_bonificaciones_sobre_ingresos: g.devDescBonIngresos,
                        Otros_ingresos: g.otrosIngresos,
                        Productos_financieros: g.productosFinancieros,
                        Otros_productos: g.otrosProductos,
                        PTU_pagada_en_el_ejercicio: g.ptuPagadaEjercicio,
                        Total_por_mes: g.suma,
                        Total_ingresos_acumulables: g.totIngresosAcum,
                        Coeficiente_de_utilidad: g.coeficienteUtilidad,
                        Utilidad_fiscal_estimada: g.utilidadFiscalEstimada,
                        Amortizacion_de_perdidas: g.amortizacionPerdidasAnt,
                        Base_de_pago_provisional: g.basePagoProvisional,
                        Tasa_de_impuestos: g.tasaDeImpuestos,
                        Impuesto_determinado: g.impuestoDeterminado,
                        Pagos_provisionales_efectuados_con_anterioridad: g.pagosProvisionalesEA,
                        Retenciones_bancarias: g.retencionesBancarias,
                        Pago_provisional_a_enterar: g.pagoProvisionalAEnterar,
                    }
                    listaExcel.push(ObjD);
                    ObjD = Object.assign({});
                }

                this.listaExcel = listaExcel;
                this.collection = listaExcel;

            },

            async GuardaCantidadesISR() {


                // SE AGREGA
                this.cantidadesISR.idCantidades = this.idCantidades
                this.cantidadesISR.anio = this.anios
                this.cantidadesISR.coeficienteUtilidad = this.coeficienteUtilidad
                this.cantidadesISR.amortizacionPerdidas = this.amortizacionPerdidasAnt
                this.cantidadesISR.tasaImpuestos = this.tasaDeImpuestos
                this.cantidadesISR.retencionesBancarias = this.retencionesBancarias

                this.loading = true;
                this.loadingMessage = 'Consultando...';

                try {
                    // console.log(this.cantidadesISR)
                    let respuesta = await axios.post('reporteAutoConta/PostCantidadesISR/' + this.empresa.empresaBase + '/' + this.idCantidades, this.cantidadesISR)
                    console.log(respuesta.data)
                    console.timeEnd();
                    this.loading = false;
                } catch (error) {
                    console.log(error.data)
                    console.timeEnd();
                    this.loading = false;
                }

            },

            async GetISRCantidades() {
                console.time();
                this.loading = true;
                this.loadingMessage = 'Consultando...';
                try {
                    let respuesta = await axios.get('reporteAutoConta/GetISRCantidad/' + this.empresa.empresaBase + '/' + this.anios)
                    this.ISRCantidad = respuesta.data;
                    console.timeEnd();
                    this.loading = false;
                    this.idCantidades = this.ISRCantidad.idCantidades
                    this.coeficienteUtilidad = this.ISRCantidad.coeficienteUtilidad
                    this.amortizacionPerdidasAnt = this.ISRCantidad.amortizacionPerdidas
                    this.tasaDeImpuestos = this.ISRCantidad.tasaImpuestos
                    this.retencionesBancarias = this.ISRCantidad.retencionesBancarias
                    // console.log(this.idCantidades)
                } catch (error) {
                    console.log(error.data)
                    console.timeEnd();
                    this.loading = false;
                }
            },
        }
    }
</script>