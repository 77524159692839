var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-toolbar',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary darken-1","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.closeDialogEstadoFlujoEfectivo()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Cerrar")])]),_c('v-spacer'),_c('b',{staticStyle:{"font-size":"30px"}},[_vm._v("Estado de Flujo de Efectivo")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary darken-1","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.CreaPdf()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-pdf-box")])],1)]}}])},[_c('span',[_vm._v("Imprimir")])])],1),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}}),[_c('v-card',{staticClass:"justify-center",attrs:{"flat":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("UTILIDAD NETA"),_c('v-spacer'),_vm._v(_vm._s(_vm.functionFormatPrice(_vm.estadoResultado)))],1)],1),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("ACTIVIDADES DE OPERACION"),_c('v-spacer'),_vm._v(" "+_vm._s(_vm.sumaTotalA))],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headersActividadesOperacion,"hide-default-footer":"","items-per-page":-1,"hide-default-header":"","items":_vm.actividadesOperacion},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.total)))])]}}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("ACTIVIDADES DE INVERSION"),_c('v-spacer'),_vm._v(_vm._s(_vm.functionFormatPrice(_vm.totalActividadInversion)))],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headersActividadesInversion,"hide-default-footer":"","items-per-page":-1,"hide-default-header":"","items":_vm.actividadesInversion},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.total)))])]}}])})],1)],1),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}}),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("IMPORTE POR APLICAR EN ACTIVIDADES DE FINANCIAMIENTO"),_c('v-spacer'),_vm._v(_vm._s(_vm.functionFormatPrice(_vm.importePorAplicar)))],1)],1),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}}),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("ACTIVIDADES DE FINANCIAMIENTO"),_c('v-spacer'),_vm._v(_vm._s(_vm.sumaTotalAF))],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headersActividadesFinanciamiento,"hide-default-footer":"","items-per-page":-1,"hide-default-header":"","items":_vm.actividadesFinanciamiento},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.total)))])]}}])})],1)],1),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}}),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("VARIACIONES EN FLUJO"),_c('v-spacer'),_vm._v(_vm._s(_vm.functionFormatPrice(_vm.variacionFlujo)))],1)],1),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}}),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("EFECTIVO AL INICIO DEL EJERCICIO"),_c('v-spacer'),_vm._v(_vm._s(_vm.functionFormatPrice(_vm.efectivoInicioEjercicio)))],1)],1),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}}),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("FLUJO DE EFECTIVO AL FINAL DEL PERIODO"),_c('v-spacer'),_vm._v(_vm._s(_vm.functionFormatPrice(_vm.flujoEfectivoFinalPeriodo)))],1)],1)],1)],1)],1)]],2),_c('v-dialog',{attrs:{"persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.loadingMessage)+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"buffer-value":"0","stream":"","color":"white"}})],1)],1)],1),_c('v-snackbar',{attrs:{"color":"green","text-center":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.mensaje)+" "),_c('v-btn',{attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("Cerrar")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }