var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',{staticClass:"text-h4 text--primary",staticStyle:{"font":"bold"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"large":"","icon":"","color":"primary","dark":"","link":"","to":{ name: 'Home' }},on:{"click":function($event){return _vm.nuevaPoliza()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)]}}])},[_c('span',[_vm._v("Atrás")])]),_vm._v(" Devoluciones de IVA "),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha Inicial","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fechaI),callback:function ($$v) {_vm.fechaI=$$v},expression:"fechaI"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.calendario),callback:function ($$v) {_vm.calendario=$$v},expression:"calendario"}},[_c('v-date-picker',{on:{"input":function($event){_vm.calendario = false, _vm.cambiaFechaFinal()}},model:{value:(_vm.fechaI),callback:function ($$v) {_vm.fechaI=$$v},expression:"fechaI"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha Final","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fechaF),callback:function ($$v) {_vm.fechaF=$$v},expression:"fechaF"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.calendario2),callback:function ($$v) {_vm.calendario2=$$v},expression:"calendario2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.calendario2 = false}},model:{value:(_vm.fechaF),callback:function ($$v) {_vm.fechaF=$$v},expression:"fechaF"}})],1)],1),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"icon":"","color":"primary","dark":""},on:{"click":function($event){return _vm.GetDevoluciones()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}])},[_c('span',[_vm._v("Buscar")])]),_c('xlsx-workbook',[_vm._l((_vm.sheets),function(sheet){return _c('xlsx-sheet',{key:sheet.name,attrs:{"collection":_vm.collection,"sheet-name":sheet.name}})}),_c('xlsx-download'),_c('xlsx-download',{attrs:{"filename":_vm.nombreReporte}},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"icon":"","color":"primary","dark":""},on:{"click":function($event){return _vm.GeneraExcel()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-excel-box-outline")])],1)]}}])},[_c('span',[_vm._v("Excel")])])],1)],2)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersDevoluciones,"items":_vm.items,"item-key":"uuid","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.subtotal",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.subtotal)))])]}},{key:"item.descuento",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.descuento)))])]}},{key:"item.iva16",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.iva16)))])]}},{key:"item.ieps",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.ieps)))])]}},{key:"item.isrRetenido",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.isrRetenido)))])]}},{key:"item.ivaRetenido",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.ivaRetenido)))])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.total)))])]}},{key:"item.descripcion",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[_vm._v(_vm._s(item.descripcion))])])]}}])})],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.loadingMessage)+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"buffer-value":"0","stream":"","color":"white"}})],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":10000,"color":"primary darken-2","elevation":"30"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(" mdi-close-circle ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }