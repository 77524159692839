<template>
    <div>
        <v-card class="justify-center" flat>
            <v-row dense>
              <v-card class="mx-auto justify-center" width="250" link :to="{ name: 'Balanza' }" @click="$store.state.nombreComponente = 'Balanza'">
                <v-card-text class="text-center">
                    <v-icon size="150" color="primary">mdi-scale-balance</v-icon>
                    <h1 class="mt-8">Balanza</h1> 
                </v-card-text>
              </v-card>

              <v-card class="mx-auto justify-center" width="250" link :to="{ name: 'EstadoDeResultados' }" @click="$store.state.nombreComponente = 'Balanza'">
                <v-card-text class="text-center">
                    <v-icon size="150" color="primary">mdi-file-chart</v-icon>
                    <h1 class="mt-8">Estado de Resultados</h1> 
                </v-card-text>
              </v-card>

              <v-card class="mx-auto justify-center" width="250" link :to="{ name: 'EstadoDeSituacionFinanciera' }" @click="$store.state.nombreComponente = 'Balanza'">
                <v-card-text class="text-center">
                    <v-icon size="150" color="primary">mdi-chart-line</v-icon>
                    <h1 class="mt-8">Estado de Situación Financiera</h1> 
                </v-card-text>
              </v-card>
              <!-- LIBRO DIARIO -->
              <v-card class="mx-auto justify-center" width="250" link :to="{ name: 'LibroDiario' }" @click="$store.state.nombreComponente = 'LibroDiario'">
                <v-card-text class="text-center">
                    <v-icon size="150" color="primary">mdi-book-open-variant</v-icon>
                    <h1 class="mt-8">Libro Diario</h1> 
                </v-card-text>
              </v-card>
            </v-row>
            <v-main>
              <v-container fluid>
                <v-fade-transition mode="out-in">
                  <router-view></router-view>
                </v-fade-transition>
              </v-container>
            </v-main>
          </v-card>
    </div>
</template>
<script>
    export default {
      name: 'ListReportes',
      components: {
        
      }
    }
    </script>