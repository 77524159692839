import Vue from 'vue'
import Vuex from 'vuex'
import { format, parseISO } from 'date-fns';
import decode from 'jwt-decode'
import router from '../router/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    usuario: null,

    empresaStore: {
      idEmpresa : 0,
            rfc:'',
            nombre:'',
              regimenFiscal: { 
                id:0,
                clave:'',
                descripcion:'',
                fisica:'',
                moral:'',
                regimenFiscal:'',
            },
            domicilioFiscal:'',
            selloCsd: {
                idEmpresa:0,
                rfc:'',
                csdKey:{base64:'',},
                csdCer:{base64:'',},
                csdContra:''

              },
            logo :{ base64:'' },
            estatus:'',
            usuarioCreador:'',
            usuarioModificador :'',
    },

    //NOMBRE COMPONENTE
    nombreComponente:'Home',
    poliza: {
      id: -1,
    },
    listPoliza:[],
    balanza: [],
    listCatalogo: [],
    fechasPoliza:{
      fechaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      fechaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
    },
    indexPolizas: -1,

    listaCuentas: [],
    cuentaPadreStore: {
      idCuenta: 0,
      numeroCuenta: '',
      nombreCuenta: '',
      numeroCuentaUnico: '',
      tipo: '',
      naturaleza: '',
      nivel: 0,
    },

    listPolizaCierreStore: [],
    listDiotStore: [],

    estadoResultado: 0,
    // ACTIVIDADES DE OPERACIÓN
    listActividadesOperacion: [],
    // ACTIVIDADES DE INVERSIÓN
    listActividadesInversion: [],
    // ACTIVIDADES DE FINANCIAMIENTO
    listActividadesFinanciamiento: [],
    // EFECTIVO AL INICIO DEL EJERCICIO
    efectivoInicioEjercicioStore: 0,

    // DEVOLUCIONES DE IVA
    listDevolucionesIva:[]
  },

  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setUsuario(state, usuario) {
      state.usuario = usuario
    }
  },
  actions: {
    guardarToken({ commit }, token) {
      commit("setToken", token)
      commit("setUsuario", decode(token))
      localStorage.setItem("token", token)
    },
    autoLogin({ commit }) {
      let token = localStorage.getItem("token")
      if (token) {
        commit("setToken", token)
        commit("setUsuario", decode(token))
        router.push({ name: 'Home' })
      }
    },
    salir({ commit }) {
      commit("setToken", null)
      commit("setUsuario", null)
      localStorage.removeItem("token")
      router.push({ name: 'Login' })
    }
  },
  modules: {
  }
})
