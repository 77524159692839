<template>
    <div>
        <v-card>
            <v-card-title style="font: bold;" class="text-h4 text--primary">
                <!-- BOTON PARA IR A UNA VENTANA ANTERIOR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn large icon v-bind="attrs" v-on="on" color="primary" dark class="mx-2" @click="nuevaPoliza()" link :to="{ name: 'Home' }"><v-icon>mdi-arrow-left</v-icon></v-btn>
                    </template>
                    <span>Atrás</span>
                </v-tooltip>
                Lista de pólizas
                <v-spacer></v-spacer>
                <!--Fecha Inicial--> 
                <v-col cols="12" sm="2" md="2">
                    <v-menu v-model="calendario" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                        offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="fechaI" label="Fecha Inicial" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                            v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="fechaI" @input="calendario = false, cambiaFechaFinal()"></v-date-picker>
                    </v-menu>
                </v-col>
                <!--Fecha Final-->
                <v-col cols="12" sm="2" md="2">
                <v-menu v-model="calendario2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="fechaF" label="Fecha Final" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="fechaF" @input="calendario2 = false"></v-date-picker>
                </v-menu>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                    <v-select label="Tipo de Póliza" :items="itemsTipoPoliza" v-model="tipoPoliza"></v-select>
                </v-col>
                <!-- BOTON DE NUEVA POLIZA -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2" @click="nuevaPoliza()"><v-icon>mdi-book-plus</v-icon></v-btn>
                    </template>
                    <span>Nueva Póliza de Diario</span>
                </v-tooltip>
                <!-- BOTON DE GENERAR POLIZAS -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2" @click="dialogGeneraPoliza=true"><v-icon>mdi-file-sign</v-icon></v-btn>
                    </template>
                    <span>Generar Pólizas desde XML's</span>
                </v-tooltip>
                <!-- BOTON PARA BUSCAR POLIZAS -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2" @click="GetPolizas()"><v-icon>mdi-magnify</v-icon></v-btn>
                    </template>
                    <span>Buscar</span>
                </v-tooltip>
                <!-- BOTON PARA POLIZA DE CIERRE -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2" @click="AbrePolizaCierre()"><v-icon>mdi-calendar-lock-outline</v-icon></v-btn>
                    </template>
                    <span>Poliza de Cierre</span>
                </v-tooltip>
                <!-- BOTON PARA CANCELACION DE POLIZAS -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2" @click="AbreCancelaPolizas()"><v-icon>mdi-book-cancel-outline</v-icon></v-btn>
                    </template>
                    <span>Cancelar Polizas</span>
                </v-tooltip>
                <!-- EXPORTA A XML -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2" @click="descargarLote()"><v-icon>mdi-folder-download</v-icon></v-btn>
                    </template>
                    <span>Descargar Seleccionados</span>
                </v-tooltip>
            </v-card-title>
            <v-card-text>
                <v-data-table v-model="itemsSeleccionados"  :single-select="false"  show-select :headers="headersPolizas" :items="filtar" class="elevation-1" item-key="id" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
                        </v-toolbar>
                    </template>
                    <!-- FILTRA POR NUMERO DE POLIZA -->
                    <template v-slot:header.numeroPoliza="{ header }">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon small :color="searchNumero ? 'primary' : ''">
                                mdi-filter
                              </v-icon>
                            </v-btn>
                          </template>
                          <div style="background-color: white; width: 280px">
                            <v-text-field v-model="searchNumero" class="pa-4" type="text" label="Buscar por número de póliza" :autofocus="true"></v-text-field>
                            <v-btn @click="searchNumero = ''" small text color="primary" class="ml-2 mb-2">Borrar Filtro</v-btn>
                          </div>
                        </v-menu>
                      </template>
                    <!-- BOTONES -->
                    <template v-slot:item.actions="{ item }">
                        <!-- EDITAR -->
                        <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon dense class="mx-1" @click="abrePoliza(item)" color="orange" v-bind="attrs" v-on="on">mdi-file-eye-outline</v-icon>
                            </template>
                            <span>Visualizar Póliza</span>
                        </v-tooltip>
                        <!-- VER POLIZA PDF -->
                        <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon dense @click="VerPdf(item)" color="orange" v-bind="attrs" v-on="on">mdi-file-pdf-box</v-icon>
                            </template>
                            <span>Ver PDF</span>
                        </v-tooltip>
                        <!-- REUTILIZAR -->
                        <template v-if="item.tipoPoliza ==='Diario'">
                            <v-tooltip bottom color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon dense class="mx-1" @click="reutilizarPoliza(item)" color="green" v-bind="attrs" v-on="on">mdi-equal</v-icon>
                                </template>
                                <span>Reutilizar Poliza</span>
                            </v-tooltip>
                        </template>
                        <!-- CANCELAR POLIZA -->
                        <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon dense class="mx-1" @click="abreDialogElimina(item, 'Cancelar', 'cancelado')" color="red darken-4" v-bind="attrs" v-on="on">mdi-cancel</v-icon>
                            </template>
                            <span>Cancelar Poliza</span>
                        </v-tooltip>
                        
                        

                        <!-- ELIMINAR POLIZA -->
                        <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon dense @click="abreDialogElimina(item, 'Eliminar', 'eliminado')" color="red darken-4" v-bind="attrs" v-on="on">mdi-delete</v-icon>
                            </template>
                            <span>Eliminar Poliza</span>
                        </v-tooltip>
                    </template>
                    <!-- FORMATO CON DECIMALES DEL CARGO -->
                    <template v-slot:item.cargo="{ item }">
                        <span>{{ functionFormatPrice(item.cargo) }}</span>
                    </template>
                    <!-- FORMATO CON DECIMALES CON ABONO -->
                    <template v-slot:item.abono="{ item }">
                        <span>{{ functionFormatPrice(item.abono) }}</span>
                    </template>
                    <!-- FORMATO DE FECHA -->
                    <template v-slot:item.fecha="{ item }">
                        <span>{{ functionFormatDate(item.fecha) }}</span>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <!--Dialog loading-->
        <v-dialog v-model="loading" persistent width="300">
            <v-card color="primary" dark>
            <v-card-text>
                {{loadingMessage}}
                <v-progress-linear buffer-value="0" stream color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
            </v-card>
        </v-dialog>
        <!-- DIALOG PARA VER UNA POLIZA -->
        <v-dialog v-model="dialogPoliza" persistent>
            <poliza @cierraDialog="closeDialogPoliza()"></poliza>
        </v-dialog>
         <!-- DIALOG PARA ELIMINAR -->
        <v-dialog v-model="dialogElimina" max-width="600px">
            <v-card>
                <v-toolbar>
                    <!-- BOTON CERRAR -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-1" dark fab small @click="dialogElimina = false"
                                v-bind="attrs" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <b style="font-size: 30px;">{{ textElimina }} Póliza</b>
                    <v-spacer></v-spacer>
                    <!-- BOTON CONFIRMAR -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-1" dark fab small @click="putEstatusPoliza()"
                                v-bind="attrs" v-on="on">
                                <v-icon>mdi-check</v-icon>
                            </v-btn>
                        </template>
                        <span>Confirmar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field label="Tipo Póliza" v-model="editPoliza.tipoPoliza" readonly></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Número Póliza" v-model="editPoliza.numeroPoliza" readonly></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Fecha Póliza" v-model="editPoliza.fecha" readonly></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Total" v-model="editPoliza.total" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Descripción" v-model="editPoliza.descripcion" readonly></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- DIALOG PARA GENERAR LAS POLIZAS -->
        <v-dialog v-model="dialogGeneraPoliza" max-width="800">
            <v-card>
                <v-toolbar>
                    <!-- BOTON CERRAR -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-1" dark fab small @click="dialogGeneraPoliza = false"
                                v-bind="attrs" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <b style="font-size: 30px;">Generar Pólizas</b>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-1" dark fab small @click="generarPolizas()"
                                v-bind="attrs" v-on="on">
                                <v-icon>mdi-cube-send</v-icon>
                            </v-btn>
                        </template>
                        <span>Generar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <br>
                <v-card-text>
                    <v-row justify="space-around">
                        <v-date-picker
                          v-model="generaPoliza.picker"
                          type="month"
                          color="primary lighten-1"
                          header-color="primary"
                        ></v-date-picker>
                      </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- DIALOG TIPO DE CAMBIO -->
        <v-dialog v-model="dialogTipoCambio" persistent>
            <v-card>
                <v-toolbar>
                    <!-- BOTON CERRAR -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-1" dark fab small @click="dialogTipoCambio = false, MandarAconsultarPendientes()"
                                v-bind="attrs" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <b style="font-size: 30px;">Aplicar tipo de cambio</b>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-1" dark fab small @click="postTipoCambio()"
                                v-bind="attrs" v-on="on">
                                <v-icon>mdi-check</v-icon>
                            </v-btn>
                        </template>
                        <span>Aplicar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <br>
                <v-card-text>
                    <h2>Existen comprobantes emitidos en Dólares Americanos, desea aplicar el tipo de cambio del Diario oficial o continuar con el tipo de cambio que se indico en el comprobante?</h2>
                    <br>
                    <h3>Si da click en cerrar, se conservarán los tipos de cambio</h3>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- DIALOG EXPORTA PÓLIZAS EN XML -->
        <v-dialog v-model="dialogExporta" persistent width="600" transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar>
                    <!-- BOTON CERRAR -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-1" dark fab small @click="dialogExporta = false"
                                v-bind="attrs" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <b style="font-size: 30px;">Generar XML</b>
                    <v-spacer></v-spacer>
                    <!-- BOTON CONFIRMAR -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-1" dark fab small @click="PostCatalogoXml()"
                                v-bind="attrs" v-on="on">
                                <v-icon>mdi-export-variant</v-icon>
                            </v-btn>
                        </template>
                        <span>Exportar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-card-text>
                    <br>
                    <v-row justify="space-around">
                        <v-date-picker
                        v-model="picker"
                        type="month"
                        color="primary lighten-1"
                        header-color="primary"
                        ></v-date-picker>
                    </v-row>
                    <v-select label="Tipo de Envío" :items="tipoEnvio" v-model="selectTipoEnvio" item-text="descripcion" return-object></v-select>
                    <template v-if="selectTipoEnvio.valor==='AF' || selectTipoEnvio.valor==='FC'">
                        <v-text-field label="Número de Orden" v-model="NumOrden" counter maxlength="13"></v-text-field>
                    </template>
                    <template v-if="selectTipoEnvio.valor==='DE' || selectTipoEnvio.valor==='CO'">
                        <v-text-field label="Número de Tramite" v-model="NumTramite" counter maxlength="14"></v-text-field>
                    </template>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- DIALOG DE POLIZA DE CIERRE -->
        <v-dialog v-model="dialogPolizaCierre" persistent  transition="dialog-bottom-transition">
            <PolizaCierre @cierraDialogPC="closeDialogPolizaCierre()"></PolizaCierre>
        </v-dialog>
        <!-- DIALOG PARA CANCELACION DE POLIZAS -->
        <v-dialog v-model="dialogCancelaPoliza" persistent  transition="dialog-bottom-transition" width="700">
            <CancelarPolizas @cierraDialogCP="closeDialogCancelaPoliza()"></CancelarPolizas>
        </v-dialog>

        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import print from 'print-js';
    import pdf from 'vue-pdf';
    import { VueEditor } from "vue2-editor";
    import Vue from 'vue'
    import VueCookies from 'vue-cookies'
    import Poliza from '../Polizas/Poliza'
    import PolizaCierre from '../Polizas/PolizaDeCierre/ListaPolizaCierre'
    import CancelarPolizas from '../Polizas/CancelarPolizas/CancelarPolizas'
    import { polizaBase64 } from '../../plugins/funciones.js'
    import JSZip from 'jszip';

    Vue.use(VueCookies)

    export default {
        name: 'LisPoliza',
        components: {
            Poliza,
            PolizaCierre,
            CancelarPolizas,
        },

        data: () => ({
            headersPolizas:[
                { text: 'Acciones', value: 'actions' },
                { text: "Tipo", value: "tipoPoliza" },
                { text: "No. póliza", value: "numeroPoliza" },
                { text: "Descripción", value: "descripcion" },
                { text: "Fecha", value: "fecha" },
                { text: "Cargo", value: "cargo", align: 'right' },
                { text: "Abono", value: "abono", align: 'right' },
                { text: "Dif", value: "diferencia", align: 'right' },
                { text: "Estado", value: "estado" },
            ],
            search: '',
            searchNumero: '',
            dialogPoliza: false,

            //LOADING
            loadingMessage: '',
            loading: false,

            //FECHAS
            fechaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            fechaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            calendario: false,
            calendario2: false,

            itemsTipoPoliza:['Todos', 'Ingreso', 'Egreso', 'Diario'],
            tipoPoliza: 'Todos',

            //CANCELAR
            dialogCancela: false,

            //ELIMINAR
            dialogElimina: false,
            textElimina:'',
            estatus:'',

            //PARA EDITAR O CANCELAR
            editPoliza:{},
            indexEdit: -1,

            //GENERAR POLIZAS
            dialogGeneraPoliza: false,
            generaPoliza:{
                picker: new Date().toISOString().substr(0, 7)
            },

            dialogTipoCambio: false,
            mesI: null,
            mesF: null,

            //EXPORTA
            dialogExporta:false,
            picker: new Date().toISOString().substr(0, 7),
            tipoEnvio:[
                { descripcion: 'AF | Acto de Fiscalización', valor: 'AF' },
                { descripcion: 'FC | Fiscalización Compulsa', valor: 'FC' },
                { descripcion: 'DE | Devolución', valor:'DE' },
                { descripcion: 'CO | Compensación', valor:'CO' },
            ],
            selectTipoEnvio: { descripcion: 'AF | Acto de Fiscalización', valor: 'AF' },
            NumOrden:'',
            NumTramite:'',
            dialogPolizaCierre: false,
            dialogCancelaPoliza: false,

            itemsSeleccionados:[],
            snackbarMessage:'',
            snackbar:false,
        }),
        watch: {
            
        },
        computed: {
            items() {
                return this.$store.state.listPoliza;
            },
            poliza(){
                return this.$store.state.poliza;
            },
            empresa(){
                return this.$store.state.usuario
            },
            filtar(){
                let conditions = [];
                if(this.searchNumero){
                    conditions.push(this.filterNumeroPoliza)
                }

                if (conditions.length > 0) {
                    return this.items.filter((dessert) => {
                    return conditions.every((condition) => {
                        return condition(dessert);
                    })
                    })
                }
                
                return this.items;
            },

            datosEmpresa(){
                return this.$store.state.empresaStore
            },
        },
        created() {
            
        },
        methods: {
            
            functionFormatPrice(value) {
                let val = (value / 1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            functionFormatDate(value){
                let fecha = moment(value).format('DD/MM/yyyy')
                return fecha;
            },
            abrePoliza(item){
                item.fecha = format(parseISO(new Date(item.fecha).toISOString()), 'yyyy-MM-dd');
                this.$store.state.poliza = Object.assign({}, item)
                this.$store.state.indexPolizas = this.$store.state.listPoliza.indexOf(item)
                this.dialogPoliza = true
                console.log(item)
            },
            async GetPolizas(){
                console.time();
                this.loadingMessage = 'Buscando pólizas...'
                this.loading = true;
                try {
                    let response = await axios.get('GetPolizas/GetPolizasAsync/'+this.empresa.empresaBase+'/' + this.fechaI + '/' + this.fechaF + '/' + this.tipoPoliza);
                    this.$store.state.listPoliza = response.data;
                    // console.log(response.data)
                    this.loading = false;
                    console.timeEnd();
                } catch (error) {   
                    // console.log(error);
                    this.$store.state.listPoliza= []
                    this.loading = false;
                    console.timeEnd();
                }
            },
            nuevaPoliza(){
                let polizaN={
                    id : 0,
                    tipoPoliza : 'Diario',
                    numeroPoliza : 0,
                    fecha: this.fechaF,
                    descripcion : '',
                    total : 0,
                    usuarioCreeador : 'Nueva',
                    origen : 'Manual',
                    estado : 'activo',

                    detalles: [],
                    xmls: [],
                }
                this.$store.state.fechasPoliza.fechaI = this.fechaI;
                this.$store.state.fechasPoliza.fechaF = this.fechaF;
                this.dialogPoliza = true
                if(this.$store.state.poliza.id = -1){
                    this.$store.state.poliza = Object.assign({}, polizaN)
                }
            },
            closeDialogPoliza(){
                this.dialogPoliza = false;
            },

            //MANDA EN LA FUNCION LOS MESES PARA CONSULTAR MESES Y MANDA LOS DIAS PARA LA FUNCION DE CREAR POLIZAS
            async MandarAconsultarPendientes(){

                let fecha = this.generaPoliza.picker.split('-');
                var primero = new Date(fecha[0],  fecha[1]-1, '01');

                var primerDia = new Date(primero.getFullYear(), primero.getMonth(), 1);
                var ultimoDia = new Date(primero.getFullYear(), primero.getMonth() + 1, 0);

                this.mesI = format(parseISO(new Date(primerDia).toISOString()), 'yyyy-MM-dd')
                this.mesF = format(parseISO(new Date(ultimoDia).toISOString()), 'yyyy-MM-dd')

                let ultimo = Number(this.mesF.split('-')[2]) + 1;
                for(var i = 1; i < ultimo; i++){
                        let fechaConsulta = new Date(primero.getFullYear(), primero.getMonth(), i);
                        let fechaChida = format(parseISO(new Date(fechaConsulta).toISOString()), 'yyyy-MM-dd')
                        console.log(fechaConsulta);
                        await this.ConsultaPendientes(fechaChida, fechaChida, i, ultimo - 1);
                    }
            },
            abreDialogElimina(item, texto, estatus){
                this.dialogElimina = true;
                this.indexEdit = this.items.indexOf(item)
                this.editPoliza = Object.assign({},item)
                this.editPoliza.fecha = this.functionFormatDate(this.editPoliza.fecha)
                this.editPoliza.total = this.functionFormatPrice(this.editPoliza.total)
                this.editPoliza.estado = estatus
                this.textElimina = texto;
                this.estatus = estatus;
            },
            async putEstatusPoliza(){
                this.editPoliza.usuarioCreeador = 'UsuarioMod';
                this.editPoliza.estado = this.estatus;

                this.loadingMessage = 'Actualizando...'
                this.loading = true;
                try {
                    let response = await axios.put('GetPolizas/PutPolizaEstatusAsync/'+this.empresa.empresaBase+'/', this.editPoliza);
                    this.dialogElimina = false;
                    this.loading = false;
                    if(this.editPoliza.estado === 'eliminado'){
                        this.items.splice(this.indexEdit, 1)
                    }
                    else{
                        this.editPoliza.total = this.editPoliza.total.replaceAll(',', '');
                        Object.assign(this.items[this.indexEdit], this.editPoliza)
                    }
                    this.indexEdit = -1;
                } catch (error) {
                    console.log(error);
                    this.loading = false;
                }
            },
            async generarPolizas(){
                let fecha = this.generaPoliza.picker.split('-');
                var primero = new Date(fecha[0],  fecha[1]-1, '01');

                var primerDia = new Date(primero.getFullYear(), primero.getMonth(), 1);
                var ultimoDia = new Date(primero.getFullYear(), primero.getMonth() + 1, 0);

                this.mesI = format(parseISO(new Date(primerDia).toISOString()), 'yyyy-MM-dd')
                this.mesF = format(parseISO(new Date(ultimoDia).toISOString()), 'yyyy-MM-dd')

                await this.ConsultaPendientesPublicoGeneral(this.mesI, this.mesF);

                let ultimo = Number(this.mesF.split('-')[2]) + 1;
                console.log(ultimo)

                // VALIDAMOS SI HAY MONEDAS EN DOLARES
                let cuenta = await this.validaTipoCambio()
                console.log(cuenta);
                if(cuenta == 0){
                    for(var i = 1; i < ultimo; i++){
                        let fechaConsulta = new Date(primero.getFullYear(), primero.getMonth(), i);
                        let fechaChida = format(parseISO(new Date(fechaConsulta).toISOString()), 'yyyy-MM-dd')
                        console.log(fechaConsulta);
                        await this.ConsultaPendientes(fechaChida, fechaChida, i, ultimo - 1);
                    }
                }
                else{
                    this.dialogTipoCambio = true;
                }

                this.loading = false;
            },
            async ConsultaPendientes(mesInicial, mesFinal, diaI, diaF){
                console.log(mesInicial)
                console.log(mesFinal)
                this.loadingMessage = 'Consultando comprobantes pendientes...'
                this.loading = true;
                try {
                    let response = await axios.get('GetPolizas/GetListComprobante/'+ this.empresa.rfc +'/' + mesInicial + '/' + mesFinal);
                    console.log(response.data)
                    let insert = await this.creaPolizas(response.data, diaI, diaF);
                    console.log(insert)
                } catch (error) {   
                    console.log(error);
                    this.loading = false;
                }
            },
            async ConsultaPendientesPublicoGeneral(mesInicial, mesFinal){
                this.loadingMessage = 'Consultando comprobantes en Publico en General...'
                this.loading = true;
                try {
                    let response = await axios.get('GetPolizas/GetListComprobantePG/' + this.empresa.empresaBase + '/' + this.empresa.rfc +'/' + mesInicial + '/' + mesFinal);
                    console.log(response.data)
                    let insert = await this.creaPolizasPublicoGeneral(response.data);
                    console.log(insert)
                } catch (error) {   
                    console.log(error);
                    this.loading = false;
                }
            },
            async creaPolizas(item, diaI, diaF){
                try{
                    this.loadingMessage = 'Generando pólizas... ' + diaI + '/' + diaF;
                    this.loading = true;

                    let response = await axios.post('AutoConta/CreaPolizasSpeed/', item);
                    console.log(response.data)
                    this.loading = false;
                }catch(error){
                    console.log(error);
                }
            },

            async creaPolizasPublicoGeneral(item){
                console.log(item)
                let pbG = item
                try{
                    this.loadingMessage = 'Generando pólizas para Publico en General...'
                    let response = await axios.post('AutoConta/CreaPolizasPG/', pbG);
                    console.log(response.data)
                    this.loading = false;
                }catch(error){
                    console.log(error);
                }
            },
            // FILTROS
            filterNumeroPoliza(item){
                let valor = String(item.numeroPoliza)
                return valor.toLowerCase().includes(this.searchNumero.toLowerCase());
            },
            
            //REUTILIZAR POLIZA
            reutilizarPoliza(item){
                let polizaN = Object.assign({}, item);
                //INICIALIZAMOS LA POLIZA
                polizaN.id = 0;
                polizaN.fecha = this.fechaF;
                polizaN.xmls = []

                this.$store.state.fechasPoliza.fechaI = this.fechaI;
                this.$store.state.fechasPoliza.fechaF = this.fechaF;
                this.$store.state.poliza = Object.assign({}, polizaN)
                this.dialogPoliza = true

            },
        
            async validaTipoCambio(){
                this.loadingMessage = 'Consultando comprobantes en dólares'
                this.loading = true;
                try {
                    let response = await axios.get('GetPolizas/GetTiposCambio/'+this.empresa.empresaBase+'/' + this.mesI + '/' + this.mesF);
                    return response.data;
                    this.loading = false;    
                } catch (error) {
                    console.log(error);
                    this.loading = false;
                }
            },
            async postTipoCambio(){
                this.loading = true;
                this.loadingMessage = 'Aplicando tipos de cambio...'
                this.dialogTipoCambio = false
                try {
                    let response = await axios.post('GetPolizas/PutTiposCambio/'+this.empresa.empresaBase+'/' + this.mesI + '/' + this.mesF);
                    //this.ConsultaPendientes();
                    //FUNCION CONSULTAR PENDIENTES Y CREAR POLIZAS PARA COMPROBANTES TIPOS DE CAMBIO
                    this.MandarAconsultarPendientes();
                    this.loading = false;    
                } catch (error) {
                    console.log(error);
                    this.loading = false;
                }
            },
            cambiaFechaFinal(){
                let fecha = this.fechaI.split('-');
                var primero = new Date(fecha[0],  fecha[1]-1, '01');
                var ultimoDia = new Date(primero.getFullYear(), primero.getMonth() + 1, 0);
                this.fechaF = format(parseISO(new Date(ultimoDia).toISOString()), 'yyyy-MM-dd')
            },
            
            abreDialogExportar(){
                this.dialogExporta=true;
            },
            async PostCatalogoXml(){
                //GENERAMOS LA BALANZA
                console.time();
                let fecha = this.picker.split('-');
                var primero = new Date(fecha[0],  fecha[1]-1, '01');

                var primerDia = new Date(primero.getFullYear(), primero.getMonth(), 1);
                var ultimoDia = new Date(primero.getFullYear(), primero.getMonth() + 1, 0);

                var mesI = format(parseISO(new Date(primerDia).toISOString()), 'yyyy-MM-dd')
                var mesF = format(parseISO(new Date(ultimoDia).toISOString()), 'yyyy-MM-dd')
                var polizas = []

                this.loadingMessage = 'Consultando...'
                this.loading = true;
                try {
                    let response = await axios.get('GetPolizas/GetPolizasAsync/'+this.empresa.empresaBase+'/' + mesI + '/' + mesF + '/Todos');
                    polizas = response.data;
                    this.loading = false;
                    console.log(polizas)
                    console.timeEnd();
                } catch (error) {
                    polizas = [];
                    console.log(error);
                    this.loading = false;
                    console.timeEnd();
                }

                console.log(polizas.length)
                if(polizas.length == 0){
                    return;
                }

                //GENERAMOS EL XML
                let anio = fecha[0]
                let mes = fecha[1]
                let rfc = this.empresa.rfc
                var cabecera = '<PLZ:Polizas xsi:schemaLocation="http://www.sat.gob.mx/esquemas/ContabilidadE/1_3/PolizasPeriodo http://www.sat.gob.mx/esquemas/ContabilidadE/1_3/PolizasPeriodo/PolizasPeriodo_1_3.xsd" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:PLZ="http://www.sat.gob.mx/esquemas/ContabilidadE/1_3/PolizasPeriodo" NumOrden="'+ this.NumOrden +'" TipoSolicitud="'+this.selectTipoEnvio.valor+'" Anio="'+ anio +'" Mes="'+ mes +'" RFC="'+ rfc +'" Version="1.3">'
                
                if(this.selectTipoEnvio.valor === 'DE' || this.selectTipoEnvio.valor === 'CO'){
                    cabecera = '<PLZ:Polizas xsi:schemaLocation="http://www.sat.gob.mx/esquemas/ContabilidadE/1_3/PolizasPeriodo http://www.sat.gob.mx/esquemas/ContabilidadE/1_3/PolizasPeriodo/PolizasPeriodo_1_3.xsd" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:PLZ="http://www.sat.gob.mx/esquemas/ContabilidadE/1_3/PolizasPeriodo" NumTramite="'+ this.NumTramite +'" TipoSolicitud="'+this.selectTipoEnvio.valor+'" Anio="'+ anio +'" Mes="'+ mes +'" RFC="'+ rfc +'" Version="1.3">'
                }

                var pie = '</PLZ:Polizas>'
                var detalles = ''
                for(var c of polizas){
                    // let cabecera = '<PLZ:Poliza NumUnIdenPol="'+ c.tipoPoliza + '' + c.numeroPoliza +'" Fecha="'+ c.fecha +'" Concepto="'+ c.descripcion.substring(0,300) +'">'
                    let cabecera = '<PLZ:Poliza NumUnIdenPol="1" Fecha="2021-01-01" Concepto="descr">'
                    detalles =  detalles + cabecera+'\n'
                    for(var d of c.detalles){
                        let detalle = '<PLZ:Transaccion NumCta="" DesCta="" Concepto="" Debe="" Haber=""/>'
                        detalles = detalles + detalle+'\n'
                    }
                    let pieDet = '</PLZ:Poliza>';
                    detalles =  detalles + pieDet+'\n'
                }

                let nombreArchivo = this.empresa.rfc +  anio + mes
                this.downloadFile(cabecera+'\n' + detalles +pie, nombreArchivo)
            },
            downloadFile(archivo, nombre) {
                let filename = nombre + '.xml';
                let text = archivo;

                let element = document.createElement('a');
                element.setAttribute('href', 'data:text/xml;charset=utf-8,' + encodeURIComponent(text));
                element.setAttribute('download', filename);

                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();

                document.body.removeChild(element);
            },
            AbrePolizaCierre(){
                this.$store.state.listPolizaCierreStore = []
                this.$store.state.cuentaPadreStore = {
                    idCuenta: 0,
                    numeroCuenta: '',
                    nombreCuenta: '',
                    numeroCuentaUnico: '',
                    tipo: '',
                    naturaleza: '',
                    nivel: 0,
                }
                this.dialogPolizaCierre = true;
            },
            closeDialogPolizaCierre(){
                this.dialogPolizaCierre = false;
            },
            AbreCancelaPolizas(){
                this.dialogCancelaPoliza = true
            },
            closeDialogCancelaPoliza(){
                this.dialogCancelaPoliza = false;
            },
        
            //PDF
            async VerPdf(item){
                console.log(this.datosEmpresa)
                let logo = this.datosEmpresa.logo.base64;
                let empresa = this.datosEmpresa.rfc + ' | ' + this.datosEmpresa.nombre;
                let colores = '662E91';
                console.log(item);

                let base64 = await polizaBase64(logo, colores, item, empresa,'UNITARIO');
                // console.log(base64)
            },

            // DESCARGAR LOTE DE PDF
            async  descargarLote(){
                console.log(this.itemsSeleccionados)
                if(this.itemsSeleccionados.length == 0){
                    this.snackbarMessage = 'Seleccione al menos una poliza para descargar..'
                    this.snackbar = true
                    return;
                }

                this.loading = true;
                this.loadingMessage = 'Generando PDF espere...'

                var zip = new JSZip();
                for(var x of this.itemsSeleccionados){
                    let logo = this.datosEmpresa.logo.base64;
                    let empresa = this.datosEmpresa.rfc + ' | ' + this.datosEmpresa.nombre;
                    let colores = '662E91';

                    let base64 = await polizaBase64(logo, colores, x, empresa, 'MASIVO');
                    let ObjPdf = base64.split(',')[1];
                    let mes_ = moment(x.fecha).format('MMMM')
                    let anio_ = moment(x.fecha).format('YYYY')
                    let nombre = mes_ + '_' + anio_ + '_' + x.tipoPoliza + '_' + x.numeroPoliza
                    let archivo2 = this.b64toBlob(ObjPdf, 'pdf');
                    zip.file("Polizas/" + nombre + ".pdf", archivo2);

                }
                let nombreArchivo = 'Polizas_'+this.datosEmpresa.rfc +'_' + this.fechaI + '_' + this.fechaF + '_'+ this.tipoPoliza
                zip.generateAsync({type:"blob"})
                .then(function(content) {
                    var url = URL.createObjectURL(content);
                    var link = document.createElement('a');
                    link.href = url;
                    link.download =  nombreArchivo + ".zip";
                    document.body.appendChild(link);
                    link.click();
                    URL.revokeObjectURL(url);
                });
                this.itemsSeleccionados=[];
                this.loading = false;
                this.snackbarMessage = 'Comprobantes descargados con éxito, puede encontrar el archivo en su carpeta de descargas.'
                    this.snackbar = true

            },
            b64toBlob(base64, tipo) {
                var binary = atob(base64);
                var length = binary.length;
                var buffer = new ArrayBuffer(length);
                var view = new Uint8Array(buffer);
                for (var i = 0; i < length; i++) {
                    view[i] = binary.charCodeAt(i);
                }
                return new Blob([buffer], {type: tipo});
            },
        },
    }
</script>