<template>
    <div>
        <v-card class="justify-center" flat>
            <v-row dense>

              <v-card class="mx-auto justify-center" width="250" link :to="{ name: 'EstadoDeCostoDeProduccionVenta' }" @click="$store.state.nombreComponente = 'EstadoDeCostoDeProduccionVenta'">
                <v-card-text class="text-center">
                    <v-icon size="150" color="primary">mdi-factory</v-icon>
                    <h1 class="mt-8">Estado de costo de producción y venta</h1> 
                </v-card-text>
              </v-card>

              <v-card class="mx-auto justify-center" width="250" link :to="{ name: 'Depreciacion' }" @click="$store.state.nombreComponente = 'Depreciacion'">
                <v-card-text class="text-center">
                    <v-icon size="150" color="primary">mdi-trending-down</v-icon>
                    <h1 class="mt-8">Depreciación</h1> 
                </v-card-text>
              </v-card>

              <v-card class="mx-auto justify-center" width="250" link :to="{ name: 'CalculoAjusteAnualInflacion' }" @click="$store.state.nombreComponente = 'CalculoAjusteAnualInflacion'">
                <v-card-text class="text-center">
                    <v-icon size="150" color="primary">mdi-calculator-variant-outline</v-icon>
                    <h1 class="mt-8">Cálculo ajuste anual por inflación</h1> 
                </v-card-text>
              </v-card>

              <v-card class="mx-auto justify-center" width="250" link :to="{ name: 'ProvisionalesIva' }" @click="$store.state.nombreComponente = 'ProvisionalesIva'">
                <v-card-text class="text-center">
                    <v-icon size="150" color="primary">mdi-database-arrow-up-outline</v-icon>
                    <h1 class="mt-8">Provisionales de IVA</h1> 
                </v-card-text>
              </v-card>

              <v-card class="mx-auto justify-center" width="250" link :to="{ name: 'ProvisionalesIsr' }" @click="$store.state.nombreComponente = 'ProvisionalesIsr'">
                <v-card-text class="text-center">
                    <v-icon size="150" color="primary">mdi-cash-plus</v-icon>
                    <h1 class="mt-8">Provisionales de ISR</h1> 
                </v-card-text>
              </v-card>

              <!-- <v-card class="mx-auto justify-center" width="250" link :to="{ name: 'PerdidasEjerciciosAnteriores' }" @click="$store.state.nombreComponente = 'PerdidasEjerciciosAnteriores'">
                <v-card-text class="text-center">
                    <v-icon size="150" color="primary">mdi-table-arrow-down</v-icon>
                    <h1 class="mt-8">Perdidas de ejercicios anteriores</h1> 
                </v-card-text>
              </v-card> -->

              <v-card class="mx-auto justify-center" width="250" link :to="{ name: 'ConciliacionContableFiscal' }" @click="$store.state.nombreComponente = 'ConciliacionContableFiscal'">
                <v-card-text class="text-center">
                    <v-icon size="150" color="primary">mdi-text-box-search-outline</v-icon>
                    <h1 class="mt-8">Conciliación contable fiscal</h1> 
                </v-card-text>
              </v-card>

              <v-card class="mx-auto justify-center" width="250" link :to="{ name: 'ReporteDiot' }" @click="$store.state.nombreComponente = 'ReporteDiot'">
                <v-card-text class="text-center">
                    <v-icon size="150" color="primary">mdi-cart-percent</v-icon>
                    <h1 class="mt-8">DIOT</h1> 
                </v-card-text>
              </v-card>

              <v-card class="mx-auto justify-center" width="250" link :to="{ name: 'FlujoEefectivo' }" @click="$store.state.nombreComponente = 'FlujoEefectivo'">
                <v-card-text class="text-center">
                    <v-icon size="150" color="primary">mdi-cash-sync</v-icon>
                    <h1 class="mt-8">Flujo de Efectivo</h1> 
                </v-card-text>
              </v-card>

              <v-card class="mx-auto justify-center" width="250" link :to="{ name: 'DevolucionesIVA' }" @click="$store.state.nombreComponente = 'DevolucionesIVA'">
                <v-card-text class="text-center">
                    <v-icon size="150" color="primary">mdi-file-percent</v-icon>
                    <h1 class="mt-8">Devoluciones de IVA</h1> 
                </v-card-text>
              </v-card>

            </v-row>
            <v-main>
              <v-container fluid>
                <v-fade-transition mode="out-in">
                  <router-view></router-view>
                </v-fade-transition>
              </v-container>
            </v-main>
          </v-card>
    </div>
</template>
<script>
    export default {
      name: 'ListReportesEspeciales',
      components: {
        
      }
    }
    </script>