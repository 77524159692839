<template>
    <div>
        <v-card>
            <v-card-title style="font: bold;" class="text-h4 text--primary">
                <!-- BOTON PARA IR A UNA VENTANA ANTERIOR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn large icon v-bind="attrs" v-on="on" color="primary" dark class="mx-2" @click="nuevaPoliza()" link :to="{ name: 'Home' }"><v-icon>mdi-arrow-left</v-icon></v-btn>
                    </template>
                    <span>Atrás</span>
                </v-tooltip>
                Reportes Diot
                <v-spacer></v-spacer>
                <!--Fecha Inicial--> 
                <v-col cols="12" sm="2" md="2">
                    <v-menu v-model="calendario" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                        offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="fechaI" label="Fecha" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                            v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="fechaI" @input="calendario = false, cambiaFechaFinal()"></v-date-picker>
                    </v-menu>
                </v-col>
                <!-- Fecha Final -->
                <v-col cols="12" sm="2" md="2">
                <v-menu v-model="calendario2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="fechaF" label="Fecha Final" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="fechaF" @input="calendario2 = false"></v-date-picker>
                </v-menu>
                </v-col>
                <!-- SELECCIONA EL TIPO DE DIOT -->
                <!-- <v-col cols="12" sm="2" md="2">
                    <v-select label="Tipo" :items="itemsDiot" v-model="tipoDiot"></v-select>
                </v-col> -->
                <!-- BOTON PARA GENERAR EL ARCHIVO TXT -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2" @click="exportToTxtFile(diot)"><v-icon>mdi-text-box-edit-outline</v-icon></v-btn>
                    </template>
                    <span>Generar archivo TXT</span>
                </v-tooltip>
                <!-- BOTON PARA BUSCAR DIOTS -->
                <v-tooltip v-if="tipoDiot === 'Diot'" bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2" @click="GetDiot()"><v-icon>mdi-magnify</v-icon></v-btn>
                    </template>
                    <span>Generar Diot</span>
                </v-tooltip>

                <v-tooltip v-if="tipoDiot === 'Diot por Poliza'" bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2" @click="GetPolizas()"><v-icon>mdi-magnify</v-icon></v-btn>
                    </template>
                    <span>Generar Diot por poliza</span>
                </v-tooltip>

            </v-card-title>

            <v-card-text>
                <v-data-table :headers="headersDiot" :items="diot" class="elevation-1" item-key="id" :search="search">

                    <!-- NUMERO DE ID FISCAL -->
                     <template v-slot:item.numeroIdFiscal="props">
                        <template v-if="props['rfc'] === 'XEXX010101000'">
                            <td>
                                <v-edit-dialog
                                    :return-value.sync="props.item.numeroIdFiscal"
                                    lazy
                                    @save="save"
                                    @cancel="cancel"
                                    @open="open"
                                    @close="close"
                                    > {{ props.item.numeroIdFiscal }}
                                    <template v-slot:input>
                                        <v-text-field
                                            v-model="props.item.numeroIdFiscal"
                                            :rules="[max25chars]"
                                            label="Edit"
                                            single-line
                                            counter
                                        ></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </td>
                        </template>
                    </template>
                    <!-- NUMERO DEL EXTRANJERO -->
                    <template v-slot:item.numeroExtranjero="props">
                        <template v-if="props['rfc'] === 'XEXX010101000'">
                            <td>
                                <v-edit-dialog
                                    :return-value.sync="props.item.numeroExtranjero"
                                    lazy
                                    @save="save"
                                    @cancel="cancel"
                                    @open="open"
                                    @close="close"
                                    > {{ props.item.numeroExtranjero }}
                                    <template v-slot:input>
                                        <v-text-field
                                            v-model="props.item.numeroExtranjero"
                                            :rules="[max25chars]"
                                            label="Edit"
                                            single-line
                                            counter
                                        ></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </td>
                        </template>
                    </template>
                    <!-- PAIS DE RESIDENCIA -->
                    <template v-slot:item.paisResidencia="props">
                        <template v-if="props['rfc'] === 'XEXX010101000'">
                            <td>
                                <v-edit-dialog
                                    :return-value.sync="props.item.paisResidencia"
                                    lazy
                                    @save="save"
                                    @cancel="cancel"
                                    @open="open"
                                    @close="close"
                                    > {{ props.item.paisResidencia }}
                                    <template v-slot:input>
                                        <v-text-field
                                            v-model="props.item.paisResidencia"
                                            :rules="[max25chars]"
                                            label="Edit"
                                            single-line
                                            counter
                                        ></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </td>
                        </template>
                    </template>
                    <!-- NACIONALIDAD -->
                    <template v-slot:item.nacionalidad="props">
                        <template v-if="props['rfc'] === 'XEXX010101000'">
                            <td>
                                <v-edit-dialog
                                    :return-value.sync="props.item.nacionalidad"
                                    lazy
                                    @save="save"
                                    @cancel="cancel"
                                    @open="open"
                                    @close="close"
                                    > {{ props.item.nacionalidad }}
                                    <template v-slot:input>
                                        <v-text-field
                                            v-model="props.item.nacionalidad"
                                            :rules="[max25chars]"
                                            label="Edit"
                                            single-line
                                            counter
                                        ></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </td>
                        </template>
                    </template>

                    <!-- <template v-slot:top>
                        <v-toolbar flat>
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
                        </v-toolbar>
                    </template> -->
                    <!-- FORMATO CON DECIMALES DEL CARGO -->
                    <template v-slot:item.cargo="{ item }">
                        <span>{{ functionFormatPrice(item.cargo) }}</span>
                    </template>
                    <!-- FORMATO CON DECIMALES CON ABONO -->
                    <template v-slot:item.abono="{ item }">
                        <span>{{ functionFormatPrice(item.abono) }}</span>
                    </template>
                    <!-- FORMATO DE FECHA -->
                    <template v-slot:item.fecha="{ item }">
                        <span>{{ functionFormatDate(item.fecha) }}</span>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <!--Dialog loading-->
        <v-dialog v-model="loading" persistent width="300">
            <v-card color="primary" dark>
            <v-card-text>
                {{loadingMessage}}
                <v-progress-linear buffer-value="0" stream color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import Vue from 'vue'
    import VueCookies from 'vue-cookies'

    
    Vue.use(VueCookies)

    export default {
        name: 'ReporteDiot',
        components: {
        },

        data: () => ({
            headersDiot:[
            

                // { text: 'Acciones', value: 'actions' },
                { text: "Tipo Tercero", value: "tipo_tercero" },
                { text: "Operacion", value: "tipo_operacion" },
                { text: "RFC", value: "rfc" },
                { text: "Número de ID Fiscal", value: "numeroIdFiscal" },
                { text: "Número del Extranjero", value: "numeroExtranjero" },
                { text: "País de Residencia", value: "paisResidencia" },
                { text: "Nacionalidad", value: "nacionalidad" },
                { text: "Base 15 o 16", value: "iva16" },
                { text: "Base 15", value: "base15" },
                { text: "Base 15 o 16 no acreditable", value: "base1516NoAcreditable" },
                { text: "Base 10 u 11", value: "base1011" },
                { text: "Base 10", value: "base10" },
                { text: "Región fronteriza", value: "iva8" },
                { text: "Base 10 u 11 no acreditable", value: "base10u11NoAcreditable" },
                { text: "IVA no acreditabable", value: "ivaNoAcreditable" },
                { text: "Importacion base 15 o 16", value: "importacion1516" },
                { text: "Importacion base 15 o 16 no acreditable", value: "importacion1516NoAcreditable" },
                { text: "Importacion base 10 u 11", value: "importacion1011" },
                { text: "Importacion base 10 u 11 no acreditable", value: "importacion1011NoAcreditable" },
                { text: "Importacion base exento", value: "importacionBaseExento" },
                { text: "Base 0", value: "base0" },
                { text: "Base exento", value: "baseExento" },
                { text: "IVA retenido", value: "ivaRetenido" },
                { text: "IVA devoluciones", value: "ivaDevoluciones" },
            ],
            search: '',
            searchRFC: '',

            //LOADING
            loadingMessage: '',
            loading: false,

            //FECHAS
            fechaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            fechaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            calendario: false,
            calendario2: false,

            itemsDiot:['Diot', 'Diot por Poliza'],
            tipoDiot: 'Diot',

            snack: false,
            snackColor: '',
            snackText: '',
            max25chars: v => v.length <= 25 || 'Input too long!',

        }),
        watch: {
            
        },
        computed: {
            items() {
                return this.$store.state.listPoliza;
            },
            poliza(){
                return this.$store.state.poliza;
            },
            empresa(){
                return this.$store.state.usuario
            },
            filtar(){
                let conditions = [];
                if(this.searchRFC){
                    conditions.push(this.filterRfc)
                }

                if (conditions.length > 0) {
                    return this.items.filter((dessert) => {
                    return conditions.every((condition) => {
                        return condition(dessert);
                    })
                    })
                }
                
                return this.items;
            },
            diot(){
               
                return this.$store.state.listDiotStore.filter(x => 
                x.numeroIdFiscal != '' || 
                x.numeroExtranjero != '' ||
                x.paisResidencia != '' ||
                x.nacionalidad != '' ||
                x.iva16 != '' ||
                x.base15 != '' ||
                x.base1516NoAcreditable != '' ||
                x.base1011 != '' ||
                x.base10 != '' ||
                x.iva8 != '' ||
                x.base10u11NoAcreditable != '' ||
                x.ivaNoAcreditable != '' ||
                x.importacion1516 != '' ||
                x.importacion1516NoAcreditable != '' ||
                x.importacion1011 != '' ||
                x.importacion1011NoAcreditable != '' ||
                x.importacionBaseExento != '' ||
                x.base0 != '' ||
                x.baseExento != '' ||
                x.ivaRetenido != '' ||
                x.ivaDevoluciones!= '');
            },
        },
        created() {
            
        },
        methods: {
            functionFormatPrice(value) {
                let val = (value / 1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            functionFormatDate(value){
                let fecha = moment(value).format('DD/MM/yyyy')
                return fecha;
            },
            async GetDiot(){
                // console.time();
                this.loadingMessage = 'Buscando pólizas...'
                this.loading = true;

                let ObjDiot = {

                    fechai: this.fechaI,
                    fechaf: this.fechaF,
                    nombrebase: this.empresa.empresaBase

                }

                try {
                    let response = await axios.post('AutoConta/GetDiot1', ObjDiot);
                    console.log('diot',response.data)
                    this.$store.state.listDiotStore = response.data;

                    for (var i = 0; i < this.$store.state.listDiotStore.length; i++) {
                        this.diot[i].numeroIdFiscal = "";
                        this.diot[i].numeroExtranjero = "";
                        this.diot[i].paisResidencia = "";
                        this.diot[i].nacionalidad = "";
                        if (this.diot[i].iva16 != 0){
                            this.diot[i].iva16 = Math.round(this.diot[i].iva16);
                        }else{
                            this.diot[i].iva16 = ""
                        }
                        this.diot[i].base1516 = "";
                        this.diot[i].base15 = "";
                        this.diot[i].base1516NoAcreditable = "";
                        this.diot[i].base1011 = "";
                        this.diot[i].base10 = "";
                        if(this.diot[i].iva8 != 0){
                            this.diot[i].iva8 =  Math.round(this.diot[i].iva8);                            
                        }else{
                            this.diot[i].iva8 = ""
                        }
                        this.diot[i].base10u11NoAcreditable = "";
                        this.diot[i].ivaNoAcreditable = "";
                        this.diot[i].importacion1516 = "";
                        this.diot[i].importacion1516NoAcreditable = "";
                        this.diot[i].importacion1011 = "";
                        this.diot[i].importacion1011NoAcreditable = "";
                        this.diot[i].importacionBaseExento = "";
                        if(this.diot[i].base0 != 0){
                            this.diot[i].base0 =  Math.round(this.diot[i].base0);                            
                        }else{
                            this.diot[i].base0 = ""
                        }
                        // this.diot[i].base0 = "";
                        if(this.diot[i].baseExento != 0){
                            this.diot[i].baseExento =  Math.round(this.diot[i].baseExento);                            
                        }else{
                            this.diot[i].baseExento = ""
                        }
                        // this.diot[i].baseExento = "";
                        this.diot[i].ivaRetenido = "";
                        this.diot[i].ivaDevoluciones = "";
                    }

                    // console.log(this.$store.state.listDiotStore)

                    this.loading = false;
                    console.timeEnd();
                } catch (error) {   
                    console.log(error);
                    this.loading = false;
                    console.timeEnd();
                }
            },
            // FILTROS
            filterRfc(item){
                let valor = String(item.numeroPoliza)
                return valor.toLowerCase().includes(this.searchRFC.toLowerCase());
            },
            cambiaFechaFinal(){
                let fecha = this.fechaI.split('-');
                var primero = new Date(fecha[0],  fecha[1]-1, '01');
                var ultimoDia = new Date(primero.getFullYear(), primero.getMonth() + 1, 0);
                this.fechaF = format(parseISO(new Date(ultimoDia).toISOString()), 'yyyy-MM-dd')
            },
            
            exportToTxtFile(data) {
                // Convertir la lista de datos en una cadena de texto con cada dato separado por "|"
                const textToSave = data.map(item => Object.values(item).join('|')).join('\n').trim();
                
                // Crear un elemento de enlace y configurarlo para descargar el archivo
                const link = document.createElement('a');
                const fileName = 'Diot_'+ this.empresa.rfc + '_Del_' + this.fechaI + '_Al_' + this.fechaF +'.txt';

                link.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(textToSave)}`);
                link.setAttribute('download', fileName);
                link.style.display = 'none';

                // Agregar el enlace al DOM y simular un clic para descargar el archivo
                document.body.appendChild(link);
                link.click();

                // Eliminar el enlace del DOM
                document.body.removeChild(link);
            },

            save () {
                this.snack = true
                this.snackColor = 'success'
                this.snackText = 'Data saved'
            },
            cancel () {
                this.snack = true
                this.snackColor = 'error'
                this.snackText = 'Canceled'
            },

            open () {
                this.snack = true
                this.snackColor = 'info'
                this.snackText = 'Dialog opened'
            },
            close () {
                console.log('Dialog closed')
            }

        },
    }
</script>