<template>
    <div>
        <v-card flat>
            <v-card-title style="font: bold;" class="text-h4 text--primary">
                <!-- BOTON PARA IR A UNA VENTANA ANTERIOR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn large icon v-bind="attrs" v-on="on" color="primary" dark class="mx-2" link
                            :to="{ name: 'ListReportesEspeciales' }">
                            <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>
                    </template>
                    <span>Atrás</span>
                </v-tooltip>
                Ajuste anual por inflación
                <v-spacer></v-spacer>
                <!-- RADIO BUTTON PARA INPC´S -->
                <v-radio-group v-model="radios">
                <v-radio label="Ejercicio Completo" value="1"></v-radio>
                <v-radio label="Menor a 12 meses" value="2"></v-radio>
                </v-radio-group>
                <v-spacer></v-spacer>
                <!-- AÑOS PARA LOS INPC´S -->
                <v-select :items="itemsAnios" label="Año del ejercicio" outlined v-model="anios"></v-select>
                <!-- MESES PARA LOS INPC'S -->
                <template v-if="radios != '1'">
                    <v-select :items="meses" label="Mes de inicio de la operacion" outlined v-model="mesInpc"></v-select>
                </template>
                <!-- BOTON PARA CONSULTAR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="primary" dark class="mb-2" @click="GetReportes()" v-bind="attrs" v-on="on">
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                    </template>
                    <span>Buscar</span>
                </v-tooltip>
                <!-- BOTON PARA EL PDF -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="primary" dark class="mb-2" @click="CreaPdf()" v-bind="attrs" v-on="on">
                            <v-icon>mdi-file-pdf-box</v-icon>
                        </v-btn>
                    </template>
                    <span>Exportar a PDF</span>
                </v-tooltip>
                <!-- BOTON PARA AGREGAR UN INPC -->
                <!-- <v-tooltip v-if="this.empresa.nombre === 'admin'" bottom color="primary">
                    <template  v-slot:activator="{ on, attrs }">
                        <v-btn icon color="primary" dark class="mb-2" @click="abreDialogNuevoInpc()" v-bind="attrs" v-on="on">
                            <v-icon>mdi-plus-box</v-icon>
                        </v-btn>
                    </template>
                    <span>NUEVO INPC</span>
                </v-tooltip> -->
            </v-card-title>

            <h2>Acumulables</h2>
            <v-system-bar color="primary" height="6"></v-system-bar><br>

            <v-card class="justify-center" flat>
                <v-row dense>
                    <v-card class="rigth" width="344">
                        <v-card-text>

                            <h4>TOTALES</h4><br>
                            <div class="text--primary"></div>
                            • Total Créditos: <span>{{ functionFormatPrice(sumaCreditos) }} </span><br><br>
                            • Total Deudas: <span>{{ functionFormatPrice(sumaDeudas) }} </span><br><br>
                            • Meses del Ejercicio: <span>{{ mesesEjercicio }} </span> <br>

                        </v-card-text>
                    </v-card>

                    <v-card class="mx-auto justify-center" width="344">
                        <v-card-text>

                            <h4>SALDOS</h4><br>
                            <div class="text--primary"></div>

                            • Saldo promedio anual de deudas: <span>{{ functionFormatPrice(promedioAnualDeudas) }}
                            </span> <br><br>
                            • Saldo promedio anual de créditos: <span>{{ functionFormatPrice(promedioAnualCreditos) }}
                            </span> <br><br>
                            • Diferencia: <span>{{ functionFormatPrice(diferencia) }} </span> <br>


                        </v-card-text>
                    </v-card>

                    <v-card class="mx-auto justify-center" width="344">
                        <v-card-text>

                            <h4>FACTOR DE AJUSTE ANUAL</h4>
                            <div class="text--primary"></div><br>

                            • Factor de Ajuste Anual: <span>{{ Math.round(factAjusteAnual*10000)/10000 }} </span>
                            <br><br>
                            • Ajuste Anual por Inflación Acumulable: <span>{{ functionFormatPrice(factAjusteAnualIA) }}
                            </span> <br>

                        </v-card-text>
                    </v-card>

                    <v-card class="rigth" width="344">
                        <v-card-text>

                            <h4>INPC's</h4><br>
                            <div class="text--primary"></div>
                            • INPC Reciente: <span>{{ Math.round(inpcReciente*10000)/10000 }} </span> <br><br>
                            • INPC Antiguo: <span>{{ Math.round(inpcAntiguo*10000)/10000 }} </span> <br>

                        </v-card-text>
                    </v-card>

                </v-row>
            </v-card>

            <v-card-text>
                <br>
                <h2>Créditos</h2>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-data-table :items="itemsReporteCreditos" :headers="headersReporteCreditos" hide-default-footer
                    :items-per-page="-1">
                    <template v-slot:item.bancos="{ item }">
                        <span>{{ functionFormatPrice(item.bancos) }}</span>
                    </template>

                    <template v-slot:item.inversiones="{ item }">
                        <span>{{ functionFormatPrice(item.inversiones) }}</span>
                    </template>

                    <template v-slot:item.otrosInstrumentosFinancieros="{ item }">
                        <span>{{ functionFormatPrice(item.otrosInstrumentosFinancieros) }}</span>
                    </template>

                    <template v-slot:item.clientes="{ item }">
                        <span>{{ functionFormatPrice(item.clientes) }}</span>
                    </template>

                    <template v-slot:item.cuentasYDocumentosPorCobrar="{ item }">
                        <span>{{ functionFormatPrice(item.cuentasYDocumentosPorCobrar) }}</span>
                    </template>

                    <template v-slot:item.partesRelacionadasNacionales="{ item }">
                        <span>{{ functionFormatPrice(item.partesRelacionadasNacionales) }}</span>
                    </template>

                    <template v-slot:item.partesRelacionadasExtranjeros="{ item }">
                        <span>{{ functionFormatPrice(item.partesRelacionadasExtranjeros) }}</span>
                    </template>
                    <template v-slot:item.otrosDeudoresDiversos="{ item }">
                        <span>{{ functionFormatPrice(item.otrosDeudoresDiversos) }}</span>
                    </template>
                    <template v-slot:item.suma="{ item }">
                        <span>{{ functionFormatPrice(item.suma) }}</span>
                    </template>
                </v-data-table>
                <br>
                <br>
                <h2>Deudas</h2>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-data-table :items="itemsReporteDeudas" :headers="headersReporteDeudas" hide-default-footer
                    :items-per-page="-1">
                    <template v-slot:item.proveedores="{ item }">
                        <span>{{ functionFormatPrice(item.proveedores) }}</span>
                    </template>

                    <template v-slot:item.cuentasPorPagarACortoPlazo="{ item }">
                        <span>{{ functionFormatPrice(item.cuentasPorPagarACortoPlazo) }}</span>
                    </template>

                    <template v-slot:item.instrumentosFinancierosCortoP="{ item }">
                        <span>{{ functionFormatPrice(item.instrumentosFinancierosCortoP) }}</span>
                    </template>

                    <template v-slot:item.acreedoresDiversosCortoPlazoN="{ item }">
                        <span>{{ functionFormatPrice(item.acreedoresDiversosCortoPlazoN) }}</span>
                    </template>

                    <template v-slot:item.acreedoresDiversosCortoPlazoE="{ item }">
                        <span>{{ functionFormatPrice(item.acreedoresDiversosCortoPlazoE) }}</span>
                    </template>

                    <template v-slot:item.acreedoresDiversosCortoPlazoNPR="{ item }">
                        <span>{{ functionFormatPrice(item.acreedoresDiversosCortoPlazoNPR) }}</span>
                    </template>

                    <template v-slot:item.acreedoresDiversosCortoPlazoEPR="{ item }">
                        <span>{{ functionFormatPrice(item.acreedoresDiversosCortoPlazoEPR) }}</span>
                    </template>

                    <template v-slot:item.otrosAcreedoresDiversosCortoP="{ item }">
                        <span>{{ functionFormatPrice(item.otrosAcreedoresDiversosCortoP) }}</span>
                    </template>

                    <template v-slot:item.otrosPasivosCortoPlazo="{ item }">
                        <span>{{ functionFormatPrice(item.otrosPasivosCortoPlazo) }}</span>
                    </template>

                    <template v-slot:item.acreedoresDivLargoPlazoN="{ item }">
                        <span>{{ functionFormatPrice(item.acreedoresDivLargoPlazoN) }}</span>
                    </template>

                    <template v-slot:item.acreedoresDivLargoPlazoE="{ item }">
                        <span>{{ functionFormatPrice(item.acreedoresDivLargoPlazoE) }}</span>
                    </template>

                    <template v-slot:item.acreedoresDivLargoPlazoNPR="{ item }">
                        <span>{{ functionFormatPrice(item.acreedoresDivLargoPlazoNPR) }}</span>
                    </template>

                    <template v-slot:item.acreedoresDivLargoPlazoEPR="{ item }">
                        <span>{{ functionFormatPrice(item.acreedoresDivLargoPlazoEPR) }}</span>
                    </template>

                    <template v-slot:item.otrosAcreedoresDiversosLargoPlazo="{ item }">
                        <span>{{ functionFormatPrice(item.otrosAcreedoresDiversosLargoPlazo) }}</span>
                    </template>

                    <template v-slot:item.cuentasPorPagarLargoPlazo="{ item }">
                        <span>{{ functionFormatPrice(item.cuentasPorPagarLargoPlazo) }}</span>
                    </template>

                    <template v-slot:item.instrumentosFinancierosLargoP="{ item }">
                        <span>{{ functionFormatPrice(item.instrumentosFinancierosLargoP) }}</span>
                    </template>

                    <template v-slot:item.otrosPasivosLargoPlazo="{ item }">
                        <span>{{ functionFormatPrice(item.otrosPasivosLargoPlazo) }}</span>
                    </template>

                    <template v-slot:item.aportacionesFuturoAumentoCapital="{ item }">
                        <span>{{ functionFormatPrice(item.aportacionesFuturoAumentoCapital) }}</span>
                    </template>

                    <template v-slot:item.suma="{ item }">
                        <span>{{ functionFormatPrice(item.suma) }}</span>
                    </template>

                </v-data-table>
                <br>
            </v-card-text>
            <v-card-actions>
            </v-card-actions>
        </v-card>

        <!--Dialog loading-->
        <v-dialog v-model="loading" persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    {{loadingMessage}}
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- DIALOG NUEVO INPC -->
        <v-dialog v-model="dialogNuevoInpc" hide-overlay persistent>
            <v-card>
                <!-- BOTONES -->
                <v-toolbar>
                    <!-- BOTON CERRAR -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-1" dark fab small @click="dialogNuevoInpc = false"
                                v-bind="attrs" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <b style="font-size: 30px;">AGREGA EL INPC FALTANTE A LA BASE</b>
                    <v-spacer></v-spacer>
                    <!-- BOTON CONFIRMAR -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-1" dark fab small @click="PostNuevaCuenta()"
                                v-bind="attrs" v-on="on">
                                <v-icon>mdi-check</v-icon>
                            </v-btn>
                        </template>
                        <span>Crear</span>
                    </v-tooltip>
                </v-toolbar>

                <!-- TABLA -->
                <v-card-text class="mt-15">
                    <div>
                        <v-row>
                            <v-data-table :headers="headersInpc" :items="this.listInpc">
                                <template v-slot:item.anio="{ item }">
                                    <td class="primary--text">{{ item.anio }}</td>
                                </template>
                                <!-- EDITA LAS CELDAS DE LOS MESES -->
                                <!-- ENERO -->
                                <template v-slot:item.enero="props">
                                    <v-edit-dialog
                                    :return-value.sync="props.item.enero"
                                    @save="save"
                                    @cancel="cancel"
                                    @open="open"
                                    @close="close"
                                    >
                                    {{ props.item.enero }}
                                    <template v-slot:input>
                                        <v-text-field
                                        v-model="props.item.enero"
                                        :rules="[max25chars]"
                                        label="Edit"
                                        single-line
                                        counter
                                        ></v-text-field>
                                    </template>
                                    </v-edit-dialog>
                                </template>
                                <!-- FEBRERO -->
                                <template v-slot:item.febrero="props">
                                    <v-edit-dialog
                                    :return-value.sync="props.item.febrero"
                                    @save="save"
                                    @cancel="cancel"
                                    @open="open"
                                    @close="close"
                                    >
                                    {{ props.item.febrero }}
                                    <template v-slot:input>
                                        <v-text-field
                                        v-model="props.item.febrero"
                                        :rules="[max25chars]"
                                        label="Edit"
                                        single-line
                                        counter
                                        ></v-text-field>
                                    </template>
                                    </v-edit-dialog>
                                </template>
                                <!-- MARZO -->
                                <template v-slot:item.marzo="props">
                                    <v-edit-dialog
                                    :return-value.sync="props.item.marzo"
                                    @save="save"
                                    @cancel="cancel"
                                    @open="open"
                                    @close="close"
                                    >
                                    {{ props.item.marzo }}
                                    <template v-slot:input>
                                        <v-text-field
                                        v-model="props.item.marzo"
                                        :rules="[max25chars]"
                                        label="Edit"
                                        single-line
                                        counter
                                        ></v-text-field>
                                    </template>
                                    </v-edit-dialog>
                                </template>
                                <!-- ABRIL -->
                                <template v-slot:item.abril="props">
                                    <v-edit-dialog
                                    :return-value.sync="props.item.abril"
                                    @save="save"
                                    @cancel="cancel"
                                    @open="open"
                                    @close="close"
                                    >
                                    {{ props.item.abril }}
                                    <template v-slot:input>
                                        <v-text-field
                                        v-model="props.item.abril"
                                        :rules="[max25chars]"
                                        label="Edit"
                                        single-line
                                        counter
                                        ></v-text-field>
                                    </template>
                                    </v-edit-dialog>
                                </template>
                                <!-- MAYO -->
                                <template v-slot:item.mayo="props">
                                    <v-edit-dialog
                                    :return-value.sync="props.item.mayo"
                                    @save="save"
                                    @cancel="cancel"
                                    @open="open"
                                    @close="close"
                                    >
                                    {{ props.item.mayo }}
                                    <template v-slot:input>
                                        <v-text-field
                                        v-model="props.item.mayo"
                                        :rules="[max25chars]"
                                        label="Edit"
                                        single-line
                                        counter
                                        ></v-text-field>
                                    </template>
                                    </v-edit-dialog>
                                </template>
                                <!-- JUNIO -->
                                <template v-slot:item.junio="props">
                                    <v-edit-dialog
                                    :return-value.sync="props.item.junio"
                                    @save="save"
                                    @cancel="cancel"
                                    @open="open"
                                    @close="close"
                                    >
                                    {{ props.item.junio }}
                                    <template v-slot:input>
                                        <v-text-field
                                        v-model="props.item.junio"
                                        :rules="[max25chars]"
                                        label="Edit"
                                        single-line
                                        counter
                                        ></v-text-field>
                                    </template>
                                    </v-edit-dialog>
                                </template>
                                <!-- JULIO -->
                                <template v-slot:item.julio="props">
                                    <v-edit-dialog
                                    :return-value.sync="props.item.julio"
                                    @save="save"
                                    @cancel="cancel"
                                    @open="open"
                                    @close="close"
                                    >
                                    {{ props.item.julio }}
                                    <template v-slot:input>
                                        <v-text-field
                                        v-model="props.item.julio"
                                        :rules="[max25chars]"
                                        label="Edit"
                                        single-line
                                        counter
                                        ></v-text-field>
                                    </template>
                                    </v-edit-dialog>
                                </template>
                                <!-- AGOSTO -->
                                <template v-slot:item.agosto="props">
                                    <v-edit-dialog
                                    :return-value.sync="props.item.agosto"
                                    @save="save"
                                    @cancel="cancel"
                                    @open="open"
                                    @close="close"
                                    >
                                    {{ props.item.agosto }}
                                    <template v-slot:input>
                                        <v-text-field
                                        v-model="props.item.agosto"
                                        :rules="[max25chars]"
                                        label="Edit"
                                        single-line
                                        counter
                                        ></v-text-field>
                                    </template>
                                    </v-edit-dialog>
                                </template>
                                <!-- SEPTIEMBRE -->
                                <template v-slot:item.septiembre="props">
                                    <v-edit-dialog
                                    :return-value.sync="props.item.septiembre"
                                    @save="save"
                                    @cancel="cancel"
                                    @open="open"
                                    @close="close"
                                    >
                                    {{ props.item.septiembre }}
                                    <template v-slot:input>
                                        <v-text-field
                                        v-model="props.item.septiembre"
                                        :rules="[max25chars]"
                                        label="Edit"
                                        single-line
                                        counter
                                        ></v-text-field>
                                    </template>
                                    </v-edit-dialog>
                                </template>
                                <!-- OCTUBRE -->
                                <template v-slot:item.octubre="props">
                                    <v-edit-dialog
                                    :return-value.sync="props.item.octubre"
                                    @save="save"
                                    @cancel="cancel"
                                    @open="open"
                                    @close="close"
                                    >
                                    {{ props.item.octubre }}
                                    <template v-slot:input>
                                        <v-text-field
                                        v-model="props.item.octubre"
                                        :rules="[max25chars]"
                                        label="Edit"
                                        single-line
                                        counter
                                        ></v-text-field>
                                    </template>
                                    </v-edit-dialog>
                                </template>
                                <!-- NOVIEMBRE -->
                                <template v-slot:item.noviembre="props">
                                    <v-edit-dialog
                                    :return-value.sync="props.item.noviembre"
                                    @save="save"
                                    @cancel="cancel"
                                    @open="open"
                                    @close="close"
                                    >
                                    {{ props.item.noviembre }}
                                    <template v-slot:input>
                                        <v-text-field
                                        v-model="props.item.noviembre"
                                        :rules="[max25chars]"
                                        label="Edit"
                                        single-line
                                        counter
                                        ></v-text-field>
                                    </template>
                                    </v-edit-dialog>
                                </template>
                                <!-- DICIEMBRE -->
                                <template v-slot:item.diciembre="props">
                                    <v-edit-dialog
                                    :return-value.sync="props.item.diciembre"
                                    @save="save"
                                    @cancel="cancel"
                                    @open="open"
                                    @close="close"
                                    >
                                    {{ props.item.diciembre }}
                                    <template v-slot:input>
                                        <v-text-field
                                        v-model="props.item.diciembre"
                                        :rules="[max25chars]"
                                        label="Edit"
                                        single-line
                                        counter
                                        ></v-text-field>
                                    </template>
                                    </v-edit-dialog>
                                </template>
                            </v-data-table>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import print from 'print-js';
    import pdf from 'vue-pdf';
    import jsPDF from 'jspdf';
    import autoTable from 'jspdf-autotable'

    export default {
        name: 'CalculoAjusteAnualInflacion',
        components: {

        },

        data: () => ({
            //LOADING
            loading: false,
            loadingMessage: '',

            //FECHAS
            fechaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            fechaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            calendario: false,
            calendario2: false,

            //DATOS DEL REPORTE
            itemsReporteCreditos: [],
            headersReporteCreditos: [
                { text: 'Mes', value: 'mes' },
                { text: 'Bancos', value: 'bancos' },
                { text: 'Inversiones', value: 'inversiones' },
                { text: 'Otros Instrumentos Financieros', value: 'otrosInstrumentosFinancieros' },
                { text: 'Clientes', value: 'clientes' },
                { text: 'Cuentas y Documentos por Cobrar', value: 'cuentasYDocumentosPorCobrar' },
                { text: 'Partes Relacionadas Nacionales', value: 'partesRelacionadasNacionales' },
                { text: 'Partes Relacionadas Extranjeros', value: 'partesRelacionadasExtranjeros' },
                { text: 'Otros Deudores Diversos', value: 'otrosDeudoresDiversos' },
                { text: 'Suma', value: 'suma' },
            ],
            itemsReporteDeudas: [],
            headersReporteDeudas: [
                { text: 'Mes', value: 'mes' },
                { text: 'Proveedores', value: 'proveedores' },
                { text: 'Cuentas por Pagar a Corto Plazo', value: 'cuentasPorPagarACortoPlazo' },
                { text: 'Instrumentos Financieros Corto Plazo', value: 'instrumentosFinancierosCortoP' },
                { text: 'Acreedores Diversos Corto Plazo Nacionales', value: 'acreedoresDiversosCortoPlazoN' },
                { text: 'Acreedores Diversos Corto Plazo Extranjeros', value: 'acreedoresDiversosCortoPlazoE' },
                { text: 'Acreedores Diversos Corto Plazo Nacional Parte Relacionada', value: 'acreedoresDiversosCortoPlazoNPR' },
                { text: 'Acreedores Diversos Corto Plazo Extranjero Parte Relacionada', value: 'acreedoresDiversosCortoPlazoEPR' },
                { text: 'Otros Acreedores Diversos Corto Plazo', value: 'otrosAcreedoresDiversosCortoP' },
                { text: 'Otros Pasivos Corto Plazo', value: 'otrosPasivosCortoPlazo' },
                { text: 'Acreedores Diversos Largo Plazo Nacional', value: 'acreedoresDivLargoPlazoN' },
                { text: 'Acreedores Diversos Largo Plazo Extranjero', value: 'acreedoresDivLargoPlazoE' },
                { text: 'Acreedores Diversos Largo Plazo Nacional Parte Relacionada', value: 'acreedoresDivLargoPlazoNPR' },
                { text: 'Acreedores Diversos Largo Plazo Extranjero Parte Relacionada', value: 'acreedoresDivLargoPlazoEPR' },
                { text: 'Otros Acreedores Diversos Largo Plazo', value: 'otrosAcreedoresDiversosLargoPlazo' },
                { text: 'Cuentas por Pagar Largo Plazo', value: 'cuentasPorPagarLargoPlazo' },
                { text: 'Instrumentos Financieros Largo Plazo', value: 'instrumentosFinancierosLargoP' },
                { text: 'Otros Pasivos Largo Plazo', value: 'otrosPasivosLargoPlazo' },
                { text: 'Aportaciones para Futuro Aumento de Capital', value: 'aportacionesFuturoAumentoCapital' },
                { text: 'Suma', value: 'suma' },
            ],
            listInpc: [],

            sumaCreditos: 0,
            sumaDeudas: 0,
            mesesEjercicio: 0,
            promedioAnualDeudas: 0,
            promedioAnualCreditos: 0,
            diferencia: 0,
            factAjusteAnual: 0,
            factAjusteAnualIA: 0,
            inpcReciente: 0,
            inpcAntiguo: 0,
            anios: '',

            arraydeudas: [],
            mes: '',

            // RADIO BUTTON PARA INPC
            radios: '1',
            meses: [
                {text: 'Enero', value: '01'},
                {text: 'Febrero', value: '02'},
                {text: 'Marzo', value: '03'},
                {text: 'Abril', value: '04'},
                {text: 'Mayo', value: '05'},
                {text: 'Junio', value: '06'},
                {text: 'Julio', value: '07'},
                {text: 'Agosto', value: '08'},
                {text: 'Septiembre', value: '09'},
                {text: 'Octubre', value: '10'},
                {text: 'Noviembre', value: '11'},
                {text: 'Diciembre', value: '12'},
            ],
            mesInpc: '',
            mesReciente: '',
            mesAntiguo: '',

            // DIALOG PARA EL NUEVO INPC
            dialogNuevoInpc:false,
            // TABLA INPC
            headersInpc:[
                { text: "Año", value: "anio" },
                { text: "Enero", value: "enero" },
                { text: "Febrero", value: "febrero" },
                { text: "Marzo", value: "marzo" },
                { text: "Abril", value: "abril" },
                { text: "Mayo", value: "mayo" },
                { text: "Junio", value: "junio" },
                { text: "Julio", value: "julio" },
                { text: "Agosto", value: "agosto" },
                { text: "Septiembre", value: "septiembre" },
                { text: "Octubre", value: "octubre" },
                { text: "Noviembre", value: "noviembre" },
                { text: "Diciembre", value: "diciembre" },
            ],
            max25chars: v => v.length <= 8 || 'Limite de caracteres excedido',
        }),

        watch: {

        },

        computed: {
            empresa() {
                return this.$store.state.usuario
            },

            datosEmpresa(){

            },

            itemsAnios() {
                let fecha = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd');
                let anio = fecha.split('-')[0];
                let lista = []
                if(this.radios === '1'){
                    lista.push(anio - 1);
                    lista.push(anio - 2);
                    lista.push(anio - 3);
                    lista.push(anio - 4);
                lista.push(anio - 5);

                }else{
                    lista.push(anio);
                    lista.push(anio - 1);
                    lista.push(anio - 2);
                    lista.push(anio - 3);
                    lista.push(anio - 4);
                lista.push(anio - 5);

                }
                

                return lista;
            },
        },

        created() {
            this.GetInpc();
            this.GetEmpresa();

        },

        methods: {
            functionFormatPrice(value) {
                let val = (value / 1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            async GetEmpresa() {
                console.log(this.empresa)
                try {
                    let response = await axios.get('https://api-erp.contago.com.mx/api/Empresas/GetEmpresa/' + this.empresa.empresaBase);
                    console.log('a', response.data)
                    if (response.data.idEmpresa != 0) {
                        this.$store.state.empresaStore = response.data
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            async GetReporte() {
                this.loading = true;
                this.loadingMessage = 'Consultando...';
                try {
                    // console.time();
                    let respuesta = await axios.get('reporteAutoConta/AjusteAnual/' + this.empresa.empresaBase + '/' + this.anios)
                    // console.log(respuesta.data)
                    var array = respuesta.data.reporte
                    this.arraydeudas = respuesta.data.reporteD

                    const sumaBancos = array.map(item => item.bancos).reduce((prev, curr) => prev + curr, 0);
                    const sumaInversiones = array.map(item => item.inversiones).reduce((prev, curr) => prev + curr, 0);
                    const sumaOtrosInstrumentosFinancieros = array.map(item => item.otrosInstrumentosFinancieros).reduce((prev, curr) => prev + curr, 0);
                    const sumaClientes = array.map(item => item.clientes).reduce((prev, curr) => prev + curr, 0);
                    const sumaCuentasYDocumentosPorCobrar = array.map(item => item.cuentasYDocumentosPorCobrar).reduce((prev, curr) => prev + curr, 0);
                    const sumaPartesRelacionadasNacionales = array.map(item => item.partesRelacionadasNacionales).reduce((prev, curr) => prev + curr, 0);
                    const sumaPartesRelacionadasExtranjeros = array.map(item => item.partesRelacionadasExtranjeros).reduce((prev, curr) => prev + curr, 0);
                    const sumaotrosDeudoresDiversos = array.map(item => item.otrosDeudoresDiversos).reduce((prev, curr) => prev + curr, 0);
                    const totalCreditos = array.map(item => item.suma).reduce((prev, curr) => prev + curr, 0);
                    var sumatoria = {
                        mes: 'Suma', bancos: sumaBancos,
                        inversiones: sumaInversiones,
                        otrosInstrumentosFinancieros: sumaOtrosInstrumentosFinancieros,
                        clientes: sumaClientes,
                        cuentasYDocumentosPorCobrar: sumaCuentasYDocumentosPorCobrar,
                        partesRelacionadasNacionales: sumaPartesRelacionadasNacionales,
                        partesRelacionadasExtranjeros: sumaPartesRelacionadasExtranjeros,
                        otrosDeudoresDiversos: sumaotrosDeudoresDiversos,
                        suma: totalCreditos
                    }
                    this.sumaCreditos = totalCreditos
                    array.push(sumatoria);

                    this.itemsReporteCreditos = array
                } catch (error) {
                    console.log(error.data)
                    console.timeEnd();
                }
            },

            async GetReporteDeudas(respuesta) {
                try {
                    // let respuesta = await axios.get('reporteAutoConta/AjusteAnualD/' + this.empresa.empresaBase + '/' + this.anios)

                    var array = this.arraydeudas

                    const sumaProveedores = array.map(item => item.proveedores).reduce((prev, curr) => prev + curr, 0);
                    const sumaCuentasPorPagarACortoPlazo = array.map(item => item.cuentasPorPagarACortoPlazo).reduce((prev, curr) => prev + curr, 0);
                    const sumaInstrumentosFinancierosCortoP = array.map(item => item.instrumentosFinancierosCortoP).reduce((prev, curr) => prev + curr, 0);
                    const sumaAcreedoresDiversosCortoPlazoN = array.map(item => item.acreedoresDiversosCortoPlazoN).reduce((prev, curr) => prev + curr, 0);
                    const sumaAcreedoresDiversosCortoPlazoE = array.map(item => item.acreedoresDiversosCortoPlazoE).reduce((prev, curr) => prev + curr, 0);
                    const sumaAcreedoresDiversosCortoPlazoNPR = array.map(item => item.acreedoresDiversosCortoPlazoNPR).reduce((prev, curr) => prev + curr, 0);
                    const sumaAcreedoresDiversosCortoPlazoEPR = array.map(item => item.acreedoresDiversosCortoPlazoEPR).reduce((prev, curr) => prev + curr, 0);
                    const sumaOtrosAcreedoresDiversosCortoP = array.map(item => item.otrosAcreedoresDiversosCortoP).reduce((prev, curr) => prev + curr, 0);
                    const sumaOtrosPasivosCortoPlazo = array.map(item => item.otrosPasivosCortoPlazo).reduce((prev, curr) => prev + curr, 0);
                    const sumaAcreedoresDivLargoPlazoN = array.map(item => item.acreedoresDivLargoPlazoN).reduce((prev, curr) => prev + curr, 0);
                    const sumaAcreedoresDivLargoPlazoE = array.map(item => item.acreedoresDivLargoPlazoE).reduce((prev, curr) => prev + curr, 0);
                    const sumaAcreedoresDivLargoPlazoNPR = array.map(item => item.acreedoresDivLargoPlazoNPR).reduce((prev, curr) => prev + curr, 0);
                    const sumaAcreedoresDivLargoPlazoEPR = array.map(item => item.acreedoresDivLargoPlazoEPR).reduce((prev, curr) => prev + curr, 0);
                    const sumaOtrosAcreedoresDiversosLargoPlazo = array.map(item => item.otrosAcreedoresDiversosLargoPlazo).reduce((prev, curr) => prev + curr, 0);
                    const sumaCuentasPorPagarLargoPlazo = array.map(item => item.cuentasPorPagarLargoPlazo).reduce((prev, curr) => prev + curr, 0);
                    const sumaInstrumentosFinancierosLargoP = array.map(item => item.instrumentosFinancierosLargoP).reduce((prev, curr) => prev + curr, 0);
                    const sumaOtrosPasivosLargoPlazo = array.map(item => item.otrosPasivosLargoPlazo).reduce((prev, curr) => prev + curr, 0);
                    const sumaAportacionesFuturoAumentoCapital = array.map(item => item.aportacionesFuturoAumentoCapital).reduce((prev, curr) => prev + curr, 0);
                    const totalDeudas = array.map(item => item.suma).reduce((prev, curr) => prev + curr, 0);
                    var sumatoria = {
                        mes: 'suma', proveedores: sumaProveedores,
                        cuentasPorPagarACortoPlazo: sumaCuentasPorPagarACortoPlazo,
                        instrumentosFinancierosCortoP: sumaInstrumentosFinancierosCortoP,
                        acreedoresDiversosCortoPlazoN: sumaAcreedoresDiversosCortoPlazoN,
                        acreedoresDiversosCortoPlazoE: sumaAcreedoresDiversosCortoPlazoE,
                        acreedoresDiversosCortoPlazoNPR: sumaAcreedoresDiversosCortoPlazoNPR,
                        acreedoresDiversosCortoPlazoEPR: sumaAcreedoresDiversosCortoPlazoEPR,
                        otrosAcreedoresDiversosCortoP: sumaOtrosAcreedoresDiversosCortoP,
                        otrosPasivosCortoPlazo: sumaOtrosPasivosCortoPlazo,
                        acreedoresDivLargoPlazoN: sumaAcreedoresDivLargoPlazoN,
                        acreedoresDivLargoPlazoE: sumaAcreedoresDivLargoPlazoE,
                        acreedoresDivLargoPlazoNPR: sumaAcreedoresDivLargoPlazoNPR,
                        acreedoresDivLargoPlazoEPR: sumaAcreedoresDivLargoPlazoEPR,
                        otrosAcreedoresDiversosLargoPlazo: sumaOtrosAcreedoresDiversosLargoPlazo,
                        cuentasPorPagarLargoPlazo: sumaCuentasPorPagarLargoPlazo,
                        instrumentosFinancierosLargoP: sumaInstrumentosFinancierosLargoP,
                        otrosPasivosLargoPlazo: sumaOtrosPasivosLargoPlazo,
                        aportacionesFuturoAumentoCapital: sumaAportacionesFuturoAumentoCapital,
                        suma: totalDeudas
                    }

                    this.sumaDeudas = totalDeudas
                    array.push(sumatoria);
                    this.itemsReporteDeudas = array

                    await this.GetInpc();

                    // VERIFICAMOS SI ESTA SELECCIONADO EL RADIO BUTTON PARA CONSULTAR TODO UN AÑO O SOLO UNOS MESES
                    // EN CASO DE QUE SEA TODO EL AÑO
                    if (this.radios === '1' || this.mesInpc === 'Enero') {
                        
                        // INPC ANTIGUO
                        let ObjInpcA = this.listInpc.find(x => x.anio == (this.anios -1))
                        let ObjMesA = 'diciembre';
                        this.inpcAntiguo = ObjInpcA[ObjMesA];

                        // INPC RECIENTE
                        let fechaActual = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
                        let fechaA = fechaActual.split('-')

                        if (this.anios === fechaA[0]){
                            this.mesesEjercicio = fechaA[1]
                            this.mesReciente = this.getLongMonthName(fechaA[1])
                        } else {
                            this.mesReciente = 'diciembre'
                            this.mesesEjercicio = '12'
                        }

                        while (this.inpcReciente === 0){

                            this.mesReciente = this.getLongMonthName(this.mesesEjercicio)

                            let ObjInpcB = this.listInpc.find(x => x.anio == this.anios)
                            this.inpcReciente = ObjInpcB[this.mesReciente];

                            // if (this.mesesEjercicio < 10){
                            //     this.mesesEjercicio = this.mesesEjercicio - 1
                            //     this.mesesEjercicio = '0' + this.mesesEjercicio
                            // } else{
                            //     this.mesesEjercicio = this.mesesEjercicio - 1
                            // }

                        }
                        
                        // EN CASO DE QUE SEAN SOLO UNOS MESES
                    } else {
                        // INPC ANTIGUO
                        let mesInpAntiguo = '';
                        if ((this.mesInpc) -1 < 10){
                            mesInpAntiguo = '0' + (this.mesInpc) -1
                        }
                        this.mesAntiguo = this.getLongMonthName(mesInpAntiguo)

                        let ObjInpcA = this.listInpc.find(x => x.anio == this.anios)
                        let ObjMesA = this.mesAntiguo;
                        this.inpcAntiguo = ObjInpcA[ObjMesA];

                        // INPC RECIENTE
                        let fechaActual = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
                        let fechaA = fechaActual.split('-')

                        if (this.anios === fechaA[0]){
                            this.mesesEjercicio = fechaA[1]
                            this.mesReciente = this.getLongMonthName(fechaA[1])
                        } else {
                            this.mesReciente = 'diciembre'
                            this.mesesEjercicio = '12'
                        }

                        while (this.inpcReciente === 0){

                            this.mesReciente = this.getLongMonthName(this.mesesEjercicio)

                            let ObjInpcB = this.listInpc.find(x => x.anio == this.anios)
                            this.inpcReciente = ObjInpcB[this.mesReciente];

                            if (this.mesesEjercicio < 10){
                                this.mesesEjercicio = this.mesesEjercicio - 1
                                this.mesesEjercicio = '0' + this.mesesEjercicio
                            } else{
                                this.mesesEjercicio = this.mesesEjercicio - 1
                            }

                        }
                    }
                    


                    // let fechaActual = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
                    // let fechaA = fechaActual.split('-')
                    // // let mesReciente = 'diciembre'
                    // // this.mesesEjercicio = '12'
                    // if (this.anios === fechaA[0]) {
                    //     this.mesesEjercicio = fechaA[1]
                    //     mesReciente = this.getLongMonthName(fechaA[1])
                    // }
                    // while (true) {
                    //     console.log('entro al while')
                    //     if (this.inpcReciente != 0) {
                    //         break;
                    //     }

                    //     let ObjInpcB = this.listInpc.find(x => x.anio == this.anios)
                    //     let ObjMesB = this.nombraMes(this.mesesEjercicio);
                    //     this.inpcReciente = ObjInpcB[this.mes];

                    //     if (this.mesesEjercicio < 10) {
                    //         this.mesesEjercicio = this.mesesEjercicio - 1
                    //         this.mesesEjercicio = '0' + this.mesesEjercicio
                    //     }
                    // }

                    // ESTO YA ESTABA COMENTADO
                    // if (this.anios === fechaA[0]) {
                    //     this.mesesEjercicio = fechaA[1]
                    //     // mesReciente = this.getLongMonthName(fechaActual)
                    //     mesReciente = this.getLongMonthName(fechaA[1])
                    // }
                    // // console.log(this.listInpc)

                    // let ObjInpcB = this.listInpc.find(x => x.anio == this.anios)
                    // let ObjMesB = mesReciente;
                    // this.inpcReciente = ObjInpcB[ObjMesB];

                    await this.promedioCreditos();
                    await this.promedioDeudas();
                    await this.diferenciaDC();
                    await this.factorAjusteAnual();
                    await this.ajusteAnualPorInflacionAcumulable();

                    // this.itemsReporteDeudas = array
                    console.timeEnd();
                    this.loading = false;
                } catch (error) {
                    console.log(error.data)
                    console.timeEnd();
                    this.loading = false;
                }
            },

            async GetReportes() {
                // await this.GetInpc();

                // let fechaActual = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
                // let fechaA = fechaActual.split('-')
                // let mesReciente = 'diciembre'
                // this.mesesEjercicio = '12'

                // if (this.anios === fechaA[0]) {
                //     this.mesesEjercicio = fechaA[1]
                //     mesReciente = this.getLongMonthName(fechaA[1])
                // }
                // while (true) {

                //     if (this.inpcReciente != 0) {
                //         break;
                //     }

                //     let ObjInpcB = this.listInpc.find(x => x.anio == this.anios)
                //     let ObjMesB = this.nombraMes(this.mesesEjercicio);
                //     this.inpcReciente = ObjInpcB[this.mes];

                //     if (this.mesesEjercicio < 10) {
                //         this.mesesEjercicio = this.mesesEjercicio - 1
                //         this.mesesEjercicio = '0' + this.mesesEjercicio
                //     }
                // }
                await this.GetReporte()
                await this.GetReporteDeudas(this.arraydeudas)
            },

            async nombraMes(numero) {

                let mes = '';
                switch (numero) {
                    case '01':
                        this.mes = 'enero'
                        break;
                    case '02':
                        this.mes = 'febrero'
                        break;
                    case '03':
                        this.mes = 'marzo'
                        break;
                    case '04':
                        this.mes = 'abril'
                        break;
                    case '05':
                        this.mes = 'mayo'
                        break;
                    case '06':
                        this.mes = 'junio'
                        break;
                    case '07':
                        this.mes = 'julio'
                        break;
                    case '08':
                        this.mes = 'agosto'
                        break;
                    case '09':
                        this.mes = 'septiembre'
                        break;
                    case '10':
                        this.mes = 'octubre'
                        break;
                    case '11':
                        this.mes = 'noviembre'
                        break;
                    case '12':
                        this.mes = 'diciembre'
                        break;
                    default:
                        this.mes = 'error'
                        break;
                }
                // console.log(mes)
                // return mes
            },

            async GetInpc() {
                this.loading = true;
                this.loadingMessage = 'Consultando...';
                let base = 'catalogos_sat';
                
                try {
                    let respuesta = await axios.get('reporteAutoConta/GetInpc/' + base)
                    this.listInpc = respuesta.data;
                    // console.log(respuesta.data)
                    this.loading = false;
                } catch (error) {
                    console.log(error.data)
                    this.loading = false;
                }
            },

            async promedioCreditos() {
                this.promedioAnualCreditos = this.sumaCreditos / this.mesesEjercicio
            },
            async promedioDeudas() {
                this.promedioAnualDeudas = this.sumaDeudas / this.mesesEjercicio
            },
            async diferenciaDC() {
                this.diferencia = this.promedioAnualDeudas - this.promedioAnualCreditos
            },
            async factorAjusteAnual() {
                this.factAjusteAnual = (this.inpcReciente / this.inpcAntiguo) - 1
            },
            async ajusteAnualPorInflacionAcumulable() {
                this.factAjusteAnualIA = this.diferencia * this.factAjusteAnual
            },
            getLongMonthName(date) {
                const numero = Number(date) - 1
                const monthNames = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
                return monthNames[numero];
            },

            abreDialogNuevoInpc(){
                
                this.dialogNuevoInpc=true;
            },

            // DIALOG NUEVO INPC
            save () {
                this.snack = true
                this.snackColor = 'success'
                this.snackText = 'Data saved'
            },
            cancel () {
                this.snack = true
                this.snackColor = 'error'
                this.snackText = 'Canceled'
            },
            open () {
                this.snack = true
                this.snackColor = 'info'
                this.snackText = 'Dialog opened'
            },
            close () {
                console.log('Dialog closed')
            },

            CreaPdf(){
                const doc = new jsPDF({
                    orientation: 'landscape'
                });
                var pageSize = {
                    width: 595.28,
                    height: 841.89
                };

                // Define los márgenes de la página (en este caso, 10mm)
                var pageMargins = {
                    top: 10,
                    bottom: 10,
                    left: 10,
                    right: 10
                };

                //PRIMERA PARTE
                let xMargen = 10;
                let yPrimeraParte = 30
                doc.setFontSize(14);
                doc.setFont('helvetica', 'bold');
                doc.text('Acumulables' , xMargen, yPrimeraParte,  { align: 'left' } );

                let color = [102,46,145]
                let width = 277;
                let height = 2;
                let cornerRadius =1;

                doc.setFillColor.apply(null, color);
                doc.setDrawColor.apply(null, color);
                doc.roundedRect(xMargen, yPrimeraParte += 2, width, height, cornerRadius, cornerRadius, 'FD');

                yPrimeraParte += 8
                doc.setFontSize(12);
                doc.text('TOTALES' , xMargen, yPrimeraParte,  { align: 'left' } );
                doc.text('SALDOS' , xMargen + 65, yPrimeraParte,  { align: 'left' } );
                doc.text('FACTOR DE AJUSTE ANUAL' , xMargen + 155, yPrimeraParte,  { align: 'left' } );
                doc.text("INPC's" , xMargen + 228, yPrimeraParte,  { align: 'left' } );
                
                // TOTALES
                doc.setFontSize(11);
                yPrimeraParte += 5
                doc.setFont('helvetica', 'regular');
                doc.text('• Total Créditos:' , xMargen +2, yPrimeraParte,  { align: 'left' } );
                doc.text('• Total Deudas:' , xMargen + 2, yPrimeraParte + 5,  { align: 'left' } );
                doc.text('• Meses del Ejercicio:' , xMargen + 2, yPrimeraParte + 10,  { align: 'left' } );

                doc.text(this.functionFormatPrice(this.sumaCreditos) , xMargen + 58, yPrimeraParte,  { align: 'right' } );
                doc.text(this.functionFormatPrice(this.sumaDeudas) , xMargen + 58, yPrimeraParte + 5,  { align: 'right' } );
                doc.text(this.mesesEjercicio , xMargen + 58, yPrimeraParte + 10,  { align: 'right' } );

                // SALDOS
                doc.text('• Saldo promedio anual de deudas:' , xMargen +68, yPrimeraParte,  { align: 'left' } );
                doc.text('• Saldo promedio anual de créditos:' , xMargen + 68, yPrimeraParte + 5,  { align: 'left' } );
                doc.text('• Diferencia:' , xMargen + 68, yPrimeraParte + 10,  { align: 'left' } );

                doc.text(this.functionFormatPrice(this.promedioAnualDeudas) , xMargen + 146, yPrimeraParte,  { align: 'right' } );
                doc.text(this.functionFormatPrice(this.promedioAnualCreditos) , xMargen + 146, yPrimeraParte + 5,  { align: 'right' } );
                doc.text(this.functionFormatPrice(this.diferencia) , xMargen + 146, yPrimeraParte + 10,  { align: 'right' } );

                // FACTOR DE AJUSTE ANUAL
                doc.text('• Factor de Ajuste Anual:' , xMargen +157, yPrimeraParte,  { align: 'left' } );
                doc.text('• Ajuste Anual por \n   Inflación Acumulable:' , xMargen + 157, yPrimeraParte + 5,  { align: 'left' } );

                doc.text(this.factAjusteAnual.toFixed(4) , xMargen + 217, yPrimeraParte,  { align: 'right' } );
                doc.text('\n' + this.functionFormatPrice(this.factAjusteAnualIA) , xMargen + 217, yPrimeraParte + 5,  { align: 'right' } );

                // INPC's
                doc.text('• INPC Reciente:' , xMargen +230, yPrimeraParte,  { align: 'left' } );
                doc.text('• INPC Antiguo:' , xMargen + 230, yPrimeraParte + 5,  { align: 'left' } );

                doc.text(this.inpcReciente.toFixed(3) , xMargen + 277, yPrimeraParte,  { align: 'right' } );
                doc.text(this.inpcAntiguo.toFixed(3) , xMargen + 277, yPrimeraParte + 5,  { align: 'right' } );

                // CREDITOS
                doc.setFontSize(14);
                doc.setFont('helvetica', 'bold');
                doc.text('Créditos' , xMargen, yPrimeraParte+20,  { align: 'left' } );

                doc.setFillColor.apply(null, color);
                doc.setDrawColor.apply(null, color);
                doc.roundedRect(xMargen, yPrimeraParte += 22, width, height, cornerRadius, cornerRadius, 'FD');

                // AQUI COMIENZA LA TABLA
                var data = [
                    // CABECERA
                    ['Mes', 'Bancos', 'Inversiones', 'Otros Instrumentos Financieros', 'Clientes', 'Cuentas y Documentos por Cobrar', 'Partes Relacionadas Nacionales', 'Partes Relacionadas Extranjeros', 'Otros Deudores Diversos', 'Suma'],
                    // DATOS
                    ['Enero', this.functionFormatPrice(this.itemsReporteCreditos[0].bancos), 
                    this.functionFormatPrice(this.itemsReporteCreditos[0].inversiones
                    ), 
                    this.functionFormatPrice(this.itemsReporteCreditos[0].otrosInstrumentosFinancieros),
                    this.functionFormatPrice(this.itemsReporteCreditos[0].clientes),
                    this.functionFormatPrice(this.itemsReporteCreditos[0].cuentasYDocumentosPorCobrar),
                    this.functionFormatPrice(this.itemsReporteCreditos[0].partesRelacionadasNacionales),
                    this.functionFormatPrice(this.itemsReporteCreditos[0].partesRelacionadasExtranjeros),
                    this.functionFormatPrice(this.itemsReporteCreditos[0].otrosDeudoresDiversos),
                    this.functionFormatPrice(this.itemsReporteCreditos[0].suma)],
                    ['Febrero', this.functionFormatPrice(this.itemsReporteCreditos[1].bancos),
                    this.functionFormatPrice(this.itemsReporteCreditos[1].inversiones), 
                    this.functionFormatPrice(this.itemsReporteCreditos[1].otrosInstrumentosFinancieros),
                    this.functionFormatPrice(this.itemsReporteCreditos[1].clientes),
                    this.functionFormatPrice(this.itemsReporteCreditos[1].cuentasYDocumentosPorCobrar),
                    this.functionFormatPrice(this.itemsReporteCreditos[1].partesRelacionadasNacionales),
                    this.functionFormatPrice(this.itemsReporteCreditos[1].partesRelacionadasExtranjeros),
                    this.functionFormatPrice(this.itemsReporteCreditos[1].otrosDeudoresDiversos),
                    this.functionFormatPrice(this.itemsReporteCreditos[1].suma)],
                    ['Marzo', this.functionFormatPrice(this.itemsReporteCreditos[2].bancos),
                    this.functionFormatPrice(this.itemsReporteCreditos[2].inversiones), 
                    this.functionFormatPrice(this.itemsReporteCreditos[2].otrosInstrumentosFinancieros),
                    this.functionFormatPrice(this.itemsReporteCreditos[2].clientes),
                    this.functionFormatPrice(this.itemsReporteCreditos[2].cuentasYDocumentosPorCobrar),
                    this.functionFormatPrice(this.itemsReporteCreditos[2].partesRelacionadasNacionales),
                    this.functionFormatPrice(this.itemsReporteCreditos[2].partesRelacionadasExtranjeros),
                    this.functionFormatPrice(this.itemsReporteCreditos[2].otrosDeudoresDiversos),
                    this.functionFormatPrice(this.itemsReporteCreditos[2].suma)],
                    ['Abril', this.functionFormatPrice(this.itemsReporteCreditos[3].bancos),
                    this.functionFormatPrice(this.itemsReporteCreditos[3].inversiones), 
                    this.functionFormatPrice(this.itemsReporteCreditos[3].otrosInstrumentosFinancieros),
                    this.functionFormatPrice(this.itemsReporteCreditos[3].clientes),
                    this.functionFormatPrice(this.itemsReporteCreditos[3].cuentasYDocumentosPorCobrar),
                    this.functionFormatPrice(this.itemsReporteCreditos[3].partesRelacionadasNacionales),
                    this.functionFormatPrice(this.itemsReporteCreditos[3].partesRelacionadasExtranjeros),
                    this.functionFormatPrice(this.itemsReporteCreditos[3].otrosDeudoresDiversos),
                    this.functionFormatPrice(this.itemsReporteCreditos[3].suma)],
                    ['Mayo', this.functionFormatPrice(this.itemsReporteCreditos[4].bancos),
                    this.functionFormatPrice(this.itemsReporteCreditos[4].inversiones), 
                    this.functionFormatPrice(this.itemsReporteCreditos[4].otrosInstrumentosFinancieros),
                    this.functionFormatPrice(this.itemsReporteCreditos[4].clientes),
                    this.functionFormatPrice(this.itemsReporteCreditos[4].cuentasYDocumentosPorCobrar),
                    this.functionFormatPrice(this.itemsReporteCreditos[4].partesRelacionadasNacionales),
                    this.functionFormatPrice(this.itemsReporteCreditos[4].partesRelacionadasExtranjeros),
                    this.functionFormatPrice(this.itemsReporteCreditos[4].otrosDeudoresDiversos),
                    this.functionFormatPrice(this.itemsReporteCreditos[4].suma)],
                    ['Junio', this.functionFormatPrice(this.itemsReporteCreditos[5].bancos),
                    this.functionFormatPrice(this.itemsReporteCreditos[5].inversiones), 
                    this.functionFormatPrice(this.itemsReporteCreditos[5].otrosInstrumentosFinancieros),
                    this.functionFormatPrice(this.itemsReporteCreditos[5].clientes),
                    this.functionFormatPrice(this.itemsReporteCreditos[5].cuentasYDocumentosPorCobrar),
                    this.functionFormatPrice(this.itemsReporteCreditos[5].partesRelacionadasNacionales),
                    this.functionFormatPrice(this.itemsReporteCreditos[5].partesRelacionadasExtranjeros),
                    this.functionFormatPrice(this.itemsReporteCreditos[5].otrosDeudoresDiversos),
                    this.functionFormatPrice(this.itemsReporteCreditos[5].suma)],
                    ['Julio', this.functionFormatPrice(this.itemsReporteCreditos[6].bancos),
                    this.functionFormatPrice(this.itemsReporteCreditos[6].inversiones), 
                    this.functionFormatPrice(this.itemsReporteCreditos[6].otrosInstrumentosFinancieros),
                    this.functionFormatPrice(this.itemsReporteCreditos[6].clientes),
                    this.functionFormatPrice(this.itemsReporteCreditos[6].cuentasYDocumentosPorCobrar),
                    this.functionFormatPrice(this.itemsReporteCreditos[6].partesRelacionadasNacionales),
                    this.functionFormatPrice(this.itemsReporteCreditos[6].partesRelacionadasExtranjeros),
                    this.functionFormatPrice(this.itemsReporteCreditos[6].otrosDeudoresDiversos),
                    this.functionFormatPrice(this.itemsReporteCreditos[6].suma)],
                    ['Agosto', this.functionFormatPrice(this.itemsReporteCreditos[7].bancos),
                    this.functionFormatPrice(this.itemsReporteCreditos[7].inversiones), 
                    this.functionFormatPrice(this.itemsReporteCreditos[7].otrosInstrumentosFinancieros),
                    this.functionFormatPrice(this.itemsReporteCreditos[7].clientes),
                    this.functionFormatPrice(this.itemsReporteCreditos[7].cuentasYDocumentosPorCobrar),
                    this.functionFormatPrice(this.itemsReporteCreditos[7].partesRelacionadasNacionales),
                    this.functionFormatPrice(this.itemsReporteCreditos[7].partesRelacionadasExtranjeros),
                    this.functionFormatPrice(this.itemsReporteCreditos[7].otrosDeudoresDiversos),
                    this.functionFormatPrice(this.itemsReporteCreditos[7].suma)],
                    ['Septiembre',this.functionFormatPrice(this.itemsReporteCreditos[8].bancos),
                    this.functionFormatPrice(this.itemsReporteCreditos[8].inversiones), 
                    this.functionFormatPrice(this.itemsReporteCreditos[8].otrosInstrumentosFinancieros),
                    this.functionFormatPrice(this.itemsReporteCreditos[8].clientes),
                    this.functionFormatPrice(this.itemsReporteCreditos[8].cuentasYDocumentosPorCobrar),
                    this.functionFormatPrice(this.itemsReporteCreditos[8].partesRelacionadasNacionales),
                    this.functionFormatPrice(this.itemsReporteCreditos[8].partesRelacionadasExtranjeros),
                    this.functionFormatPrice(this.itemsReporteCreditos[8].otrosDeudoresDiversos),
                    this.functionFormatPrice(this.itemsReporteCreditos[8].suma)],
                    ['Octubre',this.functionFormatPrice(this.itemsReporteCreditos[9].bancos),
                    this.functionFormatPrice(this.itemsReporteCreditos[9].inversiones), 
                    this.functionFormatPrice(this.itemsReporteCreditos[9].otrosInstrumentosFinancieros),
                    this.functionFormatPrice(this.itemsReporteCreditos[9].clientes),
                    this.functionFormatPrice(this.itemsReporteCreditos[9].cuentasYDocumentosPorCobrar),
                    this.functionFormatPrice(this.itemsReporteCreditos[9].partesRelacionadasNacionales),
                    this.functionFormatPrice(this.itemsReporteCreditos[9].partesRelacionadasExtranjeros),
                    this.functionFormatPrice(this.itemsReporteCreditos[9].otrosDeudoresDiversos),
                    this.functionFormatPrice(this.itemsReporteCreditos[9].suma)],
                    ['Noviembre', this.functionFormatPrice(this.itemsReporteCreditos[10].bancos),
                    this.functionFormatPrice(this.itemsReporteCreditos[10].inversiones), 
                    this.functionFormatPrice(this.itemsReporteCreditos[10].otrosInstrumentosFinancieros),
                    this.functionFormatPrice(this.itemsReporteCreditos[10].clientes),
                    this.functionFormatPrice(this.itemsReporteCreditos[10].cuentasYDocumentosPorCobrar),
                    this.functionFormatPrice(this.itemsReporteCreditos[10].partesRelacionadasNacionales),
                    this.functionFormatPrice(this.itemsReporteCreditos[10].partesRelacionadasExtranjeros),
                    this.functionFormatPrice(this.itemsReporteCreditos[10].otrosDeudoresDiversos),
                    this.functionFormatPrice(this.itemsReporteCreditos[10].suma)],
                    ['Diciembre', this.functionFormatPrice(this.itemsReporteCreditos[11].bancos),
                    this.functionFormatPrice(this.itemsReporteCreditos[11].inversiones), 
                    this.functionFormatPrice(this.itemsReporteCreditos[11].otrosInstrumentosFinancieros),
                    this.functionFormatPrice(this.itemsReporteCreditos[11].clientes),
                    this.functionFormatPrice(this.itemsReporteCreditos[11].cuentasYDocumentosPorCobrar),
                    this.functionFormatPrice(this.itemsReporteCreditos[11].partesRelacionadasNacionales),
                    this.functionFormatPrice(this.itemsReporteCreditos[11].partesRelacionadasExtranjeros),
                    this.functionFormatPrice(this.itemsReporteCreditos[11].otrosDeudoresDiversos),
                    this.functionFormatPrice(this.itemsReporteCreditos[11].suma)],
                    ['Suma', this.functionFormatPrice(this.itemsReporteCreditos[12].bancos),
                    this.functionFormatPrice(this.itemsReporteCreditos[12].inversiones), 
                    this.functionFormatPrice(this.itemsReporteCreditos[12].otrosInstrumentosFinancieros),
                    this.functionFormatPrice(this.itemsReporteCreditos[12].clientes),
                    this.functionFormatPrice(this.itemsReporteCreditos[12].cuentasYDocumentosPorCobrar),
                    this.functionFormatPrice(this.itemsReporteCreditos[12].partesRelacionadasNacionales),
                    this.functionFormatPrice(this.itemsReporteCreditos[12].partesRelacionadasExtranjeros),
                    this.functionFormatPrice(this.itemsReporteCreditos[12].otrosDeudoresDiversos),
                    this.functionFormatPrice(this.itemsReporteCreditos[12].suma)]
                ];
                // Definir las opciones de la tabla
                var options = {
                    startY: 72
                };

                // Crear la tabla utilizando el método autoTable
                doc.autoTable({
                    head: [data[0]], // Encabezados de la tabla
                    body: data.slice(1), // Cuerpo de la tabla
                    startY: options.startY, // Posición de inicio de la tabla
                    headStyles: {
                        fillColor: [102,46,145] // Color de fondo en RGB (rojo, verde, azul)
                    },
                    columnStyles: {
                        1: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        2: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        3: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        4: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        5: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        6: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        7: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        8: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        9: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        9: { fillColor: '#eeeeee' }, // Colorear la última columna en gris
                    },                    
                });

                // NUEVA PAGINA
                doc.addPage()
                // Cambiar el tamaño de la hoja a "oficio" (216x356 mm)
                // doc.setPageSize('oficio');

                // Establecer el tamaño de página en formato oficio
                var pageSize = {
                    width: 612,  // Ancho en puntos
                    height: 1008  // Alto en puntos
                };

                // Define los márgenes de la página (en este caso, 10mm)
                var pageMargins = {
                    top: 10,
                    bottom: 10,
                    left: 10,
                    right: 10
                };

                //SEGUNDA PARTE
                let xMargen2 = 10;
                let ySegundaParte = 30

                // DEUDAS
                doc.setFontSize(14);
                doc.setFont('helvetica', 'bold');
                doc.text('Deudas' , xMargen2, ySegundaParte,  { align: 'left' } );

                doc.setFillColor.apply(null, color);
                doc.setDrawColor.apply(null, color);
                doc.roundedRect(xMargen2, ySegundaParte += 2, width, height, cornerRadius, cornerRadius, 'FD');

                // AQUI COMIENZA LA TABLA
                var fontSize = 8; // Tamaño de fuente deseado
                doc.setFontSize(fontSize);
                
                 // AQUI COMIENZA LA TABLA
                var data = [
                    // CABECERAS
                    ['Mes', 'Proveedores', '1*', '2*', '3*', '4*', '5*', '6*', '7*', '8*', '9*', '10*', '11*', '12*', '13*', '14*', '15*', '16*', '17*', 'Suma'],
                    // DATOS
                    ['Enero', this.functionFormatPrice(this.itemsReporteDeudas[0].proveedores), 
                    this.functionFormatPrice(this.itemsReporteDeudas[0].cuentasPorPagarACortoPlazo), 
                    this.functionFormatPrice(this.itemsReporteDeudas[0].instrumentosFinancierosCortoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[0].acreedoresDiversosCortoPlazoN),
                    this.functionFormatPrice(this.itemsReporteDeudas[0].acreedoresDiversosCortoPlazoE),
                    this.functionFormatPrice(this.itemsReporteDeudas[0].acreedoresDiversosCortoPlazoNPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[0].acreedoresDiversosCortoPlazoEPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[0].otrosAcreedoresDiversosCortoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[0].otrosPasivosCortoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[0].acreedoresDivLargoPlazoN),
                    this.functionFormatPrice(this.itemsReporteDeudas[0].acreedoresDivLargoPlazoE),
                    this.functionFormatPrice(this.itemsReporteDeudas[0].acreedoresDivLargoPlazoNPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[0].acreedoresDivLargoPlazoEPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[0].otrosAcreedoresDiversosLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[0].cuentasPorPagarLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[0].instrumentosFinancierosLargoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[0].otrosPasivosLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[0].aportacionesFuturoAumentoCapital),
                    this.functionFormatPrice(this.itemsReporteDeudas[0].suma)],
                    ['Febrero', this.functionFormatPrice(this.itemsReporteDeudas[1].proveedores), 
                    this.functionFormatPrice(this.itemsReporteDeudas[1].cuentasPorPagarACortoPlazo), 
                    this.functionFormatPrice(this.itemsReporteDeudas[1].instrumentosFinancierosCortoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[1].acreedoresDiversosCortoPlazoN),
                    this.functionFormatPrice(this.itemsReporteDeudas[1].acreedoresDiversosCortoPlazoE),
                    this.functionFormatPrice(this.itemsReporteDeudas[1].acreedoresDiversosCortoPlazoNPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[1].acreedoresDiversosCortoPlazoEPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[1].otrosAcreedoresDiversosCortoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[1].otrosPasivosCortoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[1].acreedoresDivLargoPlazoN),
                    this.functionFormatPrice(this.itemsReporteDeudas[1].acreedoresDivLargoPlazoE),
                    this.functionFormatPrice(this.itemsReporteDeudas[1].acreedoresDivLargoPlazoNPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[1].acreedoresDivLargoPlazoEPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[1].otrosAcreedoresDiversosLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[1].cuentasPorPagarLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[1].instrumentosFinancierosLargoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[1].otrosPasivosLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[1].aportacionesFuturoAumentoCapital),
                    this.functionFormatPrice(this.itemsReporteDeudas[1].suma)],
                    ['Marzo', this.functionFormatPrice(this.itemsReporteDeudas[2].proveedores), 
                    this.functionFormatPrice(this.itemsReporteDeudas[2].cuentasPorPagarACortoPlazo), 
                    this.functionFormatPrice(this.itemsReporteDeudas[2].instrumentosFinancierosCortoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[2].acreedoresDiversosCortoPlazoN),
                    this.functionFormatPrice(this.itemsReporteDeudas[2].acreedoresDiversosCortoPlazoE),
                    this.functionFormatPrice(this.itemsReporteDeudas[2].acreedoresDiversosCortoPlazoNPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[2].acreedoresDiversosCortoPlazoEPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[2].otrosAcreedoresDiversosCortoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[2].otrosPasivosCortoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[2].acreedoresDivLargoPlazoN),
                    this.functionFormatPrice(this.itemsReporteDeudas[2].acreedoresDivLargoPlazoE),
                    this.functionFormatPrice(this.itemsReporteDeudas[2].acreedoresDivLargoPlazoNPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[2].acreedoresDivLargoPlazoEPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[2].otrosAcreedoresDiversosLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[2].cuentasPorPagarLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[2].instrumentosFinancierosLargoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[2].otrosPasivosLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[2].aportacionesFuturoAumentoCapital),
                    this.functionFormatPrice(this.itemsReporteDeudas[2].suma)],
                    ['Abril', this.functionFormatPrice(this.itemsReporteDeudas[3].proveedores), 
                    this.functionFormatPrice(this.itemsReporteDeudas[3].cuentasPorPagarACortoPlazo), 
                    this.functionFormatPrice(this.itemsReporteDeudas[3].instrumentosFinancierosCortoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[3].acreedoresDiversosCortoPlazoN),
                    this.functionFormatPrice(this.itemsReporteDeudas[3].acreedoresDiversosCortoPlazoE),
                    this.functionFormatPrice(this.itemsReporteDeudas[3].acreedoresDiversosCortoPlazoNPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[3].acreedoresDiversosCortoPlazoEPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[3].otrosAcreedoresDiversosCortoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[3].otrosPasivosCortoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[3].acreedoresDivLargoPlazoN),
                    this.functionFormatPrice(this.itemsReporteDeudas[3].acreedoresDivLargoPlazoE),
                    this.functionFormatPrice(this.itemsReporteDeudas[3].acreedoresDivLargoPlazoNPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[3].acreedoresDivLargoPlazoEPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[3].otrosAcreedoresDiversosLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[3].cuentasPorPagarLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[3].instrumentosFinancierosLargoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[3].otrosPasivosLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[3].aportacionesFuturoAumentoCapital),
                    this.functionFormatPrice(this.itemsReporteDeudas[3].suma)],
                    ['Mayo', this.functionFormatPrice(this.itemsReporteDeudas[4].proveedores), 
                    this.functionFormatPrice(this.itemsReporteDeudas[4].cuentasPorPagarACortoPlazo), 
                    this.functionFormatPrice(this.itemsReporteDeudas[4].instrumentosFinancierosCortoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[4].acreedoresDiversosCortoPlazoN),
                    this.functionFormatPrice(this.itemsReporteDeudas[4].acreedoresDiversosCortoPlazoE),
                    this.functionFormatPrice(this.itemsReporteDeudas[4].acreedoresDiversosCortoPlazoNPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[4].acreedoresDiversosCortoPlazoEPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[4].otrosAcreedoresDiversosCortoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[4].otrosPasivosCortoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[4].acreedoresDivLargoPlazoN),
                    this.functionFormatPrice(this.itemsReporteDeudas[4].acreedoresDivLargoPlazoE),
                    this.functionFormatPrice(this.itemsReporteDeudas[4].acreedoresDivLargoPlazoNPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[4].acreedoresDivLargoPlazoEPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[4].otrosAcreedoresDiversosLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[4].cuentasPorPagarLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[4].instrumentosFinancierosLargoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[4].otrosPasivosLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[4].aportacionesFuturoAumentoCapital),
                    this.functionFormatPrice(this.itemsReporteDeudas[4].suma)],
                    ['Junio', this.functionFormatPrice(this.itemsReporteDeudas[5].proveedores), 
                    this.functionFormatPrice(this.itemsReporteDeudas[5].cuentasPorPagarACortoPlazo), 
                    this.functionFormatPrice(this.itemsReporteDeudas[5].instrumentosFinancierosCortoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[5].acreedoresDiversosCortoPlazoN),
                    this.functionFormatPrice(this.itemsReporteDeudas[5].acreedoresDiversosCortoPlazoE),
                    this.functionFormatPrice(this.itemsReporteDeudas[5].acreedoresDiversosCortoPlazoNPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[5].acreedoresDiversosCortoPlazoEPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[5].otrosAcreedoresDiversosCortoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[5].otrosPasivosCortoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[5].acreedoresDivLargoPlazoN),
                    this.functionFormatPrice(this.itemsReporteDeudas[5].acreedoresDivLargoPlazoE),
                    this.functionFormatPrice(this.itemsReporteDeudas[5].acreedoresDivLargoPlazoNPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[5].acreedoresDivLargoPlazoEPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[5].otrosAcreedoresDiversosLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[5].cuentasPorPagarLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[5].instrumentosFinancierosLargoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[5].otrosPasivosLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[5].aportacionesFuturoAumentoCapital),
                    this.functionFormatPrice(this.itemsReporteDeudas[5].suma)],
                    ['Julio', this.functionFormatPrice(this.itemsReporteDeudas[6].proveedores), 
                    this.functionFormatPrice(this.itemsReporteDeudas[6].cuentasPorPagarACortoPlazo), 
                    this.functionFormatPrice(this.itemsReporteDeudas[6].instrumentosFinancierosCortoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[6].acreedoresDiversosCortoPlazoN),
                    this.functionFormatPrice(this.itemsReporteDeudas[6].acreedoresDiversosCortoPlazoE),
                    this.functionFormatPrice(this.itemsReporteDeudas[6].acreedoresDiversosCortoPlazoNPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[6].acreedoresDiversosCortoPlazoEPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[6].otrosAcreedoresDiversosCortoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[6].otrosPasivosCortoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[6].acreedoresDivLargoPlazoN),
                    this.functionFormatPrice(this.itemsReporteDeudas[6].acreedoresDivLargoPlazoE),
                    this.functionFormatPrice(this.itemsReporteDeudas[6].acreedoresDivLargoPlazoNPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[6].acreedoresDivLargoPlazoEPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[6].otrosAcreedoresDiversosLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[6].cuentasPorPagarLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[6].instrumentosFinancierosLargoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[6].otrosPasivosLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[6].aportacionesFuturoAumentoCapital),
                    this.functionFormatPrice(this.itemsReporteDeudas[6].suma)],
                    ['Agosto', this.functionFormatPrice(this.itemsReporteDeudas[7].proveedores), 
                    this.functionFormatPrice(this.itemsReporteDeudas[7].cuentasPorPagarACortoPlazo), 
                    this.functionFormatPrice(this.itemsReporteDeudas[7].instrumentosFinancierosCortoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[7].acreedoresDiversosCortoPlazoN),
                    this.functionFormatPrice(this.itemsReporteDeudas[7].acreedoresDiversosCortoPlazoE),
                    this.functionFormatPrice(this.itemsReporteDeudas[7].acreedoresDiversosCortoPlazoNPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[7].acreedoresDiversosCortoPlazoEPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[7].otrosAcreedoresDiversosCortoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[7].otrosPasivosCortoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[7].acreedoresDivLargoPlazoN),
                    this.functionFormatPrice(this.itemsReporteDeudas[7].acreedoresDivLargoPlazoE),
                    this.functionFormatPrice(this.itemsReporteDeudas[7].acreedoresDivLargoPlazoNPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[7].acreedoresDivLargoPlazoEPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[7].otrosAcreedoresDiversosLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[7].cuentasPorPagarLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[7].instrumentosFinancierosLargoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[7].otrosPasivosLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[7].aportacionesFuturoAumentoCapital),
                    this.functionFormatPrice(this.itemsReporteDeudas[7].suma)],
                    ['Septiembre', this.functionFormatPrice(this.itemsReporteDeudas[8].proveedores), 
                    this.functionFormatPrice(this.itemsReporteDeudas[8].cuentasPorPagarACortoPlazo), 
                    this.functionFormatPrice(this.itemsReporteDeudas[8].instrumentosFinancierosCortoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[8].acreedoresDiversosCortoPlazoN),
                    this.functionFormatPrice(this.itemsReporteDeudas[8].acreedoresDiversosCortoPlazoE),
                    this.functionFormatPrice(this.itemsReporteDeudas[8].acreedoresDiversosCortoPlazoNPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[8].acreedoresDiversosCortoPlazoEPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[8].otrosAcreedoresDiversosCortoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[8].otrosPasivosCortoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[8].acreedoresDivLargoPlazoN),
                    this.functionFormatPrice(this.itemsReporteDeudas[8].acreedoresDivLargoPlazoE),
                    this.functionFormatPrice(this.itemsReporteDeudas[8].acreedoresDivLargoPlazoNPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[8].acreedoresDivLargoPlazoEPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[8].otrosAcreedoresDiversosLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[8].cuentasPorPagarLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[8].instrumentosFinancierosLargoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[8].otrosPasivosLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[8].aportacionesFuturoAumentoCapital),
                    this.functionFormatPrice(this.itemsReporteDeudas[8].suma)],
                    ['Octubre', this.functionFormatPrice(this.itemsReporteDeudas[9].proveedores), 
                    this.functionFormatPrice(this.itemsReporteDeudas[9].cuentasPorPagarACortoPlazo), 
                    this.functionFormatPrice(this.itemsReporteDeudas[9].instrumentosFinancierosCortoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[9].acreedoresDiversosCortoPlazoN),
                    this.functionFormatPrice(this.itemsReporteDeudas[9].acreedoresDiversosCortoPlazoE),
                    this.functionFormatPrice(this.itemsReporteDeudas[9].acreedoresDiversosCortoPlazoNPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[9].acreedoresDiversosCortoPlazoEPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[9].otrosAcreedoresDiversosCortoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[9].otrosPasivosCortoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[9].acreedoresDivLargoPlazoN),
                    this.functionFormatPrice(this.itemsReporteDeudas[9].acreedoresDivLargoPlazoE),
                    this.functionFormatPrice(this.itemsReporteDeudas[9].acreedoresDivLargoPlazoNPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[9].acreedoresDivLargoPlazoEPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[9].otrosAcreedoresDiversosLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[9].cuentasPorPagarLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[9].instrumentosFinancierosLargoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[9].otrosPasivosLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[9].aportacionesFuturoAumentoCapital),
                    this.functionFormatPrice(this.itemsReporteDeudas[9].suma)],
                    ['Noviembre', this.functionFormatPrice(this.itemsReporteDeudas[10].proveedores), 
                    this.functionFormatPrice(this.itemsReporteDeudas[10].cuentasPorPagarACortoPlazo), 
                    this.functionFormatPrice(this.itemsReporteDeudas[10].instrumentosFinancierosCortoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[10].acreedoresDiversosCortoPlazoN),
                    this.functionFormatPrice(this.itemsReporteDeudas[10].acreedoresDiversosCortoPlazoE),
                    this.functionFormatPrice(this.itemsReporteDeudas[10].acreedoresDiversosCortoPlazoNPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[10].acreedoresDiversosCortoPlazoEPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[10].otrosAcreedoresDiversosCortoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[10].otrosPasivosCortoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[10].acreedoresDivLargoPlazoN),
                    this.functionFormatPrice(this.itemsReporteDeudas[10].acreedoresDivLargoPlazoE),
                    this.functionFormatPrice(this.itemsReporteDeudas[10].acreedoresDivLargoPlazoNPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[10].acreedoresDivLargoPlazoEPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[10].otrosAcreedoresDiversosLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[10].cuentasPorPagarLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[10].instrumentosFinancierosLargoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[10].otrosPasivosLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[10].aportacionesFuturoAumentoCapital),
                    this.functionFormatPrice(this.itemsReporteDeudas[10].suma)],
                    ['Diciembre', this.functionFormatPrice(this.itemsReporteDeudas[11].proveedores), 
                    this.functionFormatPrice(this.itemsReporteDeudas[11].cuentasPorPagarACortoPlazo), 
                    this.functionFormatPrice(this.itemsReporteDeudas[11].instrumentosFinancierosCortoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[11].acreedoresDiversosCortoPlazoN),
                    this.functionFormatPrice(this.itemsReporteDeudas[11].acreedoresDiversosCortoPlazoE),
                    this.functionFormatPrice(this.itemsReporteDeudas[11].acreedoresDiversosCortoPlazoNPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[11].acreedoresDiversosCortoPlazoEPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[11].otrosAcreedoresDiversosCortoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[11].otrosPasivosCortoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[11].acreedoresDivLargoPlazoN),
                    this.functionFormatPrice(this.itemsReporteDeudas[11].acreedoresDivLargoPlazoE),
                    this.functionFormatPrice(this.itemsReporteDeudas[11].acreedoresDivLargoPlazoNPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[11].acreedoresDivLargoPlazoEPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[11].otrosAcreedoresDiversosLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[11].cuentasPorPagarLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[11].instrumentosFinancierosLargoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[11].otrosPasivosLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[11].aportacionesFuturoAumentoCapital),
                    this.functionFormatPrice(this.itemsReporteDeudas[11].suma)],
                    ['Suma', this.functionFormatPrice(this.itemsReporteDeudas[12].proveedores), 
                    this.functionFormatPrice(this.itemsReporteDeudas[12].cuentasPorPagarACortoPlazo), 
                    this.functionFormatPrice(this.itemsReporteDeudas[12].instrumentosFinancierosCortoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[12].acreedoresDiversosCortoPlazoN),
                    this.functionFormatPrice(this.itemsReporteDeudas[12].acreedoresDiversosCortoPlazoE),
                    this.functionFormatPrice(this.itemsReporteDeudas[12].acreedoresDiversosCortoPlazoNPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[12].acreedoresDiversosCortoPlazoEPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[12].otrosAcreedoresDiversosCortoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[12].otrosPasivosCortoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[12].acreedoresDivLargoPlazoN),
                    this.functionFormatPrice(this.itemsReporteDeudas[12].acreedoresDivLargoPlazoE),
                    this.functionFormatPrice(this.itemsReporteDeudas[12].acreedoresDivLargoPlazoNPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[12].acreedoresDivLargoPlazoEPR),
                    this.functionFormatPrice(this.itemsReporteDeudas[12].otrosAcreedoresDiversosLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[12].cuentasPorPagarLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[12].instrumentosFinancierosLargoP),
                    this.functionFormatPrice(this.itemsReporteDeudas[12].otrosPasivosLargoPlazo),
                    this.functionFormatPrice(this.itemsReporteDeudas[12].aportacionesFuturoAumentoCapital),
                    this.functionFormatPrice(this.itemsReporteDeudas[12].suma)],
                ];

                // Definir las opciones de la tabla
                var options = {
                    startY: 38
                };

                // Crear la tabla utilizando el método autoTable
                doc.autoTable({
                    head: [data[0]], // Encabezados de la tabla
                    body: data.slice(1), // Cuerpo de la tabla
                    startY: options.startY, // Posición de inicio de la tabla
                    headStyles: {
                        fillColor: [102,46,145] // Color de fondo en RGB (rojo, verde, azul)
                    },
                    columnStyles:{
                        // ALINEA LAS CANTIDADES A LA DERECHA
                        1: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        2: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        3: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        4: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        5: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        6: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        7: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        8: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        9: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        10: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        11: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        12: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        13: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        14: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        15: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        16: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        17: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        18: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        19: { 
                            halign: 'right' // Alineación a la derecha
                        },
                        19: { fillColor: '#eeeeee' }, // Colorear la última columna en gris
                    },
                    styles: {
                        fontSize: 9, // Tamaño de fuente en puntos
                    }

                });

                var footerX = 10;  // Posición X
                var footerY = doc.internal.pageSize.height - 20;  // Posición Y
                var cppcpText = "*1 Cuentas por Pagar a Corto Plazo";  // Texto del pie de página
                var ifcpText = "*2 Instrumentos Financieros Corto Plazo";  // Texto del pie de página
                var adcpnText = "*3 Acreedores Diversos Corto Plazo Nacionales";  // Texto del pie de página
                var adcpeText = "*4 Acreedores Diversos Corto Plazo Extranjeros";  // Texto del pie de página
                var adcpnprText = "*5 Acreedores Diversos Corto Plazo Nacional Parte Relacionada";  // Texto del pie de página
                var adcpeprText = "*6 Acreedores Diversos Corto Plazo Extranjero Parte Relacionada";  // Texto del pie de página
                var oadcpText = "*7 Otros Acreedores Diversos Corto Plazo";  // Texto del pie de página
                var opcpText = "*8 Otros Pasivos Corto Plazo";  // Texto del pie de página
                var adlpnText = "*9 Acreedores Diversos Largo Plazo Nacional";  // Texto del pie de página
                var adlpeText = "*10 Acreedores Diversos Largo Plazo Extranjero";  // Texto del pie de página
                var adlpnprText = "*11 Acreedores Diversos Largo Plazo Nacional Parte Relacionada";  // Texto del pie de página
                var adlpeprText = "*12 Acreedores Diversos Largo Plazo Extranjero Parte Relacionada";  // Texto del pie de página
                var oadlpText = "*13 Otros Acreedores Diversos Largo Plazo";  // Texto del pie de página
                var cpplpText = "*14 Cuentas por Pagar Largo Plazo";  // Texto del pie de página
                var iflpText = "*15 Instrumentos Financieros Largo Plazo";  // Texto del pie de página
                var oplpText = "*16 Otros Pasivos Largo Plazo";  // Texto del pie de página
                var apfacText = "*17 Aportaciones para Futuro Aumento \nde Capital";  // Texto del pie de página
                var footerFontSize = 10;  // Tamaño de fuente del pie de página
                var footerFontStyle = "italic";  // Estilo de fuente del pie de página
                var footerTextColor = "#888888";  // Color de texto del pie de página

                var spacing = 5; // Puedes ajustar este valor según tus necesidades
                var y1 = doc.internal.pageSize.height - 10 - (spacing * 6);
                var y2 = doc.internal.pageSize.height - 10 - (spacing * 5);
                var y3 = doc.internal.pageSize.height - 10 - (spacing * 4);
                var y4 = doc.internal.pageSize.height - 10 - (spacing * 3);
                var y5 = doc.internal.pageSize.height - 10 - (spacing * 2);
                var y6 = doc.internal.pageSize.height - 10 - (spacing * 1);
                var y7 = doc.internal.pageSize.height - 10 - (spacing * 6);
                var y8 = doc.internal.pageSize.height - 10 - (spacing * 5);
                var y9 = doc.internal.pageSize.height - 10 - (spacing * 4);
                var y10 = doc.internal.pageSize.height - 10 - (spacing * 3);
                var y11 = doc.internal.pageSize.height - 10 - (spacing * 2);
                var y12 = doc.internal.pageSize.height - 10 - (spacing * 1);
                var y13 = doc.internal.pageSize.height - 10 - (spacing * 6);
                var y14 = doc.internal.pageSize.height - 10 - (spacing * 5);
                var y15 = doc.internal.pageSize.height - 10 - (spacing * 4);
                var y16 = doc.internal.pageSize.height - 10 - (spacing * 3);
                var y17 = doc.internal.pageSize.height - 10 - (spacing * 2);

                // Agregar el pie de página
                doc.setFontSize(footerFontSize);
                doc.setFont(footerFontStyle);
                doc.setTextColor(footerTextColor);
                doc.text(cppcpText, footerX, y1);
                doc.text(ifcpText, footerX, y2);
                doc.text(adcpnText, footerX, y3);
                doc.text(adcpeText, footerX, y4);
                doc.text(adcpnprText, footerX, y5);
                doc.text(adcpeprText, footerX, y6);
                doc.text(oadcpText, footerX + 102, y7);
                doc.text(opcpText, footerX + 102, y8);
                doc.text(adlpnText, footerX + 102, y9);
                doc.text(adlpeText, footerX + 102, y10);
                doc.text(adlpnprText, footerX + 102, y11);
                doc.text(adlpeprText, footerX + 102, y12);
                doc.text(oadlpText, footerX + 207, y13);
                doc.text(cpplpText, footerX + 207, y14);
                doc.text(iflpText, footerX + 207, y15);
                doc.text(oplpText, footerX + 207, y16);
                doc.text(apfacText, footerX + 207, y17);

                // Obtener el número total de páginas
                var totalPages = doc.internal.getNumberOfPages();

                // Recorrer todas las páginas y agregar el número de página
                for (var i = 1; i <= totalPages; i++) {

                    // TITULO
                    // Configuración del título
                    var titleText = 'Ajuste anual por inflación: ' + this.empresa.empresa + '\n' + this.empresa.rfc;
                    var titleFontSize = 15;
                    var titleTextColor = '#FF0000'; // Color en formato hexadecimal

                    // Posición del título
                    var titleX = 10;
                    var titleY = 10;

                    doc.setFontSize(14);
                    doc.setFont('helvetica', 'bold');
                    doc.setTextColor("#FF0000");
                    doc.text('REPORTE', titleX, titleY, { align: 'left' });
                    doc.setTextColor("#000");

                    // Agregar el título al documento PDF
                    doc.setFontSize(13);
                    doc.setFont('helvetica', 'bold');
                    doc.text('AJUSTE ANUAL POR INFLACIÓN', titleX, titleY += 5, { align: 'left' });
                    doc.setFontSize(12);
                    doc.setFont('helvetica', 'normal');
                    let empresa =this.empresa.rfc + ' | ' + this.empresa.empresa 
                    doc.text(empresa, titleX, titleY += 5, { align: 'left' });
                    doc.setFontSize(11);
                    let fecha = 'Fecha: '+  this.anios + ' - ' + this.mes
                    doc.text(fecha, titleX, titleY += 5, { align: 'left' });
                    
                    // NUMERO DE PAGINAS
                    doc.setFontSize(10);
                    doc.setFont('helvetica', 'bold');
                    var textColor = "#000000";  // Color
                    doc.setTextColor(textColor);
                    doc.setPage(i);
                    doc.text('Página ' + i + ' de ' + totalPages, 10, doc.internal.pageSize.height - 10);

                    // Cargar la imagen
                    if(this.$store.state.empresaStore.idEmpresa != 0){

                let imgData = this.$store.state.empresaStore.logo.base64

                    var imgWidth = 60; // Ancho del logo en puntos
                    var imgHeight = 30; // Alto del logo en puntos
                    var logoX = doc.internal.pageSize.getWidth() - 70; // Coordenada X (esquina superior derecha)
                    var logoY = -1; // Coordenada Y (esquina superior)

                    // Agregar la imagen como fondo en cada página
                    doc.addImage(imgData, 'JPEG', logoX, logoY, imgWidth, imgHeight, '', 'FAST');
                }
                }
                
                if (this.radios === '1') {
                    
                    doc.save('Ajuste_anual_por_inflación_' + this.empresa.rfc + '_ejercicio_' + this.anios + ' .pdf');
                }else{
                    doc.save('Ajuste_anual_por_inflación_' + this.empresa.rfc + '_de_enero_a_' + this.mesInpc + '_del_' + this.anios + ' .pdf');
                }
            },
        }
    }
</script>