var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',{staticClass:"text-h4 text--primary",staticStyle:{"font":"bold"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"large":"","icon":"","color":"primary","dark":"","link":"","to":{ name: 'ListReportes' }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)]}}])},[_c('span',[_vm._v("Atrás")])]),_vm._v(" Estado de Situación Financiera "),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha Inicial","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fechaI),callback:function ($$v) {_vm.fechaI=$$v},expression:"fechaI"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.calendario),callback:function ($$v) {_vm.calendario=$$v},expression:"calendario"}},[_c('v-date-picker',{on:{"input":function($event){_vm.calendario = false, _vm.cambiaFechaFinal()}},model:{value:(_vm.fechaI),callback:function ($$v) {_vm.fechaI=$$v},expression:"fechaI"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha Final","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fechaF),callback:function ($$v) {_vm.fechaF=$$v},expression:"fechaF"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.calendario2),callback:function ($$v) {_vm.calendario2=$$v},expression:"calendario2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.calendario2 = false}},model:{value:(_vm.fechaF),callback:function ($$v) {_vm.fechaF=$$v},expression:"fechaF"}})],1)],1),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"icon":"","color":"primary","dark":""},on:{"click":function($event){return _vm.GetEstadoDeSituacionFinanciera()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}])},[_c('span',[_vm._v("Buscar")])]),_c('xlsx-workbook',[_vm._l((_vm.sheets),function(sheet){return _c('xlsx-sheet',{key:sheet.name,attrs:{"collection":_vm.collection,"sheet-name":sheet.name}})}),_c('xlsx-download'),_c('xlsx-download',{attrs:{"filename":_vm.nombreReporte}},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"icon":"","color":"primary","dark":""},on:{"click":function($event){return _vm.GeneraExcel()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-excel-box-outline")])],1)]}}])},[_c('span',[_vm._v("Excel")])])],1)],2),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary darken-1","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.CreaPdf()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-pdf-box")])],1)]}}])},[_c('span',[_vm._v("Imprimir")])])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Activo Circulante"),_c('v-spacer'),_vm._v(_vm._s(_vm.functionFormatPrice(_vm.sumaActivoCirculante)))],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headersESF,"hide-default-footer":"","items-per-page":-1,"hide-default-header":"","items":_vm.itemsActivoCirculante},scopedSlots:_vm._u([{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.importe)))])]}}])})],1)],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Activo Fijo"),_c('v-spacer'),_vm._v(" "+_vm._s(_vm.functionFormatPrice(_vm.sumaActivoFijo)))],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headersESF,"hide-default-footer":"","items-per-page":-1,"hide-default-header":"","items":_vm.itemsActivoFijo},scopedSlots:_vm._u([{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.importe)))])]}}])})],1)],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Activo Diferido"),_c('v-spacer'),_vm._v(" "+_vm._s(_vm.functionFormatPrice(_vm.sumaActivoDiferido *-1)))],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headersESF,"hide-default-footer":"","items-per-page":-1,"hide-default-header":"","items":_vm.itemsActivoDiferido},scopedSlots:_vm._u([{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.importe*-1)))])]}}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Pasivo Circulante"),_c('v-spacer'),_vm._v(_vm._s(_vm.functionFormatPrice(_vm.sumaPasivoCirculante)))],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headersESF,"hide-default-footer":"","items-per-page":-1,"hide-default-header":"","items":_vm.itemsPasivoCirculante},scopedSlots:_vm._u([{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.importe)))])]}}])})],1)],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Pasivo a Largo Plazo"),_c('v-spacer'),_vm._v(_vm._s(_vm.functionFormatPrice(_vm.sumaPasivoLargoPlazo)))],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headersESF,"hide-default-footer":"","items-per-page":-1,"hide-default-header":"","items":_vm.itemsPasivoLargoPlazo},scopedSlots:_vm._u([{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.importe)))])]}}])})],1)],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Pasivo Diferido"),_c('v-spacer'),_vm._v(_vm._s(_vm.functionFormatPrice(_vm.sumaPasivoDiferido)))],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headersESF,"hide-default-footer":"","items-per-page":-1,"hide-default-header":"","items":_vm.itemsPasivoDiferido},scopedSlots:_vm._u([{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.importe)))])]}}])})],1)],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Capital"),_c('v-spacer'),_vm._v(_vm._s(_vm.functionFormatPrice(_vm.sumaCapital)))],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headersESF,"hide-default-footer":"","items-per-page":-1,"hide-default-header":"","items":_vm.itemsCapital},scopedSlots:_vm._u([{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.importe)))])]}}])})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('h1',[_vm._v(" Total Activo: "+_vm._s(_vm.functionFormatPrice(_vm.sumActivo)))])]),_c('v-col',{attrs:{"cols":"6"}},[_c('h1',[_vm._v("Total Pasivo + Capital: "+_vm._s(_vm.functionFormatPrice(_vm.sumPasivoCapital)))])])],1)],1)],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.loadingMessage)+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }