<template>
<div>
    <v-card>
            <v-card-title style="font: bold;" class="text-h4 text--primary">
                <!-- BOTON PARA IR A UNA VENTANA ANTERIOR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn large icon v-bind="attrs" v-on="on" color="primary" dark class="mx-2" @click="nuevaPoliza()" link :to="{ name: 'Home' }"><v-icon>mdi-arrow-left</v-icon></v-btn>
                    </template>
                    <span>Atrás</span>
                </v-tooltip>
                Bancos
                <v-spacer></v-spacer>
                <v-col cols="12" sm="12" md="2">
                    <v-select label="Tipo de movimiento" :items="itemsTipoMovimiento" v-model="selectTipoMovimiento" item-text="tipo" item-value="valor">
                    </v-select>
                </v-col>
                <!-- Fecha Inicial -->
                <v-col cols="12" sm="2" md="2">
                <v-menu v-model="calendario" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="fechaI" label="Fecha Inicial" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="fechaI" @input="calendario = false, cambiaFechaFinal()"></v-date-picker>
                </v-menu>
                </v-col>
                <!--Fecha Final-->
                <v-col cols="12" sm="2" md="2">
                <v-menu v-model="calendario2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="fechaF" label="Fecha Final" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="fechaF" @input="calendario2 = false"></v-date-picker>
                </v-menu>
                </v-col>
                <!-- BOTON PARA CONSULTAR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2" @click="GetMovBancos()"><v-icon>mdi-magnify</v-icon></v-btn>
                    </template>
                    <span>Consultar</span>
                </v-tooltip>
                <!-- BOTON PARA ABRIR DIALOG DE CONCILIAR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2" @click="abreDialogConciliar()"><v-icon>mdi-swap-horizontal</v-icon></v-btn>
                    </template>
                    <span>Reasignar Movimientos</span>
                </v-tooltip>
                <!-- LIMPIAR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2" @click="limpiar()"><v-icon>mdi-broom</v-icon></v-btn>
                    </template>
                    <span>Limpiar</span>
                </v-tooltip>
                <!-- NUEVA CUENTA -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2" @click="abreDialogNuevaCuenta()"><v-icon>mdi-book-plus</v-icon></v-btn>
                    </template>
                    <span>Crear Cuenta de Bancos</span>
                </v-tooltip>
            </v-card-title>
            <v-card-text>
                <v-data-table :items="itemsMovBancos" :headers="headersMovBancos" v-model="selectMovBancos" show-select item-key="id_poliza" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.total="{ item }">
                        <span>{{ functionFormatPrice(item.total) }}</span>
                    </template>
                </v-data-table>
            </v-card-text>
    </v-card>
    <!--Dialog loading-->
    <v-dialog v-model="loading" hide-overlay persistent width="300">
        <v-card color="primary" dark>
        <v-card-text>
            {{loadingMessage}}
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
        </v-card>
    </v-dialog>
    <!-- CONCILIAR BANCO -->
    <v-dialog v-model="dialogConciliar">
        <v-card>
            <v-toolbar>
                <!-- BOTON CERRAR -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary darken-1" dark fab small @click="dialogConciliar = false"
                            v-bind="attrs" v-on="on">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span>Cerrar</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <b style="font-size: 30px;">Asignar</b>
                <v-spacer></v-spacer>
                <!-- BOTON CONFIRMAR -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary darken-1" dark fab small @click="putMovBancos()"
                            v-bind="attrs" v-on="on">
                            <v-icon>mdi-check</v-icon>
                        </v-btn>
                    </template>
                    <span>Confirmar</span>
                </v-tooltip>
            </v-toolbar>
            <v-system-bar color="primary" height="6"></v-system-bar>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-select label="Seleccione la cuenta que se va a asignar" :items="listCatalogo" v-model="cuentaCatalogo" return-object item-text="cuenta"></v-select>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- NUEVA CUENTA -->
    <v-dialog v-model="dialogNuevaCuenta">
        <v-card>
            <v-toolbar>
                <!-- BOTON CERRAR -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary darken-1" dark fab small @click="dialogNuevaCuenta = false"
                            v-bind="attrs" v-on="on">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span>Cerrar</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <b style="font-size: 30px;">Nueva Cuenta</b>
                <v-spacer></v-spacer>
                <!-- BOTON CONFIRMAR -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary darken-1" dark fab small @click="postCuenta()"
                            v-bind="attrs" v-on="on">
                            <v-icon>mdi-check</v-icon>
                        </v-btn>
                    </template>
                    <span>Confirmar</span>
                </v-tooltip>
            </v-toolbar>
            <v-system-bar color="primary" height="6"></v-system-bar>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-select :items="itemsBanco" v-model="nuevaCuenta.banco" label="Seleccione un Banco"></v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field label="Número de Cuenta" v-model="nuevaCuenta.cuenta"></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- MUESTRA LOS COMPROBANTES -->
    <v-dialog v-model="dialogComprobantes">
        <v-card>
            <v-toolbar>
                <!-- BOTON CERRAR -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary darken-1" dark fab small @click="dialogComprobantes = false"
                            v-bind="attrs" v-on="on">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span>Cerrar</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <b style="font-size: 30px;">Comprobantes</b>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-system-bar color="primary" height="6"></v-system-bar>
            <v-card-text>
                <!-- TABLA DE COMPROBANTES -->
                <v-data-table :items="itemsComprobantes" :headers="headersComprobantes" show-expand item-key="folioFiscal">
                    <!-- FORMATO CON DECIMALES DEL TOTAL -->
                    <template v-slot:item.subTotal="{ item }">
                        <span>{{ functionFormatPrice(item.subTotal) }}</span>
                    </template>
                     <!-- FORMATO CON DECIMALES DEL TOTAL -->
                     <template v-slot:item.descuento="{ item }">
                        <span>{{ functionFormatPrice(item.descuento) }}</span>
                    </template>
                     <!-- FORMATO CON DECIMALES DEL TOTAL -->
                     <template v-slot:item.total="{ item }">
                        <span>{{ functionFormatPrice(item.total) }}</span>
                    </template>
                    <!-- FORMATO DE FECHA -->
                    <template v-slot:item.fecha="{ item }">
                        <span>{{ functionFormatDate(item.fecha) }}</span>
                    </template>
                    <!-- TABLA DE CONCEPTOS -->
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                            <h1>Conceptos</h1>
                            <v-card :colspan="headers.length">
                                <v-data-table :headers="headersConceptos" :items="item.detalles">
                                    <!-- FORMATO CON DECIMALES DEL TOTAL -->
                                    <template v-slot:item.valorUnitario="{ item }">
                                        <span>{{ functionFormatPrice(item.valorUnitario) }}</span>
                                    </template>
                                    <!-- FORMATO CON DECIMALES DEL TOTAL -->
                                    <template v-slot:item.importe="{ item }">
                                        <span>{{ functionFormatPrice(item.importe) }}</span>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </td>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
    <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
        <template v-slot:action="{ attrs }">
            <v-icon dark @click="snackbar = false">
                mdi-close-circle
            </v-icon>
        </template>
    </v-snackbar>
</div>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import print from 'print-js';
    import pdf from 'vue-pdf';

    export default {
        name: 'Bancos',
        components: {
            
        },

        data: () => ({
            //FECHAS
            fechaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            fechaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            calendario: false,
            calendario2: false,

            //BUSCAR
            search: '',

            //DATA TABLE
            itemsMovBancos: [],
            selectMovBancos: [],
            headersMovBancos: [
            { text: "Descripción", value: "descripcion" },
            { text: "Fecha", value: "fecha" },
            { text: "Nombre", value: "nombre" },
            { text: "RFC", value: "rfc" },
            // { text: "ID Poliza Det", value: "id_poldet"},
            // { text: "ID Poliza", value: "id_poliza" },
            { text: "Total", value: "total", align: 'right' },

                // { text: "ID POLIZA", value: "idPoliza" },
                // { text: "ID POLIZA DET", value: "idPolizaDet" },
                // { text: "Cuenta", value: "cuenta" },
                // { text: "Fecha", value: "fecha" },
                // { text: "Total", value: "importe", align: 'right' },
                // { text: "RFC", value: "comprobantes[0].rfc" },
                // { text: "Nombre", value: "comprobantes[0].nombre" },
                // // { text: "SubTotal", value: "comprobantes[0].subTotal", align: 'right' },
                // // { text: "Descuento", value: "comprobantes[0].descuento", align: 'right' },
                // { text: "Total Factura", value: "comprobantes[0].total", align: 'right' },
                // { text: "Moneda", value: "comprobantes[0].moneda" },
                // { text: "Tipo de Cambio", value: "comprobantes[0].tipoCambio", align: 'right' },
                // { text: "Tipo de Comprobante", value: "comprobantes[0].tipoDeComprobante" },
                // { text: "Método de Pago", value: "comprobantes[0].metodoPago" },
                // { text: "Forma de Pago", value: "comprobantes[0].formaPago" },
                // { text: "Comprobantes", value: "actions", sortable: false },
            ],

            //LOADING
            loading: false,
            loadingMessage: '',

            //DIALOG CONCILIAR
            dialogConciliar: false,

            //SNACK BAR
            snackbarMessage: '',
            snackbar: false,

            //NUEVA CUENTA  
            dialogNuevaCuenta: false,
            nuevaCuenta: {
                banco: '',
                cuenta: '',
            },
            nuevaCuentaDefault: {
                banco: '',
                cuenta: '',
            },
            itemsBanco: [
                'ABC CAPITAL',
                'ACCIVAL',
                'ACTINVER',
                'AFIRME',
                'AKALA',
                'AMERICAN EXPRESS',
                'ASEA',
                'AUTOFIN',
                'AZTECA',
                'B&B',
                'BAJIO',
                'BAMSA',
                'BANAMEX',
                'BANCO FAMSA',
                'BANCOMEXT',
                'BANCOPPEL',
                'BANJERCITO',
                'BANOBRAS',
                'BANORTE',
                'BANREGIO',
                'BANSEFI',
                'BANSI',
                'BARCLAYS',
                'BBASE',
                'BBVA BANCOMER',
                'BMONEX',
                'BULLTICK CB',
                'CB ACTINVER',
                'CB INTERCAM',
                'CB JPMORGAN',
                'CBDEUTSCHE',
                'CI BOLSA',
                'CIBANCO',
                'CLS',
                'COMPARTAMOS',
                'CONSUBANCO',
                'CREDIT SUISSE',
                'DEUTSCHE',
                'ESTRUCTURADORES',
                'EVERCORE',
                'FINAMEX',
                'FINCOMUN',
                'GBM',
                'HDI SEGUROS',
                'HIPOTECARIA FEDERAL',
                'HSBC',
                'INBURSA',
                'INTERACCIONES',
                'INDEVAL',
                'ING',
                'INTERBANCO',
                'INVEX',
                'IXE',
                'JP MORGAN',
                'KUSPIT',
                'LIBERTAD',
                'MAPFRE',
                'MASARI',
                'MERRILL LYNCH',
                'MIFEL',
                'MONEXCB',
                'MULTIVA',
                'NAFIN',
                'OACTIN',
                'OPCIONES EMPRESARIALES DEL NOROESTE',
                'ORDER',
                'PROFUTURO',
                'REFORMA',
                'SANTANDER',
                'SCOTIABANK',
                'SEGMTY',
                'SKANDIA',
                'SKANDIA',
                'SOFIEXPRESS',
                'STERLING',
                'STP',
                'SU CASITA',
                'TELECOMM',
                'THE ROYAL BANK',
                'TIBER',
                'TOKYO',
                'UBS BANK',
                'UNICA',
                'VALMEX',
                'VALUE',
                'VE POR MAS',
                'VECTOR',
                'VOLKSWAGEN',
                'WAL-MART',
                'ZURICH',
                'ZURICHVI'
            ],
        
            //CATALOGO DE CUENTAS
            listCatalogo: [],
            cuentaCatalogo:{},

            //TIPO DE MOVIMIENTOS
            itemsTipoMovimiento:[
                { tipo:'Pagos', valor:'Pagos' },
                { tipo:'Cobros', valor:'Ccobros' }
            ],
            selectTipoMovimiento: null,

            //COMPROBANTES
            dialogComprobantes: false,
            itemsComprobantes: [],
            headersComprobantes: [
                { text: "Tipo de comprobante", value: "tipoDeComprobante" },
                { text: "Serie", value: "serie" },
                { text: "Folio", value: "folio" },
                { text: "Fecha", value: "fecha" },
                { text: "RFC", value: "rfc" },
                { text: "Nombre", value: "nombre" },
                { text: "SubTotal", value: "subTotal", align: 'right' },
                { text: "Descuento", value: "descuento", align: 'right' },
                { text: "Total", value: "total", align: 'right' },
                { text: "Moneda", value: "moneda" },
                { text: "TipoCambio", value: "tipoCambio" },
                { text: "Forma de pago", value: "formaPago" },
                { text: "Método de pago", value: "metodoPago" },
                { text: "Folio fiscal", value: "folioFiscal" },
            ],
            headersConceptos: [
                { text: "Clave prod o serv.", value: "claveProdServ" },
                { text: "No. identidicación", value: "noIdentificacion" },
                { text: "Descripción", value: "descripcion" },
                { text: "Clave unidad", value: "claveUnidad" },
                { text: "Unidad", value: "unidad" },
                { text: "Cantidad", value: "cantidad" },
                { text: "Valor unitario", value: "valorUnitario" },
                { text: "Importe", value: "importe" },
            ],
        }),

        watch: {
            
        },

        computed: {
            empresa(){
                return this.$store.state.usuario
            },
        },

        created() {

        },

        methods: {
            async GetMovBancos(){
                console.log(this.selectTipoMovimiento)

                console.time();
                this.loadingMessage = 'Consultando movimientos...'
                this.loading = true;
                try {
                    let response = await axios.get('/AutoConta/ConsultaMovB/'+ this.empresa.empresaBase + '/' + this.fechaI + '/' + this.fechaF + '/' + this.selectTipoMovimiento);
                    // let response = await axios.get('GetPolizas/GetMovBancos/'+this.empresa.empresaBase+'/' + this.fechaI + '/' + this.fechaF + '/' + this.selectTipoMovimiento);
                    this.itemsMovBancos = response.data;
                    // console.log(response)
                    console.log(response)
                    this.loading = false;
                    console.timeEnd();
                } catch (error) {
                    console.log(error);
                    this.loading = false;
                    console.timeEnd();
                }
            },
            evaluaFormaPago(value){
                let itemsFP = [
                    { value: '01', descripcion:'01 | Efectivo' },
                    { value: '02', descripcion:'02 | Cheque nominativo' },
                    { value: '03', descripcion:'03 | Transferencia electrónica de fondos' },
                    { value: '04', descripcion:'04 | Tarjeta de crédito' },
                    { value: '05', descripcion:'05 | Monedero electrónico' },
                    { value: '06', descripcion:'06 | Dinero electrónico' },
                    { value: '08', descripcion:'08 | Vales de despensa' },
                    { value: '12', descripcion:'12 | Dación en pago' },
                    { value: '13', descripcion:'13 | Pago por subrogación' },
                    { value: '14', descripcion:'14 | Pago por consignación' },
                    { value: '15', descripcion:'15 | Condonación' },
                    { value: '17', descripcion:'17 | Compensación' },
                    { value: '23', descripcion:'23 | Novación' },
                    { value: '24', descripcion:'24 | Confusión' },
                    { value: '25', descripcion:'25 | Remisión de deuda' },
                    { value: '26', descripcion:'26 | Prescripción o caducidad' },
                    { value: '27', descripcion:'27 | A satisfacción del acreedor' },
                    { value: '28', descripcion:'28 | Tarjeta de débito' },
                    { value: '29', descripcion:'29 | Tarjeta de servicios' },
                    { value: '30', descripcion:'30 | Aplicación de anticipos' },
                    { value: '31', descripcion:'31 | Intermediario pagos' },
                    { value: '99', descripcion:'99 | Por definir' },
                    { value: '', descripcion:'' },

                ]
                let result = itemsFP.find(x => x.value === value);
                return result.descripcion;
            },
            functionFormatPrice(value) {
                let val = (value / 1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            limpiar(){
                this.selectMovBancos = [];
            },
            async abreDialogConciliar(){
                this.snackbar = false;
                console.log(this.selectMovBancos.length)
                if(this.selectMovBancos.length <1 ){
                    this.snackbar = true;
                    this.snackbarMessage = 'Seleccione al menos un movimiento';
                    return;
                }
                this.getCuentas()
                // let catalogo =  await this.getCatalogo();
                this.dialogConciliar = true;
            },
            
            async putMovBancos(){
                let movimientos = []
                for(var a of this.selectMovBancos){
                    movimientos.push(a.id_poldet)
                }
                console.log('cuentaCatalogo', this.cuentaCatalogo)

                let mov = {
                    baseAC: this.empresa.empresaBase,
                    cuentaN: this.cuentaCatalogo.id_cuenta,
                    listaids: movimientos
                }
                console.log(mov)
                this.loading = true;
                this.loadingMessage = 'Guardando cambios ...'
                //GUARDAMOS
                try {
                    let response = await axios.post('AutoConta/ActualizaMovB/', mov);
                    this.loading = false;
                    this.snackbarMessage='Cambios realzados con éxito'
                    this.snackbar=true
                    this.dialogConciliar = false

                    //ACTUALIZAMOS EL NUMERO DE CUENTA
                    for(let x of this.selectMovBancos){
                        let indice = this.itemsMovBancos.indexOf(x)
                        x.idCuenta = this.cuentaCatalogo.idCuenta
                        x.cuenta = this.cuentaCatalogo.cuentaContable

                        Object.assign(this.itemsMovBancos[indice], x);
                        console.log(indice)
                    }

                    this.selectMovBancos = [];
                } catch (error) {   
                    console.log(error);
                    this.snackbarMessage='Error al crear cambios, intente nuevamente'
                    this.snackbar=true
                    this.loading = false;
                }
                // this.loading = false;
            },

            abreDialogNuevaCuenta(){
                this.dialogNuevaCuenta = true
            },

            async postCuenta(){
                let cuentaContable= {
                    numeroCuentaPadre: '0000000102.0000000001',
                    nombreCuenta : this.nuevaCuenta.banco + ' ' + this.nuevaCuenta.cuenta,
                }
                this.loadingMessage = 'Creando cuenta...'
                this.loading = true;
                try {
                    let response = await axios.post('GetPolizas/PostCuenta/'+this.empresa.empresaBase, cuentaContable);
                    console.log(response.data)
                    this.loading = false;
                    this.dialogNuevaCuenta=false;
                    this.nuevaCuenta = Object.assign({}, this.nuevaCuentaDefault);
                    this.snackbarMessage='Cuenta creada con éxito'
                    this.snackbar=true
                } catch (error) {   
                    console.log(error);
                    this.snackbarMessage='Error al crear cuenta, intente nuevamente'
                    this.snackbar=true
                    this.loading = false;
                }
            },
            async getCatalogo(){
                this.loading = true
                this.loadingMessage = 'Consultando catálogo...'
                try {
                    let response = await axios.get('ReportesGenerales/GetCatalogo/'+this.empresa.empresaBase)
                    let respuesta = response.data;
                    this.listCatalogo = respuesta.filter(x => x.numeroCuentaUnico.includes('0000000102.0000000001') ) ;
                    this.listCatalogo.splice(0, 1)
                    this.loading = false
                    console.log(this.listCatalogo)
                } catch (error) {
                    console.log(error)
                    this.loading = false
                }
            },
            cambiaFechaFinal(){
                let fecha = this.fechaI.split('-');
                var primero = new Date(fecha[0],  fecha[1]-1, '01');
                var ultimoDia = new Date(primero.getFullYear(), primero.getMonth() + 1, 0);
                this.fechaF = format(parseISO(new Date(ultimoDia).toISOString()), 'yyyy-MM-dd')
            },

            verComprobantes(item){
                console.log(item)
                this.dialogComprobantes = true;
                this.itemsComprobantes = item.comprobantes
            },

            functionFormatDate(value){
                let fecha = moment(value).format('DD/MM/yyyy')
                return fecha;
            },

            async getCuentas(){
                this.loading = true
                this.loadingMessage = 'Consultando datos...'
                try {
                    let response = await axios.get('AutoConta/ConsultaCuentas/'+this.empresa.empresaBase)
                    let respuesta = response.data;
                    this.listCatalogo = respuesta
                    console.log('cuentas ', this.listCatalogo)
                    this.loading = false
                } catch (error) {
                    console.log(error)
                    this.loading = false
                }
            },
        },
    }
</script>