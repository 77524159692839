<template>
    <div>
        <v-card>
            <v-card-title style="font: bold;" class="text-h4 text--primary">
                <!-- BOTON PARA IR A UNA VENTANA ANTERIOR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn large icon v-bind="attrs" v-on="on" color="primary" dark class="mx-2" link
                            :to="{ name: 'ListReportes' }"><v-icon>mdi-arrow-left</v-icon></v-btn>
                    </template>
                    <span>Atrás</span>
                </v-tooltip>
                Estado de Situación Financiera
                <v-spacer></v-spacer>
                <!-- Fecha Inicial -->
                <v-col cols="12" sm="3" md="3">
                    <v-menu v-model="calendario" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="fechaI" label="Fecha Inicial" prepend-icon="mdi-calendar" readonly
                                v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="fechaI" @input="calendario = false, cambiaFechaFinal()"></v-date-picker>
                    </v-menu>
                </v-col>
                <!--Fecha Final-->
                <v-col cols="12" sm="3" md="3">
                    <v-menu v-model="calendario2" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="fechaF" label="Fecha Final" prepend-icon="mdi-calendar" readonly
                                v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="fechaF" @input="calendario2 = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <!-- BOTON PARA CONSULTAR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="primary" dark class="mb-2" @click="GetEstadoDeSituacionFinanciera()"
                            v-bind="attrs" v-on="on"><v-icon>mdi-magnify</v-icon></v-btn>
                    </template>
                    <span>Buscar</span>
                </v-tooltip>
                <!-- EXPORTA EXCEL -->
                <xlsx-workbook>
                    <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                        :sheet-name="sheet.name" />
                    <xlsx-download>
                    </xlsx-download>
                    <xlsx-download :filename="nombreReporte">
                        <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2"
                                    @click="GeneraExcel()"><v-icon>mdi-file-excel-box-outline</v-icon></v-btn>
                            </template>
                            <span>Excel</span>
                        </v-tooltip>
                    </xlsx-download>
                </xlsx-workbook>

                <!-- BOTON PARA IMPRIMIR -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary darken-1" dark fab small @click="CreaPdf()" v-bind="attrs" v-on="on">
                            <v-icon>mdi-file-pdf-box</v-icon>
                        </v-btn>
                    </template>
                    <span>Imprimir</span>
                </v-tooltip>

            </v-card-title>
            <v-card-text>
                <v-row>
                    <!-- ACTIVO -->
                    <v-col cols="6">
                        <!-- ACTIVO CIRCULANTE -->
                        <!-- <template v-if="itemsActivoCirculante.length != 0"> -->
                        <v-card flat>
                            <v-card-title>Activo
                                Circulante<v-spacer></v-spacer>{{functionFormatPrice(sumaActivoCirculante)}}</v-card-title>
                            <v-card-text>
                                <v-data-table :headers="headersESF" hide-default-footer :items-per-page="-1"
                                    hide-default-header :items="itemsActivoCirculante">
                                    <template v-slot:item.importe="{ item }">
                                        <span>{{ functionFormatPrice(item.importe) }}</span>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                        <!-- </template> -->
                        <!-- ACTIVO FIJO -->
                        <!-- <template v-if="itemsActivoFijo.length != 0"> -->
                        <v-card flat>
                            <v-card-title>Activo Fijo<v-spacer></v-spacer>
                                {{functionFormatPrice(sumaActivoFijo)}}</v-card-title>
                            <v-card-text>
                                <v-data-table :headers="headersESF" hide-default-footer :items-per-page="-1"
                                    hide-default-header :items="itemsActivoFijo">
                                    <template v-slot:item.importe="{ item }">
                                        <span>{{ functionFormatPrice(item.importe) }}</span>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                        <!-- </template> -->
                        <!-- ACTIVO FIFERIDO -->
                        <!-- <template v-if="itemsActivoDiferido.length != 0"> -->
                        <v-card flat>
                            <v-card-title>Activo Diferido<v-spacer></v-spacer> {{functionFormatPrice(sumaActivoDiferido
                                *-1)}}</v-card-title>
                            <v-card-text>
                                <v-data-table :headers="headersESF" hide-default-footer :items-per-page="-1"
                                    hide-default-header :items="itemsActivoDiferido">
                                    <template v-slot:item.importe="{ item }">
                                        <span>{{ functionFormatPrice(item.importe*-1) }}</span>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                        <!-- </template> -->
                    </v-col>
                    <!-- PASIVO Y CAPITAL -->
                    <v-col cols="6">
                        <!-- PASIVO CIRULANTE -->
                        <!-- <template v-if="itemsPasivoCirculante.length != 0"> -->
                        <v-card flat>
                            <v-card-title>Pasivo
                                Circulante<v-spacer></v-spacer>{{functionFormatPrice(sumaPasivoCirculante)}}</v-card-title>
                            <v-card-text>
                                <v-data-table :headers="headersESF" hide-default-footer :items-per-page="-1"
                                    hide-default-header :items="itemsPasivoCirculante">
                                    <template v-slot:item.importe="{ item }">
                                        <span>{{ functionFormatPrice(item.importe) }}</span>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                        <!-- </template> -->
                        <!-- PASIVO LARGO PLAZO -->
                        <!-- <template v-if="itemsPasivoLargoPlazo.length != 0"> -->
                        <v-card flat>
                            <v-card-title>Pasivo a Largo
                                Plazo<v-spacer></v-spacer>{{functionFormatPrice(sumaPasivoLargoPlazo)}}</v-card-title>
                            <v-card-text>
                                <v-data-table :headers="headersESF" hide-default-footer :items-per-page="-1"
                                    hide-default-header :items="itemsPasivoLargoPlazo">
                                    <template v-slot:item.importe="{ item }">
                                        <span>{{ functionFormatPrice(item.importe) }}</span>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                        <!-- </template> -->
                        <!-- PASIVO DIFERIDO -->
                        <!-- <template v-if="itemsPasivoDiferido.length != 0"> -->
                        <v-card flat>
                            <v-card-title>Pasivo
                                Diferido<v-spacer></v-spacer>{{functionFormatPrice(sumaPasivoDiferido)}}</v-card-title>
                            <v-card-text>
                                <v-data-table :headers="headersESF" hide-default-footer :items-per-page="-1"
                                    hide-default-header :items="itemsPasivoDiferido">
                                    <template v-slot:item.importe="{ item }">
                                        <span>{{ functionFormatPrice(item.importe) }}</span>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                        <!-- </template> -->
                        <!-- CAPITAL -->
                        <!-- <template v-if="itemsCapital.length != 0"> -->
                        <v-card flat>
                            <v-card-title>Capital<v-spacer></v-spacer>{{functionFormatPrice(sumaCapital)}}</v-card-title>
                            <v-card-text>
                                <v-data-table :headers="headersESF" hide-default-footer :items-per-page="-1"
                                    hide-default-header :items="itemsCapital">
                                    <template v-slot:item.importe="{ item }">
                                        <span>{{ functionFormatPrice(item.importe) }}</span>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                        <!-- </template> -->
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <h1> Total Activo: {{functionFormatPrice(sumActivo)}}</h1>
                    </v-col>
                    <v-col cols="6">
                        <h1>Total Pasivo + Capital: {{functionFormatPrice(sumPasivoCapital)}}</h1>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <!--Dialog loading-->
        <v-dialog v-model="loading" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    {{loadingMessage}}
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import print from 'print-js';
    import pdf from 'vue-pdf';
    import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"
    import jsPDF from 'jspdf';

    export default {
        name: 'EstadoDeSituacionFinanciera',
        components: {
            XlsxWorkbook,
            XlsxSheet,
            XlsxDownload,
        },

        data: () => ({
            //LOADING
            loading: false,
            loadingMessage: '',

            //FECHAS
            fechaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            fechaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            calendario: false,
            calendario2: false,

            headersESF: [
                { text: "Descripción", value: "descripcion" },
                { text: "Importe", value: "importe", align: 'right' },
            ],

            itemsActivoCirculante: [],
            itemsActivoFijo: [],
            itemsActivoDiferido: [],
            itemsPasivoCirculante: [],
            itemsPasivoLargoPlazo: [],
            itemsPasivoDiferido: [],
            itemsCapital: [],

            //LISTA PARA EXPORTAR
            listaExcel: [],
            sheets: [{ name: "SheetOne", data: [{ c: 2 }] }],
            collection: [{ a: 1, b: 2 }],
            nombreReporte: '',

        }),

        watch: {

        },

        computed: {
            sumaActivoCirculante() {
                return this.itemsActivoCirculante.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
            },
            sumaActivoFijo() {
                return this.itemsActivoFijo.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
            },
            sumaActivoDiferido() {
                return this.itemsActivoDiferido.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
            },

            sumaPasivoCirculante() {
                return this.itemsPasivoCirculante.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
            },
            sumaPasivoLargoPlazo() {
                return this.itemsPasivoLargoPlazo.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
            },
            sumaPasivoDiferido() {
                return this.itemsPasivoDiferido.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
            },
            sumaCapital() {
                return this.itemsCapital.map(item => item.importe).reduce((prev, curr) => prev + curr, 0);
            },

            sumActivo() {
                return this.sumaActivoCirculante + this.sumaActivoFijo - this.sumaActivoDiferido;
            },
            sumPasivoCapital() {
                return this.sumaPasivoCirculante + this.sumaPasivoLargoPlazo + this.sumaPasivoDiferido + this.sumaCapital
            },
            empresa() {
                return this.$store.state.usuario
            },

        },

        created() {
            this.GetEmpresa();
        },

        methods: {
            async GetEmpresa() {
                console.log(this.empresa)
                try {
                    let response = await axios.get('https://api-erp.contago.com.mx/api/Empresas/GetEmpresa/' + this.empresa.empresaBase);
                    console.log('a', response.data)
                    if (response.data.idEmpresa != 0) {
                        this.$store.state.empresaStore = response.data
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            functionFormatPrice(value) {
                let val = (value / 1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },

            async GetEstadoDeSituacionFinanciera() {
                this.loadingMessage = 'Consultando...'
                this.loading = true;
                try {
                    let response = await axios.get('ReportesGenerales/GetEstadoDeSituacionFinancieraAsync/' + this.empresa.empresaBase + '/' + this.fechaI + '/' + this.fechaF);
                    let respuesta = response.data;
                    console.log(response)
                    this.itemsActivoCirculante = respuesta.filter(x => x.subTipo === 'ACTIVO CIRCULANTE')
                    this.itemsActivoFijo = respuesta.filter(x => x.subTipo === 'ACTIVO FIJO')
                    this.itemsActivoDiferido = respuesta.filter(x => x.subTipo === 'ACTIVO DIFERIDO')
                    this.itemsPasivoCirculante = respuesta.filter(x => x.subTipo === 'PASIVO CIRCULANTE')
                    this.itemsPasivoLargoPlazo = respuesta.filter(x => x.subTipo === 'PASIVO LARGO PLAZO')
                    this.itemsPasivoDiferido = respuesta.filter(x => x.subTipo === 'PASIVO DIFERIDO')
                    this.itemsCapital = respuesta.filter(x => x.subTipo === 'CAPITAL')

                    this.loading = false;
                } catch (error) {
                    console.log(error);
                    this.loading = false;
                }
            },

            cambiaFechaFinal() {
                let fecha = this.fechaI.split('-');
                var primero = new Date(fecha[0], fecha[1] - 1, '01');
                var ultimoDia = new Date(primero.getFullYear(), primero.getMonth() + 1, 0);
                this.fechaF = format(parseISO(new Date(ultimoDia).toISOString()), 'yyyy-MM-dd')
            },

            GeneraExcel() {
                this.nombreReporte = 'Estado de situacion financiera' + this.empresa.rfc + '_' + this.fechaI + ' al ' + this.fechaF + '.xlsx'
                let lista = []

                //ACTIVOS
                let objActivoCirculante = {}
                let objActivoFijo = {}
                let objActivoDiferido = {}
                var salto = { descripcion: '', importe: '' }

                var objC = { descripcion: 'ACTIVO CIRCULANTE', importe: this.sumaActivoCirculante }
                lista.push(objC)
                objC = {};
                for (var a of this.itemsActivoCirculante) {
                    var obj = { descripcion: '          ' + a.descripcion, importe: a.importe }
                    lista.push(obj)
                    obj = {}
                }

                var objC = { descripcion: 'ACTIVO FIJO', importe: this.sumaActivoFijo }
                lista.push(objC)
                objC = {};
                for (var a of this.itemsActivoFijo) {
                    var obj = { descripcion: '          ' + a.descripcion, importe: a.importe }
                    lista.push(obj)
                    obj = {}
                }

                var objC = { descripcion: 'ACTIVO DIFERIDO', importe: this.sumaActivoDiferido }
                lista.push(objC)
                objC = {};
                for (var a of this.itemsActivoDiferido) {
                    var obj = { descripcion: '          ' + a.descripcion, importe: a.importe }
                    lista.push(obj)
                    obj = {}
                }

                var objC = { descripcion: 'TOTAL ACTIVO', importe: this.sumActivo }
                lista.push(objC)
                objC = {};
                lista.push(salto)

                var objC = { descripcion: 'PASIVO CIRCULANTE', importe: this.sumaPasivoCirculante }
                lista.push(objC)
                objC = {};
                for (var a of this.itemsPasivoCirculante) {
                    var obj = { descripcion: '          ' + a.descripcion, importe: a.importe }
                    lista.push(obj)
                    obj = {}
                }

                var objC = { descripcion: 'PASIVO LARGO PLAZO', importe: this.sumaPasivoLargoPlazo }
                lista.push(objC)
                objC = {};
                for (var a of this.itemsPasivoLargoPlazo) {
                    var obj = { descripcion: '          ' + a.descripcion, importe: a.importe }
                    lista.push(obj)
                    obj = {}
                }

                var objC = { descripcion: 'PASIVO DIFERIDO', importe: this.sumaPasivoDiferido }
                lista.push(objC)
                objC = {};
                for (var a of this.itemsPasivoDiferido) {
                    var obj = { descripcion: '          ' + a.descripcion, importe: a.importe }
                    lista.push(obj)
                    obj = {}
                }

                var objC = { descripcion: 'TOTAL PASIVO', importe: this.sumaPasivoCirculante + this.sumaPasivoLargoPlazo + this.sumaPasivoDiferido }
                lista.push(objC)
                objC = {};
                lista.push(salto)

                var objC = { descripcion: 'CAPITAL', importe: this.sumaCapital }
                lista.push(objC)
                objC = {};
                for (var a of this.itemsCapital) {
                    var obj = { descripcion: '          ' + a.descripcion, importe: a.importe }
                    lista.push(obj)
                    obj = {}
                }

                var objC = { descripcion: 'TOTAL PASIVO + CAPITAL', importe: this.sumPasivoCapital }
                lista.push(objC)
                objC = {};
                lista.push(salto)

                this.listaExcel = lista;
                this.collection = lista;
            },
            sum(array, key) {
            return array.reduce((a, b) => a + (b[key] || 0), 0);
        },
            CreaPdf() {

                function formatoNumerico(value) {
                    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                }

                //DATOS DE LA EMPRESA
                let xCabecera = 10;
                let yCabecera = 15;

                const doc = new jsPDF();

                if (this.$store.state.empresaStore.idEmpresa != 0){
                    let base64logo = this.$store.state.empresaStore.logo.base64
                    doc.addImage(base64logo, 'PNG', xCabecera, yCabecera, 65, 25);
                }
           
                var fecha = 'Del ' + this.fechaI + ' al ' + this.fechaF

                doc.setFontSize(14);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#FF0000");
                //doc.text('REPORTE', 195, yCabecera, { align: 'right' });
                doc.setTextColor("#000");

                doc.text('ESTADO DE SITUACIÓN FINANCIERA', 195, yCabecera += 5, { align: 'right' });
                doc.setFontSize(12);
                doc.setFont('helvetica', 'normal');
                doc.text(this.empresa.empresa, 195, yCabecera += 5, { align: 'right' });
                doc.text(this.empresa.rfc, 195, yCabecera += 5, { align: 'right' });
                doc.text(fecha, 195, yCabecera += 5, { align: 'right' });


                // Definir el color de la barra
                const color = '#662E91';
                const width = 180;
                const height = 8;
                // Dibujar la barra de color
                doc.setFillColor(color);
                doc.rect(15, yCabecera + 15, width, height, 'F');
                
                // doc.setFontSize(10);
                // doc.setFont('helvetica', 'normal');
                // doc.setTextColor("#000");
                // doc.text('CUENTA', xCabecera += 5, yCabecera += 5, { align: 'right' });

                // ACTIVO CIRCULANTE
                doc.setFontSize(14);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#000");
                let yActivoCirculante = yCabecera += 13;
                let xActivoCirculante = 25 ;
                let totalActivoCirculante = this.sum(this.itemsActivoCirculante, 'importe')

                // TITULO ACTIVO CIRCULANTE
                doc.text('ACTIVO CIRCULANTE', 15, yActivoCirculante, { align: 'left' });
                doc.text(formatoNumerico(totalActivoCirculante), 195, yActivoCirculante, { align: 'right' });


                 // ACTIVO CIRCULANTE
                doc.setFontSize(10);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#FFF");
                

                // CABEZERA DE LA TABLA
                doc.text('CUENTA', xActivoCirculante, yActivoCirculante += 7, { align: 'center' });
                doc.text('TOTAL', xActivoCirculante + 90, yActivoCirculante, { align: 'center' });


                //RECORREMOS EL ARRAY DE LOS CONCEPTOS
                yActivoCirculante += 4;
                for (let c of this.itemsActivoCirculante) {
                    //CLAVE DEL SAT
                    doc.setFont('helvetica', 'normal');
                    doc.setFontSize(12);
                    doc.setTextColor("#000");

                    let descripcion = c.descripcion
                    let importe = c.importe

                    doc.text(descripcion, 15, yActivoCirculante += 7, { align: 'left' });
                    doc.text(formatoNumerico(importe), 195, yActivoCirculante, { align: 'right' });

                    //INSERTMOS EL SALTO DE PAGINA
                    let espacioDisponible = doc.internal.pageSize.height - 40;
                    let dif = yActivoCirculante > espacioDisponible
                    if (dif) {
                        doc.addPage();
                        yActivoCirculante = 10;
                    } else {
                        const color_ = "#662E91"
                        doc.setDrawColor(color_);
                        doc.setLineWidth(.1);
                        doc.line(15, yActivoCirculante += 3, 195, yActivoCirculante);
                    }
                }


                // ACTIVO FIJO
                let yActivoFijo = yActivoCirculante += 13;
                let xActivoFijo = 17 ;

                doc.setFontSize(14);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#000");
                
                let totalActivoFijo = this.sum(this.itemsActivoFijo, 'importe')

                // TITULO ACTIVO CIRCULANTE
                doc.text('ACTIVO FIJO', 15, yActivoFijo, { align: 'left' });
                doc.text(formatoNumerico(totalActivoFijo), 195, yActivoFijo, { align: 'right' });

                doc.setFillColor(color);
                doc.rect(15, yActivoFijo +2, width, height, 'F');

                 // ACTIVO CIRCULANTE
                doc.setFontSize(10);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#FFF");
                
                // CABEZERA DE LA TABLA
                doc.text('CUENTA', xActivoFijo, yActivoFijo += 7, { align: 'left' });
                doc.text('TOTAL', xActivoFijo + 100, yActivoFijo, { align: 'center' });

                //RECORREMOS EL ARRAY DE LOS CONCEPTOS
                yActivoFijo += 5;
                for (let c of this.itemsActivoFijo) {
                    //CLAVE DEL SAT
                    doc.setFont('helvetica', 'normal');
                    doc.setFontSize(11);
                    doc.setTextColor("#000");

                    let descripcion = c.descripcion
                    let importe = c.importe

                    doc.text(descripcion, 15, yActivoFijo += 7, { align: 'left' });
                    doc.setFontSize(12);

                    doc.text(formatoNumerico(importe), 195, yActivoFijo, { align: 'right' });

                    //INSERTMOS EL SALTO DE PAGINA
                    let espacioDisponible = doc.internal.pageSize.height - 40;
                    let dif = yActivoFijo > espacioDisponible

                    if (dif) {
                        doc.addPage();
                        yActivoFijo = 10;
                    } else {
                        const color_ = "#662E91"
                        doc.setDrawColor(color_);
                        doc.setLineWidth(.1);
                        doc.line(15, yActivoFijo +=3, 195, yActivoFijo);
                    }

                }

                // ACTIVO DIFERIDO
                let yActivoDiferido = yActivoFijo += 13;
                let xActivoDiferido = 17 ;

                doc.setFontSize(14);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#000");
                
                let totalActivoDiferido = this.sum(this.itemsActivoDiferido, 'importe')

                // TITULO ACTIVO DIFERIDO
                doc.text('ACTIVO DIFERIDO', 15, yActivoDiferido, { align: 'left' });
                doc.text(formatoNumerico(totalActivoDiferido), 195, yActivoDiferido, { align: 'right' });

                doc.setFillColor(color);
                doc.rect(15, yActivoDiferido +2, width, height, 'F');

                 // ACTIVO DIFERIDO
                doc.setFontSize(10);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#FFF");
                
                // CABEZERA DE LA TABLA
                doc.text('CUENTA', xActivoDiferido, yActivoDiferido += 7, { align: 'left' });
                doc.text('TOTAL', xActivoDiferido + 100, yActivoDiferido, { align: 'center' });

                //RECORREMOS EL ARRAY DE LOS CONCEPTOS
                yActivoDiferido += 5;
                for (let c of this.itemsActivoDiferido) {
                    //CLAVE DEL SAT
                    doc.setFont('helvetica', 'normal');
                    doc.setFontSize(12);
                    doc.setTextColor("#000");

                    let descripcion = c.descripcion
                    let importe = c.importe

                    doc.text(descripcion, 15, yActivoDiferido += 7, { align: 'left' });
                    doc.text(formatoNumerico(importe), 195, yActivoDiferido, { align: 'right' });

                    //INSERTMOS EL SALTO DE PAGINA
                    let espacioDisponible = doc.internal.pageSize.height - 40;
                    let dif = yActivoDiferido > espacioDisponible

                    if (dif) {
                        doc.addPage();
                        yActivoDiferido = 10;
                    } else {
                        const color_ = "#662E91"
                        doc.setDrawColor(color_);
                        doc.setLineWidth(.1);
                        doc.line(15, yActivoDiferido +=3 , 195, yActivoDiferido);
                    }

                }

                // PASIVO CIRCULANTE
                let yPasivoCirculante = yActivoDiferido += 13;
                let xPasivoCirculante = 17 ;

                doc.setFontSize(14);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#000");
                
                let totalPasivoCirculante = this.sum(this.itemsPasivoCirculante, 'importe')

                // TITULO PASIVO CIRCULANTE
                doc.text('PASIVO CIRCULANTE', 15, yPasivoCirculante, { align: 'left' });
                doc.text(formatoNumerico(totalPasivoCirculante), 195, yPasivoCirculante, { align: 'right' });

                doc.setFillColor(color);
                doc.rect(15, yPasivoCirculante +2, width, height, 'F');

                 // PASIVO CIRCULANTE
                doc.setFontSize(10);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#FFF");
                
                // CABEZERA DE LA TABLA
                doc.text('CUENTA', xPasivoCirculante, yPasivoCirculante += 7, { align: 'left' });
                doc.text('TOTAL', xPasivoCirculante + 100, yPasivoCirculante, { align: 'center' });

                //RECORREMOS EL ARRAY DE LOS CONCEPTOS
                yPasivoCirculante += 5;
                for (let c of this.itemsPasivoCirculante) {
                    //CLAVE DEL SAT
                    doc.setFont('helvetica', 'normal');
                    doc.setFontSize(12);
                    doc.setTextColor("#000");

                    let descripcion = c.descripcion
                    let importe = c.importe

                    doc.text(descripcion, 15, yPasivoCirculante += 7, { align: 'left' });
                    doc.text(formatoNumerico(importe), 195, yPasivoCirculante, { align: 'right' });

                    //INSERTMOS EL SALTO DE PAGINA
                    let espacioDisponible = doc.internal.pageSize.height - 40;
                    let dif = yPasivoCirculante > espacioDisponible

                    if (dif) {
                        doc.addPage();
                        yPasivoCirculante = 10;
                    } else {
                        const color_ = "#662E91"
                        doc.setDrawColor(color_);
                        doc.setLineWidth(.1);
                        doc.line(15, yPasivoCirculante +=3, 195, yPasivoCirculante);
                    }

                }

                // PASIVO LARGO PLAZO
                let yPasivoLargoPlazo = yPasivoCirculante += 13;
                let xPasivoLargoPlazo = 17 ;

                doc.setFontSize(14);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#000");
                
                let totalPasivoLargoPlazo = this.sum(this.itemsPasivoLargoPlazo, 'importe')

                // TITULO PASIVO CIRCULANTE
                doc.text('PASIVO LARGO PLAZO', 15, yPasivoLargoPlazo, { align: 'left' });
                doc.text(formatoNumerico(totalPasivoLargoPlazo), 195, yPasivoLargoPlazo, { align: 'right' });

                doc.setFillColor(color);
                doc.rect(15, yPasivoLargoPlazo +2, width, height, 'F');

                 // PASIVO CIRCULANTE
                doc.setFontSize(10);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#FFF");
                
                // CABEZERA DE LA TABLA
                doc.text('CUENTA', xPasivoLargoPlazo, yPasivoLargoPlazo += 7, { align: 'left' });
                doc.text('TOTAL', xPasivoLargoPlazo + 100, yPasivoLargoPlazo, { align: 'center' });

                //RECORREMOS EL ARRAY DE LOS CONCEPTOS
                yPasivoLargoPlazo += 5;
                for (let c of this.itemsPasivoLargoPlazo) {
                    //CLAVE DEL SAT
                    doc.setFont('helvetica', 'normal');
                    doc.setFontSize(12);
                    doc.setTextColor("#000");

                    let descripcion = c.descripcion
                    let importe = c.importe

                    doc.text(descripcion, 15, yPasivoLargoPlazo += 7, { align: 'left' });
                    doc.text(formatoNumerico(importe), 195, yPasivoLargoPlazo, { align: 'right' });

                    //INSERTMOS EL SALTO DE PAGINA
                    let espacioDisponible = doc.internal.pageSize.height - 40;
                    let dif = yPasivoLargoPlazo > espacioDisponible

                    if (dif) {
                        doc.addPage();
                        yPasivoLargoPlazo = 10;
                    } else {
                        const color_ = "#662E91"
                        doc.setDrawColor(color_);
                        doc.setLineWidth(.1);
                        doc.line(15, yPasivoLargoPlazo +=3, 195, yPasivoLargoPlazo);
                    }
                }

                // PASIVO DIFERIDO
                let yPasivoDiferido = yPasivoLargoPlazo += 13;
                let xPasivoDiferido = 17 ;

                doc.setFontSize(14);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#000");
                
                let totalPasivoDiferido = this.sum(this.itemsPasivoDiferido, 'importe')

                // TITULO PASIVO CIRCULANTE
                doc.text('PASIVO DIFERIDO', 15, yPasivoDiferido, { align: 'left' });
                doc.text(formatoNumerico(totalPasivoLargoPlazo), 195, yPasivoDiferido, { align: 'right' });

                doc.setFillColor(color);
                doc.rect(15, yPasivoDiferido +2, width, height, 'F');

                 // PASIVO CIRCULANTE
                doc.setFontSize(10);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#FFF");
                
                // CABEZERA DE LA TABLA
                doc.text('CUENTA', xPasivoDiferido, yPasivoDiferido += 7, { align: 'left' });
                doc.text('TOTAL', xPasivoDiferido + 100, yPasivoDiferido, { align: 'center' });

                //RECORREMOS EL ARRAY DE LOS CONCEPTOS
                yPasivoDiferido += 5;
                for (let c of this.itemsPasivoDiferido) {
                    //CLAVE DEL SAT
                    doc.setFont('helvetica', 'normal');
                    doc.setFontSize(12);
                    doc.setTextColor("#000");

                    let descripcion = c.descripcion
                    let importe = c.importe

                    doc.text(descripcion, 15, yPasivoDiferido += 7, { align: 'left' });
                    doc.text(formatoNumerico(importe), 195, yPasivoDiferido, { align: 'right' });

                    //INSERTMOS EL SALTO DE PAGINA
                    let espacioDisponible = doc.internal.pageSize.height - 40;
                    let dif = yPasivoDiferido > espacioDisponible

                    if (dif) {
                        doc.addPage();
                        yPasivoDiferido = 10;
                    } else {
                        const color_ = "#662E91"
                        doc.setDrawColor(color_);
                        doc.setLineWidth(.1);
                        doc.line(15, yPasivoDiferido +=3, 195, yPasivoDiferido);
                    }
                }

                // CAPITAL
                let yCapital = yPasivoDiferido += 13;
                let xCapital = 17 ;

                doc.setFontSize(14);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#000");
                
                let totalCapital = this.sum(this.itemsCapital, 'importe')

                // TITULO PASIVO CIRCULANTE
                doc.text('CAPITAL', 15, yCapital, { align: 'left' });
                doc.text(formatoNumerico(totalCapital), 195, yCapital, { align: 'right' });

                doc.setFillColor(color);
                doc.rect(15, yCapital +2, width, height, 'F');

                 // PASIVO CIRCULANTE
                doc.setFontSize(10);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#FFF");
                
                // CABEZERA DE LA TABLA
                doc.text('CUENTA', xCapital, yCapital += 7, { align: 'left' });
                doc.text('TOTAL', xCapital + 100, yCapital, { align: 'center' });

                //RECORREMOS EL ARRAY DE LOS CONCEPTOS
                yCapital += 5;
                for (let c of this.itemsCapital) {
                    //CLAVE DEL SAT
                    doc.setFont('helvetica', 'normal');
                    doc.setFontSize(12);
                    doc.setTextColor("#000");

                    let descripcion = c.descripcion
                    let importe = c.importe

                    doc.text(descripcion, 15, yCapital += 7, { align: 'left' });
                    doc.text(formatoNumerico(importe), 195, yCapital, { align: 'right' });

                    //INSERTMOS EL SALTO DE PAGINA
                    let espacioDisponible = doc.internal.pageSize.height - 40;
                    let dif = yCapital > espacioDisponible

                    if (dif) {
                        doc.addPage();
                        yCapital = 10;
                    } else {
                        const color_ = "#662E91"
                        doc.setDrawColor(color_);
                        doc.setLineWidth(.1);
                        doc.line(15, yCapital +=3, 195, yCapital);
                    }
                }

                // CAPITAL
                let yTotalActivo = yCapital += 13;
                let xTotalActivo = 17 ;

                doc.setFontSize(16);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#FFF");
                doc.setFillColor(color);

                doc.rect(15, yTotalActivo, width, 15, 'F');
                doc.text('TOTAL ACTIVO', xTotalActivo, yTotalActivo +=9, { align: 'left' });
                doc.text(formatoNumerico(this.sumActivo), 195, yTotalActivo, { align: 'right' });

                // CAPITAL
                let yTotalPasivoCapital = yCapital += 17;
                let xTotalPasivoCapital = 17 ;

                doc.setFontSize(16);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#FFF");
                doc.setFillColor(color);

                doc.rect(15, yTotalPasivoCapital, width, 15, 'F');
                doc.text('TOTAL PASIVO + CAPITAL', xTotalPasivoCapital, yTotalPasivoCapital +=9, { align: 'left' });
                doc.text(formatoNumerico(this.sumPasivoCapital), 195, yTotalPasivoCapital, { align: 'right' });
                // Guardar o mostrar el PDF
                doc.save('Estado_de_situacion_financiera_' + this.empresa.rfc + '.pdf');

            },

        }
    }
</script>