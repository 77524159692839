<template>
    <div>
        <v-card>
            <v-card-title style="font: bold;" class="text-h4 text--primary">
                <!-- BOTON PARA IR A UNA VENTANA ANTERIOR -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary darken-1" dark fab small @click="closeDialogPolizaCierre()"
                            v-bind="attrs" v-on="on">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span>Cerrar</span>
                </v-tooltip>
                Poliza de Cierre
                <v-spacer></v-spacer>
                <!-- BOTON CONSULTAR -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary darken-1" dark fab small @click="generaPolizaCierre()"
                            v-bind="attrs" v-on="on">
                            <v-icon>mdi-file-document-plus-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>Generar</span>
                </v-tooltip>
                <!-- BOTON GUARDAR -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary darken-1" dark fab small @click="save()"
                            v-bind="attrs" v-on="on">
                            <v-icon>mdi-content-save-all-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>Guardar</span>
                </v-tooltip>
            </v-card-title>

            <v-card-text>
                <div>
                    <v-row>
                        <!-- AÑOS -->
                    <v-col cols="12" md="6" sm="12">
                        <v-select :items="itemsAnios" label="Año del ejercicio" solo v-model="anios"></v-select>
                    </v-col>

                    <v-col cols="12" md="6" sm="12">
                        <v-autocomplete label="Número de cuenta"
                            v-model="$store.state.cuentaPadreStore" return-object :items="$store.state.listCatalogo" :loading="loadingCuentas" item-text= "cuentaContable" solo>
                        </v-autocomplete>
                    </v-col>
                    </v-row>
                </div>

                <v-data-table :headers="headersPoliza" :items="polizaCierre" class="elevation-1" item-key="id">
                    <!-- FORMATO CON DECIMALES DEL CARGO Y ABONO -->
                    <template v-slot:item.cargo="{ item }">
                        <span>{{ functionFormatPrice(item.cargo) }}</span>
                    </template>
                    <template v-slot:item.abono="{ item }">
                        <span>{{ functionFormatPrice(item.abono) }}</span>
                    </template>
                </v-data-table>
                <div class="mt-3">
                    <v-row>
                        <v-col cols="12" md="6" sm="12">
                            <v-chip class="ma-2" color="primary" x-large>
                                CARGO: {{functionFormatPrice(cargo)}}
                            </v-chip>
                            <!-- <v-text-field label="Cargo" v-model="cargo" outlined readonly></v-text-field> -->
                        </v-col>
                        <v-col cols="12" md="6" sm="12">
                            <v-chip class="ma-2" color="secondary" x-large>
                                ABONO: {{functionFormatPrice(abono)}}
                            </v-chip>
                            <!-- <v-text-field label="Abono" v-model="abono" outlined readonly></v-text-field> -->
                        </v-col>
                    </v-row>
                </div>
                
            
            </v-card-text>
        </v-card>

        <!--Dialog loading-->
        <v-dialog v-model="loading" persistent width="300">
            <v-card color="primary" dark>
            <v-card-text>
                {{loadingMessage}}
                <v-progress-linear buffer-value="0" stream color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
            </v-card>
        </v-dialog>

        <!-- SNACKBAR DE ERROR -->
        <v-snackbar v-model="snackbar" :timeout="5000" color="red" top>
            {{ errorMessage }}
            <!-- <v-btn color="white" text @click="snackbar = false">Cerrar</v-btn> -->
        </v-snackbar>

    </div>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import Vue from 'vue'
    import VueCookies from 'vue-cookies'

    
    Vue.use(VueCookies)

    export default {
        name: 'ReporteDiot',
        components: {
        },

        data: () => ({
            headersPoliza:[
                // { text: 'Acciones', value: 'actions' },
                { text: "Número de la cuenta", value: "numeroCuenta" },
                { text: "Nombre de la cuenta", value: "nombreCuenta" },
                { text: "Cargo", value: "cargo" },
                { text: "Abono", value: "abono" },
            ],

            //LOADING
            loadingMessage: '',
            loading: false,

            //FECHAS
            fechaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            fechaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),

            anios: '',
            loadingCuentas: null,

            objetoPolizaCierre: {
                abono: 0,
                cargo: 0,
                numeroCuenta: '',
                nombreCuenta: '',
                idCuenta: 0,
            },
            listaPolizaCierre: [],

            cargo: 0,
            abono: 0,

            polCierre: false,
            snackbar: false,
            errorMessage: "",

        }),
        watch: {
            
        },
        computed: {
            polizaCierre(){
                return this.$store.state.listPolizaCierreStore;
            },
            items() {
                return this.$store.state.listPoliza;
            },
            poliza(){
                return this.$store.state.poliza;
            },
            empresa(){
                return this.$store.state.usuario
            },
            itemsAnios() {
                let fecha = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd');
                let anio = fecha.split('-')[0];
                let lista = []
                lista.push(anio);
                lista.push(anio - 1);
                lista.push(anio - 2);
                lista.push(anio - 3);
                lista.push(anio - 4);
                lista.push(anio - 5);

                return lista;
            },
            
        },
        created() {
            
            this.GetCuentas()

        },
        methods: {
            functionFormatPrice(value) {
                let val = (value / 1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            functionFormatDate(value){
                let fecha = moment(value).format('DD/MM/yyyy')
                return fecha;
            },
            closeDialogPolizaCierre(){
                this.$emit("cierraDialogPC")
            },

            async GetCuentas(){
                this.loading = true
                this.loadingMessage = 'Consultando catálogo...'
                try {
                    let response = await axios.get('ReportesGenerales/GetCatalogo/'+this.empresa.empresaBase)
                    this.$store.state.listCatalogo = response.data;
                    this.loading = false
                } catch (error) {
                    console.log(error)
                    this.loading = false
                }

                // this.loadingMessage = 'Consultando Cuentas...'
                // this.loading = true;
                // try {
                //     let response = await axios.get('AutoConta/Cuentaspadre?baseAC='+ this.empresa.empresaBase);
                //     this.$store.state.listaCuentas = response.data;
                //     this.loading = false;
                // } catch (error) {   
                //     console.log(error);
                //     this.loading = false;
                // }
            },

            async GetPolizaCierre(){
                this.loadingMessage = 'Consultando Datos...'
                this.loading = true;
                try {
                    let response = await axios.get('GetPolizas/GetPolizaCierre/'+ this.empresa.empresaBase + '/'+ this.anios );
                    this.$store.state.listPolizaCierreStore = response.data.lista
                    //AGREGA LA CUENTA SELECCIONADA A LA TABLA
                    this.listaPolizaCierre = response.data.lista
                    this.objetoPolizaCierre.numeroCuenta = this.$store.state.cuentaPadreStore.numeroCuenta
                    this.objetoPolizaCierre.nombreCuenta = this.$store.state.cuentaPadreStore.nombreCuenta
                    this.objetoPolizaCierre.idCuenta = this.$store.state.cuentaPadreStore.idCuenta

                    let sumaC = response.data.totalCargo;
                    let sumA = response.data.totalAbono;
                    
                    if(sumaC > sumA){
                        let dif =  sumaC - sumA
                        this.objetoPolizaCierre.cargo = 0
                        this.objetoPolizaCierre.abono = dif
                    }
                    else{
                        let dif = sumA- sumaC
                        this.objetoPolizaCierre.cargo = dif
                        this.objetoPolizaCierre.abono = 0
                    }
                    if(sumaC != sumA){
                        this.listaPolizaCierre.push(this.objetoPolizaCierre)
                    }
                    this.cargo = this.listaPolizaCierre.reduce((acumulador, objeto) => acumulador + objeto.cargo, 0);
                    this.abono = this.listaPolizaCierre.reduce((acumulador, objeto) => acumulador + objeto.abono, 0);
                    
                    this.loading = false;
                } catch (error) {   
                    console.log(error);
                    this.loading = false;
                }
            },

            save(){
                this.PostPoliza();
            },
            async PostPoliza(){
                let ObjPoliza = {
                    id:0,
                    tipoPoliza: 'Diario',
                    numeroPoliza: '0',
                    fecha: this.anios + '-12-31',
                    descripcion: 'Póliza de cierre ' + this.anios,
                    cargo: this.cargo,
                    abono: this.abono,
                    mes: 'diciembre',
                    año: this.anios,
                    usuarioCreeador: this.empresa.nombre,
                    origen: 'auto_conta',
                    estado: 'activo',
                    detalles: []
                }
                let detalles = []
                for(let x of this.listaPolizaCierre){
                    let polizaDet={
                        idPoliza: x.idPoliza,
                        idCuenta: x.idCuenta,
                        numeroCuenta: x.numeroCuenta,
                        numeroCuentaUnico: x.numeroCuentaUnico,
                        nombreCuenta: x.nombreCuenta,
                        cuentaContable: x.cuentaContable,
                        cargo: x.cargo,
                        abono: x.abono
                    }
                    detalles.push(polizaDet)
                }

                ObjPoliza.detalles = detalles

                console.log(ObjPoliza)

                this.loading = true
                this.loadingMessage = 'Guardando Póliza...'
                try {
                    let response = await axios.post('GetPolizas/PostPolizaAsync/'+this.empresa.empresaBase, ObjPoliza)
                    this.closeDialogPolizaCierre()
                    // this.GetPolizas(this.$store.state.fechasPoliza.fechaI, this.$store.state.fechasPoliza.fechaF)
                } catch (error) {
                    console.log(error)
                    this.loading = false
                }

            },

            async generaPolizaCierre(){
                this.loadingMessage = 'Consultando Polizas de Cierre...'
                this.loading = true;
                try {
                    let response = await axios.get('GetPolizas/PolizaCierre/' + this.empresa.empresaBase + '/' + this.anios);
                    if(response.data.length > 0){
                        this.errorMessage = "Error, Ya hay una Poliza de cierre creada previamente para el año " + this.anios
                        this.snackbar = true
                    }else{
                        this.GetPolizaCierre()
                    }
                    this.loading = false;
                } catch (error) {   
                    console.log(error);
                    this.loading = false;
                }
            },

        },
    }
</script>