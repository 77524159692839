<template>
    <div>
        <v-card>
                <v-toolbar>
                    <!-- BOTON CERRAR -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-1" dark fab small @click="closeDialogEstadoFlujoEfectivo()"
                                v-bind="attrs" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <b style="font-size: 30px;">Estado de Flujo de Efectivo</b>
                    <v-spacer></v-spacer>
                    <!-- BOTON PARA IMPRIMIR -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-1" dark fab small @click="CreaPdf()"
                                v-bind="attrs" v-on="on">
                                <v-icon>mdi-file-pdf-box</v-icon>
                            </v-btn>
                        </template>
                        <span>Imprimir</span>
                    </v-tooltip>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>

            <template>
                <v-card class="justify-center" flat>
                <v-row dense>

                    <v-col cols="12">
                        <!-- UTILIDAD NETA -->
                            <v-card flat>
                                <v-card-title>UTILIDAD NETA<v-spacer></v-spacer>{{functionFormatPrice(estadoResultado)}}</v-card-title>
                            </v-card>
                            <v-system-bar color="primary" height="6"></v-system-bar>
                    </v-col>
                    <v-col cols="6">
                        <!-- ACTIVIDADES DE OPERACION -->
                            <v-card flat>
                                <v-card-title>ACTIVIDADES DE OPERACION<v-spacer></v-spacer> {{sumaTotalA}}</v-card-title>
                                <v-card-text>
                                    <v-data-table :headers="headersActividadesOperacion" hide-default-footer :items-per-page="-1" hide-default-header :items="actividadesOperacion">
                                        <template v-slot:item.total="{ item }">
                                            <span>{{ functionFormatPrice(item.total) }}</span>
                                        </template>
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                    </v-col>
                    <v-col cols="6">
                         <!-- ACTIVIDADES DE INVERSION -->
                         <v-card flat>
                            <v-card-title>ACTIVIDADES DE INVERSION<v-spacer></v-spacer>{{functionFormatPrice(totalActividadInversion)}}</v-card-title>
                            <v-card-text>
                                <v-data-table :headers="headersActividadesInversion" hide-default-footer :items-per-page="-1" hide-default-header :items="actividadesInversion">
                                    <template v-slot:item.total="{ item }">
                                            <span>{{ functionFormatPrice(item.total) }}</span>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                        <!-- IMPORTE POR APLICAR EN ACTIVIDADES DE FINANCIAMIENTO -->
                        <v-system-bar color="primary" height="6"></v-system-bar>
                        <v-card flat>
                                <v-card-title>IMPORTE POR APLICAR EN ACTIVIDADES DE FINANCIAMIENTO<v-spacer></v-spacer>{{functionFormatPrice(importePorAplicar)}}</v-card-title>
                        </v-card>
                        <!-- ACTIVIDADES DE FINANCIAMIENTO -->
                        <v-system-bar color="primary" height="6"></v-system-bar>
                        <v-card flat>
                            <v-card-title>ACTIVIDADES DE FINANCIAMIENTO<v-spacer></v-spacer>{{sumaTotalAF}}</v-card-title>
                            <v-card-text>
                                <v-data-table :headers="headersActividadesFinanciamiento" hide-default-footer :items-per-page="-1" hide-default-header :items="actividadesFinanciamiento">
                                    <template v-slot:item.total="{ item }">
                                            <span>{{ functionFormatPrice(item.total) }}</span>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                        <!-- VARIACIONES EN FLUJO -->
                        <v-system-bar color="primary" height="6"></v-system-bar>
                        <v-card flat>
                                <v-card-title>VARIACIONES EN FLUJO<v-spacer></v-spacer>{{functionFormatPrice(variacionFlujo)}}</v-card-title>
                        </v-card>
                        <!-- EFECTIVO AL INICIO DEL EJERCICIO -->
                        <v-system-bar color="primary" height="6"></v-system-bar>
                        <v-card flat>
                                <v-card-title>EFECTIVO AL INICIO DEL EJERCICIO<v-spacer></v-spacer>{{functionFormatPrice(efectivoInicioEjercicio)}}</v-card-title>
                        </v-card>
                        <!-- FLUJO DE EFECTIVO AL FINAL DEL PERIODO -->
                        <v-system-bar color="primary" height="6"></v-system-bar>
                        <v-card flat>
                                <v-card-title>FLUJO DE EFECTIVO AL FINAL DEL PERIODO<v-spacer></v-spacer>{{functionFormatPrice(flujoEfectivoFinalPeriodo)}}</v-card-title>
                        </v-card>
                        <!-- <span>{{flujoEfectivoFinalPeriodo}}</span> -->
                    </v-col>
                </v-row>
            </v-card>
            
            </template>
            </v-card>

        <!-- Dialog loading -->
        <v-dialog v-model="loading" persistent width="300">
            <v-card color="primary" dark>
            <v-card-text>
                {{loadingMessage}}
                <v-progress-linear buffer-value="0" stream color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
            </v-card>
        </v-dialog>

        <!-- MENSAJE DE CUANTAS POLIZAS SE CANCELARON -->
        <v-snackbar v-model="snackbar" color="green" text-center>
            {{ mensaje }}
            <v-btn color="white" text @click="snackbar = false">Cerrar</v-btn>
        </v-snackbar>

    </div>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import Vue from 'vue'
    import VueCookies from 'vue-cookies'
    import jsPDF from 'jspdf';


    
    Vue.use(VueCookies)

    export default {
        name: 'ReporteDiot',
        components: {
        },

        data: () => ({
            //LOADING
            loadingMessage: '',
            loading: false,

            //FECHAS
            fechaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            fechaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),

            calendario: false,
            calendario2: false,

            radios: '1',
            //DATOS DE LA POLIZA
            singleSelect: false,
            itemsPolizaCancelada: [],

            snackbar: false,
            mensaje: "",

            // ACTIVIDADES DE OPERACION
            headersActividadesOperacion:[
                { text: "Descripción", value: "cuenta" },
                { text: "Importe", value: "total", align: 'right' },
            ],

            // ACTIVIDADES DE INVERSION
            headersActividadesInversion:[
                { text: "Descripción", value: "cuenta" },
                { text: "Importe", value: "total", align: 'right' },
            ],

            // ACTIVIDADES DE FINANCIAMIENTO
            headersActividadesFinanciamiento:[
                { text: "Descripción", value: "cuenta" },
                { text: "Importe", value: "total", align: 'right' },
            ],

        }),
        watch: {
            
        },

        computed: {
            
            empresa(){
                return this.$store.state.usuario
            },

            estadoResultado(){
                return this.$store.state.estadoResultado;
            },

            // ACTIVIDADES DE OPERACION
            actividadesOperacion(){
                return this.$store.state.listActividadesOperacion;
            },

            sumaTotalA() {
                let total = 0;
                for (let item of this.actividadesOperacion) {
                    total += item.total;
                }

                return this.functionFormatPrice(total);
            },

            // ACTIVIDADES DE INVERSION
            actividadesInversion(){
                return this.$store.state.listActividadesInversion;
            },
            totalActividadInversion(){
                return this.$store.state.listActividadesInversion[0].total
            },
            
            // ACTIVIDADES DE FINANCIAMIENTO
            actividadesFinanciamiento(){
                return this.$store.state.listActividadesFinanciamiento;
            },
            sumaTotalAF() {
                let total = 0;
                for (let item of this.actividadesFinanciamiento) {
                    total += item.total;
                }

                return this.functionFormatPrice(total);
            },

            // IMPORTE POR APLICAR EN ACTIVIDADES DE FINANCIAMIENTO
            importePorAplicar(){
                let total = this.totalActividadInversion + this.estadoResultado

                let totalActividadOperacion = 0;
                for (let item of this.actividadesOperacion) {
                    totalActividadOperacion += item.total;
                }

                return total  + totalActividadOperacion
            },

            // VARIACIONES EN FLUJO
            variacionFlujo(){

                let totalActividadFinanciamiento = 0;
                for (let item of this.actividadesFinanciamiento) {
                    totalActividadFinanciamiento += item.total;
                }

                return this.importePorAplicar + totalActividadFinanciamiento

            },

            //EFECTIVO AL INICIO DEL EJERCICIO
            efectivoInicioEjercicio(){
                return this.$store.state.efectivoInicioEjercicioStore;
            },

            //FLUJO DE EFECTIVO AL FINAL DEL PERIODO
            flujoEfectivoFinalPeriodo(){
                return this.variacionFlujo + this.efectivoInicioEjercicio
            }


        },

        created() {
            this.GetEmpresa();
        },

        methods: {
            async GetEmpresa() {
                console.log(this.empresa)
                try {
                    let response = await axios.get('https://api-erp.contago.com.mx/api/Empresas/GetEmpresa/' + this.empresa.empresaBase);
                    console.log('a', response.data)
                    if (response.data.idEmpresa != 0) {
                        this.$store.state.empresaStore = response.data
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            functionFormatPrice(value) {
                let val = (value / 1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            functionFormatDate(value){
                let fecha = moment(value).format('DD/MM/yyyy')
                return fecha;
            },
            closeDialogEstadoFlujoEfectivo(){
                this.$emit("cierraDialogEFE")
            },
            sum(array, key) {
            return array.reduce((a, b) => a + (b[key] || 0), 0);
        },
            CreaPdf(){

                function formatoNumerico(value) {
                    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                }

                //DATOS DE LA EMPRESA
                let xCabecera = 10;
                let yCabecera = 15;
                const doc = new jsPDF();

                if (this.$store.state.empresaStore.idEmpresa != 0){
                let base64logo = this.$store.state.empresaStore.logo.base64
                doc.addImage(base64logo, 'PNG', xCabecera, yCabecera, 65, 25);
            }

                // var fecha = 'De 2022 a 2023'



                doc.setFontSize(14);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#FF0000");
                // doc.text('REPORTE', 195, yCabecera, { align: 'right' });
                doc.setTextColor("#000");

                doc.text('ESTADO DE DE FLUJO DE EFECTIVO', 195, yCabecera += 5, { align: 'right' });
                doc.setFontSize(12);
                doc.setFont('helvetica', 'normal');
                doc.text(this.empresa.empresa, 195, yCabecera += 5, { align: 'right' });
                doc.text(this.empresa.rfc, 195, yCabecera += 5, { align: 'right' });
                doc.text('', 195, yCabecera += 5, { align: 'right' });

                 // Definir el color de la barra
                 const color = '#662E91';
                const width = 180;
                const height = 8;

                 // FLUJO DE EFECTIVO AL FINAL DEL PERIODO
                let yTotalUtilidadNeta = yCabecera += 8;
                let xTotalUtilidadNeta = 17 ;

                doc.setFontSize(12);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#FFF");
                doc.setFillColor(color);

                doc.rect(15, yTotalUtilidadNeta, width, 10, 'F');
                doc.text('UTILIDAD NETA', xTotalUtilidadNeta, yTotalUtilidadNeta +=6, { align: 'left' });
                doc.text(formatoNumerico(this.estadoResultado), 195, yTotalUtilidadNeta, { align: 'right' });

                
                // Dibujar la barra de color
                doc.setFillColor(color);
                doc.rect(15, yTotalUtilidadNeta + 15, width, height, 'F');

                // ACTIVIDADES DE OPERACION
                doc.setFontSize(14);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#000");
                let yActividadesOperacion = yTotalUtilidadNeta += 13;
                let xActividadesOperacion = 25;
                let totalActividadesOperacion = this.sum(this.actividadesOperacion, 'total')

                // TITULO ACTIVO CIRCULANTE
                doc.text('ACTIVIDADES DE OPERACIÓN', 15, yActividadesOperacion, { align: 'left' });
                doc.text(formatoNumerico(totalActividadesOperacion), 195, yActividadesOperacion, { align: 'right' });


                  // ACTIVIDADES DE OPERACION
                doc.setFontSize(10);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#FFF");
                

                // CABEZERA DE LA TABLA
                doc.text('CUENTA', xActividadesOperacion, yActividadesOperacion += 7, { align: 'center' });
                doc.text('TOTAL', xActividadesOperacion + 90, yActividadesOperacion, { align: 'center' });


                //RECORREMOS EL ARRAY DE LOS CONCEPTOS
                yActividadesOperacion += 4;
                for (let c of this.actividadesOperacion) {
                    //CLAVE DEL SAT
                    doc.setFont('helvetica', 'normal');
                    doc.setFontSize(12);
                    doc.setTextColor("#000");

                    let descripcion = c.cuenta
                    let importe = c.total

                    doc.text(descripcion, 15, yActividadesOperacion += 7, { align: 'left' });
                    doc.text(formatoNumerico(importe), 195, yActividadesOperacion, { align: 'right' });

                    //INSERTMOS EL SALTO DE PAGINA
                    let espacioDisponible = doc.internal.pageSize.height - 40;
                    let dif = yActividadesOperacion > espacioDisponible
                    if (dif) {
                        doc.addPage();
                        yActividadesOperacion = 10;
                    } else {
                        const color_ = "#662E91"
                        doc.setDrawColor(color_);
                        doc.setLineWidth(.1);
                        doc.line(15, yActividadesOperacion += 3, 195, yActividadesOperacion);
                    }
                }

                // ACTIVIDADES DE INVERSION
                let yActividadesInversion = yActividadesOperacion += 13;
                let xActividadesInversion = 17 ;

                doc.setFontSize(14);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#000");
                
                let totalActividadesInversion = this.sum(this.actividadesInversion, 'total')

                // TITULO ACTIVO CIRCULANTE
                doc.text('ACTIVIDADES DE INVERSIÓN', 15, yActividadesInversion, { align: 'left' });
                doc.text(formatoNumerico(totalActividadesInversion), 195, yActividadesInversion, { align: 'right' });

                doc.setFillColor(color);
                doc.rect(15, yActividadesInversion +2, width, height, 'F');

                 // ACTIVO CIRCULANTE
                doc.setFontSize(10);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#FFF");
                
                // CABEZERA DE LA TABLA
                doc.text('CUENTA', xActividadesInversion, yActividadesInversion += 7, { align: 'left' });
                doc.text('TOTAL', xActividadesInversion + 100, yActividadesInversion, { align: 'center' });

                //RECORREMOS EL ARRAY DE LOS CONCEPTOS
                yActividadesInversion += 5;
                for (let c of this.actividadesInversion) {
                    //CLAVE DEL SAT
                    doc.setFont('helvetica', 'normal');
                    doc.setFontSize(11);
                    doc.setTextColor("#000");

                    let descripcion = c.cuenta
                    let importe = c.total

                    doc.text(descripcion, 15, yActividadesInversion += 7, { align: 'left' });
                    doc.setFontSize(12);

                    doc.text(formatoNumerico(importe), 195, yActividadesInversion, { align: 'right' });

                    //INSERTMOS EL SALTO DE PAGINA
                    let espacioDisponible = doc.internal.pageSize.height - 40;
                    let dif = yActividadesInversion > espacioDisponible

                    if (dif) {
                        doc.addPage();
                        yActividadesInversion = 10;
                    } else {
                        const color_ = "#662E91"
                        doc.setDrawColor(color_);
                        doc.setLineWidth(.1);
                        doc.line(15, yActividadesInversion +=3, 195, yActividadesInversion);
                    }

                }

                // IMPORTE POR APLICAR EN ACTIVIDADES DE FINANCIAMIENTO
                let yTotalIPAEADF = yActividadesInversion += 13;
                let xTotalIPAEADF = 17 ;

                doc.setFontSize(12);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#FFF");
                doc.setFillColor(color);

                doc.rect(15, yTotalIPAEADF, width, 10, 'F');
                doc.text('IMPORTE POR APLICAR EN ACTIVIDADES DE FINANCIAMIENTO', xTotalIPAEADF, yTotalIPAEADF +=6, { align: 'left' });
                doc.text(formatoNumerico(this.importePorAplicar), 195, yTotalIPAEADF, { align: 'right' });


                // ACTIVIDADES DE FINANCIAMIENTO
                let yActividadesFinanciamiento = yTotalIPAEADF += 16;
                let xActividadesFinanciamineto = 17 ;

                doc.setFontSize(14);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#000");
                
                let totalActividadesFinanciamiento = this.sum(this.actividadesFinanciamiento, 'total')

                // TITULO ACTIVIDADES DE FINANCIAMIENTO
                doc.text('ACTIVIDADES DE FINANCIAMIENTO', 15, yActividadesFinanciamiento, { align: 'left' });
                doc.text(formatoNumerico(totalActividadesFinanciamiento), 195, yActividadesFinanciamiento, { align: 'right' });

                doc.setFillColor(color);
                doc.rect(15, yActividadesFinanciamiento +2, width, height, 'F');

                 // ACTIVIDADES DE FINANCIAMIENTO
                doc.setFontSize(10);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#FFF");
                
                // CABEZERA DE LA TABLA
                doc.text('CUENTA', xActividadesFinanciamineto, yActividadesFinanciamiento += 7, { align: 'left' });
                doc.text('TOTAL', xActividadesFinanciamineto + 100, yActividadesFinanciamiento, { align: 'center' });

                //RECORREMOS EL ARRAY DE LOS CONCEPTOS
                yActividadesFinanciamiento += 5;
                for (let c of this.actividadesFinanciamiento) {
                    //CLAVE DEL SAT
                    doc.setFont('helvetica', 'normal');
                    doc.setFontSize(11);
                    doc.setTextColor("#000");

                    let descripcion = c.cuenta
                    let importe = c.total

                    doc.text(descripcion, 15, yActividadesFinanciamiento += 7, { align: 'left' });
                    doc.setFontSize(12);

                    doc.text(formatoNumerico(importe), 195, yActividadesFinanciamiento, { align: 'right' });

                    //INSERTMOS EL SALTO DE PAGINA
                    let espacioDisponible = doc.internal.pageSize.height - 40;
                    let dif = yActividadesFinanciamiento > espacioDisponible

                    if (dif) {
                        doc.addPage();
                        yActividadesFinanciamiento = 10;
                    } else {
                        const color_ = "#662E91"
                        doc.setDrawColor(color_);
                        doc.setLineWidth(.1);
                        doc.line(15, yActividadesFinanciamiento +=3, 195, yActividadesFinanciamiento);
                    }

                }


                // VARIACIONES EN FLUJO
                let yTotalVariacionFlujo = yActividadesFinanciamiento += 13;
                let xTotalVariacionFlujo = 17 ;

                doc.setFontSize(12);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#FFF");
                doc.setFillColor(color);

                doc.rect(15, yTotalVariacionFlujo, width, 10, 'F');
                doc.text('VARIACIONES EN FLUJO', xTotalIPAEADF, yTotalVariacionFlujo +=6, { align: 'left' });
                doc.text(formatoNumerico(this.variacionFlujo), 195, yTotalVariacionFlujo, { align: 'right' });

                // EFECTIVO AL INICIO DEL EJERCICIO
                let yTotalEfectivoInicio = yTotalVariacionFlujo += 8;
                let xTotalEfectivoInicio = 17 ;

                doc.setFontSize(12);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#FFF");
                doc.setFillColor(color);

                doc.rect(15, yTotalEfectivoInicio, width, 10, 'F');
                doc.text('EFECTIVO AL INICIO DEL EJERCICIO', xTotalEfectivoInicio, yTotalEfectivoInicio +=6, { align: 'left' });
                doc.text(formatoNumerico(this.efectivoInicioEjercicio), 195, yTotalEfectivoInicio, { align: 'right' });

                // FLUJO DE EFECTIVO AL FINAL DEL PERIODO
                let yTotalEfectivoFinal = yTotalEfectivoInicio += 8;
                let xTotalEfectivoFinal = 17 ;

                doc.setFontSize(12);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor("#FFF");
                doc.setFillColor(color);

                doc.rect(15, yTotalEfectivoFinal, width, 10, 'F');
                doc.text('FLUJO DE EFECTIVO AL FINAL DEL PERIODO', xTotalEfectivoFinal, yTotalEfectivoFinal +=6, { align: 'left' });
                doc.text(formatoNumerico(this.flujoEfectivoFinalPeriodo), 195, yTotalEfectivoFinal, { align: 'right' });

                // // Guardar o mostrar el PDF
                doc.save('Flujo_de_Efectivo_' + this.empresa.rfc + '.pdf');

            }
        }
    }
</script>