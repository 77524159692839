<template>
    <div>
        <v-card flat>
            <v-card-title style="font: bold;" class="text-h4 text--primary">
                <!-- BOTON PARA IR A UNA VENTANA ANTERIOR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn large icon v-bind="attrs" v-on="on" color="primary" dark class="mx-2" link
                            :to="{ name: 'ListReportesEspeciales' }">
                            <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>
                    </template>
                    <span>Atrás</span>
                </v-tooltip>
                Depreciación
                <v-spacer></v-spacer>
                <!-- Fecha Inicial -->
                <v-col cols="12" sm="3" md="3">
                    <v-menu v-model="calendario" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="fechaI" label="Fecha de Aplicacion del Ejercicio"
                                prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="fechaI" @input="calendario = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                    <v-menu v-model="calendarioDep" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="fechaDep" label="Fecha Depreciación" prepend-icon="mdi-calendar"
                                readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="fechaDep" @input="calendarioDep = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <!--Fecha Final-->
                <!-- <v-col cols="12" sm="3" md="3">
                <v-menu v-model="calendario2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="fechaF" label="Fecha Final" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="fechaF" @input="calendario2 = false"></v-date-picker>
                </v-menu>
                </v-col> -->
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6" md="6">
                        <v-select label="Activo Fijo" :items="itemsActivoFijo" item-text="activo_fijo" return-object
                            v-model="selectActivoFijo"></v-select>
                        <span>{{selectActivoFijo.activo_fijo}}</span>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-select label="Activo Fijo" :items="itemsTipoAcivoFijo" item-text="tipo_activo_fijo"
                            return-object v-model="selectTipoActivoFijo"></v-select>
                        <span>{{selectTipoActivoFijo.tipo_activo_fijo}}</span>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field label="Descripción Activo Fijo" v-model="descripcion"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                        <v-menu v-model="calendarioAdqui" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="fechaAdqui" label="Fecha de Adquisición"
                                    prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="fechaAdqui" @input="calendarioAdqui = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                        <v-text-field label="Valor de la Adquisición" type="number" v-model="valorAdqui"
                            @keydown.enter="pushActivo"></v-text-field>
                        <span>{{functionFormatPrice(valorAdqui)}}</span>
                    </v-col>
                    <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2"
                                @click="pushActivo()">
                                <v-icon>mdi-tag-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Agrega Activo</span>
                    </v-tooltip>
                    <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2"
                                @click="calculaDep()">
                                <v-icon>mdi-calculator</v-icon>
                            </v-btn>
                        </template>
                        <span>Calcular</span>
                    </v-tooltip>
                    <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2"
                                @click="GuardarDepreciacion()">
                                <v-icon>mdi-content-save-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Guardar</span>
                    </v-tooltip>
                </v-row>
                <br>
                <h2>Reporte depreciación</h2>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-row>
                    <v-col cols="12">
                        <v-card-title>
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Filtrar" single-line
                                hide-details></v-text-field>
                        </v-card-title>
                        <v-data-table :items="listActivo" :headers="headersActivo" :search="search">
                            <!-- ICONOS PARA EDITAR Y BORRAR -->
                            <template v-slot:item.actions="{ item }">
                                <v-icon color="#e74747" @click="eliminarContacto(item)">mdi-delete</v-icon>
                            </template>
                            <!-- FORMATOS A LAS COLUMNAS -->
                            <template v-slot:item.inversion="{ item }">
                                <span>{{ functionFormatPrice(item.inversion) }}</span>
                            </template>
                            <template v-slot:item.tipoActivoFijo.porcentaje="{ item }">
                                <span>{{ functionFormatPrice(item.tipoActivoFijo.porcentaje) }}%</span>
                            </template>
                            <template v-slot:item.depreciacionAnual="{ item }">
                                <span>{{ functionFormatPrice(item.depreciacionAnual) }}</span>
                            </template>
                            <template v-slot:item.depreciacionMensual="{ item }">
                                <span>{{ functionFormatPrice(item.depreciacionMensual) }}</span>
                            </template>
                            <template v-slot:item.depreciacionEjercicio="{ item }">
                                <span>{{ functionFormatPrice(item.depreciacionEjercicio) }}</span>
                            </template>
                            <template v-slot:item.depreciacionAcumulada="{ item }">
                                <span>{{ functionFormatPrice(item.depreciacionAcumulada) }}</span>
                            </template>
                            <template v-slot:item.pendientesPorDepreciar="{ item }">
                                <span>{{ functionFormatPrice(item.pendientesPorDepreciar) }}</span>
                            </template>
                            <template v-slot:item.inpMesAdquisicion="{ item }">
                                <span>{{ functionFormatPrice(item.inpMesAdquisicion) }}</span>
                            </template>
                            <template v-slot:item.inpcUltimoMesPrimeraMitad="{ item }">
                                <span>{{ functionFormatPrice(item.inpcUltimoMesPrimeraMitad) }}</span>
                            </template>
                            <template v-slot:item.factorActualizacion="{ item }">
                                <span>{{ functionFormatPrice(item.factorActualizacion) }}</span>
                            </template>
                            <template v-slot:item.depreciacionFiscalEjercicio="{ item }">
                                <span>{{ functionFormatPrice(item.depreciacionFiscalEjercicio) }}</span>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
            </v-card-actions>
        </v-card>

        <!--Dialog loading-->
        <v-dialog v-model="loading" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    {{loadingMessage}}
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import print from 'print-js';
    import pdf from 'vue-pdf';

    export default {
        name: 'Depreciacion',
        components: {

        },

        data: () => ({
            //LOADING
            loading: false,
            loadingMessage: '',
            editedIndexContacto: -1,

            //FECHAS
            fechaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            fechaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            calendario: false,
            calendario2: false,

            itemsActivoFijo: [],
            selectActivoFijo: {},
            itemsTipoAcivoFijoPre: [],
            selectTipoActivoFijo: {},

            //FECHA ADQUICISION
            descripcion: '',
            fechaAdqui: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            calendarioAdqui: false,
            fechaDep: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            calendarioDep: false,
            valorAdqui: 0,

            //OBJETO DEL ACTIVO
            activo: {
                fechaAplicacion: '',
                folio: '',
                id: '',
                concepto: '',
                fechaAdquisicion: '',
                inversion: '',
                porcentajeDepreciacion: '',
                depreciacionAnual: '',
                depreciacionMensual: '',
                mesesDeVidaUtil: '',
                mesesDepAl: '',
                mesePendientesDeDepreciar: '',
                mesesDepreciarEnEjercicio: '',
                depreciacionEjercicio: '',
                depreciacionAcumulada: '',
                pendientesPorDepreciar: '',
                inpMesAdquisicion: '',
                inpcUltimoMesPrimeraMitad: '',
                factorActualizacion: '',
                depreciacionFiscalEjercicio: '',
                descripcionAF: '',
                fechaDepreciacion: '',
            },
            activoDefault: {
                fechaAplicacion: '',
                folio: '',
                id: '',
                concepto: '',
                fechaAdquisicion: '',
                inversion: '',
                porcentajeDepreciacion: '',
                depreciacionAnual: '',
                depreciacionMensual: '',
                mesesDeVidaUtil: '',
                mesesDepAl: '',
                mesePendientesDeDepreciar: '',
                mesesDepreciarEnEjercicio: '',
                depreciacionEjercicio: '',
                depreciacionAcumulada: '',
                pendientesPorDepreciar: '',
                inpMesAdquisicion: '',
                inpcUltimoMesPrimeraMitad: '',
                factorActualizacion: '',
                depreciacionFiscalEjercicio: '',
                descripcionAF: '',
                fechaDepreciacion: '',
            },
            listActivo: [],
            headersActivo: [
                { text: 'Acciones', sortable: false, value: 'actions' },
                { text: "Descripción del Activo Fijo", value: "descripcionAF" },
                { text: "Concepto", value: "tipoActivoFijo.tipo_activo_fijo" },
                { text: "Fecha Adquisición", value: "fechaAdquisicion" },
                { text: "Inversión", value: "inversion" },
                { text: "Porcentaje de Depreciación", value: "tipoActivoFijo.porcentaje" },
                { text: "Depreciación Anual", value: "depreciacionAnual", align: 'right' },
                { text: "Depreciación Mensual", value: "depreciacionMensual", align: 'right' },
                { text: "Meses de vida útil", value: "mesesDeVidaUtil", align: 'right' },
                { text: "Meses Depreciación a la Fecha", value: "mesesDepAl", align: 'right' },
                { text: "Meses Pendientes de Depreciar", value: "mesePendientesDeDepreciar", align: 'right' },
                { text: "Meses por Depreciar en el Ejercicio", value: "mesesDepreciarEnEjercicio" },
                { text: "Depreciación del Ejercicio", value: "depreciacionEjercicio" },
                { text: "Depreciación acumulada", value: "depreciacionAcumulada" },
                { text: "Pendiente por Depreciar en el Ejercicio", value: "pendientesPorDepreciar" },
                { text: "INPC Mes de Adquisición", value: "inpMesAdquisicion" },
                { text: "INPC Último Mes 1ra Mitad", value: "inpcUltimoMesPrimeraMitad" },
                { text: "F.A.", value: "factorActualizacion" },
                { text: "Dep. Fiscal del Ejercicio", value: "depreciacionFiscalEjercicio" },
            ],

            listInpc: [],

            search: '',
        }),

        watch: {

        },

        computed: {
            empresa() {
                return this.$store.state.usuario
            },
            itemsTipoAcivoFijo() {
                this.selectTipoActivoFijo = Object.assign({})
                var lista = []
                lista = this.itemsTipoAcivoFijoPre.filter(x => x.id_activo_fijo === this.selectActivoFijo.id_activo_fijo)
                return lista;
            },
        },

        created() {
            this.GetActivosFijos();
            this.GetInpc();
            this.GetActivos();
        },

        methods: {
            functionFormatPrice(value) {
                let val = (value / 1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            async GetActivosFijos() {
                console.time();
                this.loading = true;
                this.loadingMessage = 'Consultando...';
                try {
                    let respuesta = await axios.get('reporteAutoConta/activosFijos/' + this.empresa.empresaBase)
                    this.itemsActivoFijo = respuesta.data.reporteActivoF_;
                    this.itemsTipoAcivoFijoPre = respuesta.data.reporteTipoActivo_;
                    // console.timeEnd();
                    this.loading = false;
                    // console.log(this.itemsActivoFijo)
                    // console.log(this.itemsTipoAcivoFijoPre)
                } catch (error) {
                    console.log(error.data)
                    console.timeEnd();
                    this.loading = false;
                }
            },
            async GetInpc() {
                this.loading = true;
                this.loadingMessage = 'Consultando...';
                let base = 'catalogos_sat';

                try {
                    let respuesta = await axios.get('reporteAutoConta/GetInpc/' + base)
                    this.listInpc = respuesta.data;
                    this.loading = false;
                    // console.log(this.itemsTipoAcivoFijoPre)
                } catch (error) {
                    console.log(error.data)
                    console.timeEnd();
                    this.loading = false;
                }
            },

            async GetActivos() {
                console.time();
                this.loading = true;
                this.loadingMessage = 'Consultando...';
                try {
                    let respuesta = await axios.get('reporteAutoConta/GetDepreciacion/' + this.empresa.empresaBase + '/Todos')
                    this.listActivo = respuesta.data
                } catch (error) {
                    console.log(error.data)
                    console.timeEnd();
                    this.loading = false;
                }
            },

            pushActivo() {
                this.activo.folio = '0';

                //TIPO DE ACTIVO FIIJO
                this.activo.activoFijo = Object.assign({}, this.selectActivoFijo)
                this.activo.tipoActivoFijo = Object.assign({}, this.selectTipoActivoFijo)
                this.activo.descripcionAF = this.descripcion
                this.activo.fechaAdquisicion = this.fechaAdqui
                this.activo.fechaAplicacion = this.fechaI;
                this.activo.inversion = this.valorAdqui
                this.activo.fechaDepreciacion = this.fechaDep
                // this.activo.id = this.selectTipoActivoFijo.id
                // this.activo.concepto = this.selectTipoActivoFijo.tipo_activo_fijo
                // this.activo.porcentajeDepreciacion = this.selectTipoActivoFijo.porcentaje

                this.activo.depreciacionAnual = '0'
                this.activo.depreciacionMensual = '0'
                this.activo.mesesDeVidaUtil = '0'
                this.activo.mesesDepAl = '0'
                this.activo.mesePendientesDeDepreciar = '0'
                this.activo.mesesDepreciarEnEjercicio = '0'
                this.activo.depreciacionEjercicio = '0'
                this.activo.depreciacionAcumulada = '0'
                this.activo.pendientesPorDepreciar = '0'
                this.activo.inpMesAdquisicion = '0'
                this.activo.inpcUltimoMesPrimeraMitad = '0'
                this.activo.factorActualizacion = '0'
                this.activo.depreciacionFiscalEjercicio = '0'

                this.listActivo.push(this.activo);
                // console.log(this.activo)
                this.activo = Object.assign({}, this.activoDefault)
            },
            calculaDep() {
                try {
                    for (var i of this.listActivo) {
                        let depAnual = i.inversion * (i.tipoActivoFijo.porcentaje / 100)
                        let depMensual = depAnual / 12
                        let mesesVidaUtil = (1 / (i.tipoActivoFijo.porcentaje / 100)) * 12

                        //CONVERTIR LAS FECHAS
                        let fechaA = this.fechaI.split('-')
                        let fechaB = i.fechaAdquisicion.split('-')
                        let fechaC = this.fechaDep.split('-')

                        let fechaAplicacion = new Date(fechaA[0], fechaA[1], fechaA[2])
                        let fechaAdquicicion = new Date(fechaB[0], fechaB[1], fechaB[2])
                        let fechaDepreciacion = new Date(fechaC[0], fechaC[1], fechaC[2])

                        var diferencia = this.monthDiff(fechaAdquicicion, fechaDepreciacion);

                        var mesesDep = mesesVidaUtil
                        if (diferencia < mesesVidaUtil) {
                            mesesDep = diferencia
                        }

                        let pendientes = mesesVidaUtil - mesesDep

                        let mesesDepEjercicio = 0
                        if (pendientes >= 12) {
                            mesesDepEjercicio = 12
                        }
                        else if (pendientes < 12) {
                            mesesDepEjercicio = pendientes
                        } else if (pendientes <= 0) {
                            mesesDepEjercicio = 0
                        }
                        

                        let depreciacionDelEjercicio = 0
                        depreciacionDelEjercicio = depMensual * mesesDepEjercicio

                        let depreciciacionAcumulada = 0
                        depreciciacionAcumulada = depMensual * mesesDep + depreciacionDelEjercicio

                        let pendientePorDpreciar = 0
                        pendientePorDpreciar = i.inversion - depreciciacionAcumulada
                        
                        //OBTENEMOS INPC DEPENDIENDO DEL AÑO Y MES DE ADQUIICICION
                        let ObjInpc = this.listInpc.find(x => x.anio == fechaB[0])
                        let ObjMes = this.getLongMonthName(fechaAdquicicion);
                        var inpcMes = ObjInpc[ObjMes];
                        
                        //INPC ULTIMO MES PRIMERA MITAD
                        let fechaInicial = this.fechaI;
                        var dayFechaInicial = fechaInicial.split('-');
                        let mitadFecha = dayFechaInicial[1] / 2
                        let fechaNueva_ = new Date(dayFechaInicial[0], mitadFecha - 1, 1);
                        let ObjMitadFecha = this.listInpc.find(x => x.anio == dayFechaInicial[0])
                        let ObjtMesMitadFecha = this.getLongMonthName(fechaNueva_);
                        let inpcMitadFecha = ObjMitadFecha[ObjtMesMitadFecha]

                        //FACTOR DE ACTUALIZACION
                        let factorActualizacion = 0;
                        factorActualizacion = inpcMitadFecha / inpcMes;

                        //DEPRESIACION FISCAL DEL EJERCICIO
                        let depreciacionFiscalEjercicio = 0;
                        depreciacionFiscalEjercicio = depreciacionDelEjercicio * factorActualizacion

                        i.depreciacionAnual = Math.round(depAnual * 100) / 100
                        i.depreciacionMensual = Math.round(depMensual * 100) / 100
                        i.mesesDeVidaUtil = Math.round(mesesVidaUtil * 100) / 100
                        i.mesesDepAl = Math.round(mesesDep * 100) / 100
                        i.mesePendientesDeDepreciar = Math.round(pendientes * 100) / 100
                        i.mesesDepreciarEnEjercicio = Math.round(mesesDepEjercicio * 100) / 100
                        i.depreciacionEjercicio = Math.round(depreciacionDelEjercicio * 100) / 100
                        i.depreciacionAcumulada = Math.round(depreciciacionAcumulada * 100) / 100
                        i.pendientesPorDepreciar = Math.round(pendientePorDpreciar * 100) / 100
                        i.inpMesAdquisicion = Math.round(inpcMes * 1000000) / 1000000
                        i.inpcUltimoMesPrimeraMitad = Math.round(inpcMitadFecha * 1000000) / 1000000
                        i.factorActualizacion = Math.round(factorActualizacion * 10000) / 10000
                        i.depreciacionFiscalEjercicio = Math.round(depreciacionFiscalEjercicio * 100) / 100
                        // this.listActivo[i].depreciacionAnual = Math.round(depAnual * 100) / 100
                    }
                    
                } catch (error) {
                    console.log(error)
                }
            },
            eliminarContacto(item) {
                let index = this.listActivo.indexOf(item)
                this.listActivo.splice(index, 1)
            },
            monthDiff(d1, d2) {
                var months;
                months = (d2.getFullYear() - d1.getFullYear()) * 12;
                months -= d1.getMonth();
                months += d2.getMonth();
                return months <= 0 ? 0 : months;
            },
            getLongMonthName(date) {

                const monthNames = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
                return monthNames[date.getMonth()];
            },

            async GuardarDepreciacion() {
                console.time();
                this.loading = true;
                this.loadingMessage = 'Consultando...';
                try {
                    let respuesta = await axios.post('reporteAutoConta/PostDepreciacion/' + this.empresa.empresaBase, this.listActivo)
                    console.log(respuesta.data)
                    console.timeEnd();
                    this.loading = false;
                } catch (error) {
                    console.log(error.data)
                    console.timeEnd();
                    this.loading = false;
                }
            },
        }
    }
</script>