import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'

Vue.config.productionTip = false
  //axios.defaults.baseURL="http://localhost:63818/api/"
  axios.defaults.baseURL="https://api-auto-conta.contago.com.mx/api/"

new Vue({
  router,
  store,
  vuetify, 
  render: h => h(App)
}).$mount('#app')
   