<template>
    <div>
        <v-card>
            <v-toolbar>
                <!-- BOTON CERRAR -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary darken-1" dark fab small @click="closeDialog()"
                            v-bind="attrs" v-on="on">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span>Cerrar</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <!-- BOTON ANTERIOR -->
                <template v-if="poliza.id != 0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon color="primary darken-1"  fab small @click="polizaAnterior()" v-bind="attrs" v-on="on">
                                <v-icon>mdi-arrow-left</v-icon>
                            </v-btn>
                        </template>
                        <span>Anterior</span>
                    </v-tooltip>
                </template>
                <b style="font-size: 30px;">Póliza</b>
                <!-- BOTON SIGUIENTE -->
                <template v-if="poliza.id != 0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon color="primary darken-1" dark fab small @click="polizaSiguiente()"
                                v-bind="attrs" v-on="on">
                                <v-icon>mdi-arrow-right</v-icon>
                            </v-btn>
                        </template>
                        <span>Siguiente</span>
                    </v-tooltip>
                </template>
                <v-spacer></v-spacer>
                <!-- BOTON AGREGAR -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary darken-1" dark fab small @click="save()"
                            v-bind="attrs" v-on="on">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Guardar</span>
                </v-tooltip>
            </v-toolbar>
            <v-system-bar color="primary" height="6"></v-system-bar>

            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="12" md="4">
                        <v-select label="Tipo de póliza" v-model="poliza.tipoPoliza" :items="itemsTipoPoliza"></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                        <v-text-field label="No. Póliza" v-model="poliza.numeroPoliza"></v-text-field>
                    </v-col>
                    <!--FECHA-->
                    <v-col cols="12" sm="12" md="4">
                        <v-menu v-model="calendario" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                            offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="poliza.fecha" label="Fecha Póliza" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="poliza.fecha" @input="calendario = false"></v-date-picker>
                        </v-menu>
                    </v-col>

                    <v-col cols="12">
                        <v-text-field label="Descripción de la póliza" v-model="poliza.descripcion"></v-text-field>
                    </v-col>
                    <template>
                        <v-col cols="12" md="6" sm="12">
                            <v-autocomplete label="Número de cuenta"
                            v-model="asiento" :items="$store.state.listCatalogo" :loading="loadingAsiento" :search-input.sync="searchAsiento" item-text="cuentaContable" append-outer-icon="mdi-reload" @click:append-outer="getCatalogo()" return-object ref="_cuenta" @keydown.enter="focoCargo()">
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <v-text-field label="Cargo" v-model="asiento.cargo" append-outer-icon="mdi-plus-circle" @click:append-outer="pushCargo()" @keydown.enter="pushCargo()" type="number" ref="_cargo"></v-text-field>
                        <span>{{ functionFormatPrice(asiento.cargo) }}</span>

                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <v-text-field label="Abono" v-model="asiento.abono" append-outer-icon="mdi-plus-circle" @click:append-outer="pushAbono()" @keydown.enter="pushAbono()" type="number" ref="_abono"></v-text-field>
                        <span>{{ functionFormatPrice(asiento.abono) }}</span>

                        </v-col>
                    </template>
                </v-row>
                <!-- TABLA CON LAS DESCRIPCIONES DE LAS POLIZAS -->
                <v-data-table :headers="headersPolizas" :items="poliza.detalles" class="elevation-1" hide-default-footer :items-per-page="-1">
                    <template v-slot:item.actions="{ item }">
                        <v-icon color="orange darken-2" dense class="mr-2" @click="editAsiento(item)">mdi-pencil</v-icon>
                        <v-icon color="red darken-2" dense class="mr-2" @click="deleteAsiento(item)">mdi-delete</v-icon>
                    </template>
                    <!-- FORMATO CON DECIMALES DEL TOTAL -->
                    <template v-slot:item.cargo="{ item }">
                        <span>{{ functionFormatPrice(item.cargo) }}</span>
                    </template>
                    <!-- FORMATO CON DECIMALES DEL TOTAL -->
                    <template v-slot:item.abono="{ item }">
                        <span>{{ functionFormatPrice(item.abono) }}</span>
                    </template>
                </v-data-table>
                <br>
                <span>XML'S</span>
                <v-system-bar color="primary" height="6"></v-system-bar>

                <!-- TABLA CON LA LISTA DE LOS XML -->
                <v-data-table :headers="headersXmls" :items="poliza.xmls" class="elevation-1" hide-default-footer :items-per-page="-1">
                    <!-- FORMATO CON DECIMALES DEL TOTAL -->
                    <template v-slot:item.subTotal="{ item }">
                        <span>{{ functionFormatPrice(item.subTotal) }}</span>
                    </template>
                    <!-- FORMATO CON DECIMALES DEL TOTAL -->
                    <template v-slot:item.total="{ item }">
                        <span>{{ functionFormatPrice(item.total) }}</span>
                    </template>

                    <template v-slot:item.formaPago="{ item }">
                        <span>{{ evaluaFormaPago(item.formaPago) }}</span>
                    </template>
                </v-data-table>
                <!-- FOOTER CON LOS TOTALES -->
                <v-footer fixed>
                    <v-row class="text-center">
                        <v-col>
                            <h1>Total Cargo</h1>
                        </v-col>
                        <v-col>
                            <h1>{{ functionFormatPrice(totalCargo) }}</h1>
                        </v-col>
                        <v-col>
                            <h1>Total Abono</h1>
                        </v-col>
                        <v-col>
                            <h1>{{ functionFormatPrice(totalAbono) }}</h1>
                        </v-col>
                    </v-row>
                  </v-footer>
            </v-card-text>
        </v-card>

        <!--Dialog loading-->
        <v-dialog v-model="loading" hide-overlay persistent width="300">
            <v-card color="primary" dark>
            <v-card-text>
                {{loadingMessage}}
                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
            </v-card>
        </v-dialog>
        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import print from 'print-js';
    import pdf from 'vue-pdf';
    import { VueEditor } from "vue2-editor";
    import Vue from 'vue'
    import VueCookies from 'vue-cookies'

    
    Vue.use(VueCookies)

    export default {
        name: 'Poliza',
        components: {

        },

        data: () => ({
            headersPolizas:[
                { text: 'Acciones', value: 'actions' },
                { text: "No. Cuenta", value: "numeroCuenta" },
                { text: "Nombre cuenta", value: "nombreCuenta" },
                { text: "Cargo", value: "cargo", align: 'right' },
                { text: "Abono", value: "abono", align: 'right' },
            ],
            headersXmls:[
                { text: "Tipo", value: "tipoDeComprobante" },
                { text: "Serie", value: "serie" },
                { text: "Folio", value: "folio" },
                { text: "Fecha", value: "fecha" },
                { text: "Fecha Pago", value: "fechaPago" },
                { text: "Folio Fiscal", value: "folioFiscal" },
                { text: "Moneda", value: "moneda" },
                { text: "SubTotal", value: "subTotal", align: 'right' },
                { text: "Total", value: "total", align: 'right' },
                { text: "RFc Emisor", value: "rfcEmisor" },
                { text: "Nombre Emisor", value: "nombreEmisor" },
                { text: "RFC Receptor", value: "rfcReceptor" },
                { text: "Nombre Receptor", value: "nombreReceptor" },
                { text: "Método de Pago", value: "metodoPago" },
                { text: "Forma de Pago", value: "formaPago" },
            ],
            itemsTipoPoliza:['Egreso', 'Ingreso', 'Diario'],

            //LOADING
            loadingMessage: '',
            loading: false,

            //FECHAS
            calendario: false,

            indexAsiento: -1,
            asiento:{
                id: 0,
                numeroCuenta: '',
                numeroCuentaUnico :'',
                nombreCuenta: '',
                cuentaContable: '',
                cargo: 0,
                abono: 0,
            },
            asientoDefault:{
                id: 0,
                numeroCuenta: '',
                numeroCuentaUnico :'',
                nombreCuenta: '',
                cuentaContable: '',
                cargo: 0,
                abono: 0,
            },
            searchAsiento: null,
            loadingAsiento: null,
            limitAsiento: 60,

            //SNACKBAR
            snackbarMessage:'',
            snackbar: false,
        }),

        watch: {
            async searchAsiento(val){
                if (this.itemsAsiento.length > 0) return
                if (this.loadingAsiento) return
                this.loadingAsiento = true
                try {
                    let response = await axios.get('ReportesGenerales/GetCatalogo/auto_conta_web')
                    this.$store.state.listCatalogo = response.data;
                } catch (error) {
                    console.log(error)
                }
                this.loadingAsiento = false
            },
        },

        computed: {
            poliza(){
                return this.$store.state.poliza;
            },
            fecha(value){
                let fecha = moment(this.poliza.fecha).format('yyyy-MM-DD')
                return fecha;
            },
            totalCargo(){
                const sumall = this.poliza.detalles.map(item => Number(item.cargo)).reduce((prev, curr) => prev + curr, 0);
                return sumall;
            },
            totalAbono(){
                const sumall = this.poliza.detalles.map(item => Number(item.abono)).reduce((prev, curr) => prev + curr, 0);
                return sumall;
            },
            itemsAsiento(){
                return this.$store.state.listCatalogo.map(entry => {
                    const Description = entry.cuentaContable.length > this.limitAsiento
                        ? entry.cuentaContable.slice(0, this.limitAsiento) + '...'
                        : entry.cuentaContable

                    return Object.assign({}, entry, { Description })
                })
            },
            empresa(){
                return this.$store.state.usuario
            },
        
        },

        created() {
            this.getCatalogo();
        },

        methods: {
            functionFormatPrice(value) {
                let val = (value / 1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            async getCatalogo(){
                this.loading = true
                this.loadingMessage = 'Consultando catálogo...'
                try {
                    let response = await axios.get('ReportesGenerales/GetCatalogo/'+this.empresa.empresaBase)
                    this.$store.state.listCatalogo = response.data;
                    this.loading = false
                } catch (error) {
                    console.log(error)
                    this.loading = false
                }
            },
            pushCargo(){
                let cargo = Number(this.asiento.cargo);
                let abono = Number(this.asiento.abono);

                if(cargo == 0){
                    this.focoAbono();
                    return;
                }

                if(this.asiento.cuentaContable === ''  ||  !this.asiento.cuentaContable){
                    this.snackbarMessage = 'Seleccione una cuenta del catálogo'
                    this.snackbar=true;
                    return;
                }

                if(cargo <= 0){
                    this.snackbarMessage = 'Indique un importe mayor a 0.00'
                    this.snackbar=true;
                    return
                }
                this.asiento.abono = 0;

                if (this.indexAsiento > -1) {
                    Object.assign(this.poliza.detalles[this.indexAsiento], this.asiento)
                }else{
                    this.poliza.detalles.push(this.asiento);
                }
                this.asiento = Object.assign({}, this.asientoDefault);
                this.$refs._cuenta.focus();
                this.indexAsiento = -1;
            },
            pushAbono(){
                if(this.asiento.cuentaContable === ''  ||  !this.asiento.cuentaContable){
                    this.snackbarMessage = 'Seleccione una cuenta del catálogo'
                    this.snackbar=true;
                    return;
                }

                let cargo = Number(this.asiento.cargo);
                let abono = Number(this.asiento.abono);
                if(abono <= 0){
                    this.snackbarMessage = 'Indique un importe mayor a 0.00'
                    this.snackbar=true;
                    return
                }
                this.asiento.cargo = 0;

                if (this.indexAsiento > -1) {
                    Object.assign(this.poliza.detalles[this.indexAsiento], this.asiento)
                }else{
                    this.poliza.detalles.push(this.asiento);
                }

                this.asiento = Object.assign({}, this.asientoDefault);
                this.$refs._cuenta.focus();
                this.indexAsiento = -1;
            },
            deleteAsiento(item){
                console.log(item)
                let indice = this.poliza.detalles.indexOf(item)
                this.poliza.detalles.splice(indice, 1)
            },
            editAsiento(item){
                this.indexAsiento = this.poliza.detalles.indexOf(item)
                this.asiento = Object.assign( {}, item )
            },
            // EVENTOS FOCUS
            focoCargo(){
                this.$refs._cargo.focus();
            },
            focoAbono(){
                this.$refs._abono.focus();
            },
            
            //GUARDADO
            save(){
                if(this.poliza.id == 0){
                    // console.log('Nueva')
                    this.PostPoliza();
                }
                else{
                    // console.log('Editar')
                    this.PutPoliza();
                }
            },
            async PostPoliza(){
                //VAMOS A VALIDAR
                if(this.totalCargo + this.totalAbono  == 0){
                    this.snackbar = true;
                    this.snackbarMessage = 'Agregue asientos a la póliza';
                    return;
                }

                // if(this.totalCargo != this.totalAbono){
                //     this.snackbar = true;
                //     this.snackbarMessage = 'La póliza esta descuadrada';
                //     return;
                // }

                let poliza = Object.assign({}, this.poliza)
                poliza.total = this.totalCargo;
                this.loading = true
                this.loadingMessage = 'Guardando Póliza...'
                try {
                    let response = await axios.post('GetPolizas/PostPolizaAsync/'+this.empresa.empresaBase, poliza)
                    console.log(response.data);
                    this.GetPolizas(this.$store.state.fechasPoliza.fechaI, this.$store.state.fechasPoliza.fechaF)
                } catch (error) {
                    console.log(error)
                    this.loading = false
                }
            },
            async PutPoliza(){
                //VAMOS A VALIDAR
                if(this.totalCargo + this.totalAbono  == 0){
                    this.snackbar = true;
                    this.snackbarMessage = 'Agregue asientos a la póliza';
                    return;
                }

                // if(this.totalCargo != this.totalAbono){
                //     this.snackbar = true;
                //     this.snackbarMessage = 'La póliza esta descuadrada';
                //     return;
                // }

                let poliza = Object.assign({}, this.poliza)
                poliza.total = this.totalCargo;
                this.loading = true
                this.loadingMessage = 'Guardando Póliza...'
                try {
                    let response = await axios.put('GetPolizas/PutPolizaAsync/'+this.empresa.empresaBase, poliza)
                    console.log(response.data);
                    this.GetPolizas(this.$store.state.fechasPoliza.fechaI, this.$store.state.fechasPoliza.fechaF)
                } catch (error) {
                    console.log(error)
                    this.loading = false
                }
            },

            closeDialog(){
                this.$emit("cierraDialog")
            },

            async GetPolizas(fechaI, fechaF){
                this.loadingMessage = 'Buscando pólizas...'
                this.loading = true;
                try {
                    let response = await axios.get('GetPolizas/GetPolizasAsync/'+this.empresa.empresaBase+'/' + fechaI + '/' + fechaF);
                    this.$store.state.listPoliza = response.data;
                    console.log(response.data)
                    this.loading = false;
                    this.closeDialog();
                } catch (error) {   
                    console.log(error);
                    this.loading = false;
                }
            },
            
            //RECORRE LAS POLIZAS
            polizaAnterior(){
                let indice = this.$store.state.indexPolizas -1
                if(indice == -1){
                    indice = this.$store.state.listPoliza.length-1
                }
                this.$store.state.indexPolizas = indice
                this.$store.state.poliza = Object.assign({}, this.$store.state.listPoliza[indice])
            },
            polizaSiguiente(){
                let indice = this.$store.state.indexPolizas +1
                if(indice > this.$store.state.listPoliza.length-1){
                    indice = 0
                }
                this.$store.state.indexPolizas = indice
                this.$store.state.poliza = Object.assign({}, this.$store.state.listPoliza[indice])
            },
            evaluaFormaPago(value){
                let itemsFP = [
                    { value: '01', descripcion:'01 | Efectivo' },
                    { value: '02', descripcion:'02 | Cheque nominativo' },
                    { value: '03', descripcion:'03 | Transferencia electrónica de fondos' },
                    { value: '04', descripcion:'04 | Tarjeta de crédito' },
                    { value: '05', descripcion:'05 | Monedero electrónico' },
                    { value: '06', descripcion:'06 | Dinero electrónico' },
                    { value: '08', descripcion:'08 | Vales de despensa' },
                    { value: '12', descripcion:'12 | Dación en pago' },
                    { value: '13', descripcion:'13 | Pago por subrogación' },
                    { value: '14', descripcion:'14 | Pago por consignación' },
                    { value: '15', descripcion:'15 | Condonación' },
                    { value: '17', descripcion:'17 | Compensación' },
                    { value: '23', descripcion:'23 | Novación' },
                    { value: '24', descripcion:'24 | Confusión' },
                    { value: '25', descripcion:'25 | Remisión de deuda' },
                    { value: '26', descripcion:'26 | Prescripción o caducidad' },
                    { value: '27', descripcion:'27 | A satisfacción del acreedor' },
                    { value: '28', descripcion:'28 | Tarjeta de débito' },
                    { value: '29', descripcion:'29 | Tarjeta de servicios' },
                    { value: '30', descripcion:'30 | Aplicación de anticipos' },
                    { value: '31', descripcion:'31 | Intermediario pagos' },
                    { value: '99', descripcion:'99 | Por definir' },
                    { value: '', descripcion:'' },

                ]
                let result = itemsFP.find(x => x.value === value);
                return result.descripcion;
            },
            
        },
    }
</script>