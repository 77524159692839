var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',{staticClass:"text-h4 text--primary",staticStyle:{"font":"bold"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"large":"","icon":"","color":"primary","dark":"","link":"","to":{ name: 'Home' }},on:{"click":function($event){return _vm.nuevaPoliza()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)]}}])},[_c('span',[_vm._v("Atrás")])]),_vm._v(" Bancos "),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('v-select',{attrs:{"label":"Tipo de movimiento","items":_vm.itemsTipoMovimiento,"item-text":"tipo","item-value":"valor"},model:{value:(_vm.selectTipoMovimiento),callback:function ($$v) {_vm.selectTipoMovimiento=$$v},expression:"selectTipoMovimiento"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha Inicial","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fechaI),callback:function ($$v) {_vm.fechaI=$$v},expression:"fechaI"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.calendario),callback:function ($$v) {_vm.calendario=$$v},expression:"calendario"}},[_c('v-date-picker',{on:{"input":function($event){_vm.calendario = false, _vm.cambiaFechaFinal()}},model:{value:(_vm.fechaI),callback:function ($$v) {_vm.fechaI=$$v},expression:"fechaI"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha Final","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fechaF),callback:function ($$v) {_vm.fechaF=$$v},expression:"fechaF"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.calendario2),callback:function ($$v) {_vm.calendario2=$$v},expression:"calendario2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.calendario2 = false}},model:{value:(_vm.fechaF),callback:function ($$v) {_vm.fechaF=$$v},expression:"fechaF"}})],1)],1),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"icon":"","color":"primary","dark":""},on:{"click":function($event){return _vm.GetMovBancos()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}])},[_c('span',[_vm._v("Consultar")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"icon":"","color":"primary","dark":""},on:{"click":function($event){return _vm.abreDialogConciliar()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-swap-horizontal")])],1)]}}])},[_c('span',[_vm._v("Reasignar Movimientos")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"icon":"","color":"primary","dark":""},on:{"click":function($event){return _vm.limpiar()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-broom")])],1)]}}])},[_c('span',[_vm._v("Limpiar")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"icon":"","color":"primary","dark":""},on:{"click":function($event){return _vm.abreDialogNuevaCuenta()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-book-plus")])],1)]}}])},[_c('span',[_vm._v("Crear Cuenta de Bancos")])])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.itemsMovBancos,"headers":_vm.headersMovBancos,"show-select":"","item-key":"id_poliza","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.total)))])]}}]),model:{value:(_vm.selectMovBancos),callback:function ($$v) {_vm.selectMovBancos=$$v},expression:"selectMovBancos"}})],1)],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.loadingMessage)+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1),_c('v-dialog',{model:{value:(_vm.dialogConciliar),callback:function ($$v) {_vm.dialogConciliar=$$v},expression:"dialogConciliar"}},[_c('v-card',[_c('v-toolbar',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary darken-1","dark":"","fab":"","small":""},on:{"click":function($event){_vm.dialogConciliar = false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Cerrar")])]),_c('v-spacer'),_c('b',{staticStyle:{"font-size":"30px"}},[_vm._v("Asignar")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary darken-1","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.putMovBancos()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-check")])],1)]}}])},[_c('span',[_vm._v("Confirmar")])])],1),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}}),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Seleccione la cuenta que se va a asignar","items":_vm.listCatalogo,"return-object":"","item-text":"cuenta"},model:{value:(_vm.cuentaCatalogo),callback:function ($$v) {_vm.cuentaCatalogo=$$v},expression:"cuentaCatalogo"}})],1)],1)],1)],1)],1),_c('v-dialog',{model:{value:(_vm.dialogNuevaCuenta),callback:function ($$v) {_vm.dialogNuevaCuenta=$$v},expression:"dialogNuevaCuenta"}},[_c('v-card',[_c('v-toolbar',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary darken-1","dark":"","fab":"","small":""},on:{"click":function($event){_vm.dialogNuevaCuenta = false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Cerrar")])]),_c('v-spacer'),_c('b',{staticStyle:{"font-size":"30px"}},[_vm._v("Nueva Cuenta")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary darken-1","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.postCuenta()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-check")])],1)]}}])},[_c('span',[_vm._v("Confirmar")])])],1),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}}),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.itemsBanco,"label":"Seleccione un Banco"},model:{value:(_vm.nuevaCuenta.banco),callback:function ($$v) {_vm.$set(_vm.nuevaCuenta, "banco", $$v)},expression:"nuevaCuenta.banco"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Número de Cuenta"},model:{value:(_vm.nuevaCuenta.cuenta),callback:function ($$v) {_vm.$set(_vm.nuevaCuenta, "cuenta", $$v)},expression:"nuevaCuenta.cuenta"}})],1)],1)],1)],1)],1),_c('v-dialog',{model:{value:(_vm.dialogComprobantes),callback:function ($$v) {_vm.dialogComprobantes=$$v},expression:"dialogComprobantes"}},[_c('v-card',[_c('v-toolbar',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary darken-1","dark":"","fab":"","small":""},on:{"click":function($event){_vm.dialogComprobantes = false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Cerrar")])]),_c('v-spacer'),_c('b',{staticStyle:{"font-size":"30px"}},[_vm._v("Comprobantes")]),_c('v-spacer')],1),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}}),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.itemsComprobantes,"headers":_vm.headersComprobantes,"show-expand":"","item-key":"folioFiscal"},scopedSlots:_vm._u([{key:"item.subTotal",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.subTotal)))])]}},{key:"item.descuento",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.descuento)))])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.total)))])]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatDate(item.fecha)))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('h1',[_vm._v("Conceptos")]),_c('v-card',{attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"headers":_vm.headersConceptos,"items":item.detalles},scopedSlots:_vm._u([{key:"item.valorUnitario",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.valorUnitario)))])]}},{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.importe)))])]}}],null,true)})],1)],1)]}}])})],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":10000,"color":"primary darken-2","elevation":"30"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(" mdi-close-circle ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }