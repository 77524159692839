<template>
  <v-app>
    <v-app-bar app color="primary" dark v-if="logueado">
      <v-btn fab dark color="primary" link :to="{ name: 'Home' }" @click="$store.state.nombreComponente = 'Inicio'">
        <v-icon dark>mdi-home</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      {{obtieneUsuario}} | {{obtieneEmpresa}}
      <v-spacer></v-spacer>
      <v-btn icon :to="{ name: 'Configuracion' }"  @click="Configuracion()">
          <v-icon>mdi-cog</v-icon>
        </v-btn>
        <v-btn icon @click="salir">
          <v-icon>mdi-account-arrow-left-outline</v-icon>
        </v-btn>
    </v-app-bar>
    <v-main>
      <v-fade-transition mode="out-in">
        <router-view/>
      </v-fade-transition>  
    </v-main>
    <!-- DIALOG QUE CIERRA SESION  -->
    <v-dialog v-model="dialogCerrar" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cerrando sesión espere...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
 import axios from 'axios'
export default {
  name: 'App',

  data: () => ({
    //
    dialogCerrar: false,
  }),
computed: {
    logueado() {
      return this.$store.state.usuario;
    },
    obtieneEmpresa() {
      return this.$store.state.usuario.empresa;
    },
    obtieneUsuario() {
      return this.$store.state.usuario.nombre;
    },
    esAdministrador() {
      return (
        this.$store.state.usuario &&
        this.$store.state.usuario.rol == "Administrador"
      );
    },
  },
  created() {
    this.$store.dispatch("autoLogin");

    this.GetEmpresa();
  },
   methods: {
    Configuracion(){

    },
    async GetEmpresa() {
            try {
                let response = await axios.get('https://api-erp.contago.com.mx/api/Empresas/GetEmpresa/' + this.logueado.empresaBase);
                console.log('empresa',response.data)
                if (response.data.idEmpresa != 0) {
                    this.$store.state.empresaStore = response.data
                }
            } catch (error) {
                console.log(error);
            }
        },

    async salir() {
      this.dialogCerrar = true;
      let respuesta;
      try {
        respuesta = await axios.put(
          "https://api-framework.contago.com.mx/api/Usuarios/actualizaSesion/" +
            this.$store.state.usuario.nombre +
            "/INACTIVA"
        );


        let empresaDefault = {
          logo: {
            extencion: '',
            base64: '',
          },
          idEmpresa: 0,
          rfc: '',
          domicilioFiscal: '',
          nombre: '',
          regimenFiscal: {},
        };

        this.$store.state.listPoliza = []
        this.$store.state.balanza = []
        this.$store.state.listCatalogo= []
        this.$store.state.indexPolizas= -1
        this.$store.state.poliza.id = -1

        this.$store.state.empresaStore = { ...empresaDefault }


        this.$store.dispatch("salir");
        this.dialogCerrar = false;
      } catch (err) {
        this.dialogCerrar = false;
        console.log(err);
      }
    },
  },
};
</script>
