<template>
    <div>
        <v-card>
                <v-toolbar>
                    <!-- BOTON CERRAR -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-1" dark fab small @click="closeDialogCancelaPoliza()"
                                v-bind="attrs" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <b style="font-size: 30px;">Cancelar Poliza</b>
                    <v-spacer></v-spacer>
                    <!-- BOTON CONFIRMAR -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-1" dark fab small @click="GetCancelados()"
                                v-bind="attrs" v-on="on">
                                <v-icon>mdi-check</v-icon>
                            </v-btn>
                        </template>
                        <span>Cancelar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>

            <template>
                <v-card-text>
                    <br>
                    <!-- Fecha Inicial -->
                    <div>
                        <v-row>
                            <v-col cols="12" md="6" sm="6">
                                <v-menu v-model="calendario" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                                    offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="fechaI" label="Fecha Inicial" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                            v-on="on"></v-text-field>
                                        </template>
                                    <v-date-picker v-model="fechaI" @input="calendario = false, cambiaFechaFinal()"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="6" sm="6">
                                <v-menu v-model="calendario2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                                    offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="fechaF" label="Fecha Final" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                            v-on="on"></v-text-field>
                                        </template>
                                    <v-date-picker v-model="fechaF" @input="calendario2 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <!-- BOTON PARA BUSCAR POLIZAS -->
                        <!-- <v-tooltip bottom color="primary">
                           <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2" @click="GetCancelados()"><v-icon>mdi-magnify</v-icon></v-btn>
                            </template>
                            <span>Buscar</span>
                        </v-tooltip> -->

                    </div>
                </v-card-text>
            </template>
            </v-card>

        <!--Dialog loading-->
        <v-dialog v-model="loading" persistent width="300">
            <v-card color="primary" dark>
            <v-card-text>
                {{loadingMessage}}
                <v-progress-linear buffer-value="0" stream color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
            </v-card>
        </v-dialog>

        <!-- MENSAJE DE CUANTAS POLIZAS SE CANCELARON -->
        <v-snackbar v-model="snackbar" color="green" text-center>
            {{ mensaje }}
            <v-btn color="white" text @click="snackbar = false">Cerrar</v-btn>
        </v-snackbar>

    </div>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import Vue from 'vue'
    import VueCookies from 'vue-cookies'

    
    Vue.use(VueCookies)

    export default {
        name: 'ReporteDiot',
        components: {
        },

        data: () => ({
            //LOADING
            loadingMessage: '',
            loading: false,

            //FECHAS
            fechaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            fechaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),

            calendario: false,
            calendario2: false,

            radios: '1',
            //DATOS DE LA POLIZA
            singleSelect: false,
            itemsPolizaCancelada: [],

            snackbar: false,
            mensaje: "",

        }),
        watch: {
            
        },

        computed: {
            
            empresa(){
                return this.$store.state.usuario
            },
            
        },

        created() {
            
        },

        methods: {

            functionFormatPrice(value) {
                let val = (value / 1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            functionFormatDate(value){
                let fecha = moment(value).format('DD/MM/yyyy')
                return fecha;
            },
            closeDialogCancelaPoliza(){
                this.$emit("cierraDialogCP")
            },

            cambiaFechaFinal(){
                let fecha = this.fechaI.split('-');
                var primero = new Date(fecha[0],  fecha[1]-1, '01');
                var ultimoDia = new Date(primero.getFullYear(), primero.getMonth() + 1, 0);
                this.fechaF = format(parseISO(new Date(ultimoDia).toISOString()), 'yyyy-MM-dd')
            },

            async cancelaXLista(){
                this.loadingMessage = 'Cancelando pólizas...'
                this.loading = true;
                console.log("esta por cancelar")

                let ObjElimina = {

                    uuid: this.itemsPolizaCancelada,
                    base_datos: this.empresa.empresaBase
                }
                // console.log('Objetos', ObjElimina)
                console.log("ya esta el objeto que va a cancelar y es: ", ObjElimina)

                try {
                    let response = await axios.post('AutoConta/CancelaPolizas', ObjElimina);
                    this.loading = false;
                    console.log(response)
                    this.mensaje = "Se cancelaron " + this.itemsPolizaCancelada.length + " polizas"
                    this.snackbar = true;
                    this.closeDialogCancelaPoliza()
                } catch (error) {   
                    console.log(error);
                    this.loading = false;
                }
                console.log("ya cancelo")

            },
            
            async GetCancelados(){ 

                this.loadingMessage = 'Consultando Polizas de Cierre...'
                this.loading = true;
                console.log("entro al get para obtener la lista")
                try {
                    let response = await axios.get('GetPolizas/GetPolizaCancelada/' + this.empresa.rfc + '/' + this.fechaI + '/' + this.fechaF);
                    this.itemsPolizaCancelada = response.data
                    console.log('this.itsemsPolizaCancelada', response.data)
                    if(response.data.length > 0){
                        this.cancelaXLista()
                    }else{

                    }
                    this.loading = false;
                } catch (error) {   
                    console.log(error);
                    this.loading = false;
                }

            }

        },
    }
</script>