<template>
    <div>
        <v-card>
            <v-card-title style="font: bold;" class="text-h4 text--primary">
                <!-- BOTON PARA IR A UNA VENTANA ANTERIOR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn large icon v-bind="attrs" v-on="on" color="primary" dark class="mx-2" @click="nuevaPoliza()" link :to="{ name: 'Home' }"><v-icon>mdi-arrow-left</v-icon></v-btn>
                    </template>
                    <span>Atrás</span>
                </v-tooltip>
                Devoluciones de IVA
                <v-spacer></v-spacer>
                <!--Fecha Inicial--> 
                <v-col cols="12" sm="2" md="2">
                    <v-menu v-model="calendario" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                        offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="fechaI" label="Fecha Inicial" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                            v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="fechaI" @input="calendario = false, cambiaFechaFinal()"></v-date-picker>
                    </v-menu>
                </v-col>
                <!--Fecha Final-->
                <v-col cols="12" sm="2" md="2">
                <v-menu v-model="calendario2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="fechaF" label="Fecha Final" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="fechaF" @input="calendario2 = false"></v-date-picker>
                </v-menu>
                </v-col>
                
                <!-- BOTON PARA BUSCAR POLIZAS -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2" @click="GetDevoluciones()"><v-icon>mdi-magnify</v-icon></v-btn>
                    </template>
                    <span>Buscar</span>
                </v-tooltip>

                
                
                <!-- EXPORTA EXCEL -->
                <xlsx-workbook>
                    <xlsx-sheet
                        :collection="collection"
                        v-for="sheet in sheets"
                        :key="sheet.name"
                        :sheet-name="sheet.name"
                    />
                    <xlsx-download>
                    </xlsx-download>
                    <xlsx-download :filename="nombreReporte">
                        <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2" @click="GeneraExcel()"><v-icon>mdi-file-excel-box-outline</v-icon></v-btn>
                            </template>
                            <span>Excel</span>
                        </v-tooltip>
                    </xlsx-download>
                </xlsx-workbook>
            </v-card-title>
            <v-card-text>
                <v-data-table :headers="headersDevoluciones" :items="items" class="elevation-1" item-key="uuid" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
                        </v-toolbar>
                    </template>
                    <!-- FORMATO CON DECIMALES DEL CARGO -->
                    <template v-slot:item.subtotal="{ item }">
                        <span>{{ functionFormatPrice(item.subtotal) }}</span>
                    </template>
                    <template v-slot:item.descuento="{ item }">
                        <span>{{ functionFormatPrice(item.descuento) }}</span>
                    </template>
                    <template v-slot:item.iva16="{ item }">
                        <span>{{ functionFormatPrice(item.iva16) }}</span>
                    </template>

                    <template v-slot:item.ieps="{ item }">
                        <span>{{ functionFormatPrice(item.ieps) }}</span>
                    </template>

                    <template v-slot:item.isrRetenido="{ item }">
                        <span>{{ functionFormatPrice(item.isrRetenido) }}</span>
                    </template>

                    <template v-slot:item.ivaRetenido="{ item }">
                        <span>{{ functionFormatPrice(item.ivaRetenido) }}</span>
                    </template>

                    <template v-slot:item.total="{ item }">
                        <span>{{ functionFormatPrice(item.total) }}</span>
                    </template>

                    <template v-slot:item.descripcion="{ item }">
                        <div >
                            <span>{{ item.descripcion }}</span>
                        </div>
                    </template>
                    
                </v-data-table>
            </v-card-text>
        </v-card>

        <!--Dialog loading-->
        <v-dialog v-model="loading" persistent width="300">
            <v-card color="primary" dark>
            <v-card-text>
                {{loadingMessage}}
                <v-progress-linear buffer-value="0" stream color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
            </v-card>
        </v-dialog>
       
     

     
        

        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import print from 'print-js';
    import pdf from 'vue-pdf';
    import { VueEditor } from "vue2-editor";
    import Vue from 'vue'
    import VueCookies from 'vue-cookies'
    import JSZip from 'jszip';
    import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"

    Vue.use(VueCookies)

    export default {
        components: {
            XlsxWorkbook,
            XlsxSheet,
            XlsxDownload,
        },

        data: () => ({
            //LISTA PARA EXPORTAR
            listaExcel: [],
            sheets: [{ name: "SheetOne", data: [{ c: 2 }] }],
            collection: [{ a: 1, b: 2 }],
            nombreReporte: '',

            headersDevoluciones:[
                { text: "UUID", value: "uuid" },
                { text: "UUID RELACIONADO", value: "uuidRelacionado" },
                { text: "TIPO", value: "tipo" },
                { text: "FECHA DE EMISION", value: "fechaEmision" },
                { text: "RFC EMISOR", value: "rfcEmisor",   },
                { text: "RAZÓN DEL EMISOR", value: "nombreEmisor", },
                { text: "RFC RECEPTOR", value: "rfcReceptor",  },
                { text: "RAZÓN DEL RECEPTOR", value: "nombreReceptor" },
                { text: "CLAVE PRODUCTO O SERVICIO", value: "claveProductoServicio" },
                { text: "DESCRIPCIÓN", value: "descripcion" },
                { text: "USO DEL CFDI", value: "usoCFDI" },
                { text: "MONEDA", value: "moneda" },
                { text: "METODO DE PAGO", value: "metodoPago" },
                { text: "FORMA DE PAGO", value: "formaPago" },
                { text: "DESCUENTO", value: "descuento", align: 'right' },

                { text: "SUBTOTAL", value: "subtotal", align: 'right' },
                { text: "IVA AL 16%", value: "iva16" , align: 'right'},
                { text: "IEPS", value: "ieps" , align: 'right'},
                { text: "ISR RETENIDO", value: "isrRetenido" , align: 'right'},
                { text: "IVA RETENIDO", value: "ivaRetenido", align: 'right' },
                { text: "TOTAL", value: "total" , align: 'right'},

                { text: "POLIZA", value: "poliza" },
                { text: "REFERENCIA BANCAREA", value: "referencia" },
            ],

            //LOADING
            loadingMessage: '',
            loading: false,

            //FECHAS
            fechaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            fechaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            calendario: false,
            calendario2: false,
            search:'',
            snackbarMessage:'',
            snackbar:false,
        }),
        watch: {
            
        },
        computed: {
            items() {
                return this.$store.state.listDevolucionesIva;
            },
            empresa(){
                return this.$store.state.usuario
            },
            datosEmpresa(){
                return this.$store.state.empresaStore
            },
        },
        created() {
            
        },
        methods: {
            async GetDevoluciones(){
                this.loadingMessage = 'Buscando ...'
                this.loading = true;
                this.$store.state.listDevolucionesIva = []
                try {
                    let response = await axios.get('GetPolizas/GetDevolucionesIVA/'+this.empresa.empresaBase+'/' + this.fechaI + '/' + this.fechaF+'/'+this.empresa.rfc);
                    this.$store.state.listDevolucionesIva  = response.data;

                     console.log(response.data)
                    this.loading = false;
                } catch (error) {   
                    // console.log(error);
                    this.$store.state.listPoliza= []
                    this.loading = false;
                }
            },
            cambiaFechaFinal(){
                let fecha = this.fechaI.split('-');
                var primero = new Date(fecha[0],  fecha[1]-1, '01');
                var ultimoDia = new Date(primero.getFullYear(), primero.getMonth() + 1, 0);
                this.fechaF = format(parseISO(new Date(ultimoDia).toISOString()), 'yyyy-MM-dd')
            },
            functionFormatPrice(value) {
                let val = (value / 1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },

            GeneraExcel(){
                // console.log(this.empresa)
                this.nombreReporte = 'DevolucionesIVA_' + this.empresa.rfc + '_' +  this.fechaI + ' al ' + this.fechaF + '.xlsx'
                let listaExcel = [];
                for(let g of this.items){
                    let ObjD = { 
                        UUID: g.uuid,
                        'UUID RELACIONADO': g.uuidRelacionado,
                        'SERIE':g.serie,
                        'FOLIO':g.folio,
                        TIPO:g.tipo,
                        'FECHA DE EMISIÓN':g.fechaEmision,
                        'RFC EMISOR':g.rfcEmisor,
                        'RAZON DEL EMISOR':g.nombreEmisor,
                        'RFC DEL RECEPTOR':g.rfcReceptor,
                        'RAZON DEL RECEPTOR':g.nombreReceptor,
                        'CLAVE DE PRODUCTO O SERVICIO':g.claveProductoServicio,
                        'DESCRIPCIÓN Y/O CONCEPTO':g.descripcion,
                        'USO DEL CFDI':g.usoCFDI,
                        'MONEDA':g.moneda,
                        'METODO DE PAGO':g.metodoPago,
                        'FORMA DE PAGO':g.formaPago,
                        'SUBTOTAL':g.subtotal,
                        'DESCUENTO':g.descuento,
                        'IVA AL 16%':g.iva16,
                        'IEPS':g.ieps,
                        'ISR RETENIDO':g.isrRetenido,
                        'IVA RETENIDO':g.ivaRetenido,
                        'TOTAL':g.total,
                        'POLIZA':g.poliza,
                        'REFERENCIA BANCARIA':g.referencia,

                       
                    }
                    listaExcel.push(ObjD);
                    ObjD = Object.assign( {} );
                }
 
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
        },
    }
</script>