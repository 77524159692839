import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index'


import ListPolizas from '../components/Polizas/ListPolizas.vue'
import ListReportes from '../components/Reportes/ListReportes.vue'
import Balanza from '../components/Reportes/Balanza.vue'
import EstadoDeResultados from '../components/Reportes/EstadoDeResultados.vue'
import EstadoDeSituacionFinanciera from '../components/Reportes/EstadoDeSituacionFinanciera.vue'
import LibroDiario from '../components/Reportes/LibroDiario.vue'
import ListReportesEspeciales from '../components/ReportesEspeciales/ListReportesEspeciales.vue'
import EstadoDeCostoDeProduccionVenta from '../components/ReportesEspeciales/EstadoDeCostoDeProduccionVenta.vue'
import Depreciacion from '../components/ReportesEspeciales/Depreciacion.vue'
import CalculoAjusteAnualInflacion from '../components/ReportesEspeciales/CalculoAjusteAnualInflacion.vue'
import ProvisionalesIva from '../components/ReportesEspeciales/ProvisionalesIva.vue'
import ProvisionalesIsr from '../components/ReportesEspeciales/ProvisionalesIsr.vue'
import PerdidasEjerciciosAnteriores from '../components/ReportesEspeciales/PerdidasEjerciciosAnteriores.vue'
import ConciliacionContableFiscal from '../components/ReportesEspeciales/ConciliacionContableFiscal.vue'
import Catalogo from '../components/Catalogo/Catalogo.vue'
import Bancos from '../components/Bancos/Bancos.vue'
import Login from '../views/Login.vue'
import Configuracion from '../components/Configuracion/Configuracion.vue'
import ReporteDiot from '../components/ReportesEspeciales/ReporteDiot.vue'
import FlujoEefectivo from '../components/ReportesEspeciales/FlujoEefectivo.vue'
import DevolucionesIVA from '../components/ReportesEspeciales/DevolucionesIVA.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: Home, meta: { Administrador: true, Gasolinero: true }  },
  { path: '/ListPolizas', name: 'ListPolizas', component: ListPolizas, meta: { Administrador: true, Gasolinero: true }  },
  { path: '/ListReportes', name: 'ListReportes', component: ListReportes, meta: { Administrador: true, Gasolinero: true }  },
  { path: '/Balanza', name: 'Balanza', component: Balanza, meta: { Administrador: true, Gasolinero: true }  },
  { path: '/LibroDiario', name: 'LibroDiario', component: LibroDiario, meta: { Administrador: true, Gasolinero: true }  },
  { path: '/EstadoDeResultados', name: 'EstadoDeResultados', component: EstadoDeResultados, meta: { Administrador: true, Gasolinero: true }  },
  { path: '/EstadoDeSituacionFinanciera', name: 'EstadoDeSituacionFinanciera', component: EstadoDeSituacionFinanciera, meta: { Administrador: true, Gasolinero: true }  },
  { path: '/Catalogo', name: 'Catalogo', component: Catalogo, meta: { Administrador: true, Gasolinero: true }  },
  { path: '/Bancos', name: 'Bancos', component: Bancos, meta: { Administrador: true, Gasolinero: true }  },
  { path: '/ListReportesEspeciales', name: 'ListReportesEspeciales', component: ListReportesEspeciales, meta: { Administrador: true, Gasolinero: true }  },
  { path: '/EstadoDeCostoDeProduccionVenta', name: 'EstadoDeCostoDeProduccionVenta', component: EstadoDeCostoDeProduccionVenta, meta: { Administrador: true, Gasolinero: true }  },
  { path: '/Depreciacion', name: 'Depreciacion', component: Depreciacion, meta: { Administrador: true, Gasolinero: true }  },
  { path: '/CalculoAjusteAnualInflacion', name: 'CalculoAjusteAnualInflacion', component: CalculoAjusteAnualInflacion, meta: { Administrador: true, Gasolinero: true }  },
  { path: '/ProvisionalesIva', name: 'ProvisionalesIva', component: ProvisionalesIva, meta: { Administrador: true, Gasolinero: true }  },
  { path: '/ProvisionalesIsr', name: 'ProvisionalesIsr', component: ProvisionalesIsr, meta: { Administrador: true, Gasolinero: true }  },
  { path: '/ConciliacionContableFiscal', name: 'ConciliacionContableFiscal', component: ConciliacionContableFiscal, meta: { Administrador: true, Gasolinero: true }  },
  { path: '/PerdidasEjerciciosAnteriores', name: 'PerdidasEjerciciosAnteriores', component: PerdidasEjerciciosAnteriores, meta: { Administrador: true, Gasolinero: true }  },
  { path: '/Configuracion', name: 'Configuracion', component: Configuracion, meta: { Administrador: true, Gasolinero: true }  },
  { path: '/ReporteDiot', name: 'ReporteDiot', component: ReporteDiot, meta: { Administrador: true, Gasolinero: true }  },
  { path: '/FlujoEefectivo', name: 'FlujoEefectivo', component: FlujoEefectivo, meta: { Administrador: true, Gasolinero: true }  },
  { path: '/DevolucionesIVA', name: 'DevolucionesIVA', component: DevolucionesIVA, meta: { Administrador: true, Gasolinero: true }  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {path: '/Login', name: 'Login', component: Login, meta: { libre: true, }}

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.libre)) {
    next()
  } else if (store.state.usuario && store.state.usuario.rol == 'Administrador') {
    if (to.matched.some(record => record.meta.Administrador)) {
      next()
    }
  }else if (store.state.usuario && store.state.usuario.rol == 'Gasolinero') {
    if (to.matched.some(record => record.meta.Gasolinero)) {
      next()
    }
  }else {
    next({
      name: 'Login'
    })
  }
})
export default router
