<template>
  <div>
    <v-card class="justify-center pt-15" flat >
      <v-row dense >
        <!-- POLIZAS -->
        <v-card class="mx-auto justify-center" width="250" link :to="{ name: 'ListPolizas' }" @click="$store.state.nombreComponente = 'Pólizas'">
          <v-card-text class="text-center">
              <v-icon size="150" color="primary">mdi-file-document-multiple-outline</v-icon>
              <h1 class="mt-8">Pólizas</h1> 
          </v-card-text>
        </v-card>
        <!-- REPORTES -->
        <v-card class="mx-auto justify-center" width="250" link :to="{ name: 'ListReportes' }" @click="$store.state.nombreComponente = 'Reportes'">
          <v-card-text class="text-center">
              <v-icon size="150" color="primary">mdi-chart-line</v-icon>
              <h1 class="mt-8">Reportes</h1> 
          </v-card-text>
        </v-card>
        <!-- REPORTES ESPECIALES -->
        <v-card class="mx-auto justify-center" width="250" link :to="{ name: 'ListReportesEspeciales' }" @click="$store.state.nombreComponente = 'Reportes Especiales'">
          <v-card-text class="text-center">
              <v-icon size="150" color="primary">mdi-finance</v-icon>
              <h1 class="mt-8">Reportes Especiales</h1> 
          </v-card-text>
        </v-card>
        <!-- CATALOGO -->
        <v-card class="mx-auto justify-center" width="250" link :to="{ name: 'Catalogo' }" @click="$store.state.nombreComponente = 'Reportes'">
          <v-card-text class="text-center">
              <v-icon size="150" color="primary">mdi-clipboard-list</v-icon>
              <h1 class="mt-8">Catálogo</h1> 
          </v-card-text>
        </v-card>
        <!-- BANCOS -->
        <v-card class="mx-auto justify-center" width="250" link :to="{ name: 'Bancos' }" @click="$store.state.nombreComponente = 'Reportes'">
          <v-card-text class="text-center">
              <v-icon size="150" color="primary">mdi-bank</v-icon>
              <h1 class="mt-8">Bancos</h1> 
          </v-card-text>
        </v-card>
      </v-row>
      <v-main>
        <v-container fluid>
          <v-fade-transition mode="out-in">
            <router-view></router-view>
          </v-fade-transition>
        </v-container>
      </v-main>
    </v-card>
  </div>
</template>

<script>
 import axios from 'axios'
export default {
  name: 'Home',
  components: {
    
  }
}
</script>

