var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"text-h4 text--primary",staticStyle:{"font":"bold"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"large":"","icon":"","color":"primary","dark":"","link":"","to":{ name: 'ListReportes' }},on:{"click":function($event){return _vm.nuevaPoliza()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)]}}])},[_c('span',[_vm._v("Atrás")])]),_vm._v(" Estado de Resultados "),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha Inicial","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fechaI),callback:function ($$v) {_vm.fechaI=$$v},expression:"fechaI"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.calendario),callback:function ($$v) {_vm.calendario=$$v},expression:"calendario"}},[_c('v-date-picker',{on:{"input":function($event){_vm.calendario = false, _vm.cambiaFechaFinal()}},model:{value:(_vm.fechaI),callback:function ($$v) {_vm.fechaI=$$v},expression:"fechaI"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha Final","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fechaF),callback:function ($$v) {_vm.fechaF=$$v},expression:"fechaF"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.calendario2),callback:function ($$v) {_vm.calendario2=$$v},expression:"calendario2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.calendario2 = false}},model:{value:(_vm.fechaF),callback:function ($$v) {_vm.fechaF=$$v},expression:"fechaF"}})],1)],1),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"icon":"","color":"primary","dark":""},on:{"click":function($event){_vm.dialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}])},[_c('span',[_vm._v("Buscar")])]),[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" ¿Agregar descuentos y bonificaciones sobre ventas? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.bonificaciones = false, _vm.dialogPolizaCierre = true}}},[_vm._v(" NO ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.bonificaciones = true, _vm.dialogPolizaCierre = true}}},[_vm._v(" SI ")])],1)],1)],1)],1)],[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"250"},model:{value:(_vm.dialogPolizaCierre),callback:function ($$v) {_vm.dialogPolizaCierre=$$v},expression:"dialogPolizaCierre"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" ¿Tomar en cuenta la poliza de cierre? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.polizaCierre = false, _vm.GetEstadoResultados()}}},[_vm._v(" NO ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.polizaCierre = true, _vm.GetEstadoResultados()}}},[_vm._v(" SI ")])],1)],1)],1)],1)],_c('xlsx-workbook',[_vm._l((_vm.sheets),function(sheet){return _c('xlsx-sheet',{key:sheet.name,attrs:{"collection":_vm.collection,"sheet-name":sheet.name}})}),_c('xlsx-download'),_c('xlsx-download',{attrs:{"filename":_vm.nombreReporte}},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"icon":"","color":"primary","dark":""},on:{"click":function($event){return _vm.GeneraExcel()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-excel-box-outline")])],1)]}}])},[_c('span',[_vm._v("Excel")])])],1)],2),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary darken-1","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.CreaPdf()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-pdf-box")])],1)]}}])},[_c('span',[_vm._v("Imprimir")])])],2),_c('v-card-text',[(_vm.itemsIngresos.length != 0)?[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersER,"items":_vm.itemsIngresos,"hide-default-footer":"","items-per-page":-1,"hide-default-header":""},scopedSlots:_vm._u([{key:"item.importe",fn:function(ref){
var item = ref.item;
return [(item.tipo === '=')?_c('b',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(_vm.functionFormatPrice(item.importe)))]):_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.importe)))])]}},{key:"item.nombre",fn:function(ref){
var item = ref.item;
return [(item.tipo === '=')?_c('b',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(item.nombre))]):_c('span',[_vm._v(_vm._s(item.nombre))])]}}],null,false,1896151464)})],1)],1)]:_vm._e()],2)],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.loadingMessage)+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }