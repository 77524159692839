var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',{staticClass:"text-h4 text--primary",staticStyle:{"font":"bold"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"large":"","icon":"","color":"primary","dark":"","link":"","to":{ name: 'ListReportes' }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)]}}])},[_c('span',[_vm._v("Atrás")])]),_vm._v(" Libro Diario "),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha Inicial","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fechaI),callback:function ($$v) {_vm.fechaI=$$v},expression:"fechaI"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.calendario),callback:function ($$v) {_vm.calendario=$$v},expression:"calendario"}},[_c('v-date-picker',{on:{"input":function($event){_vm.calendario = false, _vm.cambiaFechaFinal()}},model:{value:(_vm.fechaI),callback:function ($$v) {_vm.fechaI=$$v},expression:"fechaI"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha Final","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fechaF),callback:function ($$v) {_vm.fechaF=$$v},expression:"fechaF"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.calendario2),callback:function ($$v) {_vm.calendario2=$$v},expression:"calendario2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.calendario2 = false}},model:{value:(_vm.fechaF),callback:function ($$v) {_vm.fechaF=$$v},expression:"fechaF"}})],1)],1),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"icon":"","color":"primary","dark":""},on:{"click":function($event){return _vm.GetPolizas()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}])},[_c('span',[_vm._v("Buscar")])]),_c('xlsx-workbook',[_vm._l((_vm.sheets),function(sheet){return _c('xlsx-sheet',{key:sheet.name,attrs:{"collection":_vm.collection,"sheet-name":sheet.name}})}),_c('xlsx-download'),_c('xlsx-download',{attrs:{"filename":_vm.nombreReporte}},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"icon":"","color":"primary","dark":""},on:{"click":function($event){return _vm.GeneraExcel()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-excel-box-outline")])],1)]}}])},[_c('span',[_vm._v("Excel")])])],1)],2)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersLibroDiario,"items":_vm.itemsLibroDiario,"items-per-page":10,"show-expand":"","item-key":"id"},scopedSlots:_vm._u([{key:"item.cargo",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.cargo)))])]}},{key:"item.abono",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.abono)))])]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatDate(item.fecha)))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-card',{attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"headers":_vm.headersDetalles,"items":item.detalles,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.cargo",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.cargo)))])]}},{key:"item.abono",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.abono)))])]}}],null,true)})],1)],1)]}}])}),_c('br'),_c('br'),_c('br')],1),_c('v-footer',{attrs:{"padless":"","fixed":""}},[_c('v-card',{staticClass:"text-center",attrs:{"flat":"","tile":"","width":"100%"}},[_c('v-system-bar',{attrs:{"color":"primary","height":"6"}}),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('h2',[_vm._v(_vm._s(_vm.functionFormatPrice(_vm.sumaCargos)))])]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('h2',[_vm._v(_vm._s(_vm.functionFormatPrice(_vm.sumaAbonos)))])])],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.loadingMessage)+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"stream":"","buffer-value":"0","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }