<template>
    <div>
        <v-card flat>
            <v-card-title style="font: bold;" class="text-h4 text--primary">
                <!-- BOTON PARA IR A UNA VENTANA ANTERIOR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn large icon v-bind="attrs" v-on="on" color="primary" dark class="mx-2"
                            @click="nuevaPoliza()" link :to="{ name: 'ListReportesEspeciales' }">
                            <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>
                    </template>
                    <span>Atrás</span>
                </v-tooltip>
                Conciliación Contable Fiscal
                <v-spacer></v-spacer>
                <v-select :items="itemsAnios" label="Año del ejercicio" outlined v-model="anios"></v-select>

                <!-- BOTON PARA CONSULTAR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="primary" dark class="mb-2" @click="GetCantidadCuentas()" v-bind="attrs"
                            v-on="on">
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                    </template>
                    <span>Buscar</span>
                </v-tooltip>
                <!-- EXPORTA EXCEL
                <xlsx-workbook>
                    <xlsx-sheet
                        :collection="collection"
                        v-for="sheet in sheets"
                        :key="sheet.name"
                        :sheet-name="sheet.name"
                    />
                    <xlsx-download>
                    </xlsx-download>
                    <xlsx-download :filename="nombreReporte">
                        <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2" @click="GeneraExcel()"><v-icon>mdi-file-excel-box-outline</v-icon></v-btn>
                            </template>
                            <span>Excel</span>
                        </v-tooltip>
                    </xlsx-download>
                </xlsx-workbook> -->

            </v-card-title>


            <v-data-table :items="itemsEstadoResultado" :headers="headersReporteER" hide-default-footer
                :items-per-page="-1">

                <template v-slot:item.cantidad="props">
                    <v-edit-dialog :return-value.sync="props.item.cantidad" large persistent @guardar="Guardar"
                        @cancel="Cancelar" @open="Abrir" @close="Cerrar">
                        <div>{{ props.item.cantidad }}</div>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                                ACTUALZIAR CANTIDAD
                            </div>
                            <v-text-field v-model="props.item.cantidad" :rules="[max25chars]" label="Edit" single-line
                                counter autofocus></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>

                <!-- <template v-slot:item.importe="{ item }">
                    <b style="font-size:18px" v-if="item.enero === '='">{{ functionFormatPrice(item.importe)
                        }}</b>
                    <span v-else>{{ functionFormatPrice(item.importe) }}</span>
                </template> -->
            </v-data-table>
            <br>
            <h1>INGRESOS FISCALES NO CONTABLES</h1>
            <v-data-table :items="itemsIngresosFiscalesNC" :headers="headersIngresosFNC" hide-default-footer
                :items-per-page="-1">

                <template v-slot:item.cantidad="props">
                    <v-edit-dialog :return-value.sync="props.item.cantidad" large persistent @guardar="Guardar"
                        @cancel="Cancelar" @open="Abrir" @close="Cerrar">
                        <div>{{ props.item.cantidad }}</div>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                                ACTUALZIAR CANTIDAD
                            </div>
                            <v-text-field v-model="props.item.cantidad" :rules="[max25chars]" label="Edit" single-line
                                counter autofocus></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>

                <!-- <template v-slot:item.importe="{ item }">
                    <b style="font-size:18px" v-if="item.enero === '='">{{ functionFormatPrice(item.importe)
                        }}</b>
                    <span v-else>{{ functionFormatPrice(item.importe) }}</span>
                </template> -->

            </v-data-table>
            <br>
            <h1>DEDUCCIONES CONTABLES NO FISCALES</h1>
            <v-data-table :items="itemsDeduccionesCNF" :headers="headersDeduccionesCNF" hide-default-footer
                :items-per-page="-1">

                <template v-slot:item.cantidad="props">
                    <v-edit-dialog :return-value.sync="props.item.cantidad" large persistent @guardar="Guardar"
                        @cancel="Cancelar" @open="Abrir" @close="Cerrar">
                        <div>{{ props.item.cantidad }}</div>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                                ACTUALZIAR CANTIDAD
                            </div>
                            <v-text-field v-model="props.item.cantidad" :rules="[max25chars]" label="Edit" single-line
                                counter autofocus></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>

                <!-- <template v-slot:item.importe="{ item }">
                    <b style="font-size:18px" v-if="item.enero === '='">{{ functionFormatPrice(item.importe)
                        }}</b>
                    <span v-else>{{ functionFormatPrice(item.importe) }}</span>
                </template> -->

            </v-data-table>
            <br>
            <h1>DEDUCCIONES FISCALES NO CONTABLES</h1>
            <v-data-table :items="itemsDeduccionesFNC" :headers="headersDeduccionesFNC" hide-default-footer
                :items-per-page="-1">

                <template v-slot:item.cantidad="props">
                    <v-edit-dialog :return-value.sync="props.item.cantidad" large persistent @guardar="Guardar"
                        @cancel="Cancelar" @open="Abrir" @close="Cerrar">
                        <div>{{ props.item.cantidad }}</div>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                                ACTUALZIAR CANTIDAD
                            </div>
                            <v-text-field v-model="props.item.cantidad" :rules="[max25chars]" label="Edit" single-line
                                counter autofocus></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>

                <!-- <template v-slot:item.importe="{ item }">
                    <b style="font-size:18px" v-if="item.enero === '='">{{ functionFormatPrice(item.importe)
                        }}</b>
                    <span v-else>{{ functionFormatPrice(item.importe) }}</span>
                </template> -->

            </v-data-table>
            <br>
            <h1>INGRESOS CONTABLES NO FISCALES</h1>
            <v-data-table :items="itemsIngresosCNF" :headers="headersIngresosCNF" hide-default-footer
                :items-per-page="-1">

                <template v-slot:item.cantidad="props">
                    <v-edit-dialog :return-value.sync="props.item.cantidad" large persistent @guardar="Guardar"
                        @cancel="Cancelar" @open="Abrir" @close="Cerrar">
                        <div>{{ props.item.cantidad }}</div>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                                ACTUALZIAR CANTIDAD
                            </div>
                            <v-text-field v-model="props.item.cantidad" :rules="[max25chars]" label="Edit" single-line
                                counter autofocus></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>

                <!-- <template v-slot:item.importe="{ item }">
                    <b style="font-size:18px" v-if="item.enero === '='">{{ functionFormatPrice(item.importe)
                        }}</b>
                    <span v-else>{{ functionFormatPrice(item.importe) }}</span>
                </template> -->

            </v-data-table>
            <br>
            <h1>UTILIDAD O PÉRDIDA FISCAL ANTES DE PTU</h1>
            <v-data-table :items="itemsUtilidadPFAPTU" :headers="headersUtilidadPFAPTU" hide-default-footer
                :items-per-page="-1">

                <template v-slot:item.cantidad="props">
                    <v-edit-dialog :return-value.sync="props.item.cantidad" large persistent @guardar="Guardar"
                        @cancel="Cancelar" @open="Abrir" @close="Cerrar">
                        <div>{{ props.item.cantidad }}</div>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                                ACTUALZIAR CANTIDAD
                            </div>
                            <v-text-field v-model="props.item.cantidad" :rules="[max25chars]" label="Edit" single-line
                                counter autofocus></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>

                <!-- <template v-slot:item.importe="{ item }">
                    <b style="font-size:18px" v-if="item.enero === '='">{{ functionFormatPrice(item.importe)
                        }}</b>
                    <span v-else>{{ functionFormatPrice(item.importe) }}</span>
                </template> -->

            </v-data-table>
            <br>
            <h1>AMORTIZACIÓN DE PÉRDIDAS DE EJERCICIOS ANTERIORES</h1>
            <v-data-table :items="itemsAmortizacionPEA" :headers="headersAmortizacionPEA" hide-default-footer
                :items-per-page="-1">

                <template v-slot:item.cantidad="props">
                    <v-edit-dialog :return-value.sync="props.item.cantidad" large persistent @guardar="Guardar"
                        @cancel="Cancelar" @open="Abrir" @close="Cerrar">
                        <div>{{ props.item.cantidad }}</div>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                                ACTUALZIAR CANTIDAD
                            </div>
                            <v-text-field v-model="props.item.cantidad" :rules="[max25chars]" label="Edit" single-line
                                counter autofocus></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>

                <!-- <template v-slot:item.importe="{ item }">
                    <b style="font-size:18px" v-if="item.enero === '='">{{ functionFormatPrice(item.importe)
                        }}</b>
                    <span v-else>{{ functionFormatPrice(item.importe) }}</span>
                </template> -->

            </v-data-table>
            <br>
            <h1>TASA DE ISR</h1>
            <v-data-table :items="itemsTasaISR" :headers="headersTasaISR" hide-default-footer :items-per-page="-1">

                <template v-slot:item.cantidad="props">
                    <v-edit-dialog :return-value.sync="props.item.cantidad" large persistent @guardar="Guardar"
                        @cancel="Cancelar" @open="Abrir" @close="Cerrar">
                        <div>{{ props.item.cantidad }}</div>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                                ACTUALZIAR CANTIDAD
                            </div>
                            <v-text-field v-model="props.item.cantidad" :rules="[max25chars]" label="Edit" single-line
                                counter autofocus></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>

                <!-- <template v-slot:item.importe="{ item }">
                    <b style="font-size:18px" v-if="item.enero === '='">{{ functionFormatPrice(item.importe)
                        }}</b>
                    <span v-else>{{ functionFormatPrice(item.importe) }}</span>
                </template> -->

            </v-data-table>
            <br>
            <h1>DETERMINACIÓN ANUAL DE ISR</h1>
            <v-data-table :items="itemsDetAnualTasaISR" :headers="headersDetAnualTasaISR" hide-default-footer
                :items-per-page="-1">

                <template v-slot:item.cantidad="props">
                    <v-edit-dialog :return-value.sync="props.item.cantidad" large persistent @guardar="Guardar"
                        @cancel="Cancelar" @open="Abrir" @close="Cerrar">
                        <div>{{ props.item.cantidad }}</div>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                                ACTUALZIAR CANTIDAD
                            </div>
                            <v-text-field v-model="props.item.cantidad" :rules="[max25chars]" label="Edit" single-line
                                counter autofocus></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>

                <!-- <template v-slot:item.importe="{ item }">
                    <b style="font-size:18px" v-if="item.enero === '='">{{ functionFormatPrice(item.importe)
                        }}</b>
                    <span v-else>{{ functionFormatPrice(item.importe) }}</span>
                </template> -->

            </v-data-table>
            <br>
            <h1>PAGOS PROVISIONALES Y/O RETENCIONES</h1>
            <v-data-table :items="itemsPagosProvRet" :headers="headersPagosProvRet" hide-default-footer
                :items-per-page="-1">

                <template v-slot:item.cantidad="props">
                    <v-edit-dialog :return-value.sync="props.item.cantidad" large persistent @guardar="Guardar"
                        @cancelar="Cancelar" @open="Abrir" @close="Cerrar">
                        <div>{{ props.item.cantidad }}</div>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                                ACTUALZIAR CANTIDAD
                            </div>
                            <v-text-field v-model="props.item.cantidad" :rules="[max25chars]" label="Edit" single-line
                                counter autofocus></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>

                <!-- <template v-slot:item.importe="{ item }">
                    <b style="font-size:18px" v-if="item.enero === '='">{{ functionFormatPrice(item.importe)
                        }}</b>
                    <span v-else>{{ functionFormatPrice(item.importe) }}</span>
                </template> -->

            </v-data-table>
        </v-card>



        <!--Dialog loading-->
        <v-dialog v-model="loading" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    {{loadingMessage}}
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import print from 'print-js';
    import pdf from 'vue-pdf';

    export default {
        name: 'ConciliacionContableFiscal',
        components: {

        },

        data: () => ({
            //LOADING
            loading: false,
            loadingMessage: '',

            //FECHAS
            fechaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            fechaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            calendario: false,
            calendario2: false,

            // //DATOS DEL REPORTE
            // itemsReporte: [],
            // headersReporte: [
            //     { text: '', value: 'observacion' },
            //     { text: 'Nombre Cuenta', value: 'nombre' },
            //     { text: 'Importe', value: 'importe', align: 'right' },
            // ],
            anios: '',
            mesesEjercicio: 0,
            promedioAnualDeudas: 0,
            promedioAnualCreditos: 0,
            diferencia: 0,
            factAjusteAnual: 0,
            factAjusteAnualIA: 0,

            inpcReciente: 0,
            inpcAntiguo: 0,

            //DATOS DEL REPORTE NUEVO
            headersReporteER: [
                { text: 'NUMERO CUENTA', value: 'numcuenta', align: ' d-none' },
                { text: 'DESCRIPCIÓN', value: 'descripcion' },
                { text: 'CANTIDAD', value: 'cantidad' },
                { text: 'TOTAL', value: 'total' },
            ],
            itemsEstadoResultado: [
                {
                    numcuenta: 'x',
                    descripcion: 'UTILIDAD O PÉRDIDA NETA',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'EFECTOS DE REEXPRESIÓN',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'RESULTADO POR POSICIÓN MONETARIA',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'UTILIDAD O PÉRDIDA NETA HISTÓRICA',
                    cantidad: '0.0',
                    total: '0.0'
                },

            ],
            headersIngresosFNC: [
                { text: 'NUMERO CUENTA', value: 'numcuenta', align: ' d-none' },
                { text: 'DESCRIPCIÓN', value: 'descripcion' },
                { text: 'CANTIDAD', value: 'cantidad' },
                { text: 'TOTAL', value: 'total' },
            ],
            itemsIngresosFiscalesNC: [
                {
                    numcuenta: 'x',
                    descripcion: 'INGRESOS FISCALES NO CONTABLES',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'AJUSTE ANUAL POR INFLACIÓN ACUMULABLE',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: '206',
                    descripcion: 'ANTICIPOS DE CLIENTES',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'INTERESES MORATORIOS EFECTIVAMENTE COBRADOS',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'GANANCIA EN LA ENAJENACIÓN DE ACCIONES O POR REEMBOLSO DE CAPITAL',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'GANANCIA EN LA ENAJENACIÓN DE TERRENOS Y ACTIVO FIJO',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'INVENTARIO ACUMULABLE DEL EJERCICIO',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'OTROS INGRESOS FISCALES NO CONTABLES',
                    cantidad: '0.0',
                    total: '0.0'
                },
            ],
            headersDeduccionesCNF: [
                { text: 'NUMERO CUENTA', value: 'numcuenta', align: ' d-none' },
                { text: 'DESCRIPCIÓN', value: 'descripcion' },
                { text: 'CANTIDAD', value: 'cantidad' },
                { text: 'TOTAL', value: 'total' },
            ],
            itemsDeduccionesCNF: [
                {
                    numcuenta: 'x',
                    descripcion: 'DEDUCCIONES CONTABLES NO FISCALES',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: '500',
                    descripcion: 'COSTO DE VENTAS CONTABLE',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'DEPRECIACIÓN Y AMORTIZACIÓN CONTABLE',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: '601.83',
                    descripcion: 'GASTOS QUE NO REÚNEN REQUISITOS FISCALES',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'ISR, IETU, IMPAC Y PTU',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'PÉRDIDA CONTABLE EN ENAJENACIÓN DE ACCIONES',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'PÉRDIDA CONTABLE EN ENAJENACIÓN DE ACTIVO FIJO',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'PÉRDIDA EN PARTICIPACIÓN SUBSIDIARIA',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'INTERESES DEVENGADOS QUE EXCEDEN DEL VALOR DE MERCADO Y MORATORIOS PAGADOS O NO',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'OTRAS DEDUCCIONES CONTABLES NO FISCALES',
                    cantidad: '0.0',
                    total: '0.0'
                },
            ],
            headersDeduccionesFNC: [
                { text: 'NUMERO CUENTA', value: 'numcuenta', align: ' d-none' },
                { text: 'DESCRIPCIÓN', value: 'descripcion' },
                { text: 'CANTIDAD', value: 'cantidad' },
                { text: 'TOTAL', value: 'total' },
            ],
            itemsDeduccionesFNC: [
                {
                    numcuenta: 'x',
                    descripcion: 'DEDUCCIONES FISCALES NO CONTABLES',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'AJUSTE ANUAL POR INFLACIÓN DEDUCIBLE',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: '500',
                    descripcion: 'COSTO DE LO VENDIDO FISCAL',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'DEDUCCIÓN DE INVERSIONES',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'ESTÍMULO FISCAL POR DEDUCCIÓN INMEDIATA DE INVERSIONES',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'ESTÍMULO FISCAL POR CONTRATAR PERSONAS CON DISCAPACIDAD Y/O ADULTOS MAYORES',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'DEDUCCIÓN DEL IMPUESTO SOBRE LA RENTA RETENIDO A PERSONAS CON DISCAPACIDAD Y/O ADULTOS MAYORES',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: '703.19',
                    descripcion: 'PÉRDIDA FISCAL EN ENAJENACIÓN DE ACCIONES',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: '703.01',
                    descripcion: 'PÉRDIDA FISCAL EN ENAJENACIÓN DE TERRENOS Y ACTIVO FIJO',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'INTERESES MORATORIOS EFECTIVAMENTE PAGADOS',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'OTRAS DEDUCCIONES FISCALES NO CONTABLES',
                    cantidad: '0.0',
                    total: '0.0'
                },
            ],
            headersIngresosCNF: [
                { text: 'NUMERO CUENTA', value: 'numcuenta', align: ' d-none' },
                { text: 'DESCRIPCIÓN', value: 'descripcion' },
                { text: 'CANTIDAD', value: 'cantidad' },
                { text: 'TOTAL', value: 'total' },
            ],
            itemsIngresosCNF: [
                {
                    numcuenta: 'x',
                    descripcion: 'INGRESOS CONTABLES NO FISCALES',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'INTERESES MORATORIOS DEVENGADOS A FAVOR COBRADOS O NO',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: '206',
                    descripcion: 'ANTICIPOS DE CLIENTES DE EJERCICIOS ANTERIORES',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'SALDOS A FAVOR DE IMPUESTOS Y SU ACTUALIZACIÓN',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: '704.01',
                    descripcion: 'UTILIDAD CONTABLE EN ENAJENACIÓN DE ACTIVO FIJO',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: '704.19',
                    descripcion: 'UTILIDAD CONTABLE EN ENAJENACIÓN DE ACCIONES',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'UTILIDAD EN PARTICIPACIÓN SUBSIDIARIA',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'OTROS INGRESOS CONTABLES NO FISCALES',
                    cantidad: '0.0',
                    total: '0.0'
                },
            ],
            headersUtilidadPFAPTU: [
                { text: 'NUMERO CUENTA', value: 'numcuenta', align: ' d-none' },
                { text: 'DESCRIPCIÓN', value: 'descripcion' },
                { text: 'CANTIDAD', value: 'cantidad' },
                { text: 'TOTAL', value: 'total' },
            ],
            itemsUtilidadPFAPTU: [
                {
                    numcuenta: 'x',
                    descripcion: 'UTILIDAD O PÉRDIDA FISCAL ANTES DE PTU',
                    cantidad: '0.0',
                    total: '0.0'
                },
            ],
            headersAmortizacionPEA: [
                { text: 'NUMERO CUENTA', value: 'numcuenta', align: ' d-none' },
                { text: 'DESCRIPCION', value: 'descripcion' },
                { text: 'CANTIDAD', value: 'cantidad' },
                { text: 'TOTAL', value: 'total' },
            ],
            itemsAmortizacionPEA: [
                {
                    numcuenta: 'x',
                    descripcion: 'AMORTIZACIÓN DE PÉRDIDAS DE EJERCICIOS ANTERIORES',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'RESULTADO DEL EJERCICIO',
                    cantidad: '0.0',
                    total: '0.0'
                },
            ],
            headersTasaISR: [
                { text: 'NUMERO CUENTA', value: 'numcuenta', align: ' d-none' },
                { text: 'DESCRIPCIÓN', value: 'descripcion' },
                { text: 'CANTIDAD', value: 'cantidad' },
                { text: 'TOTAL', value: 'total' },
            ],
            itemsTasaISR: [
                {
                    numcuenta: 'x',
                    descripcion: 'TASA DEL ISR',
                    cantidad: '0.0',
                    total: '30'
                },
            ],
            headersDetAnualTasaISR: [
                { text: 'NUMERO CUENTA', value: 'numcuenta', align: ' d-none' },
                { text: 'DESCRIPCIÓN', value: 'descripcion' },
                { text: 'CANTIDAD', value: 'cantidad' },
                { text: 'TOTAL', value: 'total' },
            ],
            itemsDetAnualTasaISR: [
                {
                    numcuenta: 'x',
                    descripcion: 'DETERMINACIÓN ISR ANUAL',
                    cantidad: '0.0',
                    total: '0.0'
                },
            ],
            headersPagosProvRet: [
                { text: 'NUMERO CUENTA', value: 'numcuenta', align: ' d-none' },
                { text: 'DESCRIPCIÓN', value: 'descripcion' },
                { text: 'CANTIDAD', value: 'cantidad' },
                { text: 'TOTAL', value: 'total' },
            ],
            itemsPagosProvRet: [
                {
                    numcuenta: 'x',
                    descripcion: 'PAGOS PROVISIONALES Y/O RETENCIONES',
                    cantidad: '0.0',
                    total: '0.0'
                },
                {
                    numcuenta: 'x',
                    descripcion: 'ISR A PAGAR EN DECLARACIÓN ANUAL',
                    cantidad: '0.0',
                    total: '0.0'
                },
            ],

            max25chars: v => v.length <= 25 || 'Input too long!',

        }),

        watch: {

        },

        computed: {
            empresa() {
                return this.$store.state.usuario
            },

            itemsAnios() {
                let fecha = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd');
                let anio = fecha.split('-')[0];
                let lista = []
                lista.push(anio);
                lista.push(anio - 1);
                lista.push(anio - 2);
                lista.push(anio - 3);
                lista.push(anio - 4);
                lista.push(anio - 5);

                return lista;
            },

        },

        methods: {

            async GetCantidadCuentas() {

                this.loading = true;
                this.loadingMessage = 'Consultando...';
                var fechaIni = this.anios + '-01-01'
                var fechaFin = this.anios + '-12-31'
                try {
                    console.time();
                    let respuesta = await axios.get('reporteAutoConta/ConciliacionContableFiscal/' + this.empresa.empresaBase + '/' + fechaIni + '/' + fechaFin)
                    var array = respuesta.data
                    // console.log(array[2].cantidad)
                    //INGRESOS FISCALES NO CONTABLES
                    for (var i of this.itemsIngresosFiscalesNC) {
                        // console.log(i.numcuenta)
                        // console.log(i['numcuenta'])
                        // console.log(i.cantidad)
                        if (i['numcuenta'] === '206') {
                            i['cantidad'] = i.cantidad
                        } else {
                            i['cantidad'] = 0
                        }
                    }
                    //DEDUCCIONES CONTABLES NO FISCALES
                    for (var i of this.itemsDeduccionesCNF) {
                        console.log(i.cantidad = i.count)
                        if (i['numcuenta'] === '500' || i['numcuenta'] === '601.83') {
                            i['cantidad'] = i.cantidad
                        } else {
                            i['cantidad'] = 0
                        }
                    }

                    //DEDUCCIONES FISCALES NO CONTABLES
                    for (var i of this.itemsDeduccionesFNC) {
                        // console.log(i.cantidad)
                        if (i['numcuenta'] === '500' || i['numcuenta'] === '703.19') {
                            i['cantidad'] = i.cantidad
                        } else {
                            i['cantidad'] = 0
                        }
                    }

                    //INGRESOS CONTABLES NO FISCALES
                    for (var i of this.itemsIngresosCNF) {
                        // console.log(i.cantidad)
                        if (i['numcuenta'] === '206' || i['numcuenta'] === '704.01' || i['numcuenta'] === '704.19') {
                            i['cantidad'] = i.cantidad
                        } else {
                            i['cantidad'] = 0
                        }
                    }

                    await GetEstadoResultado()
                    await GetAjusteAnualInflacion()
                    await GetDepreciacionAcumulada()
                    await GetDepreciacionFiscal()

                    console.timeEnd();
                    this.loading = false;
                } catch (error) {
                    console.log(error.data)
                    console.timeEnd();
                    this.loading = false;
                }

            },

            async GetEstadoResultado() {
                this.loading = true;
                this.loadingMessage = 'Consultando...';
                var fechaIni = this.anios + '-01-01'
                var fechaFin = this.anios + '-12-31'
                try {
                    console.time();
                    let respuesta = await axios.get('reporteAutoConta/ConciliacionContableFiscalER/' + this.empresa.empresaBase + '/' + fechaIni + '/' + fechaFin)

                    this.itemsEstadoResultado[0].total = respuesta.data
                    console.timeEnd();
                    this.loading = false;
                } catch (error) {
                    console.log(error.data)
                    console.timeEnd();
                    this.loading = false;
                }
            },

            async GetAjusteAnualInflacion() {
                this.loading = true;
                this.loadingMessage = 'Consultando...';
                var fechaIni = this.anios + '-01-01'
                var fechaFin = this.anios + '-12-31'
                try {
                    console.time();
                    let respuesta = await axios.get('reporteAutoConta/ConciliacionContableFiscalAAI/' + this.empresa.empresaBase + '/' + fechaIni + '/' + fechaFin)
                    var array = respuesta.data

                    //PROMEDIO ANUAL DE CREDITOS (SUMA DE LOS CREDITOS ENTRE MESES DEL EJERCICIO)
                    var totalCreditos = array[0].cantidad
                    var totalDeudas = array[1].cantidad

                    //OBTENEMOS LOS MESES DEL EJERCICIO
                    let fechaActual = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
                    let fechaA = fechaActual.split('-')
                    let mesReciente = 'diciembre'
                    this.mesesEjercicio = '12'
                    if (this.anios === fechaA[0]) {
                        this.mesesEjercicio = fechaA[1]
                        mesReciente = this.getLongMonthName(fechaA[1])
                    }

                    var promedioAnualCreditos = totalCreditos / this.mesesEjercicio
                    var promedioAnualDeudas = totalDeudas / this.mesesEjercicio
                    var diferencia = promedioAnualDeudas - promedioAnualCreditos

                    //OBTENEMOS LOS INPC RECIENTE Y ANTIGUO
                    await this.GetInpc();

                    //OBTENEMOS INPC DEPENDIENDO DEL AÑO Y MES DE ADQUIICICION
                    let ObjInpcA = this.listInpc.find(x => x.anio == this.anios)
                    let ObjMesA = 'enero';
                    this.inpcAntiguo = ObjInpcA[ObjMesA];

                    let ObjInpcB = this.listInpc.find(x => x.anio == this.anios)
                    let ObjMesB = mesReciente;
                    this.inpcReciente = ObjInpcB[ObjMesB];

                    var factAjusteAnual = (this.inpcReciente / this.inpcAntiguo) - 1

                    var totalAjusteAnualIA = diferencia * factAjusteAnual

                    switch (totalAjusteAnualIA) {
                        case totalAjusteAnualIA > 0:
                            this.itemsIngresosFiscalesNC[1].total = totalAjusteAnualIA
                            this.itemsDeduccionesFNC[1].total = 0
                            break;
                        case totalAjusteAnualIA < 0:
                            this.itemsIngresosFiscalesNC[1].total = 0
                            this.itemsDeduccionesFNC[1].total = totalAjusteAnualIA
                            break;
                        default:
                            this.itemsIngresosFiscalesNC[1].total = 0
                            this.itemsDeduccionesFNC[1].total = 0
                            break;
                    }


                    // this.promedioAnualCreditos = array.sumaCreditos / this.mesesEjercicio;
                    // this.promedioAnualDeudas = this.sumaDeudas / this.mesesEjercicio;
                    // this.diferencia = this.promedioAnualDeudas - this.promedioAnualCreditos;
                    // this.factAjusteAnual = (this.inpcReciente / this.inpcAntiguo) - 1;
                    // this.factAjusteAnualIA = this.diferencia * this.factAjusteAnual;

                    // let Objeto = { observacion: '+', nombre: 'INGRESOS FISCALES NO CONTABLES', importe: array }

                    // if (this.factAjusteAnualIA > 0) {

                    //     let Objeto = { observacion: '+', nombre: 'AJUSTE ANUAL POR INFLACION ACUMULABLE', importe: array }
                    //     this.itemsReporte.push(Objeto);

                    // } else {

                    //     let Objeto = { observacion: '-', nombre: 'AJUSTE ANUAL POR INFLACION DEDUCIBLE', importe: array }
                    //     this.itemsReporte.push(Objeto);

                    // }

                    console.timeEnd();
                    this.loading = false;
                } catch (error) {
                    console.log(error.data)
                    console.timeEnd();
                    this.loading = false;
                }
            },

            async GetDepreciacionAcumulada() {

                this.loading = true;
                this.loadingMessage = 'Consultando...';
                var fechaIni = this.anios + '-01-01'
                var fechaFin = this.anios + '-12-31'
                try {
                    console.time();
                    let respuesta = await axios.get('reporteAutoConta/ConciliacionContableFiscalDepAcumulada/' + this.empresa.empresaBase + '/' + fechaIni + '/' + fechaFin)
                    var array = respuesta.data

                    var depAcumulada = 0
                    for (var i of array) {
                        let depAnual = i.monto_inversion * (i.porcentaje / 100)
                        let depMensual = depAnual / 12
                        let mesesVidaUtil = (1 / (i.porcentaje / 100)) * 12

                        //CONVERTIR LAS FECHAS
                        let fechaB = i.fecha_adquisision.split('-')
                        let fechaC = i.fecha_depreciacion.split('-')

                        let fechaAdquicicion = new Date(fechaB[0], fechaB[1], fechaB[2])
                        let fechaDepreciacion = new Date(fechaC[0], fechaC[1], fechaC[2])

                        let diferencia = this.monthDiff(fechaAdquicicion, fechaDepreciacion);


                        var mesesDep = mesesVidaUtil
                        if (diferencia < mesesVidaUtil) {
                            mesesDep = diferencia
                        }

                        let pendientes = mesesVidaUtil - mesesDep

                        let mesesDepEjercicio = 0
                        if (pendientes >= 12) {
                            mesesDepEjercicio = 12
                        }
                        else if (pendientes < 12) {
                            mesesDepEjercicio = pendientes
                        } else if (pendientes <= 0) {
                            mesesDepEjercicio = 0
                        }

                        let depreciacionDelEjercicio = 0
                        depreciacionDelEjercicio = depMensual * mesesDepEjercicio

                        depAcumulada = depAcumulada + depreciacionDelEjercicio

                    }

                    this.itemsDeduccionesCNF[2].total = Math.trunc(depAcumulada * 10000) / 10000
                    console.timeEnd();
                    this.loading = false;
                } catch (error) {
                    console.log(error.data)
                    console.timeEnd();
                    this.loading = false;
                }

            },

            async GetDepreciacionFiscal() {

                this.loading = true;
                this.loadingMessage = 'Consultando...';
                var fechaIni = this.anios + '-01-01'
                var fechaFin = this.anios + '-12-31'
                try {
                    console.time();
                    let respuesta = await axios.get('reporteAutoConta/ConciliacionContableFiscalDepFiscal/' + this.empresa.empresaBase + '/' + fechaIni + '/' + fechaFin)

                    let suma = respuesta.data

                    console.log(suma)
                    this.itemsDeduccionesFNC[3].total = suma.suma

                    console.timeEnd();
                    this.loading = false;
                } catch (error) {
                    console.log(error.data)
                    console.timeEnd();
                    this.loading = false;
                }

            },

            monthDiff(d1, d2) {
                var months;
                months = (d2.getFullYear() - d1.getFullYear()) * 12;
                months -= d1.getMonth();
                months += d2.getMonth();
                return months <= 0 ? 0 : months;
            },

            async GetInpc() {
                this.loading = true;
                this.loadingMessage = 'Consultando...';
                try {
                    let respuesta = await axios.get('reporteAutoConta/GetInpc/' + this.empresa.empresaBase)
                    this.listInpc = respuesta.data;
                } catch (error) {
                    console.log(error.data)
                }
            },

            //OBTENEMOS LA SUMA DE LA COLUMNA CANTIDAD
            async GetSuma() {

                let array = this.itemsEstadoResultado
                let cantidad = 0
                let suma = 0

                suma = array.map(item => Number(item.cantidad)).reduce((prev, curr) => prev + curr, 0);

                console.log(suma)

            },

            functionFormatPrice(value) {
                let val = (value / 1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },

            cambiaFechaFinal() {
                let fecha = this.fechaI.split('-');
                var primero = new Date(fecha[0], fecha[1] - 1, '01');
                var ultimoDia = new Date(primero.getFullYear(), primero.getMonth() + 1, 0);
                this.fechaF = format(parseISO(new Date(ultimoDia).toISOString()), 'yyyy-MM-dd')
            },

            Guardar() {
                this.snack = true
                this.snackColor = 'Éxito'
                this.snackText = 'Dato guardado'
            },
            Cancelar() {
                this.snack = true
                this.snackColor = 'Error'
                this.snackText = 'Cancelado'
            },
            Abrir() {
                this.snack = true
                this.snackColor = 'Informacion'
                this.snackText = 'Dialog abierto'
            },
            Cerrar() {
                try {
                    var suma = 0
                    for (var a = 0; a < 3; a++) {
                        suma += Number(this.itemsEstadoResultado[a].cantidad)
                    }
                    this.itemsEstadoResultado[3].total = suma;

                    //INGRESOS FISCALES NO CONTABLES
                    var sumaIFNC = 0
                    for (var b = 1; b < 7; b++) {
                        sumaIFNC += Number(this.itemsIngresosFiscalesNC[b].cantidad)
                    }
                    this.itemsIngresosFiscalesNC[0].total = sumaIFNC;

                    //DEDUCCIONES CONTABLES NO FISCALES
                    var sumaDCNF = 0
                    for (var b = 1; b < 7; b++) {
                        sumaDCNF += Number(this.itemsDeduccionesCNF[b].cantidad)
                    }
                    this.itemsDeduccionesCNF[0].total = sumaDCNF;

                    //DEDUCCIONES FISCALES NO CONTABLES
                    var sumaDFNC = 0
                    for (var c = 1; c < 7; c++) {
                        sumaDFNC += Number(this.itemsDeduccionesFNC[c].cantidad)
                    }
                    this.itemsDeduccionesFNC[0].total = sumaDFNC;

                    //INGRESOS CONTABLES NO FISCALES
                    var sumaICNF = 0
                    for (var d = 1; d < 7; d++) {
                        sumaICNF += Number(this.itemsIngresosCNF[d].cantidad)
                    }
                    this.itemsIngresosCNF[0].total = sumaICNF;

                    //UTILIDADA O PERDIDA FISCAL ANTES DEL PTU
                    var sumaTotal = this.itemsEstadoResultado[3].total + this.itemsIngresosFiscalesNC[0].total + this.itemsDeduccionesCNF[0].total + this.itemsDeduccionesFNC[0].total + this.itemsIngresosCNF[0].total
                    this.itemsUtilidadPFAPTU[0].total = sumaTotal

                    //INGRESA AMORTIZACION DE PERDIDAS DEL EJERCICIO
                    //OBTIENE EL RESULTADO DEL EJERCICIO
                    var resultadoEj = sumaTotal - Number(this.itemsAmortizacionPEA[0].cantidad)
                    this.itemsAmortizacionPEA[1].total = resultadoEj

                    //DETERMINACION DE ISR ANUAL
                    var detISRA = resultadoEj * 0.30
                    this.itemsDetAnualTasaISR[0].total = detISRA

                    //ISR A PAGAR EN DECLARACION ANUAL
                    var isrAP = detISRA - Number(this.itemsPagosProvRet[0].cantidad)
                    this.itemsPagosProvRet[1].total = isrAP

                } catch (error) {
                    console.log(error)
                }

            },

        }

    }
</script>