<template>
    <div>
        <v-card>
            <v-card-title style="font: bold;" class="text-h4 text--primary">
                Catálogo de Cuentas
                <v-spacer></v-spacer>
                <!-- BOTON DE NUEVA CUENTA -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2" @click="abreDialogNuevaCuenta()"><v-icon>mdi-book-plus</v-icon></v-btn>
                    </template>
                    <span>Crear Cuenta</span>
                </v-tooltip>
                <!-- BOTON PARA CONSULTAR -->
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary" dark class="mb-2" @click="abreDialogExportar()"><v-icon>mdi-xml</v-icon></v-btn>
                    </template>
                    <span>Generar XML</span>
                </v-tooltip>
            </v-card-title>
            <v-card-text>
                <v-data-table :headers="headersCatalogo" :items="itemsCatalogo" class="elevation-1" hide-default-footer :items-per-page="-1">
                </v-data-table>
            </v-card-text>
        </v-card>
        <!-- Dialog loading -->
        <v-dialog v-model="loading" hide-overlay persistent width="300">
            <v-card color="primary" dark>
            <v-card-text>
                {{loadingMessage}}
                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
            </v-card>
        </v-dialog>
        <!-- DIALOG EXPORTA CATALOGO EN XML -->
        <v-dialog v-model="dialogExporta" hide-overlay persistent width="600">
            <v-card>
                <v-toolbar>
                    <!-- BOTON CERRAR -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-1" dark fab small @click="dialogExporta = false"
                                v-bind="attrs" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <b style="font-size: 30px;">Generar XML</b>
                    <v-spacer></v-spacer>
                    <!-- BOTON CONFIRMAR -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-1" dark fab small @click="PostCatalogoXml()"
                                v-bind="attrs" v-on="on">
                                <v-icon>mdi-check</v-icon>
                            </v-btn>
                        </template>
                        <span>Exportar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-card-text>
                    <br>
                    <v-row justify="space-around">
                        <v-date-picker
                          v-model="picker"
                          type="month"
                          color="primary lighten-1"
                          header-color="primary"
                        ></v-date-picker>
                      </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- DIALOG NUEVA CUENTA -->
        <v-dialog v-model="dialogNuevaCuenta" hide-overlay persistent width="600">
            <v-card>
                <v-toolbar>
                    <!-- BOTON CERRAR -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-1" dark fab small @click="dialogNuevaCuenta = false"
                                v-bind="attrs" v-on="on">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <b style="font-size: 30px;">Crear Cuenta Hijo</b>
                    <v-spacer></v-spacer>
                    <!-- BOTON CONFIRMAR -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-1" dark fab small @click="PostNuevaCuenta()"
                                v-bind="attrs" v-on="on">
                                <v-icon>mdi-check</v-icon>
                            </v-btn>
                        </template>
                        <span>Crear</span>
                    </v-tooltip>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-card-text>
                    <br>
                    <!-- SELECT DE LAS CUENTAS -->
                    <v-row justify="space-around">
                        <v-col cols="12" md="12" sm="12">
                            <v-autocomplete label="Número de cuenta padre"
                            v-model="$store.state.cuentaPadreStore" return-object :items="$store.state.listaCuentas" :loading="loadingCuentas" item-text= "cuenta_concatenada">
                            </v-autocomplete>

                        </v-col>
                    </v-row>
                    <!-- TEXTFIELD PARA EL NOMBRE DE LA NUEVA CUENTA -->
                    <v-row justify="space-around">
                        <v-col cols="12" md="12" sm="12">
                            <v-text-field label="Nombre de la nueva cuenta hijo" v-model="cuenta.nombre_cuenta" ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import print from 'print-js';
    import pdf from 'vue-pdf';
    import { saveAs } from 'file-saver';

    export default {
        name: 'Balanza',
        components: {
            
        },

        data: () => ({
            //LOADING
            loading: false,
            loadingMessage: '',

            //TABLE
            itemsCatalogo: [],
            headersCatalogo: [
                { text: "Número", value: "numeroCuenta" },
                { text: "Nombre", value: "nombreCuenta" },
                { text: "Naturaleza", value: "naturaleza" },
            ],

            //EXPORTA
            dialogExporta:false,
            picker: new Date().toISOString().substr(0, 7),
            // NUEVA CUENTA
            dialogNuevaCuenta:false,
            loadingCuentas: null,
            cuenta:{
                id_cuenta: 0,
                numero_cuenta: '',
                nombre_cuenta :'',
                numero_cuenta_unico: '',
                tipo: '',
                naturaleza: '',
                nivel: 0,
                cuenta_concatenada: '',
            },
        }),

        watch: {
            
        },

        computed: {
            empresa(){
                return this.$store.state.usuario
            },
            cuentaPadre(){
                return this.$store.state.cuentaPadreStore;
            },
        },

        created() {
            this.GetCatalogo();
            this.GetCuentas()
        },

        methods: {
            functionFormatPrice(value) {
                let val = (value / 1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            functionFormatDate(value){
                let fecha = moment(value).format('DD/MM/yyyy')
                return fecha;
            },
            async GetCatalogo(){
                this.loadingMessage = 'Consultando catálogo...'
                this.loading = true;
                try {
                    let response = await axios.get('ReportesGenerales/GetReporteCatalogoAsync/'+ this.empresa.empresaBase);
                    this.itemsCatalogo = response.data;
                    this.loading = false;
                } catch (error) {   
                    console.log(error);
                    this.loading = false;
                }
            },
            abreDialogExportar(){
                this.dialogExporta=true;
            },
            PostCatalogoXml(){
                let fecha = this.picker.split('-');
                let anio = fecha[0]
                let mes = fecha[1]
                let rfc = this.empresa.rfc
                var cabecera = '<catalogocuentas:Catalogo xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:catalogocuentas="http://www.sat.gob.mx/esquemas/ContabilidadE/1_3/CatalogoCuentas" xsi:schemaLocation="http://www.sat.gob.mx/esquemas/ContabilidadE/1_3/CatalogoCuentas http://www.sat.gob.mx/esquemas/ContabilidadE/1_3/CatalogoCuentas/CatalogoCuentas_1_3.xsd" Anio="'+ anio +'" Mes="'+ mes +'" RFC="'+ rfc +'" Version="1.3">'

                var pie = '</catalogocuentas:Catalogo>'
                var detalles = ''
                for(var c of this.itemsCatalogo){
                    // console.log(c)
                    if(c.nivel == 1){
                        let cuenta = '<catalogocuentas:Ctas Natur="'+c.naturaleza+'" Nivel="1" Desc="'+c.nombreCuenta+'" NumCta="'+c.numeroCuenta+'" CodAgrup="'+c.numeroCuenta+'"/>'
                        detalles =  detalles + cuenta+'\n'
                    }
                    else{
                        let splitCuenta = c.numeroCuenta.split('.'); 
                        let agrupador = splitCuenta[0] + '.' + splitCuenta[1]
                        let subCuenta = ''
                        let contador = splitCuenta.length
                        let nombre = c.nombreCuenta;
                        for(var s  = 0; s < contador - 1; s++){
                            subCuenta = subCuenta + splitCuenta[s] + '.'
                        }
                        subCuenta = subCuenta.substring(0, subCuenta.length-1)
                        nombre = nombre.replaceAll('&', '&amp;');
                        nombre = nombre.replaceAll('“', '&quot;');
                        nombre = nombre.replaceAll('<', '&lt;');
                        nombre = nombre.replaceAll('>', '&gt;');
                        nombre = nombre.replaceAll('‘', '&apos;');

                        let cuenta = '<catalogocuentas:Ctas Natur="'+c.naturaleza+'" Nivel="'+c.nivel+'" SubCtaDe="'+subCuenta+'" Desc="'+nombre+'" NumCta="'+c.numeroCuenta+'" CodAgrup="'+agrupador+'"/>'
                        detalles =  detalles + cuenta+'\n'
                    }
                }
                let nombreArchivo = this.empresa.rfc +  anio + mes + 'CT'
                this.downloadFile(cabecera+'\n' + detalles+'\n' +pie, nombreArchivo)
            },
            downloadFile(archivo, nombre) {
                let filename = nombre + '.xml';
                let text = archivo;

                let element = document.createElement('a');
                element.setAttribute('href', 'data:text/xml;charset=utf-8,' + encodeURIComponent(text));
                element.setAttribute('download', filename);

                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();

                document.body.removeChild(element);
            },

            abreDialogNuevaCuenta(){
                
                this.dialogNuevaCuenta=true;
            },

            async GetCuentas(){
                this.loadingMessage = 'Consultando Cuentas...'
                this.loading = true;
                try {
                    let response = await axios.get('AutoConta/Cuentaspadre?baseAC='+ this.empresa.empresaBase);
                    this.$store.state.listaCuentas = response.data;
                    console.log(response)
                    this.loading = false;
                } catch (error) {   
                    console.log(error);
                    this.loading = false;
                }
            },

            async PostNuevaCuenta(){
                let x = { ...this.cuentaPadre }
                let cuentaNueva = {
                    id_cuenta: x.id_cuenta,
                    numero_cuenta_unico: x.numero_cuenta_unico,
                    nombre_cuenta: this.cuenta.nombre_cuenta,
                    base_datos: this.empresa.empresaBase,
                    tipo: x.tipo,
                    naturaleza: x.naturaleza,
                    nivel: x.nivel
                }

                // console.log(cuentaNueva)
                this.loading = true
                this.loadingMessage = 'Guardando cuenta...'
                try {
                    console.log(cuentaNueva);
                    let response = await axios.post('AutoConta/CreaCuentaHijo/', cuentaNueva)
                    console.log(response.data)
                    await this.GetCatalogo()
                    this.loading = false
                    this.dialogNuevaCuenta = false
                } catch (error) {
                    console.log(error)
                    this.loading = false
                }
            },          
        },   }
</script>