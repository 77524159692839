<template>
    <div>
        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>

        <!--Dialog loading-->
        <v-dialog v-model="loading" hide-overlay persistent width="300">
            <v-card color="primary" dark>
            <v-card-text>
                {{loadingMessage}}
                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
            </v-card>
        </v-dialog>
        
        <v-card>
            <v-toolbar>
            <v-spacer></v-spacer>
            <b style="font-size: 30px;">Configuración</b>
            <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary darken-1" dark fab small @click="guardarOactualizar()"
                            v-bind="attrs" v-on="on">
                            <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                    </template>
                    <span>Guardar</span>
                </v-tooltip>
            </v-toolbar>
            <v-system-bar color="primary" height="6"></v-system-bar>
            <v-card-text>
                <v-row>

                <v-col cols="12" sm="12" md="6">
                    <v-row>
                    <v-col cols="12" sm="12" md="6">
                        <v-text-field v-model="empresa.rfc" label="RFC" variant="outlined"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <v-text-field v-model="empresa.domicilioFiscal" label="Código Postal" variant="outlined"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <v-text-field v-model="empresa.nombre" label="Nombre de la Empresa" variant="outlined"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <v-select v-model="empresa.regimenFiscal" label="Régimen Fiscal" return-object :items="itemsRegimenFiscal" item-text="regimenFiscal"></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <v-row>
                        <v-file-input label="File input" v-model="imagen" prepend-icon="mdi-camera" ></v-file-input>
                        <v-btn color="primary darken-1" @click="uploadImage" dark fab small>
                            <v-icon>mdi-check</v-icon>
                        </v-btn>
                    </v-row>

                    </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <v-row  class="justify-center">
                    <v-col class="justify-center ma-0 pa-0" cols="12" sm="12" md="6">
                        <v-img class="ma-0 pa-0"  :src="empresa.logo.base64"></v-img>
                    </v-col>
                </v-row>
                </v-col>
            </v-row>

                </v-card-text>
                        <span hidden>{{ usuario }}</span>
        </v-card>
    </div>
</template>                
<script>
import axios from 'axios';

export default {
    data() {
        return {
            snackbarMessage:'',
            snackbar:false,
            itemsRegimenFiscal: [],
            loadingMessage:'',
            loading:false,
imagen:null
        }
    },
    computed: {

        empresa() {
            return this.$store.state.empresaStore;
        },
        
        usuario() {
            return this.$store.state.usuario;
        },

    },

    created() {
        this.GetRegimenFiscal();
        this.GetEmpresa();
    },
    methods: {
        async GetRegimenFiscal() {
            try {
                let response = await axios.get("https://api-erp.contago.com.mx/api/CatalogosSat/GetRegimenFiscal/");
                console.log(response.data)
                this.itemsRegimenFiscal = response.data;

            } catch (error) {
                console.log(error);
            }
        },
        validaRegimen() {
            console.log(this.empresa.rfc)
            if (this.empresa.rfc.length === 13) {
                console.log('fisica')
                return this.itemsRegimenFiscal.filter(regimen => regimen.fisica === 'Sí');
            }
            if (this.empresa.rfc.length === 12) {
                console.log('moral')
                return this.itemsRegimenFiscal.filter(regimen => regimen.moral === 'Sí');
            }
        },
        async uploadImage() {
            // this.archivoKEY = {};

                let file = this.imagen;
                let reader = new FileReader();
                console.log(file)

                reader.onloadend = (file) => {
                    console.log(reader)
                    this.empresa.logo.base64 = reader.result;
                }
                reader.readAsDataURL(file);
        },
         
        guardarOactualizar() {
            console.log(this.empresa)
            if (this.empresa.idEmpresa == 0) {
                this.guardarConfig();
            } else {
                this.actualizarConfig();
            }
        },
        async guardarConfig() {
            this.empresa.usuarioCreador = this.usuario.nombre
            console.log(this.usuario.empresaBase)
            this.loading= true
            this.loadingMessage = 'Guardando Configuración'
            try {
                let response = await axios.post('https://api-erp.contago.com.mx/api/Empresas/GuardarConfiguracion/' + this.usuario.empresaBase, this.empresa)
                console.log(response.data)
            this.loading= false
            this.snackbarMessage = 'Información guardada..'
            this.snackbar= true

            } catch (error) {
                console.log(error)
            this.loading= false
this.snackbar= true
this.snackbarMessage = 'Error al guardar, verifique su información..'
            }
        },

        async actualizarConfig() {
            this.empresa.usuarioCreador = this.usuario.nombre
            this.loadingMessage = 'Actualizando Configuración'
            this.loading= true

            try {
                let response = await axios.post('https://api-erp.contago.com.mx/api/Empresas/ActualizarConfiguracion/' + this.usuario.empresaBase, this.empresa)
                console.log(response.data)
            this.loading= false
            this.snackbar= true
            this.snackbarMessage = 'Información Actualizada..'
            } catch (error) {
                console.log(error)
            this.loading= false
            this.snackbar= true
            this.snackbarMessage = 'Error al Actualizar, verifique su información..'
            }
        },

        async GetEmpresa() {
            try {
                let response = await axios.get('https://api-erp.contago.com.mx/api/Empresas/GetEmpresa/' + this.usuario.empresaBase);
                console.log(response.data)
                if (response.data.idEmpresa != 0) {
                    this.$store.state.empresaStore = response.data
                }
            } catch (error) {
                console.log(error);
            }
        },
         
         
         
 
    },
}
</script>

<style>

</style>