import jsPDF from 'jspdf';
import moment from 'moment'

function formatoCantidad(numero) {
    let respuesta = numero.toFixed(3);
    return respuesta;
}

function formatoImpuestos(numero) {
    let respuesta = numero.toFixed(4);
    return respuesta;
}

function formatoNumerico(value) {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

function formatoFecha(value) {
    moment.locale('es');
    return moment(value).format('DD-MMMM-YYYY HH:mm:ss')
}

function formatoFechaCorta(value) {
    moment.locale('es');
    return moment(value).format('DD-MMMM-YYYY')
}

const polizaBase64 = async (ObjLogo,  ObjColor, ObjPoliza, ObjEmpresa, exportar) => {
    return new Promise((resolve, reject) => {
        try{
            const doc = new jsPDF();
            let xCabecera = 5;
            let yCabecera = 15;

            //LOGO
            if(ObjLogo != ''){
                doc.addImage(ObjLogo, 'PNG', xCabecera, yCabecera, 58, 20);
            }

            //DATOS DE LA EMPRESA Y POLIZA
            doc.setFontSize(14);
            doc.setFont('helvetica', 'bold');
            doc.text(ObjEmpresa, 206, yCabecera, { align: 'right' });
            doc.text(ObjPoliza.tipoPoliza + ' | ' + ObjPoliza.numeroPoliza, 206, yCabecera += 5, { align: 'right' });
            doc.text(formatoFechaCorta(ObjPoliza.fecha), 206, yCabecera += 6, { align: 'right' });

            //DESCRIPCION
            doc.setFontSize(9);
            doc.setFont('helvetica', 'bold')      
            doc.text('Descripción', 110, yCabecera += 12, { align: 'center' });
            doc.setFont('helvetica', 'normal');
            doc.text(ObjPoliza.descripcion, 110, yCabecera += 5, { align: 'center' });

            //ASIENTOS
            var columnas = ['Número de cuenta', 'Cargo', 'Abono'];
            var filas = ObjPoliza.detalles.map(objeto => [objeto.cuentaContable, formatoNumerico(objeto.cargo), formatoNumerico(objeto.abono)]);
            var columnWidths = [130, 34, 34];
            var posicionInicial = yCabecera += 5
            doc.autoTable({
                startY: posicionInicial,
                margin: 6,
                head: [columnas],
                body: filas,
                headStyles:{ fillColor: ObjColor, fontSize:12 },
                columnStyles: { 0: { cellWidth: columnWidths[0] }, 1: { cellWidth: columnWidths[1], halign: 'right' }, 2: { cellWidth: columnWidths[2], halign: 'right' } },
                didParseCell: function (data) {
                    var rowIndex = data.row.index;
                    var columnIndex = data.column.index;
                    var totalColumna2 = 0;
                    var totalColumna3 = 0;

                    // // Calcular los totales de la columna 2 y columna 3
                    ObjPoliza.detalles.forEach(objeto => {
                        totalColumna2 += parseFloat(objeto.cargo);
                        totalColumna3 += parseFloat(objeto.abono);
                      });

                    // Agregar los totales en las últimas filas de la tabla
                    if (rowIndex === ObjPoliza.detalles.length - 1) {
                    if (columnIndex === 0) {
                        data.cell.text = 'Sumas iguales';
                    }
                    if (columnIndex === 1) {
                        data.cell.text = formatoNumerico(totalColumna2);
                    }
                    if (columnIndex === 2) {
                        data.cell.text = formatoNumerico(totalColumna3);
                    }
                    }

                    // // Verificar si es el último renglón
                    if (rowIndex === ObjPoliza.detalles.length - 1) {
                      data.cell.styles.fontStyle = 'bold';
                      data.cell.styles.fontSize = 12
                    }
                  }
            });

            var alturaTabla = doc.previousAutoTable.finalY;
            yCabecera = alturaTabla + 10
            doc.setFontSize(9);
            doc.setFont('helvetica', 'bold')      
            doc.text("CFDI'S Relacionados", 100, yCabecera, { align: 'center' });

            //XML RELACIONADOS
            var columnasXml = ['Serie', 'Folio', 'Fecha', 'RFC', 'Nombre', 'SubTotal', 'Total', 'Moneda', 'Folio Fiscal'];
            if(ObjPoliza.tipoPoliza === 'Egreso'){
                var filasXml = ObjPoliza.xmls.map(objeto => [objeto.serie, objeto.folio, formatoFechaCorta(objeto.fecha), objeto.rfcEmisor, objeto.nombreEmisor, formatoNumerico(objeto.subTotal), formatoNumerico(objeto.total), objeto.moneda, objeto.folioFiscal]);
            }else if(ObjPoliza.tipoPoliza === 'Ingreso'){
                var filasXml = ObjPoliza.xmls.map(objeto => [objeto.serie, objeto.folio, formatoFechaCorta(objeto.fecha), objeto.rfcReceptor, objeto.nombreReceptor, formatoNumerico(objeto.subTotal), formatoNumerico(objeto.total), objeto.moneda, objeto.folioFiscal]);
            }

            var columnWidthsXml = [10, 10, 15, 25, 40, 18, 18 ,15];
            var posicionInicialXml = yCabecera += 5
            doc.autoTable({
                startY: posicionInicialXml,
                margin: 6,
                head: [columnasXml],
                body: filasXml,
                styles: { fontSize:7 },
                headStyles:{ fillColor: ObjColor },
                columnStyles: { 0: { cellWidth: columnWidthsXml[0] }, 1: { cellWidth: columnWidthsXml[1] }, 2: { cellWidth: columnWidthsXml[2] }, 3: { cellWidth: columnWidthsXml[3] }, 4: { cellWidth: columnWidthsXml[4] }, 5: { cellWidth: columnWidthsXml[5] }, 6: { cellWidth: columnWidthsXml[6] }, 7: { cellWidth: columnWidthsXml[7] } },
            });

            //AGREGAMOS EL PIE DE PAGINA
            var totalPages = doc.internal.getNumberOfPages();
            for (var i = 1; i <= totalPages; i++) {
                doc.setPage(i);
                // Establecer la fuente y el estilo del pie de página
                doc.setFont("helvetica", "italic");
                doc.setFontSize(10);
                doc.setTextColor(150);

                // Agregar el contenido del pie de página
                doc.text('Documento generado por ContaGo', 10, doc.internal.pageSize.getHeight() - 10, { align: 'left' });
                doc.text('Página ' + i + ' de ' + totalPages, 200, doc.internal.pageSize.getHeight() - 10, { align: 'right' });
            }

            let mes_ = moment(ObjPoliza.fecha).format('MMMM')
            let anio_ = moment(ObjPoliza.fecha).format('YYYY')
            let nombre = mes_ + '_' + anio_ + '_' + ObjPoliza.tipoPoliza + '_' + ObjPoliza.numeroPoliza


            const base64 = doc.output('datauristring');
            if(exportar == 'UNITARIO'){
                doc.save(nombre + '.pdf');

            }
            resolve(base64);
        }catch(error){
            console.log(error)
            reject(error);
        }
    });
};

export { polizaBase64 };