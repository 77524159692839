var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"text-h4 text--primary",staticStyle:{"font":"bold"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"large":"","icon":"","color":"primary","dark":"","link":"","to":{ name: 'ListReportesEspeciales' }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)]}}])},[_c('span',[_vm._v("Atrás")])]),_vm._v(" Provisionales de IVA "),_c('v-spacer'),_c('v-select',{attrs:{"items":_vm.itemsAnios,"label":"Año del ejercicio","outlined":""},model:{value:(_vm.anios),callback:function ($$v) {_vm.anios=$$v},expression:"anios"}}),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4 mb-7",attrs:{"color":"primary","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.GetReporte()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}])},[_c('span',[_vm._v("Buscar")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-7",attrs:{"color":"primary darken-1","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.generarPDF()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-pdf-box")])],1)]}}])},[_c('span',[_vm._v("PDF")])]),_c('xlsx-workbook',[_vm._l((_vm.sheets),function(sheet){return _c('xlsx-sheet',{key:sheet.name,attrs:{"collection":_vm.collection,"sheet-name":sheet.name}})}),_c('xlsx-download'),_c('xlsx-download',{attrs:{"filename":_vm.nombreReporte}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-7",attrs:{"color":"primary darken-1","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.GeneraExcel()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)]}}])},[_c('span',[_vm._v("Generar Excel")])])],1)],2)],1),_c('v-card-text',[_c('br'),_c('h2',[_vm._v("PROVISIONALES DE IVA")]),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}}),_c('v-data-table',{attrs:{"items":_vm.itemsReporteProvIva,"headers":_vm.headersReporteProvIva,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.ingresos",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.ingresos)))])]}},{key:"item.productosFinancieros",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.productosFinancieros)))])]}},{key:"item.ventasNetas",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.ventasNetas)))])]}},{key:"item.ivaVentas",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.ivaVentas)))])]}},{key:"item.impuestosTrasladadosCobrados",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.impuestosTrasladadosCobrados)))])]}},{key:"item.proveedores",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.proveedores)))])]}},{key:"item.acreedores",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.acreedores)))])]}},{key:"item.sumaPagos",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.sumaPagos)))])]}},{key:"item.comprasIvaPagadas",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.comprasIvaPagadas)))])]}},{key:"item.impuestosAcreditablesPagados",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.impuestosAcreditablesPagados)))])]}},{key:"item.determinacionProvIvaC",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.determinacionProvIvaC)))])]}},{key:"item.determinacionProvIvaF",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.determinacionProvIvaF)))])]}}])})],1),_c('v-card-actions')],1),_c('v-dialog',{attrs:{"persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.loadingMessage)+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }