var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"text-h4 text--primary",staticStyle:{"font":"bold"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"large":"","icon":"","color":"primary","dark":"","link":"","to":{ name: 'ListReportes' }},on:{"click":function($event){return _vm.nuevaPoliza()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)]}}])},[_c('span',[_vm._v("Atrás")])]),_vm._v(" Pérdidas de ejercicios anteriores "),_c('v-spacer'),_c('v-select',{attrs:{"items":_vm.itemsAnios,"label":"Año del ejercicio","outlined":""},model:{value:(_vm.anios),callback:function ($$v) {_vm.anios=$$v},expression:"anios"}}),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-text-field',{attrs:{"label":"Importe","type":"number"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.agregarATabla()}},model:{value:(_vm.importe),callback:function ($$v) {_vm.importe=$$v},expression:"importe"}}),_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(_vm.importe)))])],1),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"icon":"","color":"primary","dark":""},on:{"click":function($event){return _vm.agregarATabla()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-checkbox-marked-circle-plus-outline")])],1)]}}])},[_c('span',[_vm._v("Agregar")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"icon":"","color":"primary","dark":""},on:{"click":function($event){return _vm.calculaReporte()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calculator")])],1)]}}])},[_c('span',[_vm._v("Calcular")])])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.itemsReporte,"headers":_vm.headersReporte,"hide-default-footer":"","items-per-page":-1,"id":"mytable"},scopedSlots:_vm._u([{key:"item.importe",fn:function(ref){
var item = ref.item;
return [(item.importe === '=')?_c('b',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(_vm.functionFormatPrice(item.importe)))]):_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.importe)))])]}},{key:"item.importeActualizado",fn:function(ref){
var item = ref.item;
return [(item.importeActualizado === '=')?_c('b',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(_vm.functionFormatPrice(item.importeActualizado)))]):_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.importeActualizado)))])]}}])})],1)],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.loadingMessage)+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }